import {
  Box,
  Checkbox,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";

import NewOrderModal from "../../CommonModals/NewOrderModal/NewOrderModal";

function EnhancedTableHead(props) {
  const { order, orderBy, headCells } = props;

  return (
    <TableHead sx={{ position: "relative" }}>
      <TableRow className="table-no-padding">
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              width: "max-content",
              ...headCell?.sx,
            }}
          >
            <Typography
              fontWeight={"bold"}
              fontSize={16}
              color={headCell.id === "actions" ? "#fff" : "primary.dark"}
            >
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: 0,
  width: "max-content",
}));

export default function EnhancedTable({
  rows,
  headCells,
  tableTitle,
  loading,
  selectRow,
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleEdit = (_row) => {
    setSelected(_row);
    setOpenEdit(true);
  };

  return (
    <Box mb={4} sx={{ width: "100%" }}>
      <NewOrderModal
        title={"Edit Trade"}
        order={selected}
        open={openEdit}
        handleClose={(_) => setOpenEdit(false)}
      />
      <Paper sx={{ width: "100%", position: "relative" }}>
        <Box sx={{ position: "absolute", right: 20, top: 10 }}>
          <Typography
            variant={"small"}
            component={"small"}
            sx={{ color: "red", fontWeight: "normal" }}
          >
            Select Orders to Cancel
          </Typography>{" "}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            p: 2,
            pt: 4,
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <TableContainer>
              <Table size={"medium"}>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  headCells={headCells}
                />
                <TableBody>
                  {Object.keys(rows).map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row._id}
                      >
                        <StyledTableCell component="th" scope="row">
                          {rows[row]?.orderNumber}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {rows[row]?.mkt}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {rows[row]?.ticker}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {rows[row]?.bidAsk}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {rows[row]?.description}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {rows[row]?.quantity}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {rows[row]?.price}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {rows[row]?.total}
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="center"
                        >
                          <Typography
                            sx={(theme) => ({
                              color: theme.palette.primary.main,
                              fontWight: "bold",
                              textDecoration: "underline",
                            })}
                            onClick={(_) => handleEdit(row)}
                          >
                            Edit
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="center"
                        >
                          <Checkbox
                            onChange={(e) => selectRow(e, index, rows[row])}
                          />
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Paper>
    </Box>
  );
}
