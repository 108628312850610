import { Box, Button, TextField, Typography, styled } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider, TimePicker } from "@mui/lab";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import React from "react";

function ScheduleForm({ formData, setFormData, onChange, handleSubmit }) {
  const OptionHeading = styled(Typography)({
    fontSize: 18,
    fontWeight: "bold",
    color: "#5F99FF",
  });

  const onDateChange = (label, date) =>
    setFormData({ ...formData, [label]: date });

  React.useEffect(() => console.log(formData), [formData]);

  return (
    <Box
      component={"form"}
      onSubmit={(e) => handleSubmit(e)}
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        rowGap: 5,
        alignItems: "flex-end",
      }}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          width: "90%",
          rowGap: 2,
          [theme.breakpoints.up("xl")]: {
            rowGap: 4,
          },
        })}
      >
        <Box display={"flex"} gap={4} alignItems={"center"} width={"100%"}>
          <OptionHeading>Option 1</OptionHeading>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Date"
              inputFormat="MM/dd/yyyy"
              onChange={(date) => onDateChange("optionOneDate", date)}
              value={formData?.optionOneDate}
              renderInput={(params) => (
                <TextField className="calender-icon" {...params} />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              value={formData?.optionOneTime}
              onChange={(time) => onDateChange("optionOneTime", time)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          to
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              value={formData?.optionOneTime}
              onChange={(time) => onDateChange("optionOneTime", time)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>
        <Box display={"flex"} alignItems={"center"} gap={4} width={"100%"}>
          <OptionHeading>Option 2</OptionHeading>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Date"
              inputFormat="MM/dd/yyyy"
              onChange={(date) => onDateChange("optionTwoDate", date)}
              value={formData?.optionTwoDate}
              renderInput={(params) => (
                <TextField className="calender-icon" {...params} />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              value={formData?.optionOneTime}
              onChange={(time) => onDateChange("optionOneTime", time)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          to
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              value={formData?.optionOneTime}
              onChange={(time) => onDateChange("optionOneTime", time)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>
        <Box display={"flex"} gap={4} alignItems={"center"} width={"100%"}>
          <OptionHeading>Option 3</OptionHeading>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Date"
              inputFormat="MM/dd/yyyy"
              onChange={(date) => onDateChange("optionThreeDate", date)}
              value={formData?.optionThreeDate}
              renderInput={(params) => (
                <TextField className="calender-icon" {...params} />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              value={formData?.optionOneTime}
              onChange={(time) => onDateChange("optionOneTime", time)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          to
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              value={formData?.optionOneTime}
              onChange={(time) => onDateChange("optionOneTime", time)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>
      </Box>
      <Box
        width={"10%"}
        alignItems={"center"}
        justifyContent={"flex-end"}
        display={"flex"}
      >
        <Button
          variant={"contained"}
          color={"primary"}
          sx={{
            px: 2,
            Py: 1,
          }}
          type={"submit"}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
}

export default ScheduleForm;
