import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { StyledButton } from "../Styled/Globals";

function ExternalSettings({ SVG, title, link }) {
  return (
    <Box>
      <Box
        component={"div"}
        marginTop={2}
        marginBottom={1}
        display="flex"
        alignItems={"center"}
      >
        <SVG
          style={{
            marginRight: 40,
            transform: "scale(1.5)",
          }}
        />
        <Box>
          <Typography fontWeight={"bold"} fontSize={22}>
            {title}
          </Typography>
          <Link to={link}>
            <StyledButton
              sx={{
                fontSize: 18,
                paddingY: 0,
                height: "auto",
                marginTop: 3,
              }}
            >
              Go to {title}
            </StyledButton>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default ExternalSettings;
