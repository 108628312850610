import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import Hexagon from "../../components/Hexagon/Hexagon";
import { StyledContainer } from "../../components/Styled/Globals";
import { ReactComponent as FundingSVG } from "../../assets/funding.svg";
import FundingComponent from "../../components/Funding/FundingComponent";
import SearchField from "../../components/Global/SearchField";
import FundingTable from "../../components/Funding/FundingTable";
import FundingInfoModal from "../../components/Funding/FundingInfoModal";
import RequestCheckModal from "../../components/Funding/RequestCheckModal";

function Funding() {
  const _headcells = [
    {
      id: "fundId",
      numeric: true,
      disablePadding: true,
      label: "Fund ID",
    },
    {
      id: "date",
      numeric: true,
      disablePadding: true,
      label: "Date",
    },
    {
      id: "source",
      numeric: false,
      disablePadding: true,
      label: "Source",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: true,
      label: "Status",
    },
    {
      id: "amount",
      numeric: true,
      disablePadding: true,
      label: "Amount",
    },
  ];

  const fundingData = [
    {
      fundId: "45675",
      date: Date.now(),
      source: "ACH",
      status: "Initiated",
      amount: 1000,
    },
    {
      fundId: "45675",
      date: Date.now(),
      source: "Credit",
      status: "Received",
      amount: 1000,
    },
    {
      fundId: "45675",
      date: Date.now(),
      source: "Check",
      status: "Available",
      amount: 1000,
    },

    {
      fundId: "45675",
      date: Date.now(),
      source: "Money Order",
      status: "Initiated",
      amount: 1000,
    },
    {
      fundId: "45675",
      date: Date.now(),
      source: "ACH",
      status: "Initiated",
      amount: 1000,
    },
    {
      fundId: "45675",
      date: Date.now(),
      source: "Credit",
      status: "Received",
      amount: 1000,
    },
    {
      fundId: "45675",
      date: Date.now(),
      source: "Check",
      status: "Available",
      amount: 1000,
    },

    {
      fundId: "45675",
      date: Date.now(),
      source: "Money Order",
      status: "Initiated",
      amount: 1000,
    },
    {
      fundId: "45675",
      date: Date.now(),
      source: "ACH",
      status: "Initiated",
      amount: 1000,
    },
    {
      fundId: "45675",
      date: Date.now(),
      source: "Credit",
      status: "Received",
      amount: 1000,
    },
    {
      fundId: "45675",
      date: Date.now(),
      source: "Check",
      status: "Available",
      amount: 1000,
    },

    {
      fundId: "45675",
      date: Date.now(),
      source: "Money Order",
      status: "Initiated",
      amount: 1000,
    },
    {
      fundId: "45675",
      date: Date.now(),
      source: "ACH",
      status: "Initiated",
      amount: 1000,
    },
    {
      fundId: "45675",
      date: Date.now(),
      source: "Credit",
      status: "Received",
      amount: 1000,
    },
    {
      fundId: "45675",
      date: Date.now(),
      source: "Check",
      status: "Available",
      amount: 1000,
    },

    {
      fundId: "45675",
      date: Date.now(),
      source: "Money Order",
      status: "Initiated",
      amount: 1000,
    },
    {
      fundId: "45675",
      date: Date.now(),
      source: "ACH",
      status: "Initiated",
      amount: 1000,
    },
    {
      fundId: "45675",
      date: Date.now(),
      source: "Credit",
      status: "Received",
      amount: 1000,
    },
    {
      fundId: "45675",
      date: Date.now(),
      source: "Check",
      status: "Available",
      amount: 1000,
    },

    {
      fundId: "45675",
      date: Date.now(),
      source: "Money Order",
      status: "Initiated",
      amount: 1000,
    },
  ];

  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [openRequestCheckModal, setOpenRequestCheckModal] = useState(false);

  return (
    <Box height={"100%"}>
      <StyledContainer>
        <Hexagon
          width={560}
          title={
            <Box
              display="flex"
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={1.5}
            >
              <FundingSVG
                style={{
                  transform: "scale(0.7)",
                }}
              />
              <Typography color={"primary"} fontWeight={"bold"} fontSize={22}>
                Account Funding &#38; Withdrawal{" "}
              </Typography>
            </Box>
          }
        />
        <Box mt={3}>
          <FundingComponent
            title={"Add Funding Accounts"}
            cards={[
              {
                title: "Add a Bank Account",
              },
              {
                title: (
                  <Typography
                    textAlign={"center"}
                    fontSize={20}
                    fontWeight={"bold"}
                  >
                    Add a Credit <br />
                    or Debit Card
                  </Typography>
                ),
              },
            ]}
          />
          <br />
          <FundingComponent
            title={"Update Funding Accounts"}
            cards={[
              {
                title: "Update Bank Account",
              },
              {
                title: (
                  <Typography
                    textAlign={"center"}
                    fontSize={20}
                    fontWeight={"bold"}
                  >
                    Update a Credit <br />
                    or Debit Card
                  </Typography>
                ),
              },
            ]}
          />
          <br />
          <FundingComponent
            title={"Deposit Funds"}
            cards={[
              {
                title: "Deposit Funds from Bank Account",
                onClick: null,
              },
              {
                title: (
                  <Typography
                    textAlign={"center"}
                    fontSize={20}
                    fontWeight={"bold"}
                  >
                    Deposit Funds
                    <br />
                    Credit Cards
                  </Typography>
                ),
                onClick: null,
              },
              {
                title: (
                  <Typography
                    textAlign={"center"}
                    fontSize={20}
                    fontWeight={"bold"}
                  >
                    Send a Check <br />
                    or Money Order
                  </Typography>
                ),

                onClick: (_) => setOpenInfoModal(true),
              },
            ]}
          />
          <br />
          <FundingComponent
            title={"Withdrawal Funds"}
            cards={[
              {
                title: "Withdrawal Funds to Bank Account",
              },
              {
                title: "Request a Check",
                onClick: () => setOpenRequestCheckModal(true),
              },
            ]}
          />
        </Box>
        <Grid container spacing={2} my={5}>
          <Grid item md={6}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography fontSize={28} color={"text.dark"} fontWeight={"bold"}>
                Funds In
              </Typography>
              <SearchField width={350} noMargin title={"Search"} />
            </Box>
            <Box component={"div"} my={5}>
              <FundingTable
                rows={fundingData}
                headCells={_headcells}
                tableTitle={null}
                loading={false}
              />
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography fontSize={28} color={"text.dark"} fontWeight={"bold"}>
                Funds Out
              </Typography>
              <SearchField width={350} noMargin title={"Search"} />
            </Box>
            <Box component={"div"} my={5}>
              <FundingTable
                rows={fundingData}
                headCells={_headcells}
                tableTitle={null}
                loading={false}
              />
            </Box>
          </Grid>
        </Grid>
      </StyledContainer>
      <FundingInfoModal
        open={openInfoModal}
        handleClose={() => setOpenInfoModal(false)}
      />
      <RequestCheckModal
        open={openRequestCheckModal}
        handleClose={() => setOpenRequestCheckModal(false)}
      />
    </Box>
  );
}

export default Funding;
