import {
  Box,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";

import { ReactComponent as ArchiveSVG } from "../../../assets/archive.svg";
import CustomPagination from "../../../components/Common/CustomPagination";
import { ReactComponent as GearSVG } from "../../../assets/gears.svg";
import { ReactComponent as MarkAsReadSVG } from "../../../assets/mark-as-read.svg";
import NewOrderModal from "../../../components/CommonModals/NewOrderModal/NewOrderModal";
import PropTypes from "prop-types";
import TradeDetailsModal from "../../../components/CommonModals/TradeDetailsModal/TradeDetailsModal";
import { visuallyHidden } from "@mui/utils";

// import CancelOrderSelectionModal from "../../../components/MultipleTradesManagement/CancelMultipleOrders/CancelOrderConfirmationModal";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            sx={{
              padding: "4px 0px",
              pl: index === 0 && 1,
              borderRadius: index === 0 && 10,
              width: headCell?.width || 100,
              verticalAlign: "bottom",
              textAlign: "left",
              background: headCell?.id === "action" && "#5f99ff",
              borderTopRightRadius: 5,
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell?.disableSorting ? (
              <Box
                component={"div"}
                sx={{ width: "100%", display: "flex", ...headCell?.sx }}
              >
                {headCell?.label}
              </Box>
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                sx={(theme) => ({
                  fontSize: "small",
                  padding: "5px 0px",
                  display: "flex",
                  lineHeight: 1.5,
                  width: "100%",
                  justifyContent: "flex-start",
                  columnGap: 1,
                  color: theme?.palette?.primary?.main,
                  "& .MuiTableSortLabel-icon": {
                    margin: "0px",
                    fontSize: "small",
                  },
                  [theme.breakpoints.up("xl")]: {
                    fontSize: "medium",
                    "& .MuiTableSortLabel-icon": {
                      fontSize: "medium",
                    },
                  },
                  ...headCell?.sx,
                })}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme, center }) => ({
  padding: "10px 0px",
  paddingRight: "5px",
  fontSize: "14px",
  textAlign: center ? "center" : "left",
  border: "none",
  [theme.breakpoints.up("xl")]: {
    fontSize: "16px",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: "100%",
  fontSize: "14px !important",
  [theme.breakpoints.up("xl")]: {
    fontSize: "16px !important",
  },
}));

export default function EnhancedTable({
  rows,
  headCells,
  tableTitle,
  loading,
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [page, setPage] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setCurrent(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [current, setCurrent] = React.useState(null);
  const [openDetails, setOpenDetails] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openCancel, setOpenCancel] = React.useState(false);
  const [selected, setSelected] = useState(null);

  const handleDetails = (row) => {
    setSelected(row);
    setOpenDetails(true);
    handleClose();
  };

  const handleEdit = (row) => {
    setSelected(row);
    handleClose();
    setOpenEdit(true);
  };

  const handleCancel = (row) => {
    setSelected(row);
    handleClose();
    setOpenCancel(true);
  };

  return (
    <Box mb={4} sx={{ width: "100%" }}>
      <TradeDetailsModal
        open={openDetails}
        data={selected}
        handleClose={(_) => setOpenDetails(false)}
      />

      <NewOrderModal
        title={"Edit Order"}
        open={openEdit}
        handleClose={(_) => {
          setOpenEdit(false);
          setSelected(null);
        }}
        editData={selected}
      />

      <TradeDetailsModal
        open={openCancel}
        handleClose={(_) => {
          setOpenCancel(false);
          setSelected(null);
        }}
        data={selected}
        variant={"cancel"}
        title="Cancel Trade"
      />

      <Paper sx={{ width: "100%", bgcolor: "#fff", mb: 2, borderRadius: 3 }}>
        <Box display="flex" justifyContent={"center"} alignContent={"center"}>
          {loading ? (
            <CircularProgress />
          ) : (
            <TableContainer
              sx={{
                minHeight: "360px",
              }}
            >
              <Table sx={{ minWidth: 750 }} size={"small"}>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  headCells={headCells}
                />
                <TableBody>
                  {rows.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={10}>
                        <Typography align="center">No Trades</Typography>
                      </TableCell>
                    </TableRow>
                  ) : null}

                  {stableSort(tableData, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      // const objectKeys = Object.keys(row);
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row._id}
                        >
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                            sx={{
                              pl: 1,
                            }}
                          >
                            {row?.orderNumber}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.mkt}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.ticker}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.bidAsk}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.description}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.quantity}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.price}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.total}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            <StyledButton
                              variant={"contained"}
                              color={"primary"}
                              size={"small"}
                              disableElevation
                              onClick={(_) => handleDetails(row)}
                            >
                              Details
                            </StyledButton>
                          </StyledTableCell>
                          <TableCell
                            component="td"
                            id={labelId}
                            scope="row"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              border: "none",
                            }}
                          >
                            <GearSVG
                              onClick={(e) => handleClick(e, index)}
                              className={"pointer"}
                            />
                            <Menu
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={open && current === index}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                              sx={{
                                minWidth: 155,
                              }}
                            >
                              <MenuItem
                                onClick={(_) => handleEdit(row)}
                                sx={{
                                  width: 155,
                                }}
                              >
                                <Box
                                  display={"flex"}
                                  justifyContent={"left"}
                                  alignItems="center"
                                  width={"100%"}
                                >
                                  <MarkAsReadSVG style={{ marginRight: 10 }} />
                                  <Typography>Edit</Typography>
                                </Box>
                              </MenuItem>
                              <MenuItem
                                onClick={(_) => handleCancel(row)}
                                sx={{
                                  width: 155,
                                }}
                              >
                                <Box
                                  display={"flex"}
                                  justifyContent={"left"}
                                  alignItems="center"
                                  width={"100%"}
                                >
                                  <ArchiveSVG style={{ marginRight: 10 }} />
                                  <Typography>Cancel</Typography>
                                </Box>
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
      <CustomPagination
        rowsPerPage={rowsPerPage}
        rows={rows}
        setTableData={setTableData}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
}
