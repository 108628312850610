import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Modal,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import AuthLabel from "../Styled/AuthLabel";
import { AuthModalButton } from "../Styled/Buttons";
import { Close } from "@mui/icons-material";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ReactComponent as SignUpSVG } from "../../assets/auth/sign-up.svg";
import logo from "../../assets/bullion-logo.png";
import { useTheme } from "@mui/material/styles";

const SignUpModal = ({ open, handleClose }) => {
  const [data, setData] = useState({});
  const handleSignUp = (e) => {
    e.preventDefault();
  };
  const handleOnChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const theme = useTheme();
  const isXL = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <Modal open={open} onClose={handleClose} className={"modal"}>
      <Box
        style={{
          width: 600,
        }}
        className={"no-scroll-bar"}
      >
        <Paper
          sx={{
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alginItems: "center",
              position: "relative",
              pt: 2,
            }}
          >
            <img
              src={logo}
              alt="bullion-logo"
              width={isXL ? 135 : 120}
              style={{
                display: "block",
              }}
            />
            <Close className={"c-absolute"} onClick={handleClose} />
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            ml={"10%"}
          >
            <SignUpSVG
              style={{
                transform: "scale(0.7)",
              }}
            />
            <Typography
              color={"#000"}
              fontSize={isXL ? 24 : 22}
              fontWeight="bold"
            >
              Sign Up for Bullion MKTS Account
            </Typography>
          </Box>
          <Box
            component={"form"}
            onSubmit={handleSignUp}
            sx={{
              px: 2,
              pb: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item md={4}>
                <AuthLabel
                  label="First Name"
                  type="text"
                  name={"firstName"}
                  value={data?.firstName}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item md={4}>
                <AuthLabel
                  label="Middle Name"
                  type="text"
                  name={"middleName"}
                  value={data?.middleName}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item md={4}>
                <AuthLabel
                  label="Last Name"
                  type="text"
                  name={"lastName"}
                  value={data?.lastName}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item md={12}>
                <AuthLabel
                  label="Physical Address"
                  type="text"
                  name={"physicalAddress"}
                  value={data?.physicalAddress}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item md={4}>
                <AuthLabel
                  label="City"
                  type="text"
                  name={"city"}
                  value={data?.city}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item md={4}>
                <AuthLabel
                  label="State"
                  type="text"
                  name={"state"}
                  value={data?.state}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item md={4}>
                <AuthLabel
                  label="Zip Code"
                  type="text"
                  name={"zipCode"}
                  value={data?.zipCode}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item md={4}>
                <AuthLabel
                  label="Email"
                  type="email"
                  name={"email"}
                  value={data?.email}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item md={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Date of Birth"
                    inputFormat="MM/dd/yyyy"
                    value={data?.dob}
                    inputProps={{
                      style: {
                        fontSize: isXL ? 16 : 14,
                      },
                    }} // font size of input text
                    InputLabelProps={{
                      style: {
                        fontSize: isXL ? 16 : 14,
                      },
                    }} // font size of input label
                    onChange={handleOnChange}
                    renderInput={(params) => (
                      <AuthLabel
                        className={"calender-icon"}
                        fullWidth
                        size="small"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={4}>
                <AuthLabel
                  label="Display Name"
                  type="text"
                  name={"displayName"}
                  value={data?.displayName}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item md={6}>
                <Box display={"flex"} flexDirection={"column"}>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label={
                      <Typography variant={"small"} fontSize={14}>
                        {"Terms & Conditions"}
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label={
                      <Typography variant={"small"} fontSize={14}>
                        Privacy Policy
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label={
                      <Typography variant={"small"} fontSize={14}>
                        User Agreement
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label={
                      <Typography variant={"small"} fontSize={14}>
                        Bullion MKTS Policies
                      </Typography>
                    }
                  />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box
                  component={"div"}
                  display="flex"
                  justifyContent={"flex-end"}
                  alignItems={"flex-end"}
                  height={"100%"}
                >
                  <AuthModalButton
                    variant={"contained"}
                    disableElevation
                    sx={{
                      fontSize: isXL ? "unset" : 14,
                    }}
                  >
                    Submit
                  </AuthModalButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <Box display={"flex"} width="100%">
          <Box
            sx={{
              // bgcolor: "#2152a9",
              paddingTop: 2,
              paddingBottom: 2,
              borderEndEndRadius: 4,
              borderEndStartRadius: 4,
            }}
            className={"modal-footer"}
          >
            <Box className={"modal-arrow"}></Box>
            <Typography fontWeight={"bold"} textAlign="center" color={"#fff"}>
              Bullion MKTS, LLC 2022
            </Typography>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default SignUpModal;
