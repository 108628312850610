import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";

import CustomPagination from "../../Common/CustomPagination";
import MarketModal from "./NewMarketModal/MarketModal";
import moment from "moment";

function EnhancedTableHead(props) {
  return (
    <TableHead
      sx={{
        borderRadius: 10,
      }}
    >
      <TableRow
        sx={{
          borderRadius: 10,
        }}
      >
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            sx={(theme) => ({
              color: theme.palette.primary.dark,
              textDecoration: "underline",
              paddingY: 2,
              paddingX: "5px",
              maxWidth: 100,
              borderRadius: 10,
              fontSize: "18px",
            })}
          >
            <Typography fontWeight={"bold"}>{headCell.label}</Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: "none",
  padding: "8px 5px",
  fontSize: "15px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "16px",
  },
}));

export default function NewMarketRequestsTable({ rows, loading }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [detailsData, setDetailsData] = useState(null);
  const [tableData, setTableData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (_data) => {
    setOpenDetailsModal(true);
    setDetailsData(_data);
  };

  return (
    <Box mb={4} sx={{ width: "100%" }}>
      <MarketModal
        open={openDetailsModal}
        handleClose={(_) => {
          setOpenDetailsModal(false);
          setDetailsData(null);
        }}
        variant="details"
        data={detailsData}
        setData={setDetailsData}
        title={"Market Request Details"}
        onChange={(_) => console.log(_)}
      />
      <Box
        sx={{
          width: "100%",
          bgcolor: "#fff",
          borderRadius: 5,
          pb: 1,
          border: "1px solid #c4c4c4",
          px: 2,
        }}
      >
        <Box display="flex" justifyContent={"center"} alignContent={"center"}>
          {loading ? (
            <CircularProgress />
          ) : (
            <TableContainer>
              <Table sx={{ border: "none" }}>
                <EnhancedTableHead headCells={headCells} />
                <TableBody>
                  {tableData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          tabIndex={-1}
                          hover
                          key={row._id}
                          onClick={(_) => handleClick(row)}
                          sx={{
                            border: "none",
                            cursor: "pointer",
                          }}
                        >
                          <StyledTableCell component="td" scope="row">
                            {row?.requester}
                          </StyledTableCell>
                          <StyledTableCell component="td" scope="row">
                            {row?.mkt}
                          </StyledTableCell>
                          <StyledTableCell component="td" scope="row">
                            {row?.description}
                          </StyledTableCell>
                          <StyledTableCell component="td" scope="row">
                            {row?.mint}
                          </StyledTableCell>
                          <StyledTableCell component="td" scope="row">
                            {row?.style}
                          </StyledTableCell>
                          <StyledTableCell component="td" scope="row">
                            {row?.weight}mg
                          </StyledTableCell>
                          <StyledTableCell component="td" scope="row">
                            {row?.purity}
                          </StyledTableCell>
                          <StyledTableCell component="td" scope="row">
                            {moment(row?.year).format("YYYY")}
                          </StyledTableCell>
                          <StyledTableCell component="td" scope="row">
                            {row?.condition}
                          </StyledTableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Box>
      <Box sx={{ pt: 1 }}>
        <CustomPagination
          rowsPerPage={rowsPerPage}
          rows={rows}
          setTableData={setTableData}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
}

const headCells = [
  {
    id: "requester",
    numeric: false,
    disablePadding: true,
    label: "Requester",
  },
  {
    id: "mkt",
    numeric: false,
    disablePadding: true,
    label: "MKT",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Description",
  },
  {
    id: "mint",
    numeric: true,
    disablePadding: true,
    label: "Mint",
  },
  {
    id: "style",
    numeric: true,
    disablePadding: true,
    label: "Style",
  },
  {
    id: "weight",
    numeric: true,
    disablePadding: true,
    label: "Weight",
  },
  {
    id: "purity",
    numeric: false,
    disablePadding: true,
    label: "Purity",
  },
  {
    id: "year",
    numeric: false,
    disablePadding: true,
    label: "Year",
  },
  {
    id: "condition",
    numeric: false,
    disablePadding: true,
    label: "Condition",
  },
];
