import React, { Fragment, useState } from "react";
import { Box, Typography, Paper, Grid, Button } from "@mui/material";
import { Heading, StyledTextArea } from "../../../components/Styled/Globals";
import ChangePhoneModal from "../../../components/Settings/Modals/ChangePhoneModal";
import ChangeNumberModal from "../../../components/Settings/Modals/ChangeNumberModal";

export default function TFA({ Link }) {
  const [data, setData] = useState(null);
  const [openChangeNumberModal, setOpenChangeNumberModal] = useState(false);
  const [openChangePhoneModal, setOpenChangePhoneModal] = useState(false);
  const handleChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });
  return (
    <Fragment>
      <ChangePhoneModal
        open={openChangePhoneModal}
        handleClose={() => setOpenChangePhoneModal(false)}
      />
      <ChangeNumberModal
        open={openChangeNumberModal}
        handleClose={() => setOpenChangeNumberModal(false)}
      />
      <Box marginY={3}>
        <Heading>Two Factor Authentication (Phone Verified Login)</Heading>
        <Paper sx={{ backgroundColor: "#fff", padding: "25px ", mt: 2 }}>
          <Typography variant={"p"} component={"p"} fontSize={20}>
            Enter Your Phone Number for 2FA
          </Typography>
          <Grid container spacing={5}>
            <Grid item md={12}>
              <StyledTextArea
                name={"msg"}
                minRows={5}
                value={data?.msg}
                placeholder="Messente utilized here:"
                style={{ width: "100%", padding: 50 }}
                onChange={handleChange}
              />
              <Box
                component={"div"}
                display={"flex"}
                width={"100%"}
                mt={1}
                columnGap={4}
              >
                <Link
                  onClick={() => setOpenChangeNumberModal(true)}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  Change 2FA Number
                </Link>
                <Link
                  onClick={() => setOpenChangePhoneModal(true)}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  I Changed My Phone #
                </Link>
              </Box>
            </Grid>
          </Grid>
          <Box className={"b-flex-end"}>
            <Button
              variant={"contained"}
              color={"primary"}
              sx={{ width: "25%" }}
            >
              Confirm
            </Button>
          </Box>
        </Paper>
      </Box>
    </Fragment>
  );
}
