import { Box, Grid, Paper } from "@mui/material";

import BasicForm from "./BasicForm";
import MarketEffectForm from "./MarketEffectForm";
import React from "react";

const SimulatedTradeForm = ({ data, setData }) => {
  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

  return (
    <Box>
      <Paper
        elevation={0}
        sx={{
          px: 3,
          py: 4,
        }}
      >
        <Grid container spacing={3}>
          <Grid item md={5.5} xl={6}>
            <BasicForm formData={data} />
          </Grid>
          <Grid item md={6.5} xl={6}>
            <MarketEffectForm
              data={data}
              setData={setData}
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default SimulatedTradeForm;
