import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Link,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import { Heading, StyledTextArea, SubHeading } from "../Styled/Globals";
import SizeGrid from "./SizeGrid";
import Close from "@mui/icons-material/Close";

const LabelsModal = ({ open, handleClose }) => {
  const [formData, setFormData] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    handleClose();
  };

  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  return (
    <Modal className={"modal"} open={open} onClose={handleClose}>
      <Box
        sx={{
          width: "85%",
        }}
        className={"no-scroll-bar"}
      >
        <Paper>
          <Box
            className={"close-box"}
            sx={{
              p: 2,
            }}
          >
            <Heading>Shipping and Packaging Labels </Heading>
            <Close onClick={handleClose} className={"c-pointer"} />
          </Box>
          <Divider />
          <Box px={2} pb={2} component={"form"} onSubmit={handleSubmit}>
            <SubHeading>Which labels do you want to print? </SubHeading>
            {/* Shipping Label */}
            <Box py={1}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <Box display={"flex"} flexDirection={"column"} width={"100%"}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label={<SubHeading>Shipping Label</SubHeading>}
                  />
                  <SizeGrid />
                </Box>
              </Box>
              {/* Return Label */}
              <Box pb={1}>
                <Box display={"flex"} flexDirection={"column"} width={"100%"}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label={<SubHeading>Return Label</SubHeading>}
                  />
                  <Box px={5}>
                    <StyledTextArea
                      onChange={handleChange}
                      name={"returnAddress"}
                      placeholder="Return address here"
                      style={{
                        width: "100%",
                        padding: 10,
                      }}
                      minRows={10}
                    />
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                  my={0.5}
                  px={5}
                >
                  <Link>
                    <Typography
                      sx={{
                        fontSize: 18,
                        textDecoration: "underline",
                      }}
                    >
                      Edit
                    </Typography>
                  </Link>
                </Box>
                <SizeGrid />
              </Box>
              <Box display={"flex"} flexDirection={"column"} width={"100%"}>
                <FormControlLabel
                  control={<Checkbox />}
                  label={<SubHeading>Packing Label</SubHeading>}
                />
              </Box>
              <Box display={"flex"} justifyContent={"flex-end"}></Box>
            </Box>
            <Box
              width={"100%"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              display={"flex"}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{
                  width: "25%",
                  py: 3,
                }}
                type={"submit"}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default LabelsModal;
