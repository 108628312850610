import { Box, Modal, Paper, Typography, styled } from "@mui/material";

import { Close } from "@mui/icons-material";
import React from "react";
import SearchField from "../Global/SearchField";

export default function SearchModal({ open, handleClose, title }) {
  //eslint-disable-next-line
  const [results, setResults] = React.useState([
    "Ticker 1",
    "Ticker 2",
    "Ticker 3",
    "Ticker 4",
  ]);

  const [selected, setSelected] = React.useState(null);

  const ResultElement = styled(Box)(({ theme, selected }) => ({
    width: "100%",
    cursor: "pointer",
    padding: 3,
    background: selected ? "#ccc" : null,
    ":hover": {
      background: "#c4c4c4",
    },
  }));

  return (
    <Modal className={"modal"} open={open} onClose={handleClose}>
      <Box sx={{ width: "35%" }} className={"no-scroll-bar"}>
        {/* <Box className={"b-flex-end"}>
            <Close className={"c-pointer"} onClick={handleClose} />
          </Box> */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <SearchField width="100%" noMargin title={title || "Search"} />
        </Box>
        <Paper>
          <Box width={"100%"}>
            {results.map((result) => {
              return (
                <ResultElement
                  onClick={(_) => {
                    setSelected(result);
                    handleClose();
                  }}
                  selected={result === selected}
                >
                  <Typography sx={{ px: 2 }}>{result}</Typography>
                </ResultElement>
              );
            })}
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
}
