import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import CustomPagination from "../../Common/CustomPagination";
import TradeDetailsModal from "../../CommonModals/TradeDetailsModal/TradeDetailsModal";

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            sx={{
              paddingX: 1,
              paddingY: 2,
            }}
          >
            <Typography
              fontWeight={"bold"}
              fontSize={14}
              color={"primary.dark"}
            >
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable({ rows, loading }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selected, setSelected] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [openDetails, setOpenDetails] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleClick = (_data) => {
    setSelected(_data);
    setOpenDetails(true);
  };

  return (
    <Box mb={4} sx={{ width: "100%" }}>
      <TradeDetailsModal
        data={selected}
        handleClose={(_) => setOpenDetails(false)}
        open={openDetails}
      />
      <Paper sx={{ width: "100%", mb: 2 }} elevation={0}>
        <Box
          display="flex"
          justifyContent={"center"}
          alignContent={"center"}
          py={3}
          border={"1px solid #c4c4c4"}
          borderRadius={1}
          mb={2}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <TableContainer>
              <Table sx={{ minWidth: 150, border: "none" }}>
                <EnhancedTableHead headCells={headCells} />
                <TableBody>
                  {
                    // rows
                    //   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    tableData.map((row) => {
                      return (
                        <TableRow
                          tabIndex={-1}
                          hover
                          key={row._id}
                          sx={{
                            border: "none",
                            cursor: "pointer",
                          }}
                          onClick={(_) => handleClick(row)}
                        >
                          <TableCell
                            component="td"
                            scope="row"
                            sx={{
                              border: "none",
                              textAlign: "center",
                              fontSize: 14,
                            }}
                            padding={"none"}
                          >
                            {row?.id}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "none",
                              textAlign: "center",
                            }}
                            component="td"
                            padding={"none"}
                            scope="row"
                          >
                            {row?.ticker}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "none",
                              textAlign: "center",
                            }}
                            component="td"
                            padding={"none"}
                            scope="row"
                          >
                            {row?.mkt}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "none",
                              textAlign: "center",
                            }}
                            component="td"
                            padding={"none"}
                            scope="row"
                          >
                            {row?.quantity}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "none",
                              textAlign: "center",
                            }}
                            component="td"
                            padding={"none"}
                            scope="row"
                          >
                            ${row?.price}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "none",
                              textAlign: "center",
                            }}
                            component="td"
                            padding={"none"}
                            scope="row"
                          >
                            ${row?.total}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "none",
                              textAlign: "center",
                              py: 2,
                              minWidth: 65,
                            }}
                            component="td"
                            padding={"none"}
                            scope="row"
                          >
                            <Button
                              variant={"contained"}
                              color={"primary"}
                              sx={(theme) => ({
                                fontWeight: "normal",
                                // height: 30,
                                width: "85%",
                                textTransform: "initial",
                                fontSize: "15px !important",
                                [theme.breakpoints.up("xl")]: {
                                  fontSize: "16px !important",
                                },
                              })}
                            >
                              {row?.action}
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
        <CustomPagination
          rowsPerPage={rowsPerPage}
          rows={rows}
          setTableData={setTableData}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "Order / Transaction #",
  },
  {
    id: "ticker",
    numeric: false,
    disablePadding: true,
    label: "Ticker",
  },
  {
    id: "mkt",
    numeric: false,
    disablePadding: true,
    label: "MKT",
  },
  {
    id: "quantity",
    numeric: true,
    disablePadding: true,
    label: "Quantity",
  },
  {
    id: "price",
    numeric: true,
    disablePadding: true,
    label: "Price",
  },
  {
    id: "total",
    numeric: true,
    disablePadding: true,
    label: "Total",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    label: "",
  },
];
