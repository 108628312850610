import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import {
  DataBox,
  StyledTextArea,
  DataLabel as _DataLabel,
} from "../../Styled/Globals";

import AttachmentFile from "../../Styled/AttachmentFile";
import { ReactComponent as CloudSVG } from "../../../assets/cloud.svg";
import React from "react";

const DataLabel = styled(_DataLabel)(({ theme }) => ({
  fontWeight: "bold",
}));

function ClaimForm({
  formData,
  setFormData,
  onChange,
  handleSubmit,
  user,
  setUser,
}) {
  const uploadRef = React.useRef(null);

  const files = [
    {
      name: "type.pdf",
      type: "pdf",
    },
    {
      name: "typenew.pdf",
      type: "pdf",
    },
    {
      name: "typenew_1.pdf",
      type: "pdf",
    },
  ];

  const buyerCauses = [
    "Item did not Arrive",
    "Incorrect Item Type",
    "Incorrect Quantity",
    "Counterfeit/Fake",
    "Other",
  ];

  const sellerCauses = ["Not North American Address", "Other"];

  return (
    <Box component={"form"} onSubmit={(e) => handleSubmit(e)}>
      <Grid container rowSpacing={2} columnSpacing={4}>
        <Grid item md={12}>
          <StyledTextArea
            placeholder="Arbitration Filers Statement Goes Here"
            minRows={6}
            style={{
              width: "100%",
              padding: 10,
            }}
          />
        </Grid>
        <Grid item md={12}>
          <DataBox>
            <DataLabel>Test as:</DataLabel>
            <Autocomplete
              label={"User"}
              onChange={(e, _user) => setUser(_user)}
              options={["Buyer", "Seller"]}
              value={user}
              renderInput={(params) => (
                <TextField
                  sx={{ width: "25%" }}
                  value={user}
                  size={"small"}
                  {...params}
                />
              )}
            />
          </DataBox>{" "}
        </Grid>
        <Grid item md={4}>
          <DataBox>
            <DataLabel>Filer's Cause for Arbitration</DataLabel>
            <Autocomplete
              label={"User"}
              onChange={(e, filersCause) =>
                setFormData({ ...formData, filersCause })
              }
              key={user}
              options={user === "Buyer" ? [...buyerCauses] : [...sellerCauses]}
              renderInput={(params) => (
                <TextField {...params} value={user} size={"small"} />
              )}
            />
          </DataBox>
        </Grid>
        <Grid item md={8}>
          <DataBox>
            <DataLabel>Desired Outcome</DataLabel>
            <TextField
              onChange={onChange}
              name={"desiredOutcome"}
              value={formData?.desiredOutcome}
              fullWidth
              type={"text"}
            />
          </DataBox>
        </Grid>
        <Grid item md={12}>
          <Box
            sx={(theme) => ({
              display: "flex",
              gap: 5,
              alignItems: "center",
            })}
          >
            {files.map((file) => (
              <AttachmentFile name={file.name} type={file.pdf} />
            ))}
          </Box>
        </Grid>
        <Grid item md={12}>
          <Box width={"max-content"} margin={"auto"}>
            <Typography
              component={"h5"}
              variant={"h5"}
              sx={(theme) => ({
                py: 1,
                fontSize: "large",
                fontWeight: "bold",
                color: "primary.dark",
                textAlign: "center",
                textTransform: "uppercase",
              })}
            >
              Your Reply
            </Typography>
            <Divider color="#2152A9" />
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box
            width={"100%"}
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <Button
              variant={"contained"}
              sx={(theme) => ({
                bgcolor: formData?.accepted ? "#45890f" : "#56AB13",
                border: formData?.accepted ? "5px solid #56AB13" : "none",
                color: "#fff",
                width: 300,
                height: 100,
                textTransform: "none",
                ":hover": {
                  bgcolor: "#45890f",
                },
                [theme.breakpoints.up("xl")]: {
                  width: 400,
                  height: 100,
                },
              })}
              onClick={() => setFormData({ ...formData, accepted: true })}
            >
              <Typography fontWeight={"bold"} fontSize={24}>
                Accept Statement and Filer's Request
              </Typography>
            </Button>
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box
            width={"100%"}
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            <Button
              variant={"contained"}
              sx={(theme) => ({
                bgcolor: !formData?.accepted ? "#980000" : "#BE0000",
                border: !formData?.accepted ? "5px solid #BE0000" : "none",
                color: "#fff",
                width: 300,
                height: 100,
                textTransform: "none",
                ":hover": {
                  bgcolor: "#980000",
                },
                [theme.breakpoints.up("xl")]: {
                  width: 400,
                  height: 100,
                },
              })}
              onClick={() => setFormData({ ...formData, accepted: false })}
            >
              <Typography fontWeight={"bold"} fontSize={24}>
                Reject Statement and Filer's Request
              </Typography>
            </Button>
          </Box>
        </Grid>
        <Grid item md={12}>
          <StyledTextArea
            minRows={5}
            placeholder={
              "Please type your description of what happened here. Please be 100% truthful and avoid name calling, exaggeration, or hyperbole. Think of this like a court room where no judge wants to hear how much you hate what happened or the other party, but only the facts of the case. "
            }
            style={{
              padding: 10,
              width: "100%",
            }}
          />
        </Grid>
        <Grid item md={8}>
          <DataBox>
            <DataLabel>Your Desired Outcome</DataLabel>
            <TextField
              fullWidth
              name={"yourDesiredOutcome"}
              value={formData?.yourDesiredOutcome}
              onChange={onChange}
              size={"medium"}
            />
          </DataBox>
        </Grid>
        <Grid item md={4}>
          <DataBox>
            <DataLabel>Upload Pictures, Documents, or Images</DataLabel>
            <Box
              display={"flex"}
              justifyContent={"start"}
              alignItems={"center"}
              width={"100%"}
              height={"100%"}
              border={"1px solid #c4c4c4"}
              borderRadius={1}
              px={2}
              py={1.7}
              className={"pointer"}
              onClick={(e) => uploadRef?.current?.click()}
            >
              <label htmlFor="upload-photo">
                <input
                  ref={uploadRef}
                  style={{ display: "none" }}
                  id="upload-photo"
                  name="upload-photo"
                  type="file"
                />
                <Box
                  display={"flex"}
                  width={"100%"}
                  height={"100%"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  columnGap={1.5}
                >
                  <CloudSVG style={{ transform: "scale(0.9)" }} />
                  <Typography
                    sx={(theme) => ({
                      color: theme?.palette.primary.main,
                      textDecoration: "underline",
                      fontWeight: "bold",
                      fontSize: "medium !important",
                    })}
                  >
                    Upload Now
                  </Typography>
                </Box>
              </label>
            </Box>
          </DataBox>
        </Grid>
        <Grid item md={12}>
          {/* Checkboxes */}
          <Box display={"flex"} flexDirection={"column"}>
            <FormControlLabel
              control={<Checkbox />}
              label={
                "My statements are the truth to the best of my knowledge and the provided documents and images are legitimate."
              }
            />
            <FormControlLabel
              control={<Checkbox />}
              label="I understand that if I am found to be habitually violating Bullion MKTS Policies that I will be suspended or banned from the platform."
            />
            <FormControlLabel
              control={<Checkbox sx={{ mt: -6 }} />}
              label={
                "I understand that for a quick resolution that I must answer all questions regarding Arbitration within 24 hours or I will lose an Arbitration by default. I understand and agree that the opposing party of the trade will be provided a copy of my statement, all documents I provide, and my desired outcome in the interest of transparency. I appreciate that the same will be provided to me from the opposing party."
              }
            />
            <FormControlLabel
              control={<Checkbox />}
              label="I agree to abide legally by the decision of the Arbitration as I agreed when I became a User of Bullion MKTS."
            />
          </Box>
        </Grid>
        <Grid item md={12}>
          <Box
            width={"100%"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            display={"flex"}
            pb={1}
          >
            <Button
              variant={"contained"}
              color={"primary"}
              type={"submit"}
              sx={{
                px: 4,
                py: 1,
              }}
            >
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ClaimForm;
