import {
  Autocomplete,
  Box,
  Divider,
  Grid,
  InputAdornment,
  Modal,
  Paper,
  TextField,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import { DataBox, DataLabel, Heading } from "../Styled/Globals";
import Close from "@mui/icons-material/Close";

const FilterModal = ({ open, handleClose }) => {
  // eslint-disable-next-line
  const [data, setData] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    handleClose();
  };

  const handleOnChange = (e) => {};

  return (
    <Modal className={"modal"} open={open} onClose={handleClose}>
      <Box
        style={{
          width: 700,
        }}
      >
        <Paper>
          <Box sx={{ px: 4, py: 1 }}>
            <Box className={"close-box"}>
              <Heading color={"#000"} fontWeight="bold">
                <FilterListIcon style={{ marginBottom: -3 }} /> Filter
              </Heading>
              <Close onClick={handleClose} className={"c-pointer"} />
            </Box>
          </Box>
          <Divider />
          <Box component={"form"} onSubmit={handleSubmit} sx={{ px: 4, py: 2 }}>
            <Grid container spacing={2} rowSpacing={2}>
              <Grid item md={6}>
                <DataBox>
                  <DataLabel>Date</DataLabel>
                  <TextField
                    type={"date"}
                    name={"date"}
                    onChange={handleOnChange}
                    value={data?.date}
                  />
                </DataBox>
              </Grid>
              <Grid item md={6}>
                <DataBox>
                  <DataLabel>MKT</DataLabel>
                  <TextField
                    fullWidth
                    type="text"
                    name={"mkt"}
                    value={data?.mkt}
                    onChange={handleOnChange}
                  />
                </DataBox>
              </Grid>
              <Grid item md={6}>
                <DataBox>
                  <DataLabel>Ticker</DataLabel>
                  <TextField
                    fullWidth
                    type="text"
                    name={"ticker"}
                    value={data?.ticker}
                    onChange={handleOnChange}
                  />
                </DataBox>
              </Grid>
              <Grid item md={6}>
                <DataBox>
                  <DataLabel>Bid/Ask</DataLabel>
                  <Autocomplete
                    id="style-autocomplete"
                    options={["Bid", "Ask"]}
                    getOptionLabel={(bidAsk) => `${bidAsk}`}
                    value={data?.bidAsk}
                    size={"small"}
                    onChange={(e, bidAsk, reason) =>
                      reason === "selectOption"
                        ? setData({
                            ...data,
                            bidAsk,
                          })
                        : setData({
                            ...data,
                            bidAsk: "",
                          })
                    }
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          size={"small"}
                          value={data?.bidAsk}
                          variant="outlined"
                        />
                      );
                    }}
                  />
                </DataBox>
              </Grid>
              <Grid item md={6}>
                <DataBox>
                  <DataLabel>Quantity</DataLabel>
                  <TextField
                    fullWidth
                    type="number"
                    name={"quantity"}
                    value={data?.quantity}
                    onChange={handleOnChange}
                  />
                </DataBox>
              </Grid>
              <Grid item md={6}>
                <DataBox>
                  <DataLabel>Price</DataLabel>
                  <TextField
                    fullWidth
                    type="number"
                    name={"price"}
                    value={data?.price}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    onChange={handleOnChange}
                  />
                </DataBox>
              </Grid>
            </Grid>

            <Button
              onClick={handleClose}
              type={"submit"}
              sx={{
                width: "100%",
                mt: 2,
              }}
              variant={"contained"}
              color={"primary"}
            >
              Apply
            </Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default FilterModal;
