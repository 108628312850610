import { Box, Grid, Paper, Typography, styled } from "@mui/material";
import { KreonTypography, StyledContainer } from "../components/Styled/Globals";
import React, { useState } from "react";

import Hexagon from "../components/Hexagon/Hexagon";
import { ReactComponent as PoliciesSVG } from "../assets/policies.svg";
import consequencesImg from "../assets/market-place-policy/consequences.png";
import policiesImg from "../assets/market-place-policy/policies.png";
import userResponsibilityImg from "../assets/market-place-policy/user-responsibility.png";

const SeeMoreButton = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: "pointer",
}));

function MarketPlacePolicy() {
  const _headers = [
    {
      img: policiesImg,
      title: "Policy",
    },
    {
      img: userResponsibilityImg,
      title: "User Responsibility",
    },
    {
      img: consequencesImg,
      title: "Consequences",
    },
  ];

  const [policies, setPolicies] = useState([
    {
      number: "I",
      policy:
        "Bullion MKTS is a North American-only platform, meaning trades are only allowed for people or companies shipping or taking delivery within the United States or Canada.",
      userResponsibility:
        "Sellers must not ship from or to non U.S.A. and Canada addresses and Buyers must not request shipments to addresses outside of these two counties. ",
      consequences:
        "Users are not covered by Arbitration (post shipment) and Bullion MKTS will not intervene or protect any buyer or seller that has knowingly violated this rule.",
      seeMore: false,
    },
    {
      number: "II",
      policy:
        "Sellers are responsible for packages until they are delivered with tracking at a Buyer's residence.",
      userResponsibility:
        "Sellers are responsible for packages until they are delivered with tracking at a Buyer's residence.",
      consequences:
        "If a package is stolen or lost, the Seller must refund the Buyer's funds or provide the exact same replacement product. ",
      seeMore: false,
    },
    {
      number: "III",
      policy:
        "It is a Buyer's responsibility to provide a legitimate, accurate, and secure delivery location for their order.",
      userResponsibility:
        "Buyer must provide a current address for delivery that is a true legal address. The address provided must be secure, this is the Buyer's sole responsibility. ",
      consequences:
        "If a Buyer does not provide a current address that is a true legal address or if an address is not secure and a package is lost, the responsibiity lies with the Buyer and not the Seller.",
      seeMore: false,
    },
    {
      number: "IV",
      policy:
        "There is zero tolerance for fraud, dishonesty, manipulation, bad faith, or disreputable characters.",
      userResponsibility:
        "Users are expected to not engage in any type of fraud, be honest at all points, not be manipulative, not act in bad faith, and not act disreputable.",
      consequences:
        "Any user or organization not abiding by these guidelines may be banned from the platform at the sole discretion of Bullion MKTS.",
      seeMore: false,
    },
    {
      number: "V",
      policy:
        "Due to the fluctuations in precious metals and numismatic prices, returns are only achieved via Arbitration",
      userResponsibility:
        "Sellers must be accurate in what markets they list their items and Buyers must be confident in their purchases.",
      consequences:
        "Buyer's remorse will not be sufficient for a return and refund. Only fraud and obvious gross misrepresentation of an item by a Seller.",
      seeMore: false,
    },
    {
      number: "VI",
      policy:
        "A Buyer or a vested representative must be available to receive an order when it arrives.",
      userResponsibility:
        "Buyers are expected to physically be at the location they have delivered to and/or have a legally vested representative to receive the order.",
      consequences:
        "If the Buyer is not or a legally vested representative is not available to receive an order when it arrives and a problem results, the Buyer will not win an Arbitration claim against a Seller.",
      seeMore: false,
    },
    {
      number: "VII",
      policy:
        "Sellers are expected to properly identify the market and Ticker their items should be sold in.",
      userResponsibility:
        "Seller is required to be reasonable and honesty in what market they sell their products.",
      consequences:
        "Sellers who are not found to be reasonable and honest in what market they sell their products will lose Arbitrations over a trade.",
      seeMore: false,
    },
    {
      number: "VIII",
      policy:
        "Sellers are expected to honestly represent the condition of the items sold.",
      userResponsibility:
        "Seller should incude the  item they are selling in the proper Ticker for the quality of the item.",
      consequences:
        "Sellers who do not market their products quality in the proper Ticker will lose any Arbitrations that arise over a trade.",
      seeMore: false,
    },
    {
      number: "IX",
      policy:
        "Buyers are expected to have researched Ticker items thoroughly before bidding and be knowledgeable about what the are buying.",
      userResponsibility:
        "Buyers must be 100% sure that what they are bidding on is what they want.",
      consequences:
        "Buyers who request a refund due to mistaken purchase will not be refunded.",
      seeMore: false,
    },
    {
      number: "X",
      policy:
        "Sellers are expected to discretely ship items and not mark packages with anything that would indicate bullion or numismatics are present within.",
      userResponsibility:
        "Seller's must package items without indicating in any way that bullion or numismatics are in the package. This includes not",
      consequences:
        "Sellers that do not adhere to this requirement will put themselves at risk of losing an Arbitration due to this negligence.",
      seeMore: false,
    },
    {
      number: "XI",
      policy:
        "An ask being listed on any market that is matched with a bid is considered a legally binding contract with the opposing party.",
      userResponsibility:
        "Buyer and Sellers agree that they are entering into a legal contract to buy or sell a product. ",
      consequences:
        "Buyers and Sellers may be liable to the other party for not fulfilling a transaction. Bullion MKTS reserves the right",
      seeMore: false,
    },
    {
      number: "XII",
      policy:
        "Excessive usage of Arbitration by any Buyer or Seller for bad faith reasons is prohibited.",
      userResponsibility:
        "Buyers and Sellers should only use Arbitration when they have been legitimately wronged. ",
      consequences:
        "Buyers and Sellers who abuse the Arbitration system or are found to be dishonest regarding an Arbitraton may be suspended or banned from the platform.",
      seeMore: false,
    },
    {
      number: "XIII",
      policy:
        "Rude, offensive, threatening, or violent behavior from any user is prohibited. ",
      userResponsibility:
        "Buyers and Sellers should engage with each other and with Bullion MKTS with respect and in a curteous and respectful manner, especialy when their are issues to be resolved.",
      consequences:
        "Rude, offensive, threatening, or violent behavior will result in suspension or termination from the Bullion MKTS platform.",
      seeMore: false,
    },
    {
      number: "XIV",
      policy:
        "Speed and efficiency are expected of all participants of Bullion MKTS.",
      userResponsibility:
        "Buyers and Sellers are expected to move quickly to fulfill their obligations to allow trades to settle.",
      consequences:
        "Buyer and Seller speed to complete the shipping and settlement process will be a consideration in any Arbitration that arises.",
      seeMore: false,
    },
    {
      number: "XV",
      policy:
        "Seller's must provide a tracking number in order to be able to close a transaction that is delivered if a Buyer does not do so.",
      userResponsibility:
        "Seller's must provide a tracking number in order to be able to close a transaction that is delivered if a Buyer does not do so.",
      consequences:
        "Seller's who do not utilize a tracking number for shipping and provide the number to the Buyer will lose any Arbitration",
      seeMore: false,
    },
    {
      number: "XVI",
      policy:
        "Buyers have 24 hours after a Seller confirms delivery via tracking to Accept Delivery or Arbitrate",
      userResponsibility:
        "Buyers must either confirm or deny that a package has been delivered within 24 hours of a seller doing so. ",
      consequences:
        "Buyers must either accept a delivery after a delivery is confirmed through tracking within 24 hours or they will lose the ability to Arbitrate the delivery.",
      seeMore: false,
    },
    {
      number: "XVII",
      policy:
        "All requests related to Arbitration must be replied to within 24 hours. If an item is not possible to obtain within 24 hours, respond saying by when you will have it.",
      userResponsibility:
        "Buyers and Sellers must respond to any Arbitration request within 24 hours.",
      consequences:
        "If Buyers or Sellers do not respond within 24 hours to any Arbitration case the opposing party will win the Arbitration case.",
      seeMore: false,
    },
    {
      number: "XVIII",
      policy:
        "Buyers and Sellers may not manipulate markets via any identifiable schemes that are not good faith actions to create free and fair markets.",
      userResponsibility:
        "Buyer's and Sellers should focus on making trades in normal ethical and moral ways and not seek to game the markets in any way.",
      consequences:
        "Bullion MKTS reserves the right to limit the trades of or ban any person or entity who seeks to manipulate the markets in any way.",
      seeMore: false,
    },
    {
      number: "XIV",
      policy:
        "Bullion MKTS is not required to keep competitors or sabateurs on its platform.",
      userResponsibility:
        "Competitors and saboteurs are not welcome at Bullion MKTS.",
      consequences:
        "Buyers and Sellers who are identifies as competitors or saboteurs will be banned from the platform.",
      seeMore: false,
    },
    {
      number: "XV",
      policy:
        "Buyers and Sellers are responsible for their own taxes and adhering to local regulations.",
      userResponsibility:
        "Buyers and Sellers must research, understand, and pay all taxes they are responsibly for paying in their local area and for adhering to all laws and regulations in their area.",
      consequences:
        "Bullion MKTS accept no responsibility whatsoever for users ignorance or negligence regarding their tax liabilities.",
      seeMore: false,
    },
  ]);

  const handleSeeMore = (number) =>
    setPolicies((_policies) =>
      _policies.map((policy) => {
        if (policy.number === number) policy.seeMore = true;
        return policy;
      })
    );

  return (
    <Box height={"100%"}>
      <StyledContainer>
        <Hexagon
          title={
            <Box
              display="flex"
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <PoliciesSVG
                style={{ marginRight: 10, transform: "scale(0.7)" }}
              />
              <Typography color={"primary"} fontWeight={"bold"} fontSize={24}>
                Policies
              </Typography>
            </Box>
          }
        />
        <Paper sx={{ marginY: 8, pb: 10 }}>
          <Box component={"div"}>
            <Grid container spacing={5}>
              <Grid md={3} sx={{ width: "100%" }}></Grid>

              {_headers.map((header) => {
                return (
                  <Grid md={3}>
                    <Box
                      width={"100%"}
                      display={"flex"}
                      flexDirection={"column"}
                    >
                      <img
                        src={header.img}
                        alt="policies"
                        style={{ padding: 20, width: "50%" }}
                        className="center-block"
                      />
                      <Box
                        component={"div"}
                        style={{
                          backgroundColor: "#5f99ff",
                        }}
                      >
                        <Typography
                          color={"#FFF"}
                          textAlign={"center"}
                          fontSize={28}
                        >
                          {header.title}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}

              {policies.map((policy) => {
                return (
                  <React.Fragment>
                    <Grid item md={3}>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        height={"100%"}
                      >
                        <Box
                          margin={"auto"}
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          sx={{ bgcolor: "#5f99ff" }}
                          width={120}
                          height={120}
                          borderRadius={"50%"}
                        >
                          <KreonTypography>{policy.number}</KreonTypography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={3} style={{ borderRight: "1px solid #ccc" }}>
                      <Typography py={2} px={2} textAlign={"left"}>
                        {policy.policy.length <= 150 ? (
                          policy.policy
                        ) : policy.seeMore ? (
                          policy.policy
                        ) : (
                          <React.Fragment>
                            <Typography component={"span"}>
                              {policy.policy.substring(0, 150) + "..."}
                              <Typography
                                component={"span"}
                                color={"primary"}
                                fontWeight={"bold"}
                                sx={{ cursor: "pointer" }}
                                onClick={(_) => handleSeeMore(policy.number)}
                              >
                                See More
                              </Typography>
                            </Typography>
                          </React.Fragment>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item md={3}>
                      <Typography color={"primary"} p={2}>
                        {policy.userResponsibility.length <= 150 ? (
                          policy.userResponsibility
                        ) : policy.seeMore ? (
                          policy.userResponsibility
                        ) : (
                          <React.Fragment>
                            <Typography component={"span"}>
                              {policy.userResponsibility.substring(0, 150) +
                                "..."}
                              <Typography
                                component={"span"}
                                color={"primary"}
                                fontWeight={"bold"}
                                sx={{ cursor: "pointer" }}
                                onClick={(_) => handleSeeMore(policy.number)}
                              >
                                See More
                              </Typography>
                            </Typography>
                          </React.Fragment>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item md={3} style={{ borderLeft: "1px solid #ccc" }}>
                      <Typography p={2}>
                        {policy.consequences.length <= 150 ? (
                          policy.consequences
                        ) : policy.seeMore ? (
                          policy.consequences
                        ) : (
                          <React.Fragment>
                            <Typography component={"span"}>
                              {policy.consequences.substring(0, 151) + "..."}
                              <Typography
                                component={"span"}
                                color={"primary"}
                                fontWeight={"bold"}
                                sx={{ cursor: "pointer" }}
                                onClick={(_) => handleSeeMore(policy.number)}
                              >
                                See More
                              </Typography>
                            </Typography>
                          </React.Fragment>
                        )}
                      </Typography>
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Grid>
          </Box>
        </Paper>
      </StyledContainer>
    </Box>
  );
}

export default MarketPlacePolicy;
