import { Box, Grid, TextField } from "@mui/material";
import { DataLabel, SubHeading } from "../../Styled/Globals";

import React from "react";

function DynamicPricing({ onChange, setData, data }) {
  return (
    <Box>
      <SubHeading style={{ margin: "15px 0" }}>Dynamic Pricing</SubHeading>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <DataLabel>Basis + $#</DataLabel>
          <TextField
            name="basisPlusDollar"
            fullWidth
            onChange={onChange}
            type="number"
            variant="outlined"
            value={data?.basisPlusDollar}
          />
        </Grid>
        <Grid item md={6}>
          <DataLabel>Floor</DataLabel>
          <TextField
            name="floor"
            fullWidth
            onChange={onChange}
            type="number"
            variant="outlined"
            value={data?.floor}
          />
        </Grid>
        <Grid item md={6}>
          <DataLabel>Basis X (1+%)</DataLabel>
          <TextField
            name="basisXPercentage"
            fullWidth
            onChange={onChange}
            type="number"
            variant="outlined"
            value={data?.basisXPercentage}
          />
        </Grid>
        <Grid item md={6}>
          <DataLabel>Ceiling</DataLabel>
          <TextField
            name="ceiling"
            fullWidth
            onChange={onChange}
            type="number"
            variant="outlined"
            value={data?.ceiling}
          />
        </Grid>
        <Grid item md={6}>
          <DataLabel>Pricing Based On</DataLabel>
          <TextField
            name="priceBasedOn"
            fullWidth
            onChange={onChange}
            variant="outlined"
            value={data?.priceBasedOn}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default DynamicPricing;
