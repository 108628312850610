import "./HowItWorks.css";

import { Box, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";

import Hexagon from "../../components/Hexagon/Hexagon";
import { ReactComponent as HowItWorksSVG } from "../../assets/help/how-it-works.svg";
import ImageModal from "../../components/CommonModals/ImageModal";
import { StyledContainer } from "../../components/Styled/Globals";
import howItWorksDetails from "../../assets/help/how-it-works-details.png";
import { useNavigate } from "react-router";
import whatIfTheresAProblem from "../../assets/help/what-if-theres-a-problem.png";

const HowItWorks = () => {
  const theme = useTheme();
  const isXL = useMediaQuery(theme.breakpoints.up("xl"));
  const [imgModal, setImgModal] = useState(false);
  const [imgObj, setImgObj] = useState(null);

  const handleImageModal = (src, alt) => {
    setImgObj({
      src,
      alt,
    });
    setImgModal(true);
    return;
  };

  const navigate = useNavigate();

  const handlePoliciesClick = (_) => navigate("/policies");
  const handleProtectionsClick = (_) => navigate("/protections");

  return (
    <Box height={"100%"}>
      <ImageModal
        open={imgModal}
        handleClose={(_) => setImgModal(false)}
        src={imgObj?.src}
        alt={imgObj?.alt}
      />
      <StyledContainer>
        <Hexagon
          width={"max-content"}
          title={
            <Box
              display="flex"
              width={300}
              justifyContent={"center"}
              alignItems={"center"}
              gap={1}
            >
              <HowItWorksSVG
                style={{
                  transform: "scale(0.7)",
                }}
              />
              <Typography
                color={"primary"}
                fontWeight={"bold"}
                fontSize={isXL ? 26 : 22}
              >
                How It Works
              </Typography>
            </Box>
          }
        />
        <Box pb={2} pt={2} px={"35px"}>
          <img
            src={howItWorksDetails}
            alt="how-it-works-details"
            style={{
              maxHeight: "69vh",
              maxWidth: "100%",
              margin: "auto",
              cursor: "pointer",
              display: "block",
            }}
            onClick={() =>
              handleImageModal(howItWorksDetails, "how-it-works-details")
            }
            // className="center-block w-100"
          />
          <Paper
            sx={{
              bgcolor: "#f4f6f9",
            }}
            elevation={5}
          >
            <Typography
              component={"h4"}
              variant={"h4"}
              fontWeight={"bold"}
              textAlign={"center"}
              marginY={5}
              py={2}
              fontSize={28}
              color={"#000"}
              px={1}
            >
              Bullion MKTS, along with its{" "}
              <span className="b-link" onClick={handlePoliciesClick}>
                Policies
              </span>
              , was designed after much research to materially reduce if not
              eliminate the vast majority of potential problems. <br /> See
              <span className="b-link" onClick={handleProtectionsClick}>
                {" "}
                Protections
              </span>{" "}
              for more information.
            </Typography>
          </Paper>
        </Box>
      </StyledContainer>
      <Box className={"blue-bg"} paddingBottom={5}>
        <StyledContainer>
          <Typography
            component={"h3"}
            variant={"h3"}
            color={"#fff"}
            fontWeight="bold"
            textAlign={"center"}
            padding={5}
            sx={{
              textDecoration: "underline",
            }}
          >
            What if there is a problem with a transaction?
          </Typography>
          <img
            src={whatIfTheresAProblem}
            alt="what-if-there's-a-problem"
            // className="w-100 center-block"
            style={{
              width: "100%",
              display: "block",
              margin: "auto",
              cursor: "pointer",
            }}
            onClick={(e) =>
              handleImageModal(
                whatIfTheresAProblem,
                "what-if-there's-a-problem"
              )
            }
          />
        </StyledContainer>
      </Box>
    </Box>
  );
};

export default HowItWorks;
