import { Box, Divider, Grid } from "@mui/material";
import React from "react";
import {
  DataHeading,
  DataTypography,
  SectionHeading,
} from "../Styled/TradeModal";

function DynamicSection() {
  return (
    <Box>
      <SectionHeading>Dynamic</SectionHeading>
      <Divider />
      <Grid container pt={1}>
        <Grid item md={3}>
          <DataHeading>Basic + $#</DataHeading>
          <DataTypography>-</DataTypography>
        </Grid>
        <Grid item md={3}>
          <DataHeading>Basic * (1+%)</DataHeading>
          <DataTypography>-</DataTypography>
        </Grid>
        <Grid item md={3}>
          <DataHeading>Floor</DataHeading>
          <DataTypography>-</DataTypography>
        </Grid>
        <Grid item md={3}>
          <DataHeading>Ceiling</DataHeading>
          <DataTypography>-</DataTypography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default DynamicSection;
