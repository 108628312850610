import { Close } from "@mui/icons-material";
import { Box, Modal, Paper, Typography, Link } from "@mui/material";
import React from "react";
import lockSVG from "../../../assets/settings/lock-icon.svg";

const ChangePhoneModal = ({ open, handleClose }) => {
  return (
    <Modal open={open} onClose={handleClose} className={"modal"}>
      <Box
        className={"no-scroll-bar"}
        sx={(theme) => ({
          maxWidth: "50%",
          [theme.breakpoints.up("xl")]: {
            maxWidth: "40%",
          },
        })}
      >
        <Paper sx={{ px: 4, py: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <Close
              sx={{
                mr: -1,
              }}
              className={"c-pointer"}
              onClick={handleClose}
            />
          </Box>
          <Box
            sx={{
              background: "#ECF3FF",
              height: 100,
              width: 100,
              borderRadius: "50%",
              margin: "25px auto",
              marginTop: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              alt={"lock"}
              src={lockSVG}
              style={{
                width: "100%",
                transform: "scale(0.4)",
              }}
            />
          </Box>

          <Typography
            component={"p"}
            sx={(theme) => ({
              fontSize: 20,
              [theme.breakpoints.up("xl")]: {
                fontSize: 22,
              },
            })}
          >
            As 2FA is a serious security precaution, we want to ensure that
            resetting it is being done for the correct person.
          </Typography>
          <br />
          <Typography
            component={"p"}
            sx={(theme) => ({
              fontSize: 20,
              [theme.breakpoints.up("xl")]: {
                fontSize: 22,
              },
            })}
          >
            Please email us at{" "}
            <Link href="mailto:help@bullionmkts.com">info@bullionmkts.com</Link>{" "}
            and we will review your request as quickly as possible. If
            everything looks legitimate, we will reset your 2FA number so you
            can access your account.
          </Typography>
        </Paper>
      </Box>
    </Modal>
  );
};

export default ChangePhoneModal;
