import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  TextField,
  TextareaAutosize,
  Typography,
  Link,
} from "@mui/material";
import React, { useState } from "react";
import lockSVG from "../../../assets/settings/lock-icon.svg";

const SaveModal = ({ open, handleClose }) => {
  const style = {
    position: "absolute",
    top: "50%",
    border: "none",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    overflowX: "hidden",
    display: "block",
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    handleClose();
  };

  const handleOnChange = (e) => {};

  return (
    <Modal open={open} onClose={handleClose}>
      <Box style={style}>
        <Paper sx={{ padding: 5 }}>
          <Typography component={"p"} fontSize={22}>
            You have sucessfully setup 2FA for your account! You will be
            required to enter a code from a text message sent to you phone each
            time you login in now. This should help protect your account.
          </Typography>
        </Paper>
      </Box>
    </Modal>
  );
};

export default SaveModal;
