import { Box, Divider, Paper } from "@mui/material";

import { Heading } from "../Styled/Globals";
import React from "react";
import ShippingSettlementTable from "../../components/Tables/ShippingSettlementTable";

function PaperCard({ title, SVG, options }) {
  return (
    <Box width={"100%"}>
      <Paper
        sx={{
          pt: 3,
          pb: 0,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box px={5} pb={2} display={"flex"} columnGap={2}>
          <SVG />
          <Heading>{title}</Heading>
        </Box>
        <Divider />
        <Box sx={{ px: 5 }}>
          <ShippingSettlementTable
            data={[
              {
                transactionNumber: 4567,
                mkt: "Gold",
                ticker: "AAPL",
                bidAsk: 800,
                description: "Selling all my assets",
                quantity: 200,
                price: 1000,
                total: 1000,
              },
            ]}
            options={options}
            loading={false}
            tableTitle={null}
          />
        </Box>
      </Paper>
    </Box>
  );
}

export default PaperCard;
