import "./Knowledge.css";

import { Box, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";

import Hexagon from "../../components/Hexagon/Hexagon";
import { ReactComponent as KnowledgeSVG } from "../../assets/knowledge/knowledge.svg";
import { Link } from "react-router-dom";
import React from "react";
import SearchField from "../../components/Global/SearchField";
import { StyledContainer } from "../../components/Styled/Globals";
import helpImg from "../../assets/knowledge/help.png";
import historyImg from "../../assets/knowledge/history.png";
import tickersImg from "../../assets/knowledge/tickers.png";

const KnowledgeHome = () => {
  const _sections = [
    {
      title: "History, Politics, Culture",
      img: historyImg,
      link: "/knowledge/history-politics-culture",
    },
    {
      title: "Bullion & Numismatic Types (Tickers)",
      img: tickersImg,
      link: "/knowledge/bullionnumismatics",
    },
    {
      title: "Help",
      img: helpImg,
      link: "/help",
    },
  ];

  const isXL = useMediaQuery(useTheme().breakpoints.up("xl"));

  return (
    <Box height={"100%"}>
      <StyledContainer>
        <Hexagon
          width={isXL ? 350 : 270}
          title={
            <Box
              display="flex"
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <KnowledgeSVG
                style={{
                  marginRight: 5,
                  transform: `scale(${isXL ? 0.7 : 0.5})`,
                }}
              />
              <Typography
                color={"primary"}
                fontWeight={"bold"}
                fontSize={isXL ? 26 : 22}
              >
                Knowledge
              </Typography>
            </Box>
          }
        />
        <Box
          sx={{
            paddingX: 25,
          }}
        >
          <SearchField title={"Enter Search Query Here"} />
        </Box>
        <Box mt={5} mb={10} mx={"auto"}>
          <Box
            sx={{
              display: "flex",
              gap: 5,
            }}
          >
            {_sections.map((section) => {
              return (
                <Box sx={{ width: "100%", height: "100%" }}>
                  <Link to={section.link}>
                    <Paper>
                      <Box
                        sx={(theme) => ({
                          padding: 4,
                          height: "100%",
                          alignItems: "stretch",

                          // height: "max-content",
                          // height: isXL ? 330 : 280,
                        })}
                      >
                        <img
                          src={section.img}
                          alt={section.title}
                          className={"center-block w-100"}
                        />
                        <br />
                        <Typography
                          component={"h5"}
                          variant={"h5"}
                          fontSize={20}
                          fontWeight={"bold"}
                          textAlign={"center"}
                        >
                          {section.title}
                        </Typography>
                      </Box>
                    </Paper>
                  </Link>
                </Box>
              );
            })}
          </Box>
        </Box>
      </StyledContainer>
    </Box>
  );
};

export default KnowledgeHome;
