import { Box, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";

import Hexagon from "../../components/Hexagon/Hexagon";
import ImageModal from "../../components/CommonModals/ImageModal";
import ProtectionsGrid from "../../components/Help/ProtectionsGrid";
import { StyledContainer } from "../../components/Styled/Globals";
import buyerSellerProtectionSrc from "../../assets/buyer-seller-protections/protections-infography.png";
import { useNavigate } from "react-router-dom";

function BuyerSellerProtection() {
  const isXL = useMediaQuery(useTheme().breakpoints.up("xl"));

  const [imgObj, setImgObj] = useState(null);
  const [imgModal, setImgModal] = useState(false);

  const navigate = useNavigate();

  const handleImageModal = (src, alt) => {
    setImgObj({
      src,
      alt,
    });
    setImgModal(true);
    return;
  };

  const handlePoliciesClick = (_) => navigate("/policies");

  return (
    <React.Fragment>
      <ImageModal
        open={imgModal}
        handleClose={(_) => setImgModal(false)}
        src={imgObj?.src}
        alt={imgObj?.alt}
      />
      <StyledContainer
      // sx={{
      //   display: "flex",
      //   flexDirection: "column",
      //   justifyContent: "space-between",
      //   pb: 5,
      // }}
      >
        <Hexagon
          hasIcon={false}
          width={480}
          title={
            <Typography
              color={"primary"}
              fontWeight={"bold"}
              fontSize={isXL ? 26 : 22}
            >
              Buyer &#38; Seller Protection
            </Typography>
          }
        />
        <Paper
          elevation={5}
          sx={{
            bgcolor: "#f4f6f9",
            mb: 3,
            mt: 3,
          }}
        >
          <Typography
            color={"#000"}
            fontSize={isXL ? 26 : 22}
            my={1}
            px={isXL ? 2 : 12}
            py={1}
            textAlign={"center"}
            fontWeight={"bold"}
          >
            The Bullion MKTS platform, along with its{" "}
            <span className={"b-link"} onClick={handlePoliciesClick}>
              Policies
            </span>
            , was designed after much research to materially reduce if not
            eliminate the vast majority of potential risks to Buyers and
            Sellers.
          </Typography>
        </Paper>
        <img
          src={buyerSellerProtectionSrc}
          alt="buyer-seller-protections"
          // className="openable-img"
          style={{
            maxHeight: "59vh",
            maxWidth: "100%",
            margin: "auto",
            cursor: "pointer",
            display: "block",
            marginBottom: 30,
          }}
          onClick={(_) =>
            handleImageModal(
              buyerSellerProtectionSrc,
              "buyer-seller-protections"
            )
          }
        />
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          rowGap={5}
          height={"100%"}
          mb={2}
        >
          <ProtectionsGrid variant={"buyer"} />
          <ProtectionsGrid variant={"seller"} />
        </Box>
      </StyledContainer>
    </React.Fragment>
  );
}

export default BuyerSellerProtection;
