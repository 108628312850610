import { Box, Divider, Modal, Paper } from "@mui/material";

import { Close } from "@mui/icons-material";
import React from "react";
import RecordingForm from "./RecordingForm";
import RecordingsTable from "./RecordingsTable";
import SearchField from "../../../../Global/SearchField";
import { SubHeading } from "../../../../Styled/Globals";

const UploadRecordingModal = ({ open, handleClose }) => {
  return (
    <Modal open={open} onClose={handleClose} className={"modal"}>
      <Box
        sx={(theme) => ({
          maxWidth: "70%",
          maxHeight: "100%",
          [theme.breakpoints.up("xl")]: {
            maxWidth: "55%",
          },
        })}
      >
        <Paper
          sx={(theme) => ({
            [theme.breakpoints.up("xl")]: {
              pb: 3,
            },
          })}
        >
          <Box
            sx={(theme) => ({
              px: 4,
              pt: 1,
              [theme.breakpoints.up("xl")]: { py: 2 },
            })}
          >
            <Box className={"close-box"} mb={0.5}>
              <SubHeading>Upload Call/Hearing Recordings</SubHeading>
              <Close
                onClick={handleClose}
                className={"pointer"}
                fontSize={"inherit"}
              />
            </Box>
          </Box>
          <Divider />
          <Box
            px={4}
            sx={(theme) => ({ py: 1, [theme.breakpoints.up("xl")]: { py: 2 } })}
          >
            <RecordingForm />
          </Box>
          <Box px={4} pb={2}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              mb={2}
            >
              <SubHeading
                sx={{
                  width: "100%",
                }}
              >
                Saved Recordings
              </SubHeading>
              <SearchField title={"Search"} noMargin width="100%" />
            </Box>
            <Box>
              <RecordingsTable rows={recordings} loading={false} />
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

const recordings = [
  {
    id: 21321421,
    name: "John Snow",
    date: Date.now(),
    attachment: "type.pdf",
    link: "http://bullionmkts.com/policies",
  },
  {
    id: 21321421,
    name: "John Snow",
    date: Date.now(),
    attachment: "type.pdf",
    link: "http://bullionmkts.com/policies",
  },
  {
    id: 21321421,
    name: "John Snow",
    date: Date.now(),
    attachment: "type.pdf",
    link: "http://bullionmkts.com/policies",
  },
  {
    id: 21321421,
    name: "John Snow",
    date: Date.now(),
    attachment: "type.pdf",
    link: "http://bullionmkts.com/policies",
  },
  {
    id: 21321421,
    name: "John Snow",
    date: Date.now(),
    attachment: "type.pdf",
    link: "http://bullionmkts.com/policies",
  },
  {
    id: 21321421,
    name: "John Snow",
    date: Date.now(),
    attachment: "type.pdf",
    link: "http://bullionmkts.com/policies",
  },
  {
    id: 21321421,
    name: "John Snow",
    date: Date.now(),
    attachment: "type.pdf",
    link: "http://bullionmkts.com/policies",
  },
];

export default UploadRecordingModal;
