import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { Heading } from "../Styled/Globals";

function FundingComponent({ title, cards }) {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Heading
        sx={{
          fontSize: 28,
          mb: 2,
        }}
        color={"primary"}
      >
        {title}
      </Heading>
      <Box width={"100%"}>
        <Box display={"flex"} justifyContent={"center"} gap={2}>
          {cards.map((card) => {
            return (
              <Box>
                <Paper
                  onClick={() => card?.onClick()}
                  sx={{
                    width: 320,
                    height: 140,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    py: 8,
                    px: 6,
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    textAlign={"center"}
                    fontSize={20}
                    fontWeight={"bold"}
                  >
                    {card.title}
                  </Typography>
                </Paper>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

export default FundingComponent;
