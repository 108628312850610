import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import React from "react";
import moment from "moment";

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            sx={{
              paddingX: 0,
              paddingY: 2,
              maxWidth: 100,
              width: headCell.width,
            }}
          >
            <Typography
              fontWeight={"bold"}
              fontSize={14}
              color={"primary.dark"}
            >
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function SingleArbitrationTable({ row, loading }) {
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }} elevation={0}>
        <Box
          display="flex"
          justifyContent={"center"}
          alignContent={"center"}
          px={2}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <TableContainer>
              <Table sx={{ minWidth: 150, border: "none" }}>
                <EnhancedTableHead headCells={headCells} />
                <TableBody>
                  <TableRow
                    tabIndex={-1}
                    key={row._id}
                    sx={{
                      border: "none",
                      cursor: "pointer",
                      paddingTop: "10px",
                    }}
                  >
                    <TableCell
                      component="td"
                      scope="row"
                      sx={{
                        border: "none",
                        textAlign: "left",
                        fontSize: 14,
                        py: 1,
                      }}
                      padding={"none"}
                    >
                      {row?.id || Math.floor(Math.random() * 999999)}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        textAlign: "left",
                      }}
                      component="td"
                      padding={"none"}
                      scope="row"
                    >
                      {moment(row?.transactionDate).format("DD MMMM, YYYY")}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        textAlign: "left",
                      }}
                      component="td"
                      padding={"none"}
                      scope="row"
                    >
                      {row?.mkt}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        textAlign: "left",
                      }}
                      component="td"
                      padding={"none"}
                      scope="row"
                    >
                      {row?.ticker}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        textAlign: "left",
                        width: 120,
                      }}
                      component="td"
                      padding={"none"}
                      scope="row"
                    >
                      {row?.description}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        textAlign: "left",
                      }}
                      component="td"
                      padding={"none"}
                      scope="row"
                    >
                      ${row?.price}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        textAlign: "left",
                      }}
                      component="td"
                      padding={"none"}
                      scope="row"
                    >
                      ${row?.total}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        textAlign: "left",
                      }}
                      component="td"
                      padding={"none"}
                      scope="row"
                    >
                      {moment(row?.dateFiled).format("DD MMMM, YYYY")}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        textAlign: "left",
                      }}
                      component="td"
                      padding={"none"}
                      scope="row"
                    >
                      {row?.type}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        textAlign: "left",
                      }}
                      component="td"
                      padding={"none"}
                      scope="row"
                    >
                      {row?.cause}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: "none",
                        textAlign: "left",
                      }}
                      component="td"
                      padding={"none"}
                      scope="row"
                    >
                      {row?.status}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Paper>
    </Box>
  );
}

const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "Transaction #",
    width: "7%",
  },
  {
    id: "transactionDate",
    numeric: false,
    disablePadding: true,
    label: "Transaction Date",
    width: "10%",
  },
  {
    id: "mkt",
    numeric: false,
    disablePadding: true,
    label: "MKT",
    width: "5%",
  },
  {
    id: "ticker",
    numeric: false,
    disablePadding: true,
    label: "Ticker",
    width: "5%",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Description",
    width: "20%",
  },
  {
    id: "price",
    numeric: true,
    disablePadding: true,
    width: "5%",
    label: "Price",
  },
  {
    id: "total",
    numeric: true,
    disablePadding: true,
    width: "5%",
    label: "Total",
  },
  {
    id: "dateFiled",
    numeric: true,
    width: "10%",
    disablePadding: true,
    label: "Date Filed",
  },
  {
    id: "type",
    width: "5%",
    numeric: false,
    disablePadding: true,
    label: "Type",
  },
  {
    width: "10%",
    id: "cause",
    numeric: false,
    disablePadding: true,
    label: "Cause",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    width: "10%",
    label: "Status",
  },
];
