import { Box, Grid, Paper, Typography } from "@mui/material";
import { Heading, MarketDataHeaders, SubHeading } from "../Styled/Globals";

import React from "react";

function TickerDetails({ bRef }) {
  const tickerData = [
    {
      title: "MKT",
      value: 2000,
    },
    {
      title: "Mint",
      value: null,
    },
    {
      title: "Style",
      value: null,
    },
    {
      title: "Weight",
      value: null,
    },
    {
      title: "Purity",
      value: null,
    },
    {
      title: "Condition",
      value: 2000,
    },
    {
      title: "Year",
      value: null,
    },
  ];

  return (
    <Box ref={bRef}>
      <Heading color={"primary.dark"}>Ticker Details</Heading>
      <Paper
        sx={{
          px: 4,
          py: 2,
          display: "flex",
          flexDirection: "column",
          rowGap: 3,
        }}
      >
        <Box>
          <SubHeading color={"primary"}>Details</SubHeading>
          <Box border={"1px solid #c4c4c4"} borderRadius={1} py={3} px={10}>
            <Grid container spacing={2}>
              {tickerData.map((data) => {
                return (
                  <Grid item md={2.4}>
                    <Box display={"flex"} flexDirection={"column"}>
                      <Typography color={"primary"} fontSize={16}>
                        {data?.title}
                      </Typography>
                      <MarketDataHeaders
                        sx={{
                          fontSize: 20,
                        }}
                      >
                        {data?.value ? `$${data?.value}` : "-"}
                      </MarketDataHeaders>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
        <Box>
          <SubHeading color={"primary"}>Description of Item</SubHeading>
          <Box
            border={"1px solid #c4c4c4"}
            borderRadius={1}
            py={10}
            px={10}
          ></Box>
        </Box>
        <Box>
          <SubHeading color={"primary"}>History of Item</SubHeading>
          <Box
            border={"1px solid #c4c4c4"}
            borderRadius={1}
            py={10}
            px={10}
          ></Box>
        </Box>
      </Paper>
    </Box>
  );
}

export default TickerDetails;
