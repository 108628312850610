import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ClosedTransactionsModal from "../../MarketSimulations/ClosedTransactionsModal";
import CustomPagination from "../../Common/CustomPagination";
import EditOrderModal from "../../MarketSimulations/EditOrderModal";
import EditUserModal from "./EditUserModal";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import RemoveUserModal from "./RemoveUserModal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { styled } from "@mui/material";
import { visuallyHidden } from "@mui/utils";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    console.log(property, event);
    onRequestSort(event, property);
  };
  const tableStyling = {
    padding: "0px 0px",
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={{
              ...tableStyling,
              width: headCell?.width || 100,
              verticalAlign: "bottom",
              textAlign: "left",
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell?.disableSorting ? (
              <Box
                component={"div"}
                sx={(theme) => ({
                  width: "100%",
                  color: theme.palette.primary.dark,
                  fontSize: "small",
                  padding: "5px 0px",
                  display: "flex",
                  lineHeight: 1.5,
                  justifyContent: "flex-start",
                  columnGap: 0.5,
                  [theme.breakpoints.up("xl")]: {
                    fontSize: "medium",
                  },
                  ...headCell?.sx,
                })}
              >
                {headCell?.label}
              </Box>
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                sx={(theme) => ({
                  fontSize: "small",
                  padding: "5px 0px",
                  display: "flex",
                  lineHeight: 1.5,
                  width: "100%",
                  color: theme.palette.primary.dark,
                  "& .MuiTableSortLabel-icon": {
                    margin: "0px",
                    fontSize: "small",
                  },
                  [theme.breakpoints.up("xl")]: {
                    fontSize: "medium",
                    "& .MuiTableSortLabel-icon": {
                      fontSize: "medium",
                    },
                  },
                  ...headCell?.sx,
                })}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
            {/* {headCell.label} */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme, center }) => ({
  padding: "10px 0px",
  paddingRight: "5px",
  fontSize: "14px",
  textAlign: center ? "center" : "left",
  border: "none",
  [theme.breakpoints.up("xl")]: {
    fontSize: "16px",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: "5px 0px !important",
  width: "100%",
  fontSize: "14px !important",
  [theme.breakpoints.up("xl")]: {
    fontSize: "16px !important",
  },
}));
export default function MockUsersManagementTable({ rows }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  //eslint-disable-next-line
  const [selected, setSelected] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [tableData, setTableData] = React.useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [elIndex, setElIndex] = React.useState(null);

  const [openCreateOT, setOpenCreateOT] = React.useState(false);
  const [openEditO, setOpenEditO] = React.useState(false);
  const [openClosedT, setOpenClosedT] = React.useState(false);
  const [openEditUser, setOpenEditUser] = React.useState(false);
  const [openRemoveUser, setOpenRemoveUser] = React.useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event, index, data) => {
    console.log("clicked");
    setSelected(data);
    setAnchorEl(event.currentTarget);
    setElIndex(index);
  };

  const handleClose = () => {
    setSelected(null);
    setAnchorEl(null);
    setElIndex(null);
  };

  const handleCreateOT = () => {
    setOpenCreateOT(true);
    handleClose();
  };

  const handleEditO = () => {
    setOpenEditO(true);
    handleClose();
  };

  const handleClosedT = () => {
    setOpenClosedT(true);
    handleClose();
  };

  const handleEditUser = () => {
    setOpenEditUser(true);
    handleClose();
  };

  const handleRemoveUser = () => {
    setOpenRemoveUser(true);
    handleClose();
  };

  return (
    <Box sx={{ width: "100%" }}>
      <EditOrderModal
        variant="Add"
        open={openCreateOT}
        handleClose={(_) => setOpenCreateOT(false)}
        order={null}
      />

      <EditOrderModal
        variant="Edit"
        open={openEditO}
        handleClose={(_) => setOpenEditO(false)}
        order={null}
      />

      <ClosedTransactionsModal
        open={openClosedT}
        handleClose={(_) => setOpenClosedT(false)}
      />

      <EditUserModal
        open={openEditUser}
        handleClose={(_) => setOpenEditUser(false)}
      />

      <RemoveUserModal
        open={openRemoveUser}
        handleClose={(_) => setOpenRemoveUser(false)}
      />

      <Box
        sx={{ width: "100%", mb: 2, borderRadius: 2, bgcolor: "#fff" }}
        border={"1px solid #c4c4c4"}
        borderRadius={1}
        mb={2}
      >
        <TableContainer
          sx={{
            m: "0px",
            width: "100%",
            minHeight: "350px",
          }}
        >
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"small"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(tableData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row?.mockUserName)}
                      role="checkbox"
                      tabIndex={-1}
                      key={row?.id}
                    >
                      <StyledTableCell
                        component="td"
                        id={labelId}
                        scope="row"
                        sx={{ pl: 1 }}
                      >
                        {row?.mockUserName}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        {moment(row?.joinDate).format("MM/DD/YYYY")}
                      </StyledTableCell>

                      <StyledTableCell
                        // center
                        component="td"
                        id={labelId}
                        scope="row"
                      >
                        {row?.transactions}
                      </StyledTableCell>
                      <StyledTableCell
                        // center
                        component="td"
                        id={labelId}
                        scope="row"
                      >
                        {row?.buyerTransactions}
                      </StyledTableCell>
                      <StyledTableCell
                        // center
                        component="td"
                        id={labelId}
                        scope="row"
                      >
                        {row?.sellerTransactions}
                      </StyledTableCell>
                      <StyledTableCell
                        // center
                        component="td"
                        id={labelId}
                        scope="row"
                      >
                        {row?.arbitrations}
                      </StyledTableCell>
                      <StyledTableCell
                        // center
                        component="td"
                        id={labelId}
                        scope="row"
                      >
                        {row?.arbitrationsLost}
                      </StyledTableCell>
                      <StyledTableCell
                        // center
                        component="td"
                        id={labelId}
                        scope="row"
                      >
                        {row?.arbitrationsToTransactions}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        {row?.opposingTradeUser}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        <StyledButton
                          disableElevation
                          color={"primary"}
                          variant={"contained"}
                          size={"small"}
                        >
                          Look
                        </StyledButton>
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        <StyledButton
                          disableElevation
                          color={"primary"}
                          variant={"contained"}
                          size={"small"}
                          onClick={(e) => handleClick(e, index, row)}
                        >
                          Action
                        </StyledButton>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open && elIndex === index}
                          onClose={handleClose}
                          sx={{
                            minWidth: 155,
                          }}
                        >
                          <MenuItem
                            onClick={handleCreateOT}
                            sx={{
                              width: 155,
                            }}
                          >
                            <Box
                              display={"flex"}
                              justifyContent={"left"}
                              alignItems="center"
                              width={"100%"}
                            >
                              <Typography>Create O/T</Typography>
                            </Box>
                          </MenuItem>
                          <MenuItem
                            onClick={handleEditO}
                            sx={{
                              width: 155,
                            }}
                          >
                            <Box
                              display={"flex"}
                              justifyContent={"left"}
                              alignItems="center"
                              width={"100%"}
                            >
                              <Typography>Edit Open O</Typography>
                            </Box>
                          </MenuItem>
                          <MenuItem
                            onClick={handleClosedT}
                            sx={{
                              width: 155,
                            }}
                          >
                            <Box
                              display={"flex"}
                              justifyContent={"left"}
                              alignItems="center"
                              width={"100%"}
                            >
                              <Typography>Closed T</Typography>
                            </Box>
                          </MenuItem>
                          <MenuItem
                            onClick={handleEditUser}
                            sx={{
                              width: 155,
                            }}
                          >
                            <Box
                              display={"flex"}
                              justifyContent={"left"}
                              alignItems="center"
                              width={"100%"}
                            >
                              <Typography>Edit User</Typography>
                            </Box>
                          </MenuItem>
                          <MenuItem
                            onClick={handleRemoveUser}
                            sx={{
                              width: 155,
                            }}
                          >
                            <Box
                              display={"flex"}
                              justifyContent={"left"}
                              alignItems="center"
                              width={"100%"}
                            >
                              <Typography>Remove User</Typography>
                            </Box>
                          </MenuItem>
                        </Menu>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
      <CustomPagination
        rowsPerPage={rowsPerPage}
        rows={rows}
        setTableData={setTableData}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
}

const headCells = [
  {
    id: "mockUserName",
    numeric: false,
    disablePadding: true,
    label: (
      <React.Fragment>
        Mock <br /> User Name
      </React.Fragment>
    ),
    width: "14%",
    sx: {
      pl: 1,
    },
  },
  {
    id: "joinDate",
    numeric: true,
    disablePadding: false,
    label: <React.Fragment>Join Date</React.Fragment>,
    width: "14%",
  },

  {
    id: "transactions",
    numeric: true,
    disablePadding: false,
    label: "# of Transactions",
    width: "10%",
  },
  {
    id: "buyerTransactions",
    numeric: true,
    disablePadding: false,
    label: "# of Buyer Transactions",
    width: "10%",
  },
  {
    id: "sellerTransactions",
    numeric: true,
    disablePadding: false,
    label: "# of Seller Transactions",
    width: "10%",
  },
  {
    id: "arbitrations",
    numeric: true,
    disablePadding: false,
    label: "# of Arbitrations",
    width: "9%",
  },
  {
    id: "lostArbitrations",
    numeric: true,
    disablePadding: false,
    label: "# of Lost Arbitrations",
    width: "9%",
  },
  {
    id: "arbitrationsToTransactions",
    numeric: true,
    disablePadding: false,
    label: "Arbitrations to Transactions %",
    width: "14%",
  },
  {
    id: "opposingTradeUser",
    numeric: true,
    disablePadding: false,
    label: "Opposing Trade User",
    width: "10%",
  },
  {
    id: "marketBtn",
    numeric: true,
    disablePadding: false,
    label: "See In Market",
    width: "8%",
    disableSorting: true,
  },
  {
    id: "actionBtn",
    numeric: true,
    disablePadding: false,
    label: "Action",
    width: "8%",
    disableSorting: true,
  },
];
