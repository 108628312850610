import {
  Box,
  Checkbox,
  Typography,
  FormControlLabel,
  Button,
  styled,
} from "@mui/material";

import { Heading, SubHeading, StyledTextArea } from "../../Styled/Globals";
import React from "react";

function QuestionsForm({ formData, setFormData, onChange, handleSubmit }) {
  const QustionBox = styled(Box)(({ theme }) => ({
    display: "flex",
    dlexDirection: "column",
    width: "100%",
    rowGap: 5,
  }));

  const QuestionHeading = styled(Heading)({
    fontWeight: "bold",
    color: "#5F99FF",
    textAlign: "center",
    textDecoration: "underline",
  });

  const AnswerHeading = styled(SubHeading)({
    fontWeight: "bold",
    color: "#000",
    textAlign: "start",
    marginTop: 10,
  });

  return (
    <Box component={"form"} onSubmit={(e) => handleSubmit(e)} my={2}>
      <Box display={"flex"} flexDirection={"column"} width={"100%"} rowGap={1}>
        <QustionBox display={"flex"} flexDirection={"column"}>
          <QuestionHeading>Question 1</QuestionHeading>
          <StyledTextArea
            minRows={7}
            style={{
              width: "100%",
              padding: 10,
            }}
          />
          <AnswerHeading>Your Reply</AnswerHeading>
          <StyledTextArea
            minRows={7}
            style={{
              width: "100%",
              padding: 10,
            }}
          />
        </QustionBox>
        <QustionBox display={"flex"} flexDirection={"column"}>
          <QuestionHeading>Question 2</QuestionHeading>
          <StyledTextArea
            minRows={7}
            style={{
              width: "100%",
              padding: 10,
            }}
          />
          <AnswerHeading>Your Reply</AnswerHeading>
          <StyledTextArea
            minRows={7}
            style={{
              width: "100%",
              padding: 10,
            }}
          />
        </QustionBox>
      </Box>
      <Box display={"flex"} flexDirection={"column"} mt={2}>
        <FormControlLabel
          control={<Checkbox />}
          label={
            <Typography>
              My statements are the truth to the best of my knowledge and the
              provided documents and images are legitimate.
            </Typography>
          }
        />

        <FormControlLabel
          control={<Checkbox sx={{ mt: -6 }} />}
          label={
            <Box
              display={"flex"}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
              width={"100%"}
            >
              <Typography>
                I understand that for a quick resolution that I must answer all
                questions regarding Arbitration within 24 hours or I will lose
                an Arbitration by default. I understand and agree that the
                opposing party of the trade will be provided a copy of my
                statement, all documents I provide, and my desired outcome in
                the interest of transparency. I appreciate that the same will be
                provided to me from the opposing party.
              </Typography>
            </Box>
          }
        />
      </Box>

      <Box
        width={"100%"}
        alignItems={"center"}
        justifyContent={"flex-end"}
        display={"flex"}
      >
        <Button
          color={"primary"}
          variant={"contained"}
          sx={{
            px: 4,
            py: 1,
          }}
          type={"submit"}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
}

export default QuestionsForm;
