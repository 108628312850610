import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  Modal,
  Paper,
  TextField,
} from "@mui/material";
import { DataLabel, Heading, SubHeading } from "../Styled/Globals";

import Close from "@mui/icons-material/Close";
import React from "react";

const ReviewTradeModal = ({ open, handleClose, data, setData, onSubmit }) => {
  const _reputationOptions = [
    "95% or better",
    "90% or better",
    "80% or better",
    "70% or better",
    "60% or better",
    "Below 50%",
    "Any",
  ];

  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });
  const handleSubmit = (e) => {
    e?.preventDefault();
    onSubmit();
  };

  return (
    <Modal className={"modal"} open={open} onClose={handleClose}>
      <Box
        className={"no-scroll-bar"}
        sx={{
          maxWidth: "80%",
        }}
      >
        <Paper>
          <Box
            component={"div"}
            width={"100%"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems="center"
            sx={{
              pt: 2,
              px: 4,
            }}
          >
            <Box>
              <Heading>Review Trade</Heading>
            </Box>
            <Close className={"c-pointer"} onClick={handleClose} />
          </Box>
          <Divider />
          <Box px={4} py={1}>
            <SubHeading
              sx={{
                textTransform: "uppercase",
                fontWeight: "500",
              }}
            >
              {data?.mkt || "GOLD"} MKT
            </SubHeading>
            <Box component={"form"} sx={{ mt: 2 }} onSubmit={handleSubmit}>
              <Grid container columnSpacing={4} rowSpacing={3}>
                <Grid item md={4}>
                  <DataLabel>Ticker</DataLabel>
                  <Autocomplete
                    id="ticker-autocomplete"
                    options={["Ticker", "Spot"]}
                    getOptionLabel={(ticker) => `${ticker}`}
                    value={data?.ticker}
                    onChange={(e, ticker, reason) =>
                      reason === "selectOption"
                        ? setData({
                            ...data,
                            ticker,
                          })
                        : setData({
                            ...data,
                            ticker: "",
                          })
                    }
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          variant="outlined"
                          value={data?.ticker}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item md={4}>
                  <DataLabel>Timing</DataLabel>
                  <TextField
                    fullWidth
                    value={data?.timing}
                    name={"timing"}
                    onChange={onChange}
                    type={"text"}
                  />
                </Grid>
                <Grid item md={4}>
                  <DataLabel>Basis + $#</DataLabel>
                  <TextField
                    fullWidth
                    value={data?.basisPlusDollar}
                    name={"basisPlusDollar"}
                    onChange={onChange}
                    type={"text"}
                  />
                </Grid>
                <Grid item md={4}>
                  <DataLabel>Bid/Ask</DataLabel>
                  <Autocomplete
                    id="bid-ask-autocomplete"
                    options={["Bid", "Ask"]}
                    getOptionLabel={(bidAsk) => `${bidAsk}`}
                    value={data?.bidAsk}
                    onChange={(e, bidAsk, reason) =>
                      reason === "selectOption"
                        ? setData({
                            ...data,
                            bidAsk,
                          })
                        : setData({
                            ...data,
                            bidAsk: "",
                          })
                    }
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          variant="outlined"
                          value={data?.bidAsk}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item md={4}>
                  <DataLabel>Limit</DataLabel>
                  <TextField
                    fullWidth
                    value={data?.limit}
                    name={"limit"}
                    label={"Limit"}
                    onChange={onChange}
                    type={"text"}
                  />
                </Grid>
                <Grid item md={4}>
                  <DataLabel>Basis X (1+%)</DataLabel>
                  <TextField
                    fullWidth
                    value={data?.basisXPercentage}
                    name={"basisXPercentage"}
                    onChange={onChange}
                    type={"text"}
                  />
                </Grid>
                <Grid item md={4}>
                  <DataLabel>Price</DataLabel>
                  <TextField
                    name="price"
                    fullWidth
                    type="number"
                    variant="outlined"
                    value={data?.price}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={4}>
                  <DataLabel>Stop</DataLabel>
                  <TextField
                    fullWidth
                    value={data?.stop}
                    name={"stop"}
                    label={"Stop"}
                    onChange={onChange}
                    type={"number"}
                  />
                </Grid>
                <Grid item md={4}>
                  <DataLabel>Floor</DataLabel>
                  <TextField
                    fullWidth
                    value={data?.floor}
                    name={"floor"}
                    label={"Floor"}
                    onChange={onChange}
                    type={"number"}
                  />
                </Grid>
                <Grid item md={4}>
                  <DataLabel>Quantity</DataLabel>
                  <TextField
                    type="number"
                    name={"quantity"}
                    onChange={onChange}
                    fullWidth
                    value={data?.quantity}
                  />
                </Grid>
                <Grid item md={4}>
                  <DataLabel>Reputation</DataLabel>
                  <Autocomplete
                    id="reputation-autocomplete"
                    options={_reputationOptions}
                    getOptionLabel={(reputation) => `${reputation}`}
                    value={data?.reputation}
                    onChange={(e, reputation, reason) =>
                      reason === "selectOption"
                        ? setData({
                            ...data,
                            reputation,
                          })
                        : setData({
                            ...data,
                            reputation: "",
                          })
                    }
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          variant="outlined"
                          value={data?.reputation}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item md={4}>
                  <DataLabel>Transactions</DataLabel>
                  <Autocomplete
                    id="quanity-autocomplete"
                    options={["100+", "10+", "1+", "Any"]}
                    getOptionLabel={(transactions) => `${transactions}`}
                    value={data?.transactions}
                    onChange={(e, transactions, reason) =>
                      reason === "selectOption"
                        ? setData({
                            ...data,
                            transactions,
                          })
                        : setData({
                            ...data,
                            transactions: "",
                          })
                    }
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          variant="outlined"
                          value={data?.transactions}
                        />
                      );
                    }}
                  />
                </Grid>

                <Grid item md={4}>
                  <DataLabel>Ceiling</DataLabel>
                  <TextField
                    fullWidth
                    value={data?.ceiling}
                    name={"ceiling"}
                    onChange={onChange}
                    type="number"
                  />
                </Grid>
                <Grid item md={4}>
                  <DataLabel>Order Type</DataLabel>
                  <TextField
                    fullWidth
                    value={data?.orderType}
                    name={"orderType"}
                    onChange={onChange}
                  />
                </Grid>
                <Grid item md={4}>
                  <DataLabel>Price Based On</DataLabel>
                  <TextField
                    fullWidth
                    value={data?.priceBasedOn}
                    name={"priceBasedOn"}
                    onChange={onChange}
                  />
                </Grid>
                <Grid item md={12}>
                  <DataLabel>
                    Est. Total before Tax, Shipping & Isurance
                  </DataLabel>
                  <TextField
                    sx={{
                      width: "66%",
                    }}
                    value={data?.estTotal}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    name={"estTotal"}
                    onChange={onChange}
                  />
                </Grid>
                <Grid item md={12}>
                  <DataLabel>Est. Sub-Total</DataLabel>
                  <TextField
                    sx={{
                      width: "66%",
                    }}
                    value={data?.estSubTotal}
                    name={"estSubTotal"}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    onChange={onChange}
                  />
                </Grid>
                <Grid item md={12}>
                  <DataLabel>Est. Buyer Shipping & Insurance Subsidy</DataLabel>
                  <TextField
                    sx={{
                      width: "66%",
                    }}
                    value={data?.estShippingSubsidy}
                    name={"estShippingSubsidy"}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    onChange={onChange}
                  />
                </Grid>
                <Grid item md={12}>
                  <DataLabel style={{ fontWeight: "bold" }}>
                    Est. Total Funds Withheld for Trade
                  </DataLabel>
                  <TextField
                    sx={{
                      width: "66%",
                    }}
                    value={data?.totalWithheldFunds}
                    name={"totalWithheldFunds"}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    onChange={onChange}
                  />
                </Grid>
                <Grid item md={12}>
                  <FormControlLabel
                    control={<Checkbox sx={{ mt: -6 }} />}
                    label="I agree that if this trade order finds a counter party to the trade that I am forming a legal contract with the counter party. I am expected to deliver either currency as a buyer or physical bullion as described above as a seller. Breech of contract is subject to legal action and suspension or termination from Bullion MKTS."
                  />
                </Grid>
                <Grid item md={12}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label={
                      "I agree that any trades on Bullion MKTS are subject to Arbitration by either party and that Arbitration decisions are final."
                    }
                  />
                </Grid>
                <Grid item md={12}>
                  <Box
                    display={"flex"}
                    width={"100%"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                  >
                    <Button
                      variant={"contained"}
                      onClick={handleClose}
                      color={"primary"}
                      disableElevation
                      sx={(theme) => ({
                        width: "25%",
                        background: "transparent",
                        fontWeight: "bold",
                        color: theme.palette.primary.main,
                        ":hover": {
                          background: "transparent",
                          color: theme.palette.primary.dark,
                        },
                      })}
                    >
                      Edit Trade
                    </Button>
                    <Button
                      type="submit"
                      variant={"contained"}
                      color={"primary"}
                      disableElevation
                      sx={{ width: "25%" }}
                    >
                      Confirm
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default ReviewTradeModal;
