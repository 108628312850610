import * as React from "react";

import Box from "@mui/material/Box";
import CustomPagination from "../../Common/CustomPagination";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { styled } from "@mui/material";
import { visuallyHidden } from "@mui/utils";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    console.log(property, event);
    onRequestSort(event, property);
  };
  const tableStyling = {
    padding: "0px 0px",
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={{
              ...tableStyling,
              width: headCell?.width || 100,
              verticalAlign: "bottom",
              textAlign: "left",
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {/* {headCell.label} */}
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={(theme) => ({
                fontSize: "small",
                padding: "8px 0px",
                display: "flex",
                lineHeight: 1.5,
                width: "100%",
                color: theme.palette.primary.dark,
                justifyContent: "flex-start",
                columnGap: 0.5,
                "& .MuiTableSortLabel-icon": {
                  margin: "0px",
                  fontSize: "small",
                },
                [theme.breakpoints.up("xl")]: {
                  fontSize: "medium",
                  "& .MuiTableSortLabel-icon": {
                    fontSize: "medium",
                  },
                },
                ...headCell?.sx,
              })}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function ListDataTable({ rows }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [tableData, setTableData] = React.useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const StyledTableCell = styled(TableCell)(({ theme, center }) => ({
    padding: "10px 0px",
    paddingRight: "0px",
    fontSize: "14px",
    textAlign: center ? "center" : "left",
    border: "none",
    [theme.breakpoints.up("xl")]: {
      fontSize: "16px",
    },
  }));

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          bgcolor: "#fff",
          border: "1px solid #c4c4c4",
          width: "100%",
          mb: 2,
          borderRadius: 2,
        }}
      >
        <TableContainer
          sx={{
            m: "0px",
            borderRadius: 2,
            width: "100%",
            minHeight: "295px",
          }}
        >
          <Table
            sx={{ minWidth: 750, borderRadius: 2 }}
            aria-labelledby="tableTitle"
            size={"small"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(tableData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row?.id}>
                      <StyledTableCell
                        component="td"
                        id={labelId}
                        scope="row"
                        sx={{ pl: 1 }}
                      >
                        {row?.displayName}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        {row?.realName}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        {row?.city}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        {row?.state}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        {row?.zip}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        {row?.joinDate}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        {row?.accountType}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        {row?.reputation}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        {row?.transactions}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        Level {row?.sellerLevel}
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
      <CustomPagination
        rowsPerPage={rowsPerPage}
        rows={rows}
        setTableData={setTableData}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
}

const headCells = [
  {
    id: "displayName",
    numeric: false,
    label: (
      <React.Fragment>
        Display <br /> Name
      </React.Fragment>
    ),
    width: "12%",
    sx: {
      pl: 1,
    },
  },
  {
    id: "realName",
    numeric: false,
    label: (
      <React.Fragment>
        Real <br /> Name
      </React.Fragment>
    ),
    width: "5%",
  },
  {
    id: "city",
    numeric: false,
    label: "City",
    width: "6%",
  },
  {
    id: "state",
    numeric: false,
    label: "State",
    width: "10%",
  },
  {
    id: "zip",
    numeric: true,
    label: "Zip",
    width: "5%",
  },
  {
    id: "joinDate",
    numeric: true,
    label: "Date Joined",
    width: "10%",
  },
  {
    id: "accountType",
    numeric: true,
    label: "Account Type",
    width: "9%",
  },
  {
    id: "reputation",
    numeric: true,
    label: "Reputation",
    width: "9%",
  },
  {
    id: "transactions",
    numeric: true,
    label: "# of Transactions",
    width: "11%",
  },
  {
    id: "sellerLevel",
    numeric: true,
    label: "Seller Level",
    width: "8%",
  },
];
