import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import React, { useState } from "react";
import {
  StyledButton,
  StyledContainer,
  StyledTextArea,
} from "../../components/Styled/Globals";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Hexagon from "../../components/Hexagon/Hexagon";
import { ReactComponent as NewMarketSVG } from "../../assets/new-market.svg";

function RequestNewMarket() {
  const [data, setData] = useState({});
  const onSubmit = (e) => {
    e.preventDefault();
    console.log(data);
  };
  const onChange = (e) =>
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });

  return (
    <Box height={"100%"}>
      <StyledContainer>
        <Hexagon
          width={500}
          title={
            <Box
              display="flex"
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={1.5}
            >
              <NewMarketSVG />
              <Typography color={"primary"} fontWeight={"bold"} fontSize={26}>
                Request a New Market
              </Typography>
            </Box>
          }
        />
        <Typography component={"p"} fontSize={22}>
          If you have checked out our Bullion &#38; Numismatic Types (Tickers)
          section and are not finding an existing market for the bullion or
          numismatic item you are looking to buy or sell, you may fill out the
          below form to request that a new market is created. This feature is
          meant to be primarily for sellers as buyers may have to wait some time
          before a seller appears to sell a specific bullion or numismatic item,
          but buyers are welcome to request a new market as well.
        </Typography>
        <Box component={"form"} my={5} onSubmit={onSubmit}>
          <Grid container spacing={5}>
            <Grid item md={12}>
              <StyledTextArea
                minRows={8}
                placeholder={"Written Description of Item"}
                style={{
                  width: "100%",
                  padding: 10,
                }}
                name={"description"}
                onChange={onChange}
                value={data?.description}
              />
            </Grid>
            <Grid item md={4}>
              <Autocomplete
                value={data?.eventType}
                options={[
                  "Gold",
                  "Silver",
                  "Platinum",
                  "Palladium",
                  "Copper",
                  "Numismatic",
                  "Other (New)",
                ]}
                sx={{ bgcolor: "#fff" }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label={"MKT"}
                      name={"mkt"}
                      value={data?.mkt}
                      size={"small"}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                type="text"
                name={"mint"}
                label={"Mint"}
                onChange={onChange}
                fullWidth
                value={data?.mint}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                type="text"
                name={"style"}
                label={"Style"}
                onChange={onChange}
                fullWidth
                value={data?.style}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                type="text"
                name={"weight"}
                label={"Weight"}
                onChange={onChange}
                fullWidth
                value={data?.weight}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                type="text"
                name={"purity"}
                label={"Purity"}
                onChange={onChange}
                fullWidth
                value={data?.purity}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                type="text"
                name={"condition"}
                label={"Condition"}
                onChange={onChange}
                fullWidth
                value={data?.condition}
              />
            </Grid>
            <Grid item md={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Year"
                  views={["year"]}
                  onChange={(year) => {
                    setData({
                      ...data,
                      year,
                    });
                  }}
                  value={data?.year}
                  renderInput={(params) => (
                    <TextField
                      className="calender-icon"
                      fullWidth
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item md={12}>
              <StyledTextArea
                minRows={8}
                placeholder={"Reason Bullion MKTS should create this market"}
                style={{
                  width: "100%",
                  padding: 10,
                }}
                name={"reason"}
                onChange={onChange}
                value={data?.reason}
              />
              <Typography component={"p"}>
                Please note that Bullion MKTS reserves the right to modify any
                of the information submitted if found to be incorrect.
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Box
                display="flex"
                justifyContent={"flex-end"}
                alignItems={"center"}
              >
                <StyledButton type={"submit"} sx={{ width: 200, fontSize: 20 }}>
                  SUBMIT
                </StyledButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </StyledContainer>
    </Box>
  );
}

export default RequestNewMarket;
