import { Box, Modal, Paper, Typography } from "@mui/material";
import React from "react";

const IncorrectCodeModal = ({ open, handleClose }) => {
  const style = {
    position: "absolute",
    top: "50%",
    border: "none",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    overflowX: "hidden",
    display: "block",
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box style={style}>
        <Paper sx={{ padding: 5 }}>
          <Typography component={"p"} fontSize={22}>
            Incorrect Code. Please resend and try again.
          </Typography>
        </Paper>
      </Box>
    </Modal>
  );
};

export default IncorrectCodeModal;
