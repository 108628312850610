import { Box, Button, Divider, Modal, Paper } from "@mui/material";
import React, { useState } from "react";

import CancelOrderConfirmationModal from "./CancelOrderConfirmationModal";
import CancelOrderSelectionTable from "./CancelOrderSelectionTable";
import Close from "@mui/icons-material/Close";
import { Heading } from "../../Styled/Globals";
import SearchField from "../../Global/SearchField";
import moment from "moment";

const CancelOrderSelectionModal = ({ open, handleClose }) => {
  const [selectedData, setSelectedData] = useState([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [data, setData] = useState({
    1: {
      orderNumber: "456365738",
      ticker: "GLD",
      mkt: "GOLD",
      bidAsk: "Ask",
      description: "Buying a assets",
      quantity: 200,
      price: 5000,
      total: 5500,
      selected: false,
      orderType: "Gold",
      timing: moment(Date.now).format("hh:mma"),
      limit: 2000,
      stop: 5000,
      transactions: 4000,
      spotPlusDollar: 10,
      spotXPercentage: 10,
      floor: 10,
      ceiling: 20,
      priceBasedOn: 5000,
    },
    2: {
      orderNumber: "456365738",
      ticker: "GLD",
      mkt: "GOLD",
      bidAsk: "Ask",
      description: "Buying a assets",
      quantity: 200,
      price: 5000,
      total: 5500,
      selected: false,
      orderType: "Gold",
      timing: moment(Date.now).format("hh:mma"),
      limit: 2000,
      stop: 5000,
      transactions: 4000,
      spotPlusDollar: 10,
      spotXPercentage: 10,
      floor: 10,
      ceiling: 20,
      priceBasedOn: 5000,
    },
    3: {
      orderNumber: "456365738",
      ticker: "GLD",
      mkt: "GOLD",
      bidAsk: "Ask",
      description: "Buying a assets",
      quantity: 200,
      price: 5000,
      total: 5500,
      selected: false,
      orderType: "Gold",
      timing: moment(Date.now).format("hh:mma"),
      limit: 2000,
      stop: 5000,
      transactions: 4000,
      spotPlusDollar: 10,
      spotXPercentage: 10,
      floor: 10,
      ceiling: 20,
      priceBasedOn: 5000,
    },
  });

  const selectRow = (e, index, row) => {
    setData({
      ...data,
      [index + 1]: {
        ...row,
        selected: !row?.selected,
      },
    });
  };

  const handleSubmit = () => {
    const selected = [];
    Object.keys(data).forEach(
      (i) => data[i].selected && selected.push(data[i])
    );
    if (selected.length < 1) return alert("Please select an order to cancel!");
    setSelectedData(selected);
    setOpenConfirmationModal(true);
  };

  // const onChange = (e, index, row) => {
  //   setData({
  //     ...data,
  //     [index + 1]: {
  //       ...row,
  //       [e.target.name]: e.target.value,
  //     },
  //   });
  // };

  return (
    <Modal open={open} className={"modal"} onClose={handleClose}>
      <Box
        sx={(theme) => ({
          width: "min(1250px, 100%)",
        })}
        className={"no-scroll-bar"}
      >
        <Paper>
          <Box
            component={"div"}
            sx={(theme) => ({
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              px: 4,
              py: 2,
            })}
          >
            <Heading>Cancel Multiple Order(s)</Heading>
            <Close
              onClick={handleClose}
              sx={(theme) => ({
                cursor: "pointer",
                fontSize: "small",
              })}
            />
          </Box>
          <Divider />
          <Box sx={{ px: 4 }}>
            <Box
              my={2}
              display={"flex"}
              justifyContent={"flex-end"}
              width={"100%"}
            >
              <SearchField title={"Search"} noMargin width={500} />
            </Box>
            <CancelOrderSelectionTable
              rows={data}
              headCells={_headcells}
              selectRow={selectRow}
            />
            <Box
              justifyContent={"flex-end"}
              display={"flex"}
              width={"100%"}
              pb={2}
            >
              <Button
                variant={"contained"}
                color={"primary"}
                onClick={handleSubmit}
                sx={{ width: "25%" }}
              >
                Submit{" "}
              </Button>
            </Box>
          </Box>
        </Paper>
        <CancelOrderConfirmationModal
          open={openConfirmationModal}
          handleClose={() => setOpenConfirmationModal(false)}
          data={selectedData}
          cancelParent={() => handleClose()}
        />
      </Box>
    </Modal>
  );
};

const _headcells = [
  {
    id: "orderNumber",
    numeric: true,
    disablePadding: true,
    label: "Order#",
  },
  {
    id: "mkt",
    numeric: true,
    disablePadding: true,
    label: "MKT",
  },
  {
    id: "ticker",
    numeric: false,
    disablePadding: true,
    label: "Ticker",
  },
  {
    id: "bid-ask",
    numeric: false,
    disablePadding: true,
    label: "Bid/Ask",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Description",
  },

  {
    id: "quantity",
    numeric: true,
    disablePadding: true,
    label: "Quantity",
  },
  {
    id: "price",
    numeric: true,
    disablePadding: true,
    label: "Price",
  },
  {
    id: "total",
    numeric: true,
    disablePadding: true,
    label: "Total",
  },
  {
    id: "details",
    numeric: true,
    disablePadding: true,
    label: "Details",
    sx: {
      textAlign: "center",
    },
  },
  {
    id: "select",
    numeric: true,
    disablePadding: true,
    sx: {
      width: "150px",
      textAlign: "center",
    },
    label: "Select",
  },
];

export default CancelOrderSelectionModal;
