import { Box, Divider, Modal, Paper } from "@mui/material";
import { Heading, StyledButton } from "../../Styled/Globals";
import React, { useState } from "react";

import { Close } from "@mui/icons-material";
import MessagingForm from "./MessagingForm";

const TemplateModal = ({
  open,
  handleClose,
  selected,
  title,
  handleSubmit,
}) => {
  const [data, setData] = useState({});
  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

  React.useEffect(() => setData(selected), [selected, open]);

  return (
    <Modal open={open} onClose={handleClose} className={"modal"}>
      <Box className={"no-scroll-bar"}>
        <Paper sx={{ py: 2 }}>
          <Box sx={{ px: 4, pt: 1 }}>
            <Box
              display="flex"
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Heading>{title}</Heading>
              <Close
                onClick={handleClose}
                sx={{
                  cursor: "pointer",
                }}
              />
            </Box>
          </Box>
          <Divider
            sx={{
              my: 1,
            }}
          />
          <Box px={4} pt={1}>
            <MessagingForm
              data={data}
              setData={setData}
              onChange={onChange}
              variant={"template"}
            />
          </Box>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            width={"100%"}
            alignItems={"center"}
            px={4}
            pt={1}
          >
            <StyledButton
              sx={{
                width: 200,
                height: "100%",
                fontSize: 20,
              }}
              onClick={handleClose}
            >
              Save
            </StyledButton>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default TemplateModal;
