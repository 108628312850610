import { Box, Divider, Modal, Paper } from "@mui/material";

import { Close } from "@mui/icons-material";
import MessagesTable from "./MessagesTable";
import React from "react";
import SearchField from "../../Global/SearchField";
import { SubHeading } from "../../Styled/Globals";

const SentMessages = ({ open, handleClose, handleSubmit }) => {
  return (
    <Modal open={open} onClose={handleClose} className={"modal"}>
      <Box className={"no-scroll-bar"} width={850}>
        <Paper>
          <Box
            display={"flex"}
            alignItems="center"
            justifyContent={"flex-end"}
            p={1}
          >
            <Close fontSize="small" className="pointer" onClick={handleClose} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              px: 4,
              pb: 2,
            }}
          >
            <SubHeading sx={{ width: "100%" }}>Sent Messages</SubHeading>
            <SearchField
              width={"100%"}
              noMargin
              title={"Search"}
              height={"45px"}
            />
          </Box>
          <Divider />
          <Box px={4} py={2}>
            <Box border={"1px solid #c4c4c4"} borderRadius={1}>
              <MessagesTable rows={templatesData} loading={false} />
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

const templatesData = [
  {
    sentTo: "Peter",
    templateName: "Gold assets",
    subject: "Sending all my assets",
    daySent: Date.now(),
    timeSent: Date.now(),
  },
  {
    sentTo: "Peter",
    templateName: "Gold assets",
    subject: "Sending all my assets",
    daySent: Date.now(),
    timeSent: Date.now(),
  },
  {
    sentTo: "Peter",
    templateName: "Gold assets",
    subject: "Sending all my assets",
    daySent: Date.now(),
    timeSent: Date.now(),
  },
  {
    sentTo: "Peter",
    templateName: "Gold assets",
    subject: "Sending all my assets",
    daySent: Date.now(),
    timeSent: Date.now(),
  },
  {
    sentTo: "Peter",
    templateName: "Gold assets",
    subject: "Sending all my assets",
    daySent: Date.now(),
    timeSent: Date.now(),
  },
];

export default SentMessages;
