import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import MetalsGrid from "../components/HomePage/MetalsGrid";
import SecondGrid from "../components/HomePage/SecondGrid";
import ProtectionGrid from "../components/HomePage/ProtectionGrid";
import WorkingGrid from "../components/HomePage/WorkingGrid";
import SignUpModal from "../components/Auth/SignUpModal";
import { ReactComponent as ListIcon } from "../assets/list-disc.svg";
import LoginModal from "../components/Auth/LoginModal";
import RetrieveUserLogin from "../components/Auth/RetrieveUserLoginModal";
import PasswordResetModal from "../components/Auth/PasswordResetModal";
import NewPasswordModal from "../components/Auth/NewPasswordModal";
import HomeMarqee from "../components/HomePage/HomeMarqee";

const Home = () => {
  const _list = [
    `Pro-USA, Capitalism, Freedom & Liberty, and REAL MONEY`,
    `Free for buyers, low fees for sellers (low to zero payment
      processing fees)`,
    `Built-in protections for buyers and sellers`,
    `Buy a little or buy a massive amount`,
  ];

  const [openSignUp, setOpenSignUp] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);

  return (
    <Box>
      <Box>
        <HomeMarqee />
      </Box>
      <Container>
        <Box
          sx={(theme) => ({
            mt: 2,
            [theme.breakpoints.up("xl")]: {
              mb: 4,
            },
          })}
        >
          <Grid container spacing={7}>
            {/* Metals Grid */}
            <Grid item md={6}>
              <MetalsGrid />
            </Grid>
            <Grid item md={6}>
              <Box width={475} margin={"auto"}>
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    margin: "auto",
                  }}
                >
                  <IconButton sx={{ p: "10px" }} aria-label="menu">
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search Description/Ticker"
                    inputProps={{ "aria-label": "search description ticker" }}
                  />
                </Paper>
                <Typography
                  marginTop={5}
                  marginLeft={1}
                  fontWeight={400}
                  fontSize={25}
                  sx={(theme) => ({
                    mt: 2,
                    ml: 1,
                    fontWeight: 400,
                    fontSize: 22,
                    [theme.breakpoints.up("xl")]: {
                      fontSize: 25,
                    },
                  })}
                >
                  Buy and sell physical Bullion like stocks, bonds, mutual
                  funds, and ETF's in a safe, secure, and liquid market.
                </Typography>
              </Box>
              <Box width={550} fontSize={18} marginY={2}>
                <Grid
                  container
                  sx={(theme) => ({
                    rowGap: 2,
                    [theme.breakpoints.up("xl")]: {
                      rowGap: 4.3,
                    },
                  })}
                >
                  {_list.map((text) => {
                    return (
                      <React.Fragment>
                        <Grid item xs={1}>
                          <ListIcon />
                        </Grid>
                        <Grid item xs={11}>
                          <Typography
                            fontSize={18}
                            fontWeight="semi-bold"
                            component={"span"}
                          >
                            {text}
                          </Typography>
                        </Grid>
                      </React.Fragment>
                    );
                  })}
                </Grid>
              </Box>
              <Button
                variant={"contained"}
                sx={{
                  color: "#fff",
                  width: "75%",
                  margin: "50px auto",
                  display: "block",
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: "20px !important",
                }}
                disableElevation
                fullWidth
                onClick={() => setOpenSignUp(true)}
              >
                Sign Up
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <SecondGrid setOpenSignUp={setOpenSignUp} />
      <ProtectionGrid setOpenSignUp={setOpenSignUp} />
      <WorkingGrid setOpenSignUp={setOpenSignUp} />

      {/* Modals */}
      <SignUpModal open={openSignUp} handleClose={() => setOpenSignUp(false)} />
      <LoginModal open={openLogin} handleClose={() => setOpenLogin(false)} />
      <RetrieveUserLogin open={false} handleClose={() => setOpenLogin(false)} />
      <PasswordResetModal
        open={false}
        handleClose={() => setOpenLogin(false)}
      />
      <NewPasswordModal open={false} handleClose={() => setOpenLogin(false)} />
    </Box>
  );
};

export default Home;
