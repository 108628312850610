import { Box, Button, Divider, Modal, Paper } from "@mui/material";

import Close from "@mui/icons-material/Close";
import { Heading } from "../../Styled/Globals";
import React from "react";
import VerifyMultipleOrdersTable from "./VerifyMultipleOrdersTable";

const VerifyMultipleOrdersModal = ({
  open,
  handleClose,
  data,
  handleSubmit,
}) => {
  return (
    <Modal open={open} className={"modal"} onClose={handleClose}>
      <Box sx={{ width: "min(100%, 1900px)" }} className={"no-scroll-bar"}>
        <Paper>
          <Box
            component={"div"}
            sx={(theme) => ({
              px: 4,
              py: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            })}
          >
            <Heading>Verify Multiple Orders</Heading>
            <Close
              onClick={handleClose}
              sx={{
                fontSize: "small",
                cursor: "pointer",
              }}
            />
          </Box>
          <Divider />
          <Box px={4} py={2}>
            <VerifyMultipleOrdersTable
              rows={data}
              headCells={_headcells}
              handleEdit={handleClose}
            />
            <Box justifyContent={"flex-end"} display={"flex"} width={"100%"}>
              <Button
                disableElevation
                variant={"contained"}
                color={"primary"}
                sx={{
                  width: "25%",
                  textTransform: "uppercase",
                  alignItems: "center",
                }}
                onClick={handleSubmit}
              >
                Confirm Order
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

const _headcells = [
  {
    id: "ticker",
    numeric: false,
    disablePadding: true,
    label: "Ticker",
  },

  {
    id: "bid-ask",
    numeric: false,
    disablePadding: true,
    label: "Bid/Ask",
  },

  {
    id: "price",
    numeric: true,
    disablePadding: true,
    label: "Price",
  },
  {
    id: "quantity",
    numeric: true,
    disablePadding: true,
    label: "Quantity",
  },
  {
    id: "orderType",
    numeric: false,
    disablePadding: true,
    label: "Order Type",
  },
  {
    id: "timing",
    numeric: false,
    disablePadding: true,
    label: "Timing",
  },
  {
    id: "limit",
    numeric: true,
    disablePadding: true,
    label: "Limit",
  },
  {
    id: "stop",
    numeric: true,
    disablePadding: true,
    label: "Stop",
  },
  {
    id: "transactions",
    numeric: true,
    disablePadding: true,
    label: (
      <Box>
        Reputation/
        <br /># of Transactions
      </Box>
    ),
  },
  {
    id: "spotPlusDollar",
    numeric: true,
    disablePadding: true,
    label: "Spot + $#",
  },
  {
    id: "spotXPercentage",
    numeric: true,
    disablePadding: true,
    label: "Spot X (1+%)",
  },
  {
    id: "floor",
    numeric: true,
    disablePadding: true,
    label: "Floor",
  },
  {
    id: "ceiling",
    numeric: true,
    disablePadding: true,
    label: "Ceiling",
  },
  {
    id: "priceBasedOn",
    numeric: true,
    disablePadding: true,
    label: "Price Based On",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: true,
    label: "Action",
  },
];

export default VerifyMultipleOrdersModal;
