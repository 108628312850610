import * as React from "react";

import { Typography, styled } from "@mui/material";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={(theme) => ({
              padding: "0px",
              margin: "0px",
              width: headCell?.width || "max-content",
              verticalAlign: "center",
              textAlign: "left",
              color: "primary",
              //   borderWidth: "thin",
              //   borderStyle: "solid",
              //   borderColor: "rgba(0, 0, 0, 0.12)",
              //   borderTop: "none",
              pt: 2,
              pb: 1,
              borderBottom: "none",

              ":first-child": {
                borderLeft: "none",
              },
              ":last-child": {
                borderRight: "none",
              },
            })}
          >
            <Typography
              sx={(theme) => ({
                fontSize: "12px !important",
                color: theme.palette.primary.dark,
                textAlign: "center",
                [theme.breakpoints.up("xl")]: {
                  fontSize: "15px !important",
                },
              })}
            >
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme, first, last }) => ({
  padding: "10px 0px",
  fontSize: "13px",
  // textAlign: center ? "center" : "left",
  textAlign: "center",
  // borderWidth: "thin",
  // borderStyle: "solid",
  // borderColor: "rgba(0, 0, 0, 0.12)",
  borderLeft: first && "none",
  borderRight: last && "none",
  borderBottom: "none",
  [theme.breakpoints.up("xl")]: {
    fontSize: "14px",
  },
}));
export default function FundsByMarketTable() {
  return (
    <Box sx={{ width: "100%" }}>
      <Paper elevation={0} sx={{ p: 1, borderRadius: 3 }}>
        <Paper elevation={0} sx={{ width: "100%", mb: 2 }}>
          <TableContainer
            sx={{
              m: "0px",
              width: "100%",
              borderWidth: "thin",
              borderStyle: "solid",
              borderColor: "rgba(0, 0, 0, 0.12)",
              borderRadius: 5,
            }}
          >
            <Table
              sx={{
                width: "100%",
              }}
              aria-labelledby="tableTitle"
              size={"small"}
            >
              <EnhancedTableHead />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {rows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <StyledTableCell
                        component="td"
                        first
                        id={labelId}
                        scope="row"
                      >
                        {row?.all}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        {row?.coinCurrency}
                      </StyledTableCell>
                      <StyledTableCell
                        center
                        component="td"
                        id={labelId}
                        scope="row"
                      >
                        {row?.gold}
                      </StyledTableCell>
                      <StyledTableCell
                        center
                        component="td"
                        id={labelId}
                        scope="row"
                      >
                        {row?.silver}
                      </StyledTableCell>
                      <StyledTableCell
                        center
                        component="td"
                        id={labelId}
                        scope="row"
                      >
                        {row?.platinum}
                      </StyledTableCell>
                      <StyledTableCell
                        center
                        component="td"
                        id={labelId}
                        scope="row"
                      >
                        {row?.palladium}
                      </StyledTableCell>
                      <StyledTableCell
                        center
                        component="td"
                        id={labelId}
                        scope="row"
                      >
                        {row?.copper}
                      </StyledTableCell>
                      <StyledTableCell
                        center
                        component="td"
                        id={labelId}
                        scope="row"
                      >
                        {row?.rhodium}
                      </StyledTableCell>
                      <StyledTableCell
                        center
                        component="td"
                        id={labelId}
                        scope="row"
                      >
                        {row?.iridium}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        {row?.osmium}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        {row?.rhenium}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        {row?.ruthenium}
                      </StyledTableCell>
                      <StyledTableCell
                        last
                        component="td"
                        id={labelId}
                        scope="row"
                      >
                        {row?.beryllium}
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Paper>
    </Box>
  );
}

const headCells = [
  {
    id: "All",
    numeric: false,
    disablePadding: true,
    label: "All",
  },

  {
    id: "Gold",
    numeric: true,
    disablePadding: true,
    label: "Gold",
  },
  {
    id: "Silver",
    numeric: true,
    disablePadding: true,
    label: "Silver",
  },
  {
    id: "Platinum",
    numeric: true,
    disablePadding: true,
    label: "Platinum",
  },
  {
    id: "Palladium",
    numeric: true,
    disablePadding: true,
    label: "Palladium",
  },
  {
    id: "Copper",
    numeric: true,
    disablePadding: true,
    label: "Copper",
  },
  {
    id: "Rhodium",
    numeric: true,
    disablePadding: true,
    label: "Rhodium",
  },
  {
    id: "Iridium",
    numeric: true,
    disablePadding: true,
    label: "Iridium",
  },
  {
    id: "Osmium",
    numeric: true,
    disablePadding: true,
    label: "Osmium",
  },
  {
    id: "Rhenium",
    numeric: true,
    disablePadding: true,
    label: "Rhenium",
  },
  {
    id: "Ruthenium",
    numeric: true,
    disablePadding: true,
    label: "Ruthenium",
  },
  {
    id: "Beryllium",
    numeric: true,
    disablePadding: true,
    label: "Beryllium",
  },
  {
    id: "Numismatic",
    numeric: true,
    disablePadding: true,
    label: "Numismatic",
  },
];
const rows = [
  {
    all: "$1,000",
    coinCurrency: "$1,000",
    gold: "$1,000",
    silver: "$1,000",
    platinum: "$1,000",
    palladium: "$1,000",
    copper: "$1,000",
    rhodium: "$1,000",
    iridium: "$1,000",
    osmium: "$1,000",
    rhenium: "$1,000",
    ruthenium: "$1,000",
    beryllium: "$1,000",
  },
  {
    all: "$1,000",
    coinCurrency: "$1,000",
    gold: "$1,000",
    silver: "$1,000",
    platinum: "$1,000",
    palladium: "$1,000",
    copper: "$1,000",
    rhodium: "$1,000",
    iridium: "$1,000",
    osmium: "$1,000",
    rhenium: "$1,000",
    ruthenium: "$1,000",
    beryllium: "$1,000",
  },
  {
    all: "$1,000",
    coinCurrency: "$1,000",
    gold: "$1,000",
    silver: "$1,000",
    platinum: "$1,000",
    palladium: "$1,000",
    copper: "$1,000",
    rhodium: "$1,000",
    iridium: "$1,000",
    osmium: "$1,000",
    rhenium: "$1,000",
    ruthenium: "$1,000",
    beryllium: "$1,000",
  },
  {
    all: "$1,000",
    coinCurrency: "$1,000",
    gold: "$1,000",
    silver: "$1,000",
    platinum: "$1,000",
    palladium: "$1,000",
    copper: "$1,000",
    rhodium: "$1,000",
    iridium: "$1,000",
    osmium: "$1,000",
    rhenium: "$1,000",
    ruthenium: "$1,000",
    beryllium: "$1,000",
  },
];
