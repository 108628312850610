import React from "react";
import { DesktopDatePicker, LocalizationProvider, TimePicker } from "@mui/lab";
import { Box, TextField, Typography } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
export default function TradeRemovalForm() {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
      width={"100%"}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
        mt={1}
        columnGap={1}
      >
        <Box width={"65%"}>
          <Typography fontSize={14}>
            Remove Trade from Statistics on:
          </Typography>
        </Box>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Date"
            inputFormat="MM/dd/yyyy"
            onChange={(e) => {}}
            renderInput={(params) => (
              <TextField className="calender-icon" size={"small"} {...params} />
            )}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            onChange={(e) => {}}
            renderInput={(params) => <TextField size={"small"} {...params} />}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  );
}
