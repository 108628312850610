import "./TradeForm.module.css";

import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputAdornment,
  Link as MUILink,
  TextField,
  Typography,
} from "@mui/material";
import {
  Heading,
  LimitBox,
  LimitField,
  StyledTextArea,
  SubHeading,
} from "../Styled/Globals";
import React, { useState } from "react";
import {
  orderTypes,
  priceOps,
  reputations,
  timings,
  transactionOps,
} from "../../constants/general";

import Close from "@mui/icons-material/Close";
import ReputationModal from "./ReputationModal";
import ReviewTradeModal from "./ReviewTradeModal";

function TradeForm({ isModal = false, handleClose = null }) {
  const [data, setData] = useState(null);
  const [opneReputationModal, setOpenReputationModal] = useState(false);
  const [openReviewModal, setOpenReviewModal] = useState(false);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Box clasName={"tradeform"}>
      <ReputationModal
        open={opneReputationModal}
        handleClose={() => setOpenReputationModal(false)}
      />
      <ReviewTradeModal
        open={openReviewModal}
        handleClose={() => setOpenReviewModal(false)}
        data={data}
        setData={setData}
        onSubmit={handleClose}
      />
      <Box className={"close-box"} mb={1}>
        <Heading>Make a Trade</Heading>
        <Close className={"c-pointer"} onClick={handleClose} />
      </Box>
      <Grid container>
        <Grid item md={12}>
          <StyledTextArea
            minRows={7}
            name={"summary"}
            value={data?.summary}
            placeholder="Summarizes the trade as user fills in data"
            style={{ width: "100%", padding: 7 }}
            onChange={handleChange}
          />
        </Grid>
        <Grid md={6}>
          <Box>
            <SubHeading mb={1}>Basic</SubHeading>
            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid item md={6}>
                <TextField
                  size={"small"}
                  label="Ticker"
                  name={"ticker"}
                  onChange={handleChange}
                  variant="outlined"
                  value={data?.ticker}
                  required
                />
              </Grid>
              <Grid item md={6}>
                <Autocomplete
                  placeholder="Bid or Ask"
                  id="bid-or-ask-autocomplete"
                  options={["Bid", "Ask"]}
                  getOptionLabel={(bidAsk) => `${bidAsk}`}
                  value={data?.bidAsk}
                  onChange={(e, bidAsk, reason) =>
                    reason === "selectOption"
                      ? setData({
                          ...data,
                          bidAsk,
                        })
                      : setData({
                          ...data,
                          bidAsk: "",
                        })
                  }
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label="Bid or Ask"
                        variant="outlined"
                        value={data?.bidAsk}
                        required
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  size={"small"}
                  name="price"
                  label="Enter Price"
                  fullWidth
                  type="number"
                  variant="outlined"
                  value={data?.price}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  size={"small"}
                  name="quantity"
                  label="Enter Quantity"
                  fullWidth
                  type="number"
                  variant="outlined"
                  value={data?.quantity}
                />
              </Grid>
              <Grid item md={4}>
                <Autocomplete
                  id="orderType-autocomplete"
                  options={[...orderTypes]}
                  getOptionLabel={(orderType) => `${orderType}`}
                  value={data?.orderType}
                  onChange={(e, orderType, reason) =>
                    reason === "selectOption"
                      ? setData({
                          ...data,
                          orderType,
                        })
                      : setData({
                          ...data,
                          orderType: "",
                        })
                  }
                  size={"small"}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label="Order Type"
                        variant="outlined"
                        size={"small"}
                        value={data?.orderType}
                        required
                        sx={{
                          "& input::placeholder": {
                            fontSize: "20px",
                          },
                        }}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <LimitBox>
                  <Box width={"100%"}>
                    <SubHeading px={2}>Stop</SubHeading>
                  </Box>
                  <LimitField>
                    <TextField
                      name="stopInitial"
                      size="medium"
                      onChange={handleChange}
                      variant="standard"
                      value={data?.stopInitial}
                      sx={{
                        px: 1,
                      }}
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                  </LimitField>
                  <LimitField
                    sx={{
                      borderRight: "none",
                    }}
                  >
                    <TextField
                      name="stopEnd"
                      size="medium"
                      sx={{
                        borderRightColor: "#fff",
                        px: 1,
                      }}
                      onChange={handleChange}
                      variant="standard"
                      value={data?.stopEnd}
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end">#</InputAdornment>
                        ),
                      }}
                    />
                  </LimitField>
                </LimitBox>
              </Grid>
              <Grid item md={6}>
                <LimitBox>
                  <Box width={"100%"}>
                    <SubHeading px={2}>Limit</SubHeading>
                  </Box>
                  <LimitField key={"initi"}>
                    <TextField
                      key={"initi"}
                      name="limitInitial"
                      size="medium"
                      onChange={handleChange}
                      variant="standard"
                      sx={{
                        px: 1,
                      }}
                      value={data?.limitInitial}
                      // value={initLimit}
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                  </LimitField>
                  <LimitField
                    sx={{
                      borderRight: "none",
                    }}
                  >
                    <TextField
                      name="limitEnd"
                      size="small"
                      onChange={handleChange}
                      variant="standard"
                      sx={{
                        borderRightColor: "#fff",
                        px: 1,
                      }}
                      value={data?.limitEnd}
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end">#</InputAdornment>
                        ),
                      }}
                    />
                  </LimitField>
                </LimitBox>
              </Grid>
              <Grid item md={6}>
                <Autocomplete
                  id="timing-autocomplete"
                  options={[...timings]}
                  getOptionLabel={(timing) => `${timing}`}
                  value={data?.timing}
                  onChange={(e, timing, reason) =>
                    reason === "selectOption"
                      ? setData({
                          ...data,
                          timing,
                        })
                      : setData({
                          ...data,
                          timing: "",
                        })
                  }
                  size={"small"}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label="Timing"
                        variant="outlined"
                        size={"small"}
                        value={data?.timing}
                        required
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid md={6}>
          <Box pl={4}>
            <SubHeading mb={1}>Dynamic Pricing</SubHeading>
            <Grid container columnSpacing={4} rowSpacing={3}>
              <Grid item md={6}>
                <TextField
                  size={"small"}
                  name="basisPlusDollar"
                  label="Basis + $#"
                  fullWidth
                  onChange={handleChange}
                  type="number"
                  variant="outlined"
                  value={data?.basisPlusDollar}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  size={"small"}
                  name="ceiling"
                  label="Ceiling"
                  fullWidth
                  onChange={handleChange}
                  type="number"
                  variant="outlined"
                  value={data?.ceiling}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  size={"small"}
                  name="basisXPercentage"
                  label="Basis X (1+%)"
                  fullWidth
                  onChange={handleChange}
                  type="number"
                  variant="outlined"
                  value={data?.basisXPercentage}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  size={"small"}
                  name="floor"
                  label="Floor"
                  fullWidth
                  onChange={handleChange}
                  type="number"
                  variant="outlined"
                  value={data?.floor}
                />
              </Grid>
              <Grid item md={6}>
                <Autocomplete
                  id="priceBasedOn-autocomplete"
                  options={[...priceOps]}
                  getOptionLabel={(priceBasedOn) => `${priceBasedOn}`}
                  value={data?.priceBasedOn}
                  onChange={(e, priceBasedOn, reason) =>
                    reason === "selectOption"
                      ? setData({
                          ...data,
                          priceBasedOn,
                        })
                      : setData({
                          ...data,
                          priceBasedOn: "",
                        })
                  }
                  fullWidth
                  size={"small"}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label="Price Based On"
                        variant="outlined"
                        size={"small"}
                        value={data?.priceBasedOn}
                        required
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <Box
                  display={"flex"}
                  width={"100%"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <SubHeading>
                    Reputation &#38; Completed Transactions
                  </SubHeading>
                  <Typography
                    onClick={() => setOpenReputationModal(true)}
                    sx={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    <MUILink>What's this?</MUILink>
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Autocomplete
                  id="reputation-autocomplete"
                  options={[...reputations]}
                  getOptionLabel={(reputation) => `${reputation}`}
                  value={data?.reputation}
                  onChange={(e, reputation, reason) =>
                    reason === "selectOption"
                      ? setData({
                          ...data,
                          reputation,
                        })
                      : setData({
                          ...data,
                          reputation: "",
                        })
                  }
                  size={"small"}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label="Reputation"
                        variant="outlined"
                        size={"small"}
                        value={data?.reputation}
                        required
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <Autocomplete
                  id="completedTransactions-autocomplete"
                  options={[...transactionOps]}
                  getOptionLabel={(completedTransactions) =>
                    `${completedTransactions}`
                  }
                  value={data?.completedTransactions}
                  onChange={(e, completedTransactions, reason) =>
                    reason === "selectOption"
                      ? setData({
                          ...data,
                          completedTransactions,
                        })
                      : setData({
                          ...data,
                          completedTransactions: "",
                        })
                  }
                  size={"small"}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label="# of Completed Transactions"
                        variant="outlined"
                        size={"small"}
                        value={data?.completedTransactions}
                        required
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <Box
                  display={"flex"}
                  width={"100%"}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                >
                  <Button
                    color={"primary"}
                    variant={"contained"}
                    sx={{
                      textTransform: "uppercase",
                      alignItems: "center",
                      fontSize: 22,
                      py: 2,
                    }}
                    onClick={() => setOpenReviewModal(true)}
                  >
                    Review &#38; Submit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default TradeForm;
