import { Box, Button, Grid, Typography, styled } from "@mui/material";
import React, { useState } from "react";

import { SubHeading } from "../../Styled/Globals";
import UserOrdersModal from "./UserOrdersModal";

const TitleText = styled(Typography)(({ theme }) => ({
  fontSize: 13,
  [theme.breakpoints.up("xl")]: {
    fontSize: 15,
  },
  color: "#5F99FF",
}));

const ValueText = styled(Typography)({
  fontSize: 18,
  fontWeight: "bold",
});

function UserDetails() {
  const [openOrders, setOpenOrders] = useState(false);
  const [openTransactions, setOpenTransactions] = useState(false);

  const handleUserOrders = () => {
    setOpenOrders(true);
  };
  const handleUserTransactions = () => {
    setOpenTransactions(true);
  };
  return (
    <Box border={"1px solid #c4c4c4"} borderRadius={2} py={1} px={2} my={1}>
      <UserOrdersModal
        open={openOrders}
        handleClose={(_) => setOpenOrders(false)}
        variant="orders"
      />

      <UserOrdersModal
        open={openTransactions}
        handleClose={(_) => setOpenTransactions(false)}
        variant={"transactions"}
      />

      <Box>
        <SubHeading>User Details</SubHeading>
      </Box>
      <Grid container>
        <Grid item md={9.5}>
          <Grid container rowGap={4}>
            {/* ROW 1 / START */}
            <Grid item md={2.1}>
              <TitleText>Display Name:</TitleText>
              <ValueText>Bid</ValueText>
            </Grid>
            <Grid item md={2.3}>
              <TitleText>Date Joined:</TitleText>
              <ValueText>Bid</ValueText>
            </Grid>
            <Grid item md={2.6}>
              <TitleText>Seller Level:</TitleText>
              <ValueText>Bid</ValueText>
            </Grid>
            <Grid item md={5}>
              <TitleText>All Time Dollar Value on Bullion MKTS:</TitleText>
              <ValueText>Bid</ValueText>
            </Grid>
            {/* ROW 1 / END */}

            {/* ROW 2 / START */}
            <Grid item md={2.1}>
              <TitleText>Real Name:</TitleText>
              <ValueText>Bid</ValueText>
            </Grid>
            <Grid item md={2.3}>
              <TitleText>Account Type:</TitleText>
              <ValueText>Bid</ValueText>
            </Grid>
            <Grid item md={2.6}>
              <TitleText>Most Active 3 MKTS:</TitleText>
              <ValueText>Bid</ValueText>
            </Grid>
            <Grid item md={5}>
              <TitleText>All Time Commission to Bullion MKTS:</TitleText>
              <ValueText>Bid</ValueText>
            </Grid>
            {/* ROW 2 / END */}

            {/* ROW 3 / START */}
            <Grid item md={2.1}>
              <TitleText>City, State:</TitleText>
              <ValueText>Bid</ValueText>
            </Grid>
            <Grid item md={2.3}>
              <TitleText>Reputation:</TitleText>
              <ValueText>Bid</ValueText>
            </Grid>
            <Grid item md={2.6}>
              <TitleText>Most Active 3 Tickets:</TitleText>
              <ValueText>Bid</ValueText>
            </Grid>
            <Grid item md={5}>
              <TitleText>Last 30D Commission to Bullion MKTS:</TitleText>
              <ValueText>Bid</ValueText>
            </Grid>
            {/* ROW 3 / END */}

            {/* ROW 4 / START */}
            <Grid item md={2.1}>
              <TitleText>Zip:</TitleText>
              <ValueText>Bid</ValueText>
            </Grid>
            <Grid item md={2.3}>
              <TitleText># of Transactions:</TitleText>
              <ValueText>Bid</ValueText>
            </Grid>
            <Grid item md={2.6}>
              <TitleText> # of Arbitrations:</TitleText>
              <ValueText>Bid</ValueText>
            </Grid>
            {/* ROW 4 / END */}
          </Grid>
        </Grid>
        <Grid item md={2.5}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            rowGap={5}
          >
            <InfoButton text={"See User Orders"} action={handleUserOrders} />
            <InfoButton
              text={"See User Transactions"}
              action={handleUserTransactions}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

const InfoButton = ({ text, action }) => {
  return (
    <Button
      disableElevation
      variant={"contained"}
      color={"primary"}
      onClick={action}
      sx={(theme) => ({
        p: 0,
        py: 1,
        width: "100%",
        borderRadius: 3,
        textTransform: "capitalize",
        fontSize: "17px !important",
        [theme.breakpoints.down("xl")]: {
          fontSize: "15px !important",
        },
      })}
    >
      {text}
    </Button>
  );
};

export default UserDetails;
