import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Modal,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import { Heading, SubHeading } from "../../../Styled/Globals";
import React, { useEffect, useState } from "react";

import { Close } from "@mui/icons-material";

const TitleText = styled(Typography)(({ theme }) => ({
  fontSize: 13,
  [theme.breakpoints.up("xl")]: {
    fontSize: 15,
  },
  color: "#5F99FF",
}));

const ValueText = styled(Typography)({
  fontSize: 18,
  fontWeight: "bold",
});
const UserButton = styled(Button)(({ theme }) => ({
  width: "100%",
}));

export default function UserDetailsModal({ open, handleClose }) {
  const [user, setUser] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (!user) {
      setOpenDialog(true);
      setOpenModal(false);
    } else {
      setOpenDialog(false);
      setOpenModal(true);
    }
  }, [user]);

  useEffect(() => {
    setUser(null);
  }, [open]);

  return (
    open && (
      <Box>
        <Dialog onClose={handleClose} open={openDialog}>
          <DialogTitle>
            <Box className={"close-box"}>
              <SubHeading>Select User</SubHeading>
              <Close
                className="pointer"
                fontSize={"inherit"}
                onClick={handleClose}
              />
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box
              display={"flex"}
              width={200}
              flexDirection={"column"}
              rowGap={2}
            >
              <UserButton
                disableElevation
                onClick={(_) => {
                  setUser("buyer");
                }}
                variant={"contained"}
                color={"primary"}
              >
                Buyer
              </UserButton>
              <UserButton
                disableElevation
                onClick={(_) => setUser("seller")}
                variant={"contained"}
                color={"primary"}
              >
                Seller
              </UserButton>
            </Box>
          </DialogContent>
        </Dialog>
        <Modal className={"modal"} open={openModal} onClose={handleClose}>
          <Box
            sx={{
              maxHeight: "100%",
              width: 850,
            }}
            // className={"no-scroll-bar"}
          >
            <Paper sx={(theme) => ({})}>
              <Box
                sx={(theme) => ({
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  px: 2,
                  pt: 2,
                })}
              >
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    alignItems: "center",
                    columnGap: 1,
                  })}
                >
                  <Heading>User Details</Heading>
                  {user && (
                    <Chip
                      label={user}
                      size="small"
                      sx={{
                        backgroundColor: "background.dark",
                        color: "#fff",
                        textTransform: "capitalize",
                      }}
                    />
                  )}
                </Box>
                <Close
                  fontSize={"small"}
                  sx={(theme) => ({
                    cursor: "pointer",
                  })}
                  onClick={handleClose}
                />
              </Box>
              <Divider />
              <Box
                sx={(theme) => ({
                  px: 2,
                  pb: 2,
                })}
              >
                <Box
                  border={"1px solid #c4c4c4"}
                  borderRadius={2}
                  py={1}
                  px={2}
                  my={1}
                >
                  <Grid container rowGap={4}>
                    {/* ROW 1 / START */}
                    <Grid item md={2.1}>
                      <TitleText>Display Name:</TitleText>
                      <ValueText>Bid</ValueText>
                    </Grid>
                    <Grid item md={2.3}>
                      <TitleText>Date Joined:</TitleText>
                      <ValueText>Bid</ValueText>
                    </Grid>
                    <Grid item md={2.6}>
                      <TitleText>Seller Level:</TitleText>
                      <ValueText>Bid</ValueText>
                    </Grid>
                    <Grid item md={5}>
                      <TitleText>
                        All Time Dollar Value on Bullion MKTS:
                      </TitleText>
                      <ValueText>Bid</ValueText>
                    </Grid>
                    {/* ROW 1 / END */}

                    {/* ROW 2 / START */}
                    <Grid item md={2.1}>
                      <TitleText>Real Name:</TitleText>
                      <ValueText>Bid</ValueText>
                    </Grid>
                    <Grid item md={2.3}>
                      <TitleText>Account Type:</TitleText>
                      <ValueText>Bid</ValueText>
                    </Grid>
                    <Grid item md={2.6}>
                      <TitleText>Most Active 3 MKTS:</TitleText>
                      <ValueText>Bid</ValueText>
                    </Grid>
                    <Grid item md={5}>
                      <TitleText>
                        All Time Commission to Bullion MKTS:
                      </TitleText>
                      <ValueText>Bid</ValueText>
                    </Grid>
                    {/* ROW 2 / END */}

                    {/* ROW 3 / START */}
                    <Grid item md={2.1}>
                      <TitleText>City, State:</TitleText>
                      <ValueText>Bid</ValueText>
                    </Grid>
                    <Grid item md={2.3}>
                      <TitleText>Reputation:</TitleText>
                      <ValueText>Bid</ValueText>
                    </Grid>
                    <Grid item md={2.6}>
                      <TitleText>Most Active 3 Tickets:</TitleText>
                      <ValueText>Bid</ValueText>
                    </Grid>
                    <Grid item md={5}>
                      <TitleText>
                        Last 30D Commission to Bullion MKTS:
                      </TitleText>
                      <ValueText>Bid</ValueText>
                    </Grid>
                    {/* ROW 3 / END */}

                    {/* ROW 4 / START */}
                    <Grid item md={2.1}>
                      <TitleText>Zip:</TitleText>
                      <ValueText>Bid</ValueText>
                    </Grid>
                    <Grid item md={2.3}>
                      <TitleText># of Transactions:</TitleText>
                      <ValueText>Bid</ValueText>
                    </Grid>
                    <Grid item md={2.6}>
                      <TitleText> # of Arbitrations:</TitleText>
                      <ValueText>Bid</ValueText>
                    </Grid>
                    {/* ROW 4 / END */}
                  </Grid>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Modal>
      </Box>
    )
  );
}
