import {
  Box,
  CircularProgress,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";

import CustomPagination from "../Common/CustomPagination";
import { ReactComponent as GearSVG } from "../../assets/gears.svg";
import PropTypes from "prop-types";
import moment from "moment";
import { visuallyHidden } from "@mui/utils";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            sx={{
              padding: "0px 0px",
              width: headCell?.width || 100,
              verticalAlign: "bottom",
              textAlign: "left",
              background: headCell?.id === "action" && "#5f99ff",
              borderTopRightRadius: 5,
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell?.disableSorting ? (
              <Box
                component={"div"}
                sx={(theme) => ({
                  width: "100%",
                  display: "flex",
                  color: theme?.palette?.primary?.main,
                  fontSize: "small",
                  [theme.breakpoints.up("xl")]: {
                    fontSize: "medium",
                  },
                  ...headCell?.sx,
                })}
              >
                {headCell?.label}
              </Box>
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                sx={(theme) => ({
                  fontSize: "small",
                  padding: "5px 0px",
                  display: "flex",
                  lineHeight: 1.5,
                  width: "100%",
                  justifyContent: "flex-start",
                  color: theme?.palette?.primary?.main,
                  "& .MuiTableSortLabel-icon": {
                    margin: "0px",
                    fontSize: "small",
                  },
                  [theme.breakpoints.up("xl")]: {
                    fontSize: "medium",
                    "& .MuiTableSortLabel-icon": {
                      fontSize: "medium",
                    },
                  },
                  ...headCell?.sx,
                })}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme, center }) => ({
  padding: "10px 0px",
  paddingRight: "5px",
  fontSize: "14px",
  textAlign: center ? "center" : "left",
  border: "none",
  [theme.breakpoints.up("xl")]: {
    fontSize: "16px",
  },
}));

export default function CurrentArbitrationsTable({
  rows,
  loading,
  onMenuSelect,
  handleDataSelect,
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const dense = true;
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tableData, setTableData] = useState([]);
  const [menuIndex, setMenuIndex] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, id) => {
    setMenuIndex(id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setMenuIndex(null);
    setAnchorEl(null);
  };

  const handleMenuSelect = (id) => onMenuSelect(id);

  const actionMenus = [
    {
      id: 1,
      title: "Proivde Statement",
    },
    {
      id: 2,
      title: "Answer Question(s)",
    },
    {
      id: 3,
      title: "Provide Evidence",
    },
    {
      id: 4,
      title: "Schedule Call",
    },
    {
      id: 5,
      title: "Schedule Hearing",
    },
    {
      id: 6,
      title: "Archive",
    },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Box mb={2}>
      <Box
        display="flex"
        justifyContent={"center"}
        alignContent={"center"}
        sx={{ width: "100%", bgcolor: "#fff", mb: 2, borderRadius: 4 }}
        // minHeight={350}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <TableContainer
            sx={(theme) => ({
              minHeight: "380px",
            })}
          >
            <Table sx={{ minWidth: 750 }} size={dense ? "small" : "medium"}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                headCells={headCells}
              />
              <TableBody>
                {rows.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={10}>
                      <Typography align="center">No Arbirations</Typography>
                    </TableCell>
                  </TableRow>
                ) : null}

                {stableSort(tableData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    // const objectKeys = Object.keys(row);
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row._id}
                        selected={isItemSelected}
                      >
                        <StyledTableCell
                          component="td"
                          id={labelId}
                          scope="row"
                          sx={{ pl: 1 }}
                        >
                          {row?.transactionNumber}
                        </StyledTableCell>

                        <StyledTableCell
                          component="td"
                          id={labelId}
                          scope="row"
                        >
                          {moment(row?.date).format("MM/DD/YYYY")}
                        </StyledTableCell>
                        <StyledTableCell
                          component="td"
                          id={labelId}
                          scope="row"
                        >
                          {row?.mkt}
                        </StyledTableCell>
                        <StyledTableCell
                          component="td"
                          id={labelId}
                          scope="row"
                        >
                          {row?.ticker}
                        </StyledTableCell>
                        <StyledTableCell
                          component="td"
                          id={labelId}
                          scope="row"
                        >
                          {row?.description}
                        </StyledTableCell>

                        <StyledTableCell
                          component="td"
                          id={labelId}
                          scope="row"
                        >
                          {row?.quantity}
                        </StyledTableCell>
                        <StyledTableCell
                          component="td"
                          id={labelId}
                          scope="row"
                        >
                          ${row?.price}
                        </StyledTableCell>
                        <StyledTableCell
                          component="td"
                          id={labelId}
                          scope="row"
                        >
                          ${row?.total}
                        </StyledTableCell>
                        <StyledTableCell
                          component="td"
                          id={labelId}
                          scope="row"
                        >
                          {moment(row?.dateFiled).format("MM/DD/YYYY")}
                        </StyledTableCell>
                        <StyledTableCell
                          component="td"
                          id={labelId}
                          scope="row"
                        >
                          {row?.type}
                        </StyledTableCell>
                        <StyledTableCell
                          component="td"
                          id={labelId}
                          scope="row"
                        >
                          {row?.cause}
                        </StyledTableCell>
                        <StyledTableCell
                          component="td"
                          id={labelId}
                          scope="row"
                        >
                          {row?.status}
                        </StyledTableCell>
                        <TableCell
                          component="td"
                          id={labelId}
                          scope="row"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "none",
                          }}
                        >
                          <GearSVG
                            onClick={(e) => handleClick(e, labelId)}
                            className={"pointer"}
                          />
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open && menuIndex === labelId}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                            sx={{
                              minWidth: 155,
                              width: "100%",
                            }}
                          >
                            {actionMenus?.map((menu) => (
                              <MenuItem
                                onClick={(_) => {
                                  handleDataSelect(row);
                                  handleMenuSelect(menu?.id);
                                  handleClose();
                                }}
                              >
                                <Box
                                  display={"flex"}
                                  justifyContent={"left"}
                                  alignItems="center"
                                  width={"100%"}
                                >
                                  <Typography
                                    sx={(theme) => ({
                                      fontSize: "small",
                                      [theme.breakpoints.up("xl")]: {
                                        fontSize: "medium",
                                      },
                                    })}
                                  >
                                    {menu?.title}
                                  </Typography>
                                </Box>
                              </MenuItem>
                            ))}
                          </Menu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
      <CustomPagination
        rowsPerPage={rowsPerPage}
        rows={rows}
        setTableData={setTableData}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
}
const headCells = [
  {
    id: "transactionNumber",
    numeric: false,
    width: "1%",
    label: "Transaction#",
    sx: { pl: 1 },
  },
  {
    id: "date",
    numeric: false,
    width: "2%",
    label: "Transaction Date",
  },
  {
    id: "mkt",
    numeric: false,
    width: "2%",
    label: "MKT",
  },
  {
    id: "ticker",
    numeric: false,
    width: "2%",
    label: "Ticker",
  },
  {
    id: "description",
    numeric: false,
    width: "15%",
    label: "Description",
  },
  {
    id: "quantity",
    numeric: false,
    width: "1%",
    label: "Quantity",
  },
  {
    id: "price",
    numeric: true,
    width: "1%",
    label: "Price",
  },
  {
    id: "total",
    numeric: true,
    width: "1%",
    label: "Total",
  },
  {
    id: "dateFiled",
    numeric: false,
    width: "2%",
    label: "Date Filed",
  },
  {
    id: "type",
    numeric: false,
    width: "2%",
    label: "Type",
  },
  {
    id: "cause",
    numeric: false,
    width: "10%",
    label: "Cause",
  },
  {
    id: "status",
    numeric: false,
    width: "2%",
    label: "Status",
  },
  {
    id: "action",
    numeric: false,
    width: "5%",
    label: "Action",
    disableSorting: true,
    sx: {
      alignItems: "center",
      justifyContent: "center",
      fontSize: "large",
      color: "#fff",
      padding: 0,
      height: "max-content",
    },
  },
];
