import { Box, styled } from "@mui/material";
import React from "react";
import Marquee from "react-fast-marquee";
import { Link as DOMLink } from "react-router-dom";

function HomeMarqee() {
  const Link = styled(DOMLink)(({ theme }) => ({
    fontSize: 12,
    [theme.breakpoints.up("xl")]: {
      fontSize: 14,
    },
  }));

  return (
    <Marquee pauseOnHover speed={45}>
      <Box
        bgcolor={"#fff"}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          margin: "auto",
        }}
        height={30}
        gap={5}
        borderTop={"1px solid #000"}
        borderBottom={"1px solid #000"}
      >
        <Link to={"/markets"} style={{ marginLeft: 50, color: "#696a6a" }}>
          Gold INDEX:
        </Link>
        <Link to={"/markets"} style={{ color: "#696a6a" }}>
          Gold B MKTS:
        </Link>
        <Link to={"/markets"} style={{ color: "#696a6a" }}>
          Silver INDEX:
        </Link>
        <Link to={"/markets"} style={{ color: "#696a6a" }}>
          Silver B MKTS:
        </Link>
        <Link to={"/markets"} style={{ color: "#696a6a" }}>
          Platinum INDEX:
        </Link>
        <Link to={"/markets"} style={{ color: "#696a6a" }}>
          Platinum B MKTS:
        </Link>
        <Link to={"/markets"} style={{ color: "#696a6a" }}>
          Palladium INDEX:
        </Link>
        <Link to={"/markets"} style={{ color: "#696a6a" }}>
          Palladium B MKTS:
        </Link>
        <Link to={"/markets"} style={{ color: "#696a6a" }}>
          Copper INDEX:
        </Link>
        <Link to={"/markets"} style={{ color: "#696a6a" }}>
          Copper B MKTS:
        </Link>
      </Box>
    </Marquee>
  );
}

export default HomeMarqee;
