import React, { useState } from "react";
import { Box, Divider, Modal, Paper, Typography } from "@mui/material";
import SingleTransactionTable from "../SingleTransactionTable/SingleTransactionTable";
import { Heading, SubHeading } from "../../Styled/Globals";
import EvidenceForm from "./EvidenceForm";
import Close from "@mui/icons-material/Close";

const EvidenceModal = ({ open, handleClose, data, onSubmit }) => {
  const [formData, setFormData] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  return (
    <Modal className={"modal"} open={open} onClose={handleClose}>
      <Box
        sx={{
          maxWidth: "95%",
        }}
        className={"no-scroll-bar"}
      >
        <Paper>
          <Box
            component={"div"}
            width={"100%"}
            display={"flex"}
            justifyContent={"center"}
            position={"relative"}
            py={1}
          >
            <Heading>Provide Evidence</Heading>
            <Close onClick={handleClose} className={"c-absolute"} />
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: 1,
              px: 4,
              py: 2,
            }}
          >
            <Typography>
              The Arbitrator at Bullion MKTS has a question that you provide
              evidence to them to support a claim that you have made related to
              the below transaction that is currently in Arbitration. Please
              return an answer within 24 hours to avoid a default judgement
              against you.
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: 1,
              }}
            >
              <SubHeading>Transaction Details</SubHeading>
              <SingleTransactionTable loading={false} data={data} />
            </Box>

            <Box>
              <EvidenceForm
                formData={formData}
                setFormData={setFormData}
                onChange={onChange}
                handleSubmit={handleSubmit}
              />
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default EvidenceModal;
