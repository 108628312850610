import {
  Box,
  // Checkbox,
  // FormControlLabel,
  // Menu,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
// import styled from "@emotion/styled";
import { FilterList as FilterListIcon } from "@mui/icons-material";
import { StyledButton } from "../Styled/Globals";
import FilterModal from "./FilterModal";

const MarketsFilterButton = () => {
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => setAnchorEl(event.currentTarget);
  // const handleClose = () => setAnchorEl(null);

  const theme = useTheme();
  const isXL = useMediaQuery(theme.breakpoints.up("xl"));

  // const StyledMenu = styled((props) => (
  //   <Menu
  //     elevation={0}
  //     anchorOrigin={{
  //       vertical: "bottom",
  //       horizontal: "right",
  //     }}
  //     transformOrigin={{
  //       vertical: "top",
  //       horizontal: "right",
  //     }}
  //     {...props}
  //   />
  // ))(({ theme }) => ({
  //   // boxShadow: " 0 20px 10px rgb(0 0 0 / 0.2)",
  // }));
  // const markets = [
  //   "Gold",
  //   "Silver",
  //   "Platinum",
  //   "Palladium",
  //   "Copper",
  //   "Gold/Silver",
  //   "Coin & Currency",
  //   "Rhodium",
  //   "Iridium",
  //   "Osmium",
  //   "Rhenium",
  //   "Ruthenium",
  //   "Beryllium",
  // ];

  const [openFilterModal, setOpenFilterModal] = React.useState(false);

  return (
    <div>
      <StyledButton
        id="demo-positioned-button"
        // aria-controls={open ? "demo-positioned-menu" : undefined}
        // aria-haspopup="true"
        // aria-expanded={open ? "true" : undefined}
        disableElevation
        // onClick={handleClick}
        onClick={(_) => setOpenFilterModal(true)}
        style={{
          width: isXL ? 150 : 115,
          height: isXL ? 65 : 50,
          fontSize: 24,
        }}
      >
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <FilterListIcon
            style={{
              transform: "scale(1.3)",
              marginRight: 15,
            }}
          />
          <Typography
            sx={{
              fontSize: isXL ? 24 : 20,
              fontWeight: "bold",
            }}
          >
            Filter
          </Typography>
        </Box>
      </StyledButton>
      <FilterModal
        handleClose={(_) => setOpenFilterModal(false)}
        open={openFilterModal}
        showMKTS={true}
      />
      {/* <StyledMenu
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box display={"grid"} pl={2}>
          {markets.map((market) => {
            return (
              <FormControlLabel
                control={<Checkbox defaultChecked sx={{ mr: 2 }} />}
                label={<Typography fontWeight={"bold"}>{market}</Typography>}
              />
            );
          })}
        </Box>
      </StyledMenu> */}
    </div>
  );
};
export default MarketsFilterButton;
