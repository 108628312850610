import React, { Fragment, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import { StyledCheckboxLabel } from "../../../components/Styled/Forms";
import { Heading } from "../../../components/Styled/Globals";

export default function CommunicationPreferences({ CommunicationSVG, bRef }) {
  //eslint-disable-next-line
  const [data, setData] = useState(null);
  return (
    <Fragment>
      <Box
        ref={bRef}
        component={"div"}
        display="flex"
        alignItems={"center"}
        my={3}
      >
        <CommunicationSVG
          style={{
            marginRight: 20,
          }}
        />
        <Typography fontSize={30} fontWeight={"bold"}>
          Communication Preferences
        </Typography>
      </Box>
      <Box>
        <Heading mb={2}>Notification Preferences</Heading>
        <Paper style={{ backgroundColor: "#fff", padding: "25px " }}>
          <Typography
            variant={"p"}
            component={"p"}
            fontSize={18}
            fontWeight={"bold"}
            marginBottom={3}
          >
            Select:
          </Typography>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <FormControlLabel
                control={<Checkbox />}
                label={<StyledCheckboxLabel>Trade Occurs</StyledCheckboxLabel>}
              />
            </Grid>
            <Grid item md={6}>
              <FormControlLabel
                control={<Checkbox />}
                label={<StyledCheckboxLabel>Order Expires</StyledCheckboxLabel>}
              />
            </Grid>
            <Grid item md={6}>
              <FormControlLabel
                control={<Checkbox />}
                label={
                  <StyledCheckboxLabel>Arbitration Event</StyledCheckboxLabel>
                }
              />
            </Grid>
            <Grid item md={6}>
              <FormControlLabel
                control={<Checkbox />}
                label={
                  <StyledCheckboxLabel>
                    Technical Problems on Platform
                  </StyledCheckboxLabel>
                }
              />
            </Grid>
            <Grid item md={6}>
              <FormControlLabel
                control={<Checkbox />}
                label={
                  <StyledCheckboxLabel>
                    Platform News or New Features
                  </StyledCheckboxLabel>
                }
              />
            </Grid>
          </Grid>
          <Box
            display="flex"
            alignItems={"center"}
            justifyContent={"end"}
            width={"100%"}
          >
            <Button
              variant={"contained"}
              color={"primary"}
              sx={{
                width: "25%",
              }}
            >
              Save
            </Button>
          </Box>
        </Paper>
      </Box>
      <Box>
        <Heading my={2}>Newsletter Preferences </Heading>

        <Paper style={{ backgroundColor: "#fff", padding: "25px " }}>
          <Typography
            variant={"p"}
            component={"p"}
            fontSize={18}
            fontWeight={"bold"}
            marginBottom={3}
          >
            Email Me the Newsletter:{" "}
          </Typography>
          <Grid container spacing={3}>
            <Grid item md={3}>
              <FormControlLabel
                control={<Checkbox />}
                label={<StyledCheckboxLabel>Daily</StyledCheckboxLabel>}
              />
            </Grid>
            <Grid item md={3}>
              <FormControlLabel
                control={<Checkbox />}
                label={<StyledCheckboxLabel>Bi-Monthly</StyledCheckboxLabel>}
              />
            </Grid>
            <Grid item md={3}>
              <FormControlLabel
                control={<Checkbox />}
                label={<StyledCheckboxLabel>Bi-Annually</StyledCheckboxLabel>}
              />
            </Grid>
            <Grid item md={3}>
              <FormControlLabel
                control={<Checkbox />}
                label={<StyledCheckboxLabel>Never</StyledCheckboxLabel>}
              />
            </Grid>
            <Grid item md={3}>
              <FormControlLabel
                control={<Checkbox />}
                label={<StyledCheckboxLabel>Weekly</StyledCheckboxLabel>}
              />
            </Grid>
            <Grid item md={3}>
              <FormControlLabel
                control={<Checkbox />}
                label={<StyledCheckboxLabel>Monthly</StyledCheckboxLabel>}
              />
            </Grid>
            <Grid item md={3}>
              <FormControlLabel
                control={<Checkbox />}
                label={<StyledCheckboxLabel>Annually</StyledCheckboxLabel>}
              />
            </Grid>
          </Grid>
          <Box
            display="flex"
            alignItems={"center"}
            justifyContent={"end"}
            width={"100%"}
          >
            <Button
              variant={"contained"}
              color={"primary"}
              sx={{
                width: "25%",
              }}
            >
              Save
            </Button>
          </Box>
        </Paper>
      </Box>
    </Fragment>
  );
}
