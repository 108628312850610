import { Box, Typography } from "@mui/material";
import React from "react";
import Hexagon from "../../components/Hexagon/Hexagon";
import { Heading, StyledContainer } from "../../components/Styled/Globals";
import { ReactComponent as NotificationsSVG } from "../../assets/bell-icon.svg";
import NotificationsTable from "../../components/Notifications/NotificationsTable";
import { Link } from "react-router-dom";
import SearchField from "../../components/Global/SearchField";
import { sampleNotifications } from "../../constants/SampleData/notifications";

const Notifications = () => {
  const _headcells = [
    {
      id: "notificationNumber",
      label: "Notification#",
      numeric: true,
      width: "5%",
    },
    {
      id: "date",
      numeric: false,
      label: "Date",
      width: "5%",
    },
    {
      id: "relatedTo",
      numeric: false,
      label: "Related To",
      width: "7%",
    },
    {
      id: "tradeNumber",
      numeric: true,
      label: "Trade#",
      width: "5%",
    },
    {
      id: "subject",
      numeric: false,
      label: "Subject",
      width: "40%",
    },
    {
      id: "action",
      numeric: false,
      label: "Action",
      disableSorting: true,
      width: "10%",
      sx: {
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
        fontSize: "large",
      },
    },
  ];
  return (
    <Box height={"100%"} pb={15}>
      <StyledContainer>
        <Hexagon
          title={
            <Box
              display="flex"
              width={"100%"}
              gap={2}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <NotificationsSVG />
              <Typography color={"primary"} fontWeight={"bold"} fontSize={26}>
                Notifications
              </Typography>
            </Box>
          }
        />

        {/* NEW */}
        <Box>
          <Box mb={1}>
            <Heading>New</Heading>
          </Box>
          <Box component={"div"}>
            <NotificationsTable
              rows={sampleNotifications}
              headCells={_headcells}
              tableTitle={null}
              loading={false}
            />
          </Box>
        </Box>
        {/* SEEN */}
        <Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mb={1}
          >
            <Box
              display={"flex"}
              alignItems={"baseline"}
              justifyContent={"flex-start"}
              columnGap={5}
              width={"100%"}
            >
              <Heading>Seen</Heading>
              <Link to={"/notifications/archived"}>
                <Typography
                  fontWeight={"bold"}
                  color={"primary"}
                  sx={{ textDecoration: "underline" }}
                >
                  See Archives
                </Typography>
              </Link>
            </Box>
            <Box
              display={"flex"}
              width={"100%"}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <SearchField title={"Search..."} noMargin />
            </Box>
          </Box>
          <Box component={"div"}>
            <NotificationsTable
              rows={[...sampleNotifications]}
              headCells={_headcells}
              tableTitle={null}
              loading={false}
            />
          </Box>
        </Box>
      </StyledContainer>
    </Box>
  );
};

export default Notifications;
