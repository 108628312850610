import React, { Fragment } from "react";

import MarketControlTable from "./MarketControlTable";
import { SubHeading } from "../../../Styled/Globals";

const MarketControl = () => {
  return (
    <Fragment>
      <SubHeading
        sx={{
          mt: 2,
          mb: 1,
        }}
      >
        Market Control
      </SubHeading>
      <MarketControlTable />
    </Fragment>
  );
};

export default MarketControl;
