import { Box, Button, Typography } from "@mui/material";

import FundingTable from "./FundingTable";
import { Link } from "react-router-dom";
import React from "react";

function Funding({ SVG, bRef }) {
  return (
    <Box ref={bRef}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          component={"div"}
          marginBottom={2}
          display="flex"
          alignItems={"center"}
          width={"100%"}
        >
          <SVG
            style={{
              marginRight: 20,
            }}
          />
          <Typography fontSize={30} fontWeight={"bold"}>
            Funding
          </Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          alginItems={"center"}
          width="100%"
        >
          <Button variant={"contained"} color={"primary"}>
            <Link to={"/funding"}>
              <Typography
                color={"#fff"}
                sx={{
                  textTransform: "uppercase",
                }}
              >
                Full Dashboard
              </Typography>
            </Link>
          </Button>
        </Box>
      </Box>

      <Box component={"div"}>
        <FundingTable
          rows={[
            {
              fundId: 4432,
              date: new Date("07-20-1999"),
              source: "AAPL",
              status: "Complete",
              amount: "$2300",
              type: "Out",
            },
            {
              fundId: 4245,
              date: new Date("10-20-1999"),
              source: "GOLD",
              status: "Incomplete",
              amount: "$4500",
              type: "In",
            },
            {
              fundId: 5325,
              date: new Date("02-05-2005"),
              source: "AAPL",
              status: "Incomplete",
              amount: "$5000",
              type: "Out",
            },
            {
              fundId: 5323,
              date: Date.now(),
              source: "PLAT",
              status: "Complete",
              amount: "$5500",
              type: "In",
            },
            {
              fundId: 6632,
              date: new Date("12-22-2020"),
              source: "AAPL",
              status: "Complete",
              amount: "$6900",
              type: "Out",
            },
            {
              fundId: 6635,
              date: new Date("12-12-2012"),
              source: "SLVR",
              status: "Incomplete",
              amount: "$9000",
              type: "In",
            },
          ]}
          headCells={[
            {
              id: "fundId",
              numeric: false,
              disablePadding: true,
              label: "Fund ID",
            },
            {
              id: "date",
              numeric: false,
              disablePadding: false,
              label: "Date",
            },
            {
              id: "source",
              numeric: false,
              disablePadding: true,

              label: "Source",
            },
            {
              id: "status",
              numeric: false,
              disablePadding: true,
              label: "Status",
            },
            {
              id: "amount",
              numeric: true,
              disablePadding: true,
              label: "Amount",
            },
            {
              id: "type",
              numeric: false,
              disablePadding: true,
              label: "Type",
            },
          ]}
          tableTitle={null}
          loading={false}
        />
      </Box>
    </Box>
  );
}

export default Funding;
