import React from "react";
import { Box, Divider, Modal, Paper, Typography } from "@mui/material";
import { Heading, StyledButton } from "../../Styled/Globals";
import Logo from "../../../assets/bullion-logo.png";
import PackagingSlipTable from "./PackagingSlipTable";
import moment from "moment";

const PackagingSlip = ({ open, handleClose }) => {
  const style = {
    position: "absolute",
    top: "50%",
    border: "none",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "98%",
    height: "98%",
    overflowX: "hidden",
    display: "block",
  };

  const data = {
    transactionNumber: 4567,
    mkt: "Gold",
    ticker: "AAPL",
    bidAsk: 800,
    description: "Selling all my assets",
    quantity: 200,
    price: 1000,
    total: 1000,
  };

  const LabelCard = (title, name, addressLane1, addressLane2) => {
    return (
      <Box display={"flex"} flexDirection={"column"} pb={10}>
        <Heading>{title}</Heading>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
            p: 2,
            bgcolor: "#E5E5E5",
            borderRadius: 2,
            width: 350,
            height: 150,
          }}
        >
          <Typography sx={{ fontSize: 22 }}>{name}</Typography>
          <Typography sx={{ fontSize: 22 }}>{addressLane1}</Typography>
          <Typography sx={{ fontSize: 22 }}>{addressLane2}</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box style={style} className={"no-scroll-bar"}>
        <Paper>
          <Box
            component={"div"}
            width={"100%"}
            display={"flex"}
            sx={{
              px: 5,
            }}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            {LabelCard(
              "Shipping Label",
              "John",
              "312 Florida Dr.",
              "Cape Coral, FL 74565"
            )}
            {LabelCard(
              "Return Label",
              "Rony",
              "534 Florida Dr.",
              "Cape Coral, FL 74565"
            )}
          </Box>
          <Divider />
          <Box py={10}>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection="column"
            >
              <img
                src={Logo}
                width={230}
                alt="bullion-logo"
                className="center-block"
              />
              <Heading>Packaging Slip</Heading>
            </Box>
            <Box mx={5} border={"1px solid #c4c4c4"} borderRadius={1}>
              <PackagingSlipTable
                rows={Array.from(Array(5), () => data)}
                loading={false}
              />
            </Box>
            <Box mt={5} mx={5}>
              <Box display={"flex"} flexDirection={"column"} rowGap={5}>
                <Typography fontSize={22}>
                  <Typography
                    fontWeight={"bold"}
                    fontSize={22}
                    component={"span"}
                  >
                    Seller Display Name:{" "}
                  </Typography>{" "}
                  John Smith
                </Typography>
                <Typography fontSize={22}>
                  <Typography
                    fontWeight={"bold"}
                    fontSize={22}
                    component={"span"}
                  >
                    Trade Date:{" "}
                  </Typography>{" "}
                  {moment(Date.now()).format("DD MMMM, YYYY")}
                </Typography>
                <Typography fontSize={22}>
                  <Typography
                    fontWeight={"bold"}
                    fontSize={22}
                    component={"span"}
                  >
                    Close Date:{" "}
                  </Typography>{" "}
                  {moment(Date.now()).format("DD MMMM, YYYY")}
                </Typography>
              </Box>
              <Typography my={4}>
                If your trade received is as described, please log onto your
                Bullion MKTS account and confirm delivery so the trade will
                settle. The seller may declare the item delivered based on the
                tracking number data and you will then have 24 hours to agree or
                Arbitrate.
              </Typography>
              <Box
                width={"100%"}
                alignItems={"center"}
                justifyContent={"center"}
                display={"flex"}
              >
                <StyledButton
                  style={{
                    width: 200,
                    height: 75,
                    textTransform: "uppercase",
                  }}
                  onClick={() => handleClose()}
                >
                  Print
                </StyledButton>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default PackagingSlip;
