import { Box, Typography } from "@mui/material";

import ClearedFunds from "../../components/ClearedFunds";
import Hexagon from "../../components/Hexagon/Hexagon";
import OpenOrderTable from "../MyAccount/OpenOrder/OpenOrderTable";
import { ReactComponent as OpenOrdersSVG } from "../../assets/my-account/open-orders.svg";
import React from "react";
import SearchField from "../../components/Global/SearchField";
import { StyledContainer } from "../../components/Styled/Globals";

function OpenOrders() {
  return (
    <Box height={"100%"}>
      <StyledContainer>
        <Box className={"b-flex-end"}>
          <ClearedFunds />
        </Box>
        <Box
          className={"b-flex-end"}
          sx={{
            mb: -5,
          }}
        >
          <Typography sx={{ fontWeight: 500 }}>
            Available for Trading
          </Typography>
        </Box>
        <Hexagon
          title={
            <Box
              display="flex"
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={1.5}
            >
              <OpenOrdersSVG />
              <Typography color={"primary"} fontWeight={"bold"} fontSize={26}>
                Open Orders
              </Typography>
            </Box>
          }
        />
        <Box component={"div"}>
          <Box component={"div"} my={2}>
            <SearchField width={500} title={"Search"} noMargin />
          </Box>
          <Box>
            <OpenOrderTable
              rows={[
                {
                  orderNumber: 1234567890,
                  mkt: "Gold",
                  ticker: "AAPL",
                  bidAsk: 800,
                  description: "Reference site about in lorem email",
                  quantity: 200,
                  price: 1000,
                  total: 1000,
                },
                {
                  orderNumber: 4356783514,
                  mkt: "Platinum",
                  ticker: "PLAT",
                  bidAsk: 800,
                  description: "Reference site about in lorem email",
                  quantity: 500,
                  price: 2000,
                  total: 3500,
                },
                {
                  orderNumber: 8473920582,
                  mkt: "Silver",
                  ticker: "SLVR",
                  bidAsk: 830,
                  description: "Reference site about in lorem email",
                  quantity: 250,
                  price: 1030,
                  total: 1020,
                },
                {
                  orderNumber: 2984769302,
                  mkt: "Gold",
                  ticker: "AAPL",
                  bidAsk: 800,
                  description: "Reference site about in lorem email",
                  quantity: 200,
                  price: 1000,
                  total: 1000,
                },
                {
                  orderNumber: 1385739584,
                  mkt: "Gold",
                  ticker: "AAPL",
                  bidAsk: 800,
                  description: "Reference site about in lorem email",
                  quantity: 200,
                  price: 1000,
                  total: 1000,
                },
                {
                  orderNumber: 9585746303,
                  mkt: "Gold",
                  ticker: "AAPL",
                  bidAsk: 800,
                  description: "Reference site about in lorem email",
                  quantity: 200,
                  price: 1000,
                  total: 1000,
                },
              ]}
              headCells={[
                {
                  id: "orderNumber",
                  numeric: false,
                  width: "10%",
                  label: "Order#",
                },
                {
                  id: "mkt",
                  numeric: false,
                  width: "5%",
                  label: "MKT",
                },
                {
                  id: "ticker",
                  numeric: false,
                  width: "2%",
                  label: "Ticker",
                },
                {
                  id: "bidAsk",
                  numeric: false,
                  width: "2%",
                  label: "Bid/Ask",
                },
                {
                  id: "description",
                  numeric: false,
                  width: "25%",
                  label: "Description",
                },
                {
                  id: "quantity",
                  numeric: true,
                  width: "5%",
                  label: "Quantity",
                },
                {
                  id: "price",
                  numeric: true,
                  width: "5%",
                  label: "Price",
                },
                {
                  id: "total",
                  numeric: true,
                  width: "5%",
                  label: "Total",
                },
                {
                  id: "options",
                  numeric: false,
                  width: "5%",
                  label: "",
                  disableSorting: true,
                },
                {
                  id: "action",
                  numeric: false,
                  width: "15%",
                  label: "Action",
                  disableSorting: true,
                  sx: {
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "large",
                    color: "#fff",
                    paddingY: 1,
                  },
                },
              ]}
              tableTitle={null}
              loading={false}
            />
          </Box>
        </Box>
      </StyledContainer>
    </Box>
  );
}

export default OpenOrders;
