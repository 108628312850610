import {
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import React, { useState } from "react";

import { ReactComponent as GearSVG } from "../../assets/gears.svg";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";

// import styled from 'styled-components';

// import { StyledButton } from '../../../styled/Button';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            sx={
              headCell.id === "actions"
                ? {
                    backgroundColor: "#5f99ff",
                    justifyContent: "center",
                    borderTopRightRadius: 10,
                    paddingX: 1,
                    paddingY: 2,
                  }
                : {
                    paddingX: 0,
                    paddingY: 2,
                  }
            }
            // paddingY={"10px"}
            // paddingX={"none"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={
                headCell.id === "actions"
                  ? {
                      justifyContent: "center",
                      width: "100%",
                      backgroundColor: "#5f99ff",
                      paddingX: 3,
                      paddingY: 0,
                      borderTopRightRadius: 5,
                    }
                  : {}
              }
            >
              <Typography
                fontSize={18}
                color={headCell.id === "actions" ? "#fff" : "primary.dark"}
              >
                {headCell.label}
              </Typography>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({ data, options, loading }) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Box mb={4} sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }} elevation={0}>
        <Box
          px={2}
          display="flex"
          justifyContent={"center"}
          alignContent={"center"}
          py={3}
          // minHeight={350}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <TableContainer>
              <Table sx={{ minWidth: 750 }} size={"small"}>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  headCells={headCells}
                />
                <TableBody>
                  {stableSort(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      // const objectKeys = Object.keys(row);
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row._id}
                          selected={isItemSelected}
                        >
                          <TableCell component="td" id={labelId} scope="row">
                            {row?.transactionNumber}
                          </TableCell>
                          <TableCell
                            component="td"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row?.ticker}
                          </TableCell>
                          <TableCell
                            component="td"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row?.description}
                          </TableCell>{" "}
                          <TableCell component="td" id={labelId} scope="row">
                            {row?.mkt}
                          </TableCell>
                          <TableCell
                            component="td"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row?.quantity}
                          </TableCell>
                          <TableCell
                            component="td"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            ${row?.price}
                          </TableCell>
                          <TableCell
                            component="td"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            ${row?.total}
                          </TableCell>
                          <TableCell
                            component="td"
                            id={labelId}
                            scope="row"
                            padding={"normal"}
                            sx={{ cursor: "pointer" }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <GearSVG onClick={handleClick} />
                            </Box>
                            <Menu
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={open && options?.length > 0}
                              onClose={handleClose}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                              sx={{
                                minWidth: 155,
                              }}
                            >
                              {options?.map((option) => (
                                <MenuItem onClick={option?.handler} sx={{}}>
                                  <Box
                                    display={"flex"}
                                    justifyContent={"left"}
                                    alignItems="center"
                                    width={"100%"}
                                  >
                                    <Typography>{option?.title}</Typography>
                                  </Box>
                                </MenuItem>
                              ))}
                            </Menu>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
    </Box>
  );
}

const headCells = [
  {
    id: "transactionNumber",
    numeric: false,
    disablePadding: true,
    label: "Transaction#",
  },
  {
    id: "ticker",
    numeric: false,
    disablePadding: true,
    label: "Ticker",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Description",
  },
  {
    id: "mkt",
    numeric: false,
    disablePadding: true,
    label: "MKT",
  },

  {
    id: "quantity",
    numeric: true,
    disablePadding: true,
    label: "Quantity",
  },
  {
    id: "price",
    numeric: true,
    disablePadding: true,
    label: "Price",
  },
  {
    id: "total",
    numeric: true,
    disablePadding: true,
    label: "Total",
  },
  //   {
  //     id: "options",
  //     numeric: false,
  //     disablePadding: true,
  //     label: "Options",
  //   },
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    label: "Action",
  },
];
