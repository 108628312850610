import "./Markets.css";

import {
  Box,
  Button,
  Grid,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Paper,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import { StyledButton, StyledContainer } from "../../components/Styled/Globals";

import BidTable from "../../components/Markets/BidTable";
import Hexagon from "../../components/Hexagon/Hexagon";
import HomeMarqee from "../../components/HomePage/HomeMarqee";
import ImageModal from "../../components/CommonModals/ImageModal";
import { Link } from "react-router-dom";
import MarketsFilterButton from "../../components/Markets/Filter";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import SearchModal from "../../components/CommonModals/SearchModal";
import { additionalMarkets } from "../../constants/markets";
import marketsImg from "../../assets/markets/markets.png";
import { useTheme } from "@mui/material/styles";

function Markets() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchModal, setSearchModal] = React.useState(false);
  const [openImg, setOpenImg] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const _markets = ["gold", "silver", "platinum", "palladium", "copper"];

  const theme = useTheme();
  const isXL = useMediaQuery(theme.breakpoints.up("xl"));

  const TableLabel = styled(Typography)({
    fontSize: isXL ? 28 : 22,
    fontWeight: "bold",
    margin: "16px 0",
  });

  return (
    <Box height={"100%"}>
      <ImageModal
        open={openImg}
        handleClose={(_) => setOpenImg(false)}
        src={marketsImg}
        alt={"bullion mkts trading terminal img"}
      />
      <SearchModal
        open={searchModal}
        handleClose={(e) => setSearchModal(false)}
        title={"Search"}
      />
      <HomeMarqee />
      <StyledContainer>
        <Hexagon
          width={440}
          title={
            <Box
              display="flex"
              width={"100%"}
              justifyContent={"space-around"}
              alignItems={"center"}
            >
              <Typography color={"primary"} fontWeight={"bold"} fontSize={22}>
                Bullion MKTS Trading Terminal{" "}
              </Typography>
            </Box>
          }
        />
        <StyledContainer mt={1} mb={2}>
          <img
            src={marketsImg}
            alt={"markets"}
            width={"47%"}
            className={" center-block pointer"}
            onClick={(_) => setOpenImg(true)}
          />
        </StyledContainer>
      </StyledContainer>

      <Box position={"relative"} py={1} mb={8}>
        <Box className="blue-bg-markets"></Box>
        <StyledContainer>
          <StyledContainer>
            <Paper
              onClick={() => setSearchModal(true)}
              component="form"
              sx={{
                p: "0px 4px",
                display: "flex",
                alignItems: "center",
                margin: "25px auto",
                background: "#fff",
              }}
            >
              <IconButton sx={{ p: "10px" }} aria-label="menu">
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1, paddingY: 1.5 }}
                placeholder="Search Description/Ticker"
                inputProps={{ "aria-label": "search description ticker" }}
              />
            </Paper>
          </StyledContainer>
          <Box width={350} margin={"auto"}>
            <Paper sx={{ width: "100%", padding: 3 }}>
              <Typography
                fontWeight={"bold"}
                component={"p"}
                variant={"p"}
                fontSize={"1.2rem"}
                textAlign={"center"}
                sx={{
                  textDecoration: "underline",
                }}
              >
                Your Cleared Funds
              </Typography>
              <Typography textAlign={"center"} color={"primary"} fontSize={22}>
                $000,000,000.00
              </Typography>
            </Paper>
            <Typography
              fontSize={"1.1rem"}
              textAlign={"center"}
              color={"#fff"}
              mt={0.5}
            >
              Available for Trading
            </Typography>
          </Box>
        </StyledContainer>
      </Box>
      <StyledContainer>
        <Box component={"div"} position={"relative"}>
          <Box
            component={"div"}
            position={"absolute"}
            sx={{
              bottom: -25,
              right: 25,
            }}
          >
            <Button
              id="demo-positioned-button"
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              variant={"contained"}
              sx={{
                color: "#fff",
                margin: "auto",
                marginTop: "25px",
                display: "block",
                height: "50%",
                textTransform: "none",
                fontWeight: "bold",
                fontSize: isXL ? 20 : 16,
                bgcolor: "primary.dark",
              }}
            >
              Additional Markets
            </Button>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {additionalMarkets.map((metal) => (
                <Link to={metal.link}>
                  <MenuItem sx={{ width: 155 }} onClick={handleClose}>
                    {metal.title}
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>
          <Box>
            <Paper
              sx={{
                padding: 5,
              }}
            >
              <Box display="flex" justifyContent={"space-between"}>
                {_markets.map((market, index) => {
                  return (
                    <Box key={index}>
                      <Link to={`/markets/${market}`}>
                        <Typography
                          fontSize={"1.3rem"}
                          color={"primary.dark"}
                          fontWeight={"bold"}
                          textTransform={"capitalize"}
                          textAlign={"center"}
                        >
                          {market}
                        </Typography>
                        <img
                          src={require(`../../assets/metals/${market}.png`)}
                          className="center-block "
                          width={isXL ? "100%" : 100}
                          alt={market}
                        />
                      </Link>
                    </Box>
                  );
                })}
                <Box>
                  <Link to={`/markets/coinscurrency`}>
                    <Typography
                      fontSize={"1.3rem"}
                      color={"primary.dark"}
                      fontWeight={"bold"}
                      textTransform={"capitalize"}
                      textAlign={"center"}
                    >
                      Coin &#38; Currency
                    </Typography>
                    <img
                      src={require(`../../assets/metals/coins-and-currency.png`)}
                      className="center-block"
                      width={isXL ? "100%" : 100}
                      alt="coins and currency"
                    />
                  </Link>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Box>

        <Box component={"div"} mt={8} mb={4}>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <Paper>
                <Box component={"div"} padding={3} margin={"auto"}>
                  <Link
                    to={"/account/openorders"}
                    style={{
                      color: "#fff",
                    }}
                  >
                    <StyledButton
                      fullWidth
                      sx={{
                        mb: 5,
                        mx: "auto",
                        fontSize: isXL ? 24 : 18,
                      }}
                    >
                      Open Orders
                    </StyledButton>
                  </Link>{" "}
                  <Link
                    to={"/transactions"}
                    style={{
                      color: "#fff",
                    }}
                  >
                    <StyledButton
                      fullWidth
                      sx={{ margin: "auto", fontSize: isXL ? 24 : 18 }}
                    >
                      Transactions
                    </StyledButton>
                  </Link>
                </Box>
              </Paper>
            </Grid>
            <Grid item md={4}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-between"}
                height="100%"
              >
                <Box
                  component={"div"}
                  display="flex"
                  flexDirection="column"
                  alignItems={"center"}
                >
                  <Paper
                    component="form"
                    sx={{
                      p: "0px ",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      margin: "auto",
                      background: "#fff",
                    }}
                  >
                    <IconButton sx={{ p: "10px" }} aria-label="menu">
                      <SearchIcon />
                    </IconButton>
                    <InputBase
                      sx={{ ml: 1, flex: 1, paddingY: 1.5 }}
                      placeholder="Search Active Orders"
                      inputProps={{ "aria-label": "search active orders" }}
                    />
                  </Paper>
                  {/* <Typography color={"#FF0000"} fontSize={18}>
                  Click on any Active Bid or Ask to make a trade.
                </Typography> */}
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  pt={2}
                  height={"100%"}
                  flexDirection={"column"}
                >
                  <Typography color={"#FF0000"} fontSize={isXL ? 18 : 14}>
                    Click on any Active Bid or Ask to make a trade.
                  </Typography>
                  <MarketsFilterButton />
                </Box>
              </Box>
            </Grid>
            <Grid item md={4}>
              <Paper>
                <Box component={"div"} padding={3} margin={"auto"}>
                  <Link
                    to={"/funding"}
                    style={{
                      color: "#fff",
                    }}
                  >
                    {" "}
                    <StyledButton
                      fullWidth
                      sx={{ mb: 5, mx: "auto", fontSize: isXL ? 24 : 18 }}
                    >
                      Funding
                    </StyledButton>
                  </Link>
                  <Link
                    to={"/ss"}
                    style={{
                      color: "#fff",
                    }}
                  >
                    <StyledButton
                      fullWidth
                      sx={{ margin: "auto", fontSize: isXL ? 24 : 18 }}
                    >
                      Shipping &#38; Settlement
                    </StyledButton>
                  </Link>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <Box component={"div"}>
          <Grid container columnSpacing={4}>
            <Grid item md={6}>
              <TableLabel color={"primary.dark"} textAlign={"center"}>
                Active Bids &#38; Asks
              </TableLabel>
              <BidTable
                rows={_data}
                headCells={_headcells}
                tableTitle={null}
                loading={false}
              />
            </Grid>
            <Grid item md={6}>
              <TableLabel color={"primary.dark"} textAlign={"center"}>
                Completed Trades
              </TableLabel>
              <BidTable
                rows={_data}
                headCells={_headcells}
                tableTitle={null}
                loading={false}
              />
            </Grid>
          </Grid>
        </Box>
      </StyledContainer>
    </Box>
  );
}

const _headcells = [
  {
    id: "mkt",
    numeric: false,
    disablePadding: true,
    label: "MKT#",
  },
  {
    id: "ticker",
    numeric: false,
    disablePadding: true,
    label: "Ticker",
  },
  {
    id: "bid-ask",
    numeric: false,
    disablePadding: true,
    label: "Bid/Ask",
  },
  {
    id: "quantity",
    numeric: true,
    disablePadding: true,
    label: "Quantity",
  },
  {
    id: "price",
    numeric: true,
    disablePadding: true,
    label: "Price",
  },
  {
    id: "options",
    numeric: false,
    disablePadding: true,
    label: "",
  },
];

const _data = [
  {
    mkt: "Gold",
    ticker: "GLD",
    bidAsk: "Bid",
    quantity: 200,
    price: 1000,
  },
  {
    mkt: "Gold",
    ticker: "GLD",
    bidAsk: "Bid",
    quantity: 200,
    price: 1000,
  },
  {
    mkt: "Gold",
    ticker: "GLD",
    bidAsk: "Bid",
    quantity: 200,
    price: 1000,
  },
  {
    mkt: "Gold",
    ticker: "GLD",
    bidAsk: "Bid",
    quantity: 200,
    price: 1000,
  },
  {
    mkt: "Gold",
    ticker: "GLD",
    bidAsk: "Bid",
    quantity: 200,
    price: 1000,
  },
  {
    mkt: "Gold",
    ticker: "GLD",
    bidAsk: "Bid",
    quantity: 200,
    price: 1000,
  },
  {
    mkt: "Gold",
    ticker: "GLD",
    bidAsk: "Bid",
    quantity: 200,
    price: 1000,
  },
  {
    mkt: "Gold",
    ticker: "GLD",
    bidAsk: "Bid",
    quantity: 200,
    price: 1000,
  },
  {
    mkt: "Gold",
    ticker: "GLD",
    bidAsk: "Bid",
    quantity: 200,
    price: 1000,
  },
  {
    mkt: "Gold",
    ticker: "GLD",
    bidAsk: "Bid",
    quantity: 200,
    price: 1000,
  },
  {
    mkt: "Gold",
    ticker: "GLD",
    bidAsk: "Bid",
    quantity: 200,
    price: 1000,
  },
];

export default Markets;
