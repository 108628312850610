import { Box, Button, styled } from "@mui/material";
import React, { useState } from "react";

import SentMessages from "./SentMessagesModal";
import TemplateListModal from "./TemplateListModal";
import TemplateModal from "./TemplateModal";

const Templates = () => {
  const [openCreateTemplate, setOpenCreateTemplate] = useState(false);
  const [openEditTemplate, setOpenEditTemplate] = useState(false);
  const [openMessagesModal, setOpenMessagesModal] = useState(false);
  const [openEditTempalteForm, setOpenEditTempalteForm] = useState(false);
  const [selected, setSelected] = useState(null);

  const buttons = [
    {
      title: "Create Template",
      action: () => setOpenCreateTemplate(true),
    },
    {
      title: "Edit Templates",
      action: () => setOpenEditTemplate(true),
    },
    {
      title: "Sent Messages",
      action: (_) => setOpenMessagesModal(true),
    },
  ];

  return (
    <Box
      display={"flex"}
      width={"100%"}
      alignItems={"center"}
      justifyContent={"space-between"}
      gap={5}
      my={5}
    >
      {buttons.map((button) => (
        <CardButton onClick={button?.action}>{button?.title}</CardButton>
      ))}
      <TemplateModal
        title={"Create New Template"}
        open={openCreateTemplate}
        handleClose={() => setOpenCreateTemplate(false)}
      />
      {/* EDIT TEMPLATE MODAL */}
      <TemplateListModal
        title={"Edit"}
        open={openEditTemplate}
        clickHandler={(data) => {
          setSelected(data);
          setOpenEditTempalteForm(true);
        }}
        handleClose={() => setOpenEditTemplate(false)}
      />

      <TemplateModal
        open={openEditTempalteForm}
        handleClose={() => setOpenEditTempalteForm(false)}
        selected={selected}
        title={"Edit Template"}
      />

      {/* SENT MESSAGES MODAL */}
      <SentMessages
        open={openMessagesModal}
        handleClose={(_) => setOpenMessagesModal(false)}
      />
    </Box>
  );
};

export default Templates;

const CardButton = styled(Button)(({ theme }) => ({
  variant: "contained",
  backgroundColor: "#fff",
  color: "#2152A9",
  display: "block",
  textTransform: "none",
  fontWeight: "bold",
  height: 200,
  width: 375,
  border: "1px solid #c4c4c4",
  borderRadius: 10,
  fontSize: "25px !important",
  padding: "40px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "28px !important",
  },
  ":hover": {
    color: "#fff",
    backgroundColor: "#2152A9",
  },
}));
