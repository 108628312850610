import {
  Autocomplete,
  Box,
  Divider,
  Grid,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import {
  LimitBox,
  LimitField,
  StyledTextArea,
  SubHeading,
} from "../../Styled/Globals";
import React, { useState } from "react";

const BasicForm = ({ formData }) => {
  const [data, setData] = useState(null);
  const [initLimit, setInitLimit] = useState(0);
  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

  React.useEffect(() => {
    setData({ ...data, formData });
    // console.log("formdata", formData);
  }, [formData]);

  return (
    <Box>
      <SubHeading>Basic</SubHeading>
      <Box mb={4}>
        <Divider />
      </Box>
      <Grid container rowSpacing={2} columnSpacing={4}>
        <Grid item md={12}>
          {/* TODO: Fix the lag */}
          <StyledTextArea
            placeholder="Summarizes the trade as user fills in data"
            onChange={onChange}
            name={"summary"}
            value={data?.summary}
            minRows={5}
            style={{
              width: "100%",
              padding: 5,
            }}
          />
        </Grid>
        <Grid item md={6}>
          <Autocomplete
            placeholder="Choose Ticker"
            id="ticker-autocomplete"
            options={["Spot", "Ticker"]}
            getOptionLabel={(ticker) => `${ticker}`}
            value={data?.ticker}
            onChange={(e, ticker, reason) =>
              reason === "selectOption"
                ? setData({
                    ...data,
                    ticker,
                  })
                : setData({
                    ...data,
                    ticker: "",
                  })
            }
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label="Choose Ticker"
                  variant="outlined"
                  value={data?.ticker}
                  required
                />
              );
            }}
          />
        </Grid>
        <Grid item md={6}>
          <Autocomplete
            placeholder="Bid/Ask"
            id="bid-ask-autocomplete"
            options={["Bid", "Ask"]}
            getOptionLabel={(bidAsk) => `${bidAsk}`}
            value={data?.bidAsk}
            onChange={(e, bidAsk, reason) => {
              console.log("reason", reason);
              reason === "selectOption"
                ? setData({
                    ...data,
                    bidAsk,
                  })
                : setData({
                    ...data,
                    bidAsk: "",
                  });
            }}
            size={"small"}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label="Bid or Ask"
                  variant="outlined"
                  value={data?.bidAsk}
                  required
                />
              );
            }}
          />
        </Grid>
        <Grid item md={4}>
          <TextField
            name="price"
            label="Enter Price"
            fullWidth
            type="number"
            variant="outlined"
            value={data?.price}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              inputProps: { min: 0 },
            }}
          />
        </Grid>
        <Grid item md={4}>
          <TextField
            label="Enter Quantity"
            fullWidth
            type="number"
            name="quantity"
            variant="outlined"
            InputProps={{ inputProps: { min: 0 } }}
            value={data?.quantity}
          />
        </Grid>
        <Grid item md={4}>
          <TextField
            label="Order Type"
            fullWidth
            type="text"
            name="orderType"
            variant="outlined"
            value={data?.orderType}
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            label="Timing"
            fullWidth
            type="text"
            name="timing"
            variant="outlined"
            value={data?.timing}
          />
        </Grid>
        <Grid item md={6}>
          <LimitBox>
            <Box width={"100%"}>
              <SubHeading px={2}>Limit</SubHeading>
            </Box>
            <LimitField key={"initi"}>
              <TextField
                key={"initi"}
                name="limitInitial"
                size="medium"
                onChange={(e) => setInitLimit(e.target.value)}
                variant="standard"
                sx={{
                  px: 1,
                }}
                // value={data?.limitInitial}
                value={initLimit}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </LimitField>
            <LimitField
              sx={{
                borderRight: "none",
              }}
            >
              <TextField
                name="limitEnd"
                size="small"
                onChange={onChange}
                variant="standard"
                sx={{
                  borderRightColor: "#fff",
                  px: 1,
                }}
                value={data?.limitEnd}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </LimitField>
          </LimitBox>
        </Grid>
        <Grid item md={6}>
          <LimitBox>
            <Box width={"100%"}>
              <SubHeading px={2}>Stop</SubHeading>
            </Box>
            <LimitField>
              <TextField
                name="limitInitial"
                size="medium"
                onChange={onChange}
                variant="standard"
                value={data?.stopInitial}
                sx={{
                  px: 1,
                }}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </LimitField>
            <LimitField
              sx={{
                borderRight: "none",
              }}
            >
              <TextField
                name="stopEnd"
                size="medium"
                sx={{
                  px: 1,
                }}
                onChange={onChange}
                variant="standard"
                value={data?.stopEnd}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </LimitField>
          </LimitBox>
        </Grid>
        <Grid item md={12}>
          <Box
            display="flex"
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <SubHeading>Reputation &#38; Completed Transactions</SubHeading>
            <Link>
              <Typography
                style={{
                  fontSize: 16,
                  textDecoration: "underline",
                }}
              >
                What's this?
              </Typography>
            </Link>
          </Box>
          <Divider />
        </Grid>
        <Grid item md={6}>
          <TextField
            label={"Reputation"}
            type="number"
            fullWidth
            onChange={onChange}
            variant="outlined"
            name="reputation"
            value={data?.reputation}
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            label={"# of Completed Transactions"}
            type="number"
            fullWidth
            onChange={onChange}
            variant="outlined"
            name="completedTransactions"
            value={data?.completedTransactions}
          />
        </Grid>
        {/* Dynamic Pricing Section */}
        <Grid item md={12}>
          <SubHeading>Dynamic Pricing (Premium to Spot)</SubHeading>
          <Divider />
        </Grid>
        <Grid item md={6}>
          <TextField
            name="basisPlusDollar"
            label="Basis + $#"
            fullWidth
            onChange={onChange}
            type="number"
            variant="outlined"
            value={data?.basisPlusDollar}
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            name="basisXPercentage"
            label="Basis X (1+%)"
            fullWidth
            onChange={onChange}
            type="number"
            variant="outlined"
            value={data?.basisXPercentage}
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            name="floor"
            label="Floor"
            fullWidth
            onChange={onChange}
            type="number"
            variant="outlined"
            value={data?.floor}
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            name="ceiling"
            label="Ceiling"
            fullWidth
            onChange={onChange}
            type="number"
            variant="outlined"
            value={data?.ceiling}
          />
        </Grid>
        <Grid item md={6}>
          <Autocomplete
            name="priceBasedOn"
            options={["Spot", "Ticker"]}
            onChange={(e, priceBasedOn, reason) =>
              reason === "selectOption"
                ? setData({
                    ...data,
                    priceBasedOn,
                  })
                : setData({
                    ...data,
                    priceBasedOn: "",
                  })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="priceBasedOn"
                label="Pricing Based On"
                fullWidth
                onChange={onChange}
                variant="outlined"
                value={data?.priceBasedOn}
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BasicForm;
