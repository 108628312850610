import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Paper,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { StyledTextArea, SubHeading } from "../../../Styled/Globals";

import { Close } from "@mui/icons-material";

export default function EventRepliesModal({ open, handleClose }) {
  const [data, setData] = useState({});
  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });
  return (
    <Modal open={open} onClose={handleClose} className={"modal"}>
      <Box
        className={"no-scroll-bar"}
        sx={{
          width: 800,
        }}
      >
        <Paper>
          <Box className={"close-box"} sx={{ px: 4, py: 1 }}>
            <SubHeading>Event/Replies</SubHeading>
            <Close onClick={handleClose} className={"c-pointer"} />
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              px: 4,
              py: 2,
            }}
          >
            <Grid container columnSpacing={3} rowSpacing={2}>
              <Grid item md={6}>
                <TextField
                  name={"initiatedDate"}
                  label="Initiated Date"
                  type="date"
                  fullWidth
                  onChange={onChange}
                  size={"small"}
                  value={data?.initiatedDate}
                  InputLabelProps={{
                    className: "calender-icon",
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  name={"completedDate"}
                  label="Completed Date"
                  type="date"
                  fullWidth
                  onChange={onChange}
                  size={"small"}
                  value={data?.completedDate}
                  InputLabelProps={{
                    className: "calender-icon",
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <Autocomplete
                  value={data?.eventType}
                  options={["Option 1", "Option 2", "Option 3"]}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label={"Event Type"}
                        name={"eventType"}
                        value={data?.eventType}
                        size={"small"}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <Autocomplete
                  value={data?.initiator}
                  options={["Admin", "Buyer", "Seller"]}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label={"Initiator"}
                        name={"initiator"}
                        value={data?.initiator}
                        size={"small"}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <Autocomplete
                  value={data?.receiver}
                  options={["Admin", "Buyer", "Seller"]}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label={"Receiver"}
                        name={"receiver"}
                        value={data?.receiver}
                        size={"small"}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <StyledTextArea
                  onChange={onChange}
                  name={"deatils"}
                  placeholder={"Details"}
                  minRows={5}
                />
              </Grid>
              <Grid item md={12}>
                <Box
                  display={"flex"}
                  width={"100%"}
                  justifyContent={"flex-end"}
                >
                  <Button
                    disableElevation
                    size={"small"}
                    sx={{
                      width: "15%",
                    }}
                    variant={"contained"}
                    color={"primary"}
                    onClick={handleClose}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
}
