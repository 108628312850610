import {
  Grid,
  Box,
  TextField,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as CloudSVG } from "../../../../../assets/cloud.svg";
import { StyledTextArea } from "../../../../Styled/Globals";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

function RecordingForm() {
  const isXL = useMediaQuery(useTheme().breakpoints.up("xl"));
  const [data, setData] = useState({
    transactionNumber: Math.floor(Math.random() * 100000),
  });
  const onChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const uploadButton = () => {
    return (
      <Box
        display={"flex"}
        justifyContent={"start"}
        alignItems={"center"}
        width={"100%"}
        sx={(theme) => ({
          height: "37.13px",
          [theme.breakpoints.up("xl")]: {
            height: "40px",
          },
        })}
      >
        <label htmlFor="upload-photo">
          <Button
            variant="text"
            component="span"
            sx={(theme) => ({
              border: "1px solid #c4c4c4",
              textTransform: "initial",
              px: 4,
              height: "37.13px",
              [theme.breakpoints.up("xl")]: {
                height: "40px",
              },
            })}
          >
            <input
              style={{ display: "none" }}
              id="upload-photo"
              name="upload-photo"
              type="file"
            />
            <Box
              display={"flex"}
              width={"100%"}
              height={"100%"}
              alignItems={"center"}
              columnGap={1}
              justifyContent={"center"}
            >
              <CloudSVG
                style={{
                  transform: isXL ? "scale(0.9)" : "scale(0.7)",
                }}
              />
              <Typography
                color="primary"
                sx={{
                  textDecoration: "underline",
                  fontSize: isXL ? 18 : 16,
                }}
              >
                Upload
              </Typography>
            </Box>
          </Button>
        </label>
      </Box>
    );
  };

  return (
    <Box mt={1}>
      <Grid container columnSpacing={2} rowSpacing={1.5}>
        <Grid item md={4}>
          <TextField
            size="small"
            name={"name"}
            label={"Recording Name"}
            fullWidth
            onChange={onChange}
          />
        </Grid>
        <Grid item md={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {/* <DesktopDatePicker
              label="Date"
              onChange={(date) => {}}
              renderInput={(params) => (
                <TextField
                  className="calender-icon"
                  fullWidth
                  {...params}
                  size={"small"}
                />
              )}
            /> */}

            <TextField
              id="datetime-local"
              name={"recordingDateTime"}
              label="Date &#38; Time"
              type="datetime-local"
              size={"small"}
              fullWidth
              onChange={onChange}
              InputLabelProps={{
                shrink: true,
                className: "calender-icon",
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item md={4}>
          <TextField
            size="small"
            name={"id"}
            readOnly={true}
            value={data?.transactionNumber}
            label={"Transaction#"}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item md={12}>
          <StyledTextArea
            size="small"
            name={"description"}
            placeholder={"Description/Notes"}
            onChange={onChange}
            minRows={5}
            style={{
              width: "100%",
              padding: 5,
            }}
          />
        </Grid>
        <Grid item md={2.7}>
          <Box height={"100%"}>{uploadButton()}</Box>
        </Grid>
        <Grid item md={9.3}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            columnGap={2}
          >
            <TextField
              label={"Paste Link to Recording Here"}
              size="small"
              name={"recordingLink"}
              value={data?.recordingLink}
              onChange={onChange}
              fullWidth
            />
            <Button
              disabled={!data?.recordingLink}
              size={"small"}
              color={"primary"}
              variant={"contained"}
            >
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default RecordingForm;
