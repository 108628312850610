import MarketComponent from "../../components/Markets/MarketComponent";
import React from "react";
import palladiumImg from "../../assets/metals/small/palladium.png";

function PalladiumMarket() {
  const _bidData = [
    {
      orderNumber: 1010,
      mkt: "Palladium",
      ticker: "PLD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Palladium",
      ticker: "PLD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Palladium",
      ticker: "PLD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Palladium",
      ticker: "PLD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Palladium",
      ticker: "PLD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Palladium",
      ticker: "PLD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Palladium",
      ticker: "PLD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Palladium",
      ticker: "PLD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Palladium",
      ticker: "PLD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Palladium",
      ticker: "PLD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Palladium",
      ticker: "PLD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Palladium",
      ticker: "PLD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Palladium",
      ticker: "PLD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Palladium",
      ticker: "PLD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
  ];

  const _tradeData = [
    {
      orderNumber: 1010,
      mkt: "Palladium",
      ticker: "PLD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Palladium",
      ticker: "PLD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Palladium",
      ticker: "PLD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Palladium",
      ticker: "PLD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Palladium",
      ticker: "PLD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Palladium",
      ticker: "PLD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Palladium",
      ticker: "PLD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Palladium",
      ticker: "PLD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Palladium",
      ticker: "PLD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Palladium",
      ticker: "PLD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Palladium",
      ticker: "PLD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Palladium",
      ticker: "PLD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Palladium",
      ticker: "PLD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Palladium",
      ticker: "PLD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
  ];

  return (
    <React.Fragment>
      <MarketComponent
        title={"Palladium"}
        img={palladiumImg}
        bidData={_bidData}
        tradeData={_tradeData}
      />
    </React.Fragment>
  );
}

export default PalladiumMarket;
