import { Box } from "@mui/material";
import React from "react";
import { Heading } from "../../Styled/Globals";
import MarketControl from "./MarketControl/MarketControl";

function Management({ bRef }) {
  return (
    <Box mb={4} ref={bRef}>
      <Heading>Management</Heading>
      <MarketControl />
    </Box>
  );
}

export default Management;
