import { ArbitrationCardButton, SubHeading } from "../../../Styled/Globals";
import { Box, Grid } from "@mui/material";
import React, { useState } from "react";

import ArbitrationNotes from "./ArbitratorNotes";
import AskQuestionModal from "./AskQuestionModal";
import CallRequestModal from "./CallRequestModal";
import EventRepliesModal from "./EventRepliesModal";
import HearingRequestModal from "./HearingRequestModal";
import MakeRullingModal from "./MakeRullingModal";
import RequestDocumentationModal from "./RequestDocumentationModal";
import RequestResponseModal from "./RequestResponseModal";
import StatusModal from "./StatusModal";
import TradeDetailsModal from "../../../CommonModals/TradeDetailsModal/TradeDetailsModal";
import UploadRecordingModal from "./UploadRecording/UploadRecordingModal";
import UserDetailsModal from "./UserDetailsModal";
import UserHistoryModal from "./UserHistoryModal/UserHistoryModal";

// import UserTransactionModal from "./UserTransactionModal";
// import UserInformationModal from "./UserInformationModal";

const ActionButtons = ({ row }) => {
  const [openRequestResponseModal, setOpenRequestResponseModal] =
    useState(false);
  const [requestResponseVariant, setRequestResponseVariant] = useState(null);
  const [openUserInfoModal, setOpenUserInfoModal] = useState(false);
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [openUserHistoryModal, setOpenUserHistoryModal] = useState(false);
  const [openArbitratorNotes, setOpenArbitratorNotes] = useState(false);
  const [openDocumentationModal, setOpenDocumentationModal] = useState(false);
  const [openQuestionsModal, setOpenQuestionsModal] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [openCallRequestModal, setOpenCallRequestModal] = useState(false);
  const [openHearingRequestModal, setOpenHearingRequestModal] = useState(false);
  const [openRecordingModal, setOpenRecordingModal] = useState(false);
  const [openRullingModal, setOpenRullingModal] = useState(false);
  const [openEventRepliesModal, setOpenEventRepliesModal] = useState(false);

  const reviewButtons = [
    {
      title: "Read Filer's Complaint",
      action: () => {
        setRequestResponseVariant("filer");
        setOpenRequestResponseModal(true);
      },
    },
    {
      title: "Read Response from Other Party",
      action: () => {
        setRequestResponseVariant("response");
        setOpenRequestResponseModal(true);
      },
    },
    {
      title: "See User Details",
      action: () => setOpenUserInfoModal(true),
    },
    {
      title: "See Transaction Data",
      action: () => setOpenTransactionModal(true),
    },
    {
      title: "See User Order & Transaction History",
      action: () => setOpenUserHistoryModal(true),
    },
  ];
  const actionButtons = [
    {
      title: "Arbitrator's Notes",
      action: () => setOpenArbitratorNotes(true),
    },
    {
      title: "Request Documentation",
      action: () => setOpenDocumentationModal(true),
    },
    {
      title: "Ask Question(s)",
      action: () => setOpenQuestionsModal(true),
    },
    {
      title: "Change Status",
      action: () => setOpenStatusModal(true),
    },
    {
      title: "Request Call",
      action: () => setOpenCallRequestModal(true),
    },
    {
      title: "Request Hearing",
      action: () => setOpenHearingRequestModal(true),
    },
    {
      title: "Call/Hearing Recordings",
      action: () => setOpenRecordingModal(true),
    },
    {
      title: "Make Ruling",
      action: () => setOpenRullingModal(true),
    },
    {
      title: "Events/Replies",
      action: () => setOpenEventRepliesModal(true),
    },
  ];

  return (
    <Box>
      <Box mb={1}>
        <SubHeading>Information &#38; Actions</SubHeading>
      </Box>
      <Box px={2} py={1} border={"1px solid #c4c4c4"} borderRadius={1}>
        <Box
          sx={(theme) => ({
            mb: 0.2,
            [theme.breakpoints.up("xl")]: {
              mb: 2,
            },
          })}
        >
          <SubHeading
            sx={{
              color: "#2152A9",
            }}
          >
            Review Information
          </SubHeading>
        </Box>
        <Grid container spacing={2}>
          {reviewButtons.map((button) => {
            return (
              <Grid item md={2.4}>
                <ArbitrationCardButton onClick={button?.action}>
                  {button?.title}
                </ArbitrationCardButton>
              </Grid>
            );
          })}
        </Grid>
        <Box
          sx={{
            color: "#2152A9",
          }}
        >
          <Box
            sx={(theme) => ({
              my: 0.5,
              [theme.breakpoints.up("xl")]: {
                my: 2,
              },
            })}
          >
            <SubHeading>Arbitrator's Actions</SubHeading>
          </Box>
          <Grid container spacing={2}>
            {actionButtons.map((button) => {
              return (
                <Grid item md={2.4}>
                  <ArbitrationCardButton onClick={button?.action}>
                    {button?.title}
                  </ArbitrationCardButton>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
      <MakeRullingModal
        open={openRullingModal}
        handleClose={() => setOpenRullingModal(false)}
      />
      <UploadRecordingModal
        open={openRecordingModal}
        handleClose={() => setOpenRecordingModal(false)}
      />
      <HearingRequestModal
        open={openHearingRequestModal}
        handleClose={() => setOpenHearingRequestModal(false)}
      />
      <CallRequestModal
        open={openCallRequestModal}
        handleClose={() => setOpenCallRequestModal(false)}
      />
      <StatusModal
        open={openStatusModal}
        handleClose={() => setOpenStatusModal(false)}
      />
      <AskQuestionModal
        open={openQuestionsModal}
        handleClose={() => setOpenQuestionsModal(false)}
      />
      <RequestDocumentationModal
        open={openDocumentationModal}
        handleClose={() => setOpenDocumentationModal(false)}
      />
      <ArbitrationNotes
        open={openArbitratorNotes}
        handleClose={() => setOpenArbitratorNotes(false)}
      />
      <RequestResponseModal
        open={openRequestResponseModal}
        variant={requestResponseVariant}
        handleClose={() => {
          setOpenRequestResponseModal(false);
          setRequestResponseVariant(null);
        }}
      />
      {/* <UserInformationModal
        open={openUserInfoModal}
        handleClose={() => setOpenUserInfoModal(false)}
      /> */}

      <UserDetailsModal
        open={openUserInfoModal}
        handleClose={() => setOpenUserInfoModal(false)}
      />

      {/* <UserTransactionModal
        open={openTransactionModal}
        handleClose={() => setOpenTransactionModal(false)}
      /> */}

      <TradeDetailsModal
        open={openTransactionModal}
        handleClose={() => setOpenTransactionModal(false)}
        data={row}
      />

      <UserHistoryModal
        open={openUserHistoryModal}
        handleClose={() => setOpenUserHistoryModal(false)}
      />
      <EventRepliesModal
        open={openEventRepliesModal}
        handleClose={(_) => setOpenEventRepliesModal(false)}
      />
    </Box>
  );
};

export default ActionButtons;
