import { Box, Button, TextField, styled } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import {
  DisabledTextField,
  StyledButton,
  SubHeading,
} from "../../Styled/Globals";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import MarketEffect from "./MarketEffect/MarketEffect";
import MockUserForm from "./MarketEffect/MockUserForm";
import MockUserModal from "../MockUserModal";
import React from "react";
import TradeLogisticsForm from "./MarketEffect/TradeLogisticsForm";
import TradeRemovalForm from "./MarketEffect/TradeRemovalForm";

function MarketEffectForm() {
  const [data, setData] = React.useState(null);
  const [openMockUserModal, setOpenMockUserModal] = React.useState(false);
  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

  const mockUserFormFields = [
    {
      title: "Display Name for Trade:",
      fieldOne: (
        <TextField
          name={"nameOrderCreator"}
          onChange={onChange}
          value={data?.nameOrderCreator}
          sx={{
            width: "100%",
          }}
          size={"small"}
          type={"text"}
        />
      ),
      fieldTwo: (
        <TextField
          name={"nameMatchedOrderCreator"}
          onChange={onChange}
          value={data?.nameMatchedOrderCreator}
          size={"small"}
          sx={{
            width: "100%",
          }}
          type={"text"}
        />
      ),
    },
    {
      title: "Join Date:",
      fieldOne: (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Date"
            inputFormat="MM/dd/yyyy"
            onChange={(date) => {
              setData({ ...data, orderCreatorJoinDate: date });
            }}
            value={data?.orderCreatorJoinDate}
            renderInput={(params) => (
              <TextField
                fullWidth
                value={data?.orderCreatorJoinDate}
                size={"small"}
                className="calender-icon"
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      ),
      fieldTwo: (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="Date"
            inputFormat="MM/dd/yyyy"
            onChange={(date) =>
              setData({ ...data, matchedOrderCreatorJoinDate: date })
            }
            value={data?.matchedOrderCreatorJoinDate}
            renderInput={(params) => (
              <TextField
                size={"small"}
                fullWidth
                value={data?.matchedOrderCreatorJoinDate}
                className="calender-icon"
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      ),
    },
    {
      title: "Reputation:",
      fieldOne: (
        <DisabledTextField
          disabled
          name={"orderCreatorReputation"}
          onChange={onChange}
          value={data?.orderCreatorReputation}
          sx={{
            width: "100%",
          }}
          size={"small"}
          type={"number"}
        />
      ),
      fieldTwo: (
        <DisabledTextField
          disabled
          name={"matchedOrderCreatorReputation"}
          onChange={onChange}
          value={data?.matchedOrderCreatorReputation}
          sx={{
            width: "100%",
          }}
          size={"small"}
          type={"number"}
        />
      ),
    },
    {
      title: "# of Transactions:",
      fieldOne: (
        <TextField
          name={"orderCreatorTransactions"}
          onChange={onChange}
          value={data?.orderCreatorTransactions}
          sx={{
            width: "100%",
          }}
          size={"small"}
          type={"number"}
        />
      ),
      fieldTwo: (
        <TextField
          name={"matchedOrderCreatorTransactions"}
          onChange={onChange}
          value={data?.matchedOrderCreatorTransactions}
          sx={{
            width: "100%",
          }}
          size={"small"}
          type={"number"}
        />
      ),
    },
    {
      title: "# of Buyer Transactions:",
      fieldOne: (
        <TextField
          name={"orderCreatorBuyerTransactions"}
          onChange={onChange}
          value={data?.orderCreatorBuyerTransactions}
          sx={{
            width: "100%",
          }}
          size={"small"}
          type={"number"}
        />
      ),
      fieldTwo: (
        <TextField
          name={"matchedOrderCreatorBuyerTransactions"}
          onChange={onChange}
          value={data?.matchedOrderCreatorBuyerTransactions}
          sx={{
            width: "100%",
          }}
          size={"small"}
          type={"number"}
        />
      ),
    },
    {
      title: "# of Seller Transactions:",
      fieldOne: (
        <TextField
          name={"orderCreatorSellerTransactions"}
          onChange={onChange}
          value={data?.orderCreatorSellerTransactions}
          sx={{
            width: "100%",
          }}
          size={"small"}
          type={"number"}
        />
      ),
      fieldTwo: (
        <TextField
          name={"matchedOrderCreatorSellerTransactions"}
          onChange={onChange}
          value={data?.matchedOrderCreatorSellerTransactions}
          sx={{
            width: "100%",
          }}
          size={"small"}
          type={"number"}
        />
      ),
    },
    {
      title: "# of Arbitrations:",
      fieldOne: (
        <TextField
          name={"orderCreatorArbitrations"}
          onChange={onChange}
          value={data?.orderCreatorArbitrations}
          sx={{
            width: "100%",
          }}
          size={"small"}
          type={"number"}
        />
      ),
      fieldTwo: (
        <TextField
          name={"matchedOrderCreatorArbitrations"}
          onChange={onChange}
          value={data?.matchedOrderCreatorArbitrations}
          sx={{
            width: "100%",
          }}
          size={"small"}
          type={"number"}
        />
      ),
    },
    {
      title: `Arbitrations to Transactions %:`,
      fieldOne: (
        <TextField
          name={"orderCreatorArbitrationsToTransactions"}
          onChange={onChange}
          value={data?.orderCreatorArbitrationsToTransactions}
          sx={{
            width: "100%",
          }}
          size={"small"}
          type={"number"}
        />
      ),
      fieldTwo: (
        <TextField
          name={"matchedOrderCreatorArbitrationsToTransactions"}
          onChange={onChange}
          value={data?.matchedOrderCreatorArbitrationsToTransactions}
          sx={{
            width: "100%",
          }}
          size={"small"}
          type={"number"}
        />
      ),
    },
    {
      title: "",
      fieldOne: (
        <Button
          variant={"contained"}
          disableElevation
          size={"small"}
          sx={{
            color: "#000",
            bgcolor: "#F3F3F3",
            textTransform: "none",
            fontWeight: "normal",
            px: 1,
            ":hover": {
              bgcolor: "primary",
              color: "#fff",
            },
          }}
          onClick={(_) => setOpenMockUserModal(true)}
        >
          Chose Mock User
        </Button>
      ),
      fieldTwo: (
        <Button
          variant={"contained"}
          disableElevation
          size={"small"}
          sx={(theme) => ({
            color: "#000",
            bgcolor: "#F3F3F3",
            textTransform: "none",
            fontWeight: "normal",
            px: 1,
            ":hover": {
              bgcolor: "primary",
              color: "#fff",
            },
          })}
          onClick={(_) => setOpenMockUserModal(true)}
        >
          Chose Mock User
        </Button>
      ),
    },
  ];

  const LinedHeading = styled(SubHeading)(({ theme }) => ({
    borderWidth: 0,
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, 0.12)",
    width: "40%",
    borderBottomWidth: "thin",
  }));

  return (
    <Box>
      <MockUserModal
        open={openMockUserModal}
        handleClose={(_) => setOpenMockUserModal(false)}
      />
      <Box>
        <LinedHeading>Market Effect (NOW)</LinedHeading>
        <MarketEffect data={data} setData={setData} />
      </Box>

      {/* Trade Logistics */}
      <Box>
        <Box mb={2}>
          <LinedHeading>Trade Logistics</LinedHeading>
        </Box>
        <TradeLogisticsForm />
      </Box>

      {/* Mock User Information */}
      <Box>
        <Box mt={2}>
          <LinedHeading>Mock User Information </LinedHeading>
        </Box>
        <MockUserForm fields={mockUserFormFields} />
      </Box>
      {/* Trade Removal */}
      <Box>
        <LinedHeading>Trade Removal</LinedHeading>
        <TradeRemovalForm />
      </Box>
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        my={2}
      >
        <StyledButton
          sx={{
            height: 67,
            width: "100%",
            textTransform: "uppercase",
            alignItems: "center",
            fontSize: 22,
          }}
          onClick={() => {}}
        >
          Review &#38; Submit
        </StyledButton>
      </Box>
    </Box>
  );
}

export default MarketEffectForm;
