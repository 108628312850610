const service = {
  getData: ({ rows, from, to }) => {
    return new Promise((resolve, reject) => {
      const data = rows.slice(from, to);
      resolve({ count: rows.length, data: data });
    });
  },
};

export default service;
