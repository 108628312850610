import { Box, Button, Typography } from "@mui/material";

import { Link } from "react-router-dom";
import NotificationsTable from "./NotificationsTable";
import React from "react";

function Notifications({ SVG, bRef }) {
  return (
    <Box ref={bRef}>
      <Box
        component={"div"}
        marginBottom={2}
        display="flex"
        alignItems={"center"}
      >
        <Box
          columnGap={2}
          display={"flex"}
          width={"100%"}
          alignItems={"center"}
        >
          <SVG />
          <Typography fontSize={30} fontWeight={"bold"}>
            Notifications
          </Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          alginItems={"center"}
          width="100%"
        >
          <Button variant={"contained"} color={"primary"}>
            <Link to={"/notifications"}>
              <Typography
                color={"#fff"}
                sx={{
                  textTransform: "uppercase",
                }}
              >
                Full Dashboard
              </Typography>
            </Link>
          </Button>
        </Box>
      </Box>

      <Box component={"div"}>
        <NotificationsTable
          showDetails={false}
          rows={[
            {
              notificationNumber: `TX${Math.floor(Math.random() * 100000)}`,
              date: new Date("01-03-2022"),
              relatedTo: "Buying",
              tradeNumber: Math.floor(Math.random() * 10000),
              subject:
                "Enim velit adipisicing ullamco quis sunt sint dolore adipisicing adipisicing.",
            },
            {
              notificationNumber: `TX${Math.floor(Math.random() * 100000)}`,
              date: new Date("01-04-2022"),
              relatedTo: "Selling",
              tradeNumber: Math.floor(Math.random() * 10000),
              subject:
                "Pariatur esse sit tempor magna nostrud dolore do commodo cillum esse veniam magna.",
            },
            {
              notificationNumber: `TX${Math.floor(Math.random() * 100000)}`,
              date: new Date("01-05-2022"),
              relatedTo: "Buying",
              tradeNumber: Math.floor(Math.random() * 10000),
              subject:
                "Cupidatat deserunt in sit consectetur dolor labore sit officia sit irure elit.",
            },
            {
              notificationNumber: `TX${Math.floor(Math.random() * 100000)}`,
              date: new Date("01-04-2022"),
              relatedTo: "Selling",
              tradeNumber: Math.floor(Math.random() * 10000),
              subject:
                "Pariatur fugiat anim nisi eiusmod ad qui laborum ullamco fugiat aute qui.",
            },
            {
              notificationNumber: `TX${Math.floor(Math.random() * 100000)}`,
              date: new Date("01-06-2022"),
              relatedTo: "Buying",
              tradeNumber: Math.floor(Math.random() * 10000),
              subject:
                "Laboris ipsum pariatur veniam quis occaecat ad eu consectetur Lorem aliquip exercitation ut voluptate.",
            },
            {
              notificationNumber: `TX${Math.floor(Math.random() * 100000)}`,
              date: new Date("01-05-2022"),
              relatedTo: "Buying",
              tradeNumber: Math.floor(Math.random() * 10000),
              subject:
                "Cupidatat deserunt in sit consectetur dolor labore sit officia sit irure elit.",
            },
            {
              notificationNumber: `TX${Math.floor(Math.random() * 100000)}`,
              date: new Date("01-04-2022"),
              relatedTo: "Selling",
              tradeNumber: Math.floor(Math.random() * 10000),
              subject:
                "Pariatur fugiat anim nisi eiusmod ad qui laborum ullamco fugiat aute qui.",
            },
            {
              notificationNumber: `TX${Math.floor(Math.random() * 100000)}`,
              date: new Date("01-06-2022"),
              relatedTo: "Buying",
              tradeNumber: Math.floor(Math.random() * 10000),
              subject:
                "Laboris ipsum pariatur veniam quis occaecat ad eu consectetur Lorem aliquip exercitation ut voluptate.",
            },
          ]}
          headCells={[
            {
              id: "notificationNumber",
              numeric: false,
              width: "5%",
              label: "Notification#",
            },
            {
              id: "date",
              numeric: false,
              width: "5%",
              label: "Date",
            },
            {
              id: "relatedTo",
              numeric: false,
              width: "10%",
              label: "Related To",
            },
            {
              id: "tradeNumber",
              numeric: false,
              width: "5%",
              label: "Trade#",
            },
            {
              id: "subject",
              numeric: false,
              width: "40%",
              label: "Subject",
            },
            {
              id: "action",
              numeric: false,
              width: "15%",
              label: "Action",
              disableSorting: true,
              sx: {
                alignItems: "center",
                justifyContent: "center",
                fontSize: "large",
                color: "#fff",
                paddingY: 1,
              },
            },
          ]}
        />
      </Box>
    </Box>
  );
}

export default Notifications;
