import { getRandomNumber } from "./helper";
import moment from "moment";

export const sampleList = [
  {
    displayName: "shujja_99",
    realName: "Shujja",
    city: "LA",
    state: "California",
    zip: 90001,
    accountType: "Saving",
    joinDate: moment(Date.now()).format("MMMM DD, YYYY"),
    sellerLevel: getRandomNumber(10),
    reputation: getRandomNumber(100),
    transactions: getRandomNumber(1000),
    buyerTransactions: getRandomNumber(500),
    sellerTransactions: getRandomNumber(500),
    arbitrations: getRandomNumber(2),
    arbitrationsLost: getRandomNumber(1),
    arbitrationsToTransactions: getRandomNumber(1),
    opposingTradeUser: "vecna",
  },
  {
    displayName: "andy_99",
    realName: "Andy",
    city: "MN",
    state: "New York",
    zip: 90001,
    accountType: "Saving",
    joinDate: moment(Date.now()).format("MMMM DD, YYYY"),
    sellerLevel: getRandomNumber(10),
    reputation: getRandomNumber(100),
    transactions: getRandomNumber(1000),
    buyerTransactions: getRandomNumber(500),
    sellerTransactions: getRandomNumber(500),
    arbitrations: getRandomNumber(2),
    arbitrationsLost: getRandomNumber(1),
    arbitrationsToTransactions: getRandomNumber(1),
    opposingTradeUser: "vecna",
  },
  {
    displayName: "jim_99",
    realName: "Jim",
    city: "LA",
    state: "California",
    zip: 90001,
    accountType: "Saving",
    joinDate: moment(Date.now()).format("MMMM DD, YYYY"),
    sellerLevel: getRandomNumber(10),
    reputation: getRandomNumber(100),
    transactions: getRandomNumber(1000),
    buyerTransactions: getRandomNumber(500),
    sellerTransactions: getRandomNumber(500),
    arbitrations: getRandomNumber(2),
    arbitrationsLost: getRandomNumber(1),
    arbitrationsToTransactions: getRandomNumber(1),
    opposingTradeUser: "vecna",
  },
  {
    displayName: "dwitght_22",
    realName: "Dwight",
    city: "LA",
    state: "California",
    zip: 90001,
    accountType: "Saving",
    joinDate: moment(Date.now()).format("MMMM DD, YYYY"),
    sellerLevel: getRandomNumber(10),
    reputation: getRandomNumber(100),
    transactions: getRandomNumber(1000),
    buyerTransactions: getRandomNumber(500),
    sellerTransactions: getRandomNumber(500),
    arbitrations: getRandomNumber(2),
    arbitrationsLost: getRandomNumber(1),
    arbitrationsToTransactions: getRandomNumber(1),
    opposingTradeUser: "vecna",
  },
  {
    displayName: "shujja_99",
    realName: "Shujja",
    city: "LA",
    state: "California",
    zip: 90001,
    accountType: "Saving",
    joinDate: moment(Date.now()).format("MMMM DD, YYYY"),
    sellerLevel: getRandomNumber(10),
    reputation: getRandomNumber(100),
    transactions: getRandomNumber(1000),
    buyerTransactions: getRandomNumber(500),
    sellerTransactions: getRandomNumber(500),
    arbitrations: getRandomNumber(2),
    arbitrationsLost: getRandomNumber(1),
    arbitrationsToTransactions: getRandomNumber(1),
    opposingTradeUser: "vecna",
  },
  {
    displayName: "shujja_99",
    realName: "Shujja",
    city: "LA",
    state: "California",
    zip: 90001,
    accountType: "Saving",
    joinDate: moment(Date.now()).format("MMMM DD, YYYY"),
    sellerLevel: getRandomNumber(10),
    reputation: getRandomNumber(100),
    transactions: getRandomNumber(1000),
    buyerTransactions: getRandomNumber(500),
    sellerTransactions: getRandomNumber(500),
    arbitrations: getRandomNumber(2),
    arbitrationsLost: getRandomNumber(1),
    arbitrationsToTransactions: getRandomNumber(1),
    opposingTradeUser: "vecna",
  },
  {
    displayName: "shujja_99",
    realName: "Shujja",
    city: "LA",
    state: "California",
    zip: 90001,
    accountType: "Saving",
    joinDate: moment(Date.now()).format("MMMM DD, YYYY"),
    sellerLevel: getRandomNumber(10),
    reputation: getRandomNumber(100),
    transactions: getRandomNumber(1000),
    buyerTransactions: getRandomNumber(500),
    sellerTransactions: getRandomNumber(500),
    arbitrations: getRandomNumber(2),
    arbitrationsLost: getRandomNumber(1),
    arbitrationsToTransactions: getRandomNumber(1),
    opposingTradeUser: "vecna",
  },
  {
    displayName: "shujja_99",
    realName: "Shujja",
    city: "LA",
    state: "California",
    zip: 90001,
    accountType: "Saving",
    joinDate: moment(Date.now()).format("MMMM DD, YYYY"),
    sellerLevel: getRandomNumber(10),
    reputation: getRandomNumber(100),
    transactions: getRandomNumber(1000),
    buyerTransactions: getRandomNumber(500),
    sellerTransactions: getRandomNumber(500),
    arbitrations: getRandomNumber(2),
    arbitrationsLost: getRandomNumber(1),
    arbitrationsToTransactions: getRandomNumber(1),
    opposingTradeUser: "vecna",
  },
];
