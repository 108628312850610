import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";

import TradeDetailsModal from "../../CommonModals/TradeDetailsModal/TradeDetailsModal";

function EnhancedTableHead({ headCells }) {
  return (
    <TableHead>
      <TableRow className="table-no-padding">
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            sx={{
              width: "max-content",
            }}
          >
            <Typography
              fontWeight={"bold"}
              fontSize={16}
              color={headCell.id === "actions" ? "#fff" : "primary.dark"}
            >
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "5px",
}));

export default function EnhancedTable({
  rows,
  headCells,
  data,
  setData,
  onChange,
  tableTitle,
  loading,
  editable = true,
  handleEdit,
}) {
  const [openDetailsModal, setOpenDetailsModal] = useState(false);

  return (
    <Box mb={4} sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Box
          display="flex"
          justifyContent={"center"}
          px={2}
          alignContent={"center"}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <TableContainer
              sx={{
                minHeight: 350,
              }}
            >
              <Table size={"medium"}>
                <EnhancedTableHead
                  rowCount={rows.length}
                  headCells={headCells}
                />
                <TableBody>
                  {Object.keys(rows).map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row._id}
                      >
                        <StyledTableCell component="th" scope="row">
                          {rows[row]?.ticker}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {rows[row]?.bidAsk}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {rows[row]?.price}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {rows[row]?.quantity}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {rows[row]?.orderType}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {rows[row]?.timing}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {rows[row]?.limit}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {rows[row]?.stop}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {rows[row]?.transactions}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {rows[row]?.spotPlusDollar}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {rows[row]?.spotXPercentage}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {rows[row]?.floor}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {rows[row]?.ceiling}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {rows[row]?.priceBasedOn}
                        </StyledTableCell>
                        {editable && (
                          <StyledTableCell
                            component="th"
                            scope="row"
                            sx={{ cursor: "pointer", py: 1 }}
                          >
                            <Typography
                              sx={(theme) => ({
                                color: theme.palette.primary.main,
                              })}
                              onClick={handleEdit}
                            >
                              Edit
                            </Typography>
                          </StyledTableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Paper>
      <TradeDetailsModal
        open={openDetailsModal}
        handleClose={() => setOpenDetailsModal(false)}
      />
    </Box>
  );
}
