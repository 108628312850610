import React from "react";
import { DesktopDatePicker, LocalizationProvider, TimePicker } from "@mui/lab";
import { Box, TextField, Typography } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
export default function TradeLogisticsForm() {
  return (
    <Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        rowGap={5}
        width={"100%"}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          columnGap={1}
        >
          <Box width={"60%"}>
            <Typography fontSize={14}>Scheduled Order Post Time</Typography>
          </Box>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Date"
              inputFormat="MM/dd/yyyy"
              onChange={(e) => {}}
              renderInput={(params) => (
                <TextField
                  className="calender-icon"
                  size={"small"}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              onChange={(e) => {}}
              renderInput={(params) => <TextField size={"small"} {...params} />}
            />
          </LocalizationProvider>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          columnGap={1}
        >
          <Box width={"60%"}>
            <Typography fontSize={14}>Time Till Trade Matched</Typography>
          </Box>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Date"
              inputFormat="MM/dd/yyyy"
              onChange={(e) => {}}
              renderInput={(params) => (
                <TextField
                  className="calender-icon"
                  size={"small"}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              onChange={(e) => {}}
              renderInput={(params) => <TextField size={"small"} {...params} />}
            />
          </LocalizationProvider>
        </Box>
      </Box>
    </Box>
  );
}
