import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Heading } from "../../Styled/Globals";
import { Close } from "@mui/icons-material";
import React, { useState } from "react";
// import "./AuthModals.css";

const ChangePasswordModal = ({ open, handleClose }) => {
  // eslint-disable-next-line
  const [data, setData] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    handleClose();
  };

  const handleOnChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });

  return (
    <Modal open={open} onClose={handleClose} className={"modal"}>
      <Box className={"no-scroll-bar"}>
        <Paper sx={{ p: 3 }}>
          <Box className={"close-box"}>
            <Heading>Change Password Request </Heading>
            <Close className={"c-pointer"} onClick={handleClose} />
          </Box>
          <Box component={"form"} onSubmit={handleSubmit}>
            <Grid marginTop={2} container spacing={2}>
              <Grid item md={4} display="flex" alignItems={"center"}>
                <Typography fontSize={16} fontWeight="bold">
                  Enter Current Password
                </Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  size={"small"}
                  fullWidth
                  type="password"
                  name={"oldPassword"}
                  value={data?.oldPassword}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item md={4} display="flex" alignItems={"center"}>
                <Typography fontSize={16} fontWeight="bold">
                  Enter New Password
                </Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  size={"small"}
                  fullWidth
                  type="password"
                  name={"newPassowrd"}
                  value={data?.newPassowrd}
                  onChange={handleOnChange}
                />
              </Grid>
            </Grid>
            <Heading my={2}>Confirm Security Challenge Question:</Heading>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <TextField
                  size={"small"}
                  fullWidth
                  placeholder="Question here"
                  name={"question"}
                  value={data?.question}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  size={"small"}
                  fullWidth
                  placeholder="Type User Answer"
                  name={"answer"}
                  value={data?.answer}
                  onChange={handleOnChange}
                />
              </Grid>
            </Grid>
            <Box
              component={"div"}
              display="flex"
              justifyContent={"flex-end"}
              alignItems={"center"}
              pt={2}
            >
              {/* BButon */}
              <Button
                type={"submit"}
                variant={"contained"}
                color={"primary"}
                size={"medium"}
                sx={{
                  width: "25%",
                }}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default ChangePasswordModal;
