import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Modal,
  Paper,
  TextField,
  styled,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import {
  DataBox as _DataBox,
  DataLabel,
  Heading,
  SubHeading,
} from "../Styled/Globals";
import { mkts, reputations } from "../../constants/general";

const DataBox = styled(_DataBox)(({ theme }) => ({
  width: "100%",
}));

const FilterModal = ({ open, handleClose, showMKTS = false }) => {
  const formRef = React.useRef();
  const [data, setData] = useState(null);
  const onChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setData(null);
    e.target.reset();
    handleClose();
  };

  const handleClear = (e) => {
    setData(null);
    formRef?.current.reset();
  };

  return (
    <Modal className={"modal"} open={open} onClose={handleClose}>
      <Box
        sx={{
          width: 750,
        }}
        className={"no-scroll-bar"}
      >
        <Paper>
          <Box className={"b-relative"}>
            <Close className={"c-absolute"} onClick={handleClose} />
          </Box>
          <Box
            component={"div"}
            width={"100%"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            px={2}
            mb={1}
          >
            <Heading>Filtering</Heading>
            <Button
              variant={"contained"}
              disableElevation
              sx={{
                fontWeight: "bold",
                background: "#e5e5e5",
                textTransform: "none",
                px: 4,
                fontSize: 18,
                mr: 2,
              }}
              onClick={handleClear}
            >
              Clear
            </Button>
          </Box>
          <Divider />
          <Box p={2}>
            <Box mb={1}>
              <SubHeading>Filter by:</SubHeading>
            </Box>
            <Box component={"form"} onSubmit={handleSubmit} ref={formRef}>
              <Box
                sx={{
                  display: "flex",
                  columnGap: 4,
                }}
              >
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                    rowGap: 1,
                    [theme.breakpoints.up("xl")]: {
                      rowGap: 2,
                    },
                  })}
                >
                  <DataBox>
                    <DataLabel>Bid/Ask:</DataLabel>
                    <Autocomplete
                      placeholder="Bid/Ask"
                      id="bid-ask-autocomplete"
                      options={["Bid", "Ask"]}
                      getOptionLabel={(bidAsk) => `${bidAsk}`}
                      value={data?.bidAsk}
                      key={data?.bidAsk}
                      fullWidth
                      onChange={(e, bidAsk, reason) =>
                        reason === "selectOption"
                          ? setData({
                              ...data,
                              bidAsk,
                            })
                          : setData({
                              ...data,
                              bidAsk: "",
                            })
                      }
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            variant="outlined"
                            value={data?.bidAsk}
                            fullWidth
                            required
                          />
                        );
                      }}
                    />
                  </DataBox>
                  {showMKTS && (
                    <DataBox>
                      <DataLabel>MKT:</DataLabel>
                      <Autocomplete
                        placeholder="MKT"
                        id="bid-ask-autocomplete"
                        options={[...mkts]}
                        getOptionLabel={(mkt) => `${mkt}`}
                        value={data?.mkt}
                        key={data?.mkt}
                        fullWidth
                        onChange={(e, mkt, reason) =>
                          reason === "selectOption"
                            ? setData({
                                ...data,
                                mkt,
                              })
                            : setData({
                                ...data,
                                mkt: "",
                              })
                        }
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              variant="outlined"
                              value={data?.mkt}
                              fullWidth
                              required
                            />
                          );
                        }}
                      />
                    </DataBox>
                  )}
                  <DataBox>
                    <DataLabel>Ticker:</DataLabel>
                    <Autocomplete
                      placeholder="Ticker"
                      id="ticker-autocomplete"
                      options={["Ticker", "Spot"]}
                      getOptionLabel={(ticker) => `${ticker}`}
                      value={data?.ticker}
                      key={data?.ticker}
                      onChange={(e, ticker, reason) =>
                        reason === "selectOption"
                          ? setData({
                              ...data,
                              ticker,
                            })
                          : setData({
                              ...data,
                              ticker: "",
                            })
                      }
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            key={data?.ticker}
                            variant="outlined"
                            value={data?.ticker}
                            required
                          />
                        );
                      }}
                    />{" "}
                  </DataBox>
                  <DataBox>
                    <DataLabel>Price:</DataLabel>
                    <TextField
                      type="number"
                      name={"price"}
                      onChange={onChange}
                      fullWidth
                      value={data?.price}
                    />
                  </DataBox>
                  <DataBox>
                    <DataLabel>Quantity:</DataLabel>
                    <TextField
                      type="number"
                      name={"quantity"}
                      onChange={onChange}
                      fullWidth
                      value={data?.quantity}
                    />
                  </DataBox>
                  <DataBox>
                    <DataLabel>Total:</DataLabel>
                    <TextField
                      type="number"
                      name={"total"}
                      onChange={onChange}
                      fullWidth
                      value={data?.total}
                    />
                  </DataBox>
                </Box>
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    width: "100%",
                    rowGap: 1,
                    [theme.breakpoints.up("xl")]: {
                      rowGap: 2,
                    },
                  })}
                >
                  <DataBox>
                    <DataLabel>Reputation:</DataLabel>
                    <Autocomplete
                      placeholder="Reputation"
                      id="reputation-autocomplete"
                      options={[...reputations]}
                      getOptionLabel={(reputation) => `${reputation}`}
                      value={data?.reputation}
                      key={data?.reputation}
                      onChange={(e, reputation, reason) =>
                        reason === "selectOption"
                          ? setData({
                              ...data,
                              reputation,
                            })
                          : setData({
                              ...data,
                              reputation: "",
                            })
                      }
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            variant="outlined"
                            value={data?.reputation}
                            required
                          />
                        );
                      }}
                    />
                  </DataBox>
                  <DataBox>
                    <DataLabel># of Transactions:</DataLabel>
                    <Autocomplete
                      id="transactions-autocomplete"
                      options={["100+", "10+", "1+", "Any"]}
                      getOptionLabel={(transactions) => `${transactions}`}
                      value={data?.transactions}
                      key={data?.transactions}
                      onChange={(e, transactions, reason) =>
                        reason === "selectOption"
                          ? setData({
                              ...data,
                              transactions,
                            })
                          : setData({
                              ...data,
                              transactions: "",
                            })
                      }
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            variant="outlined"
                            value={data?.transactions}
                            required
                          />
                        );
                      }}
                    />
                  </DataBox>
                </Box>
              </Box>
              <Box className={"b-flex-end"}>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  type={"submit"}
                  sx={{
                    textTransform: "uppercase",
                    px: 4,
                  }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default FilterModal;
