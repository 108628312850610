import {
  Box,
  Button,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";

import { styled } from "@mui/system";

export const MarketDataHeaders = styled(Typography)({
  fontSize: 24,
  fontWeight: "bold",
});

export const Heading = styled(Typography)(({ theme }) => ({
  fontSize: 23,
  [theme.breakpoints.up("xl")]: {
    fontSize: 28,
  },
  fontWeight: "bold",
}));
export const SubHeading = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  [theme.breakpoints.up("xl")]: {
    fontSize: 22,
  },
  fontWeight: "bold",
}));

export const StyledContainer = styled(Box)(({ theme }) => ({
  paddingRight: "55px",
  paddingLeft: "55px",
  marginRight: "auto",
  marginLeft: "auto",
  [theme.breakpoints.up("xl")]: {
    paddingRight: "90px",
    paddingLeft: "90px",
  },
}));

export const AdminContainer = styled(Box)(({ theme }) => ({
  paddingRight: "10px",
  paddingLeft: "10px",
  marginRight: "auto",
  marginLeft: "auto",
  [theme.breakpoints.up("xl")]: {
    paddingRight: "30px",
    paddingLeft: "30px",
  },
}));

export const StyledButton = styled(Button)({
  variant: "contained",
  backgroundColor: "#5f99ff",
  color: "#fff",
  // margin: "125px auto",
  display: "block",
  height: "50px",
  textTransform: "none",
  fontWeight: "bold",
  fontSize: 24,
  ":hover": {
    color: "#fff",
    backgroundColor: "#2958ac",
  },
});

export const StyledTableButton = styled(Button)({
  variant: "contained",
  backgroundColor: "#5f99ff",
  color: "#fff",
  // margin: "125px auto",
  display: "block",
  // height: "px",
  textTransform: "none",
  fontSize: 14,
  ":hover": {
    color: "#000",
  },
});

export const KreonTypography = styled(Typography)({
  fontSize: 48,
  fontFamily: "Kreon",
  textAlign: "center",
  color: "#fff",
  fontWeight: "bold",
});

export const StyledTextArea = styled(TextareaAutosize)((theme) => ({
  fontFamily: "Roboto",
  resize: "none",
  width: "100%",
  color: "#000",
  padding: 4,
  borderColor: "#c4c4c4",
  borderRadius: 3,
}));

export const DisabledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input.Mui-disabled": {
    background: "#f2f2f2 !important",
  },
}));

// export const CardButton = styled(Button)((theme) => {
//   return {
//     variant: "contained",
//     backgroundColor: "#fff",
//     color: "#2152A9",
//     display: "block",
//     textTransform: "none",
//     fontWeight: "bold",
//     padding: "40px 20px",
//     fontSize: 28,
//     height: 200,
//     width: 375,
//     border: "1px solid #c4c4c4",
//     borderRadius: 10,

//     ":hover": {
//       color: "#fff",
//       backgroundColor: "#2152A9",
//     },
//   };
// });

export const CardButton = styled(Button)(({ theme }) => ({
  variant: "contained",
  backgroundColor: "#fff",
  color: "#2152A9",
  display: "block",
  textTransform: "none",
  fontWeight: "bold",
  height: 200,
  width: 375,
  border: "1px solid #c4c4c4",
  borderRadius: 10,
  fontSize: "28px !important",
  padding: "40px 65px",
  [theme.breakpoints.up("xl")]: {
    padding: "40px 80px",
  },
  ":hover": {
    color: "#fff",
    backgroundColor: "#2152A9",
  },
}));

export const ArbitrationCardButton = styled(Button)(({ theme }) => ({
  variant: "contained",
  display: "block",
  fontWeight: "bold",
  border: "1px solid #c4c4c4",
  borderRadius: 10,
  backgroundColor: "#2152A9",
  width: 200,
  height: 90,
  lineHeight: "25px",
  padding: "0px 10px",
  color: "#fff",
  fontSize: "16px !important",
  textTransform: "none",
  [theme.breakpoints.up("xl")]: {
    fontSize: "20px !important",
    width: 230,
    height: 100,
    padding: "5px 30px",
  },
  ":hover": {
    color: "#fff",
    backgroundColor: "#5F99FF",
  },
}));

export const DataLabel = styled(Typography)(({ theme }) => ({
  fontSize: 15,
  color: "#000",
  [theme.breakpoints.up("xl")]: {
    fontSize: 18,
  },
}));

export const DataBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

export const LimitField = styled(Box)(({ theme }) => ({
  border: "1px solid #ccc",
  borderTop: "none",
  borderBottom: "none",
  display: "flex",
  alignItems: "center",
  width: "100%",
  height: "100%",
}));

export const LimitBox = styled(Box)(({ theme }) => ({
  height: "100%",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-evenly",
  display: "flex",
  columnGap: "16px",
  px: 4,
  borderRadius: 5,
  border: "1px solid #ccc",
}));

export const AttachmentsGrid = styled(Box)(({ theme }) => ({
  display: "flex",
  columnGap: "50px",
  mt: 1,
}));
