import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Paper,
  Radio,
} from "@mui/material";
import AddressForm from "./AddressForm";

export default function ShippingDetails({ ShippingInformationSVG }) {
  const [sameShipping, setSameShipping] = useState(true);

  useEffect(() => {
    console.log(sameShipping);
  }, [sameShipping]);

  return (
    <Box>
      <Box component={"div"} my={3} display="flex" alignItems={"center"}>
        <ShippingInformationSVG
          style={{
            marginRight: 20,
          }}
        />
        <Typography fontSize={30} fontWeight={"bold"}>
          Shipping Information
        </Typography>
      </Box>
      {/* <Box display={"grid"} sx={{ margin: "auto" }}>
        <FormControlLabel label="Same as Contact Information" />
        <FormControlLabel label="Not the same, shipping information below" />
      </Box> */}

      <Box>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={sameShipping}
            onChange={(e) => setSameShipping(e.target.value === "true")}
          >
            <FormControlLabel
              value={true}
              control={<Radio />}
              label="Same as Contact Information"
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="Not the same, shipping information below"
            />
          </RadioGroup>
        </FormControl>
      </Box>

      {!sameShipping && (
        <Box component={"div"}>
          <Paper style={{ backgroundColor: "#fff", padding: "25px " }}>
            <AddressForm />
          </Paper>
        </Box>
      )}
    </Box>
  );
}
