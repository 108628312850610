import Close from "@mui/icons-material/Close";
import { Box, Button, Modal, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { StyledTextArea } from "../../Styled/Globals";

const VerificationModal = ({ open, handleClose }) => {
  const [data, setData] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    handleClose();
  };

  const handleChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });

  return (
    <Modal className={"modal"} open={open} onClose={handleClose}>
      <Box
        sx={{
          maxWidth: "50%",
        }}
        className={"no-scroll-bar"}
      >
        <Paper sx={{ px: 2, pt: 1 }}>
          <Box className={"b-relative"}>
            <Close
              sx={{
                fontSize: "small",
                position: "absolute",
                right: -12,
                top: 0,
              }}
              onClick={handleClose}
            />
          </Box>
          <Typography color={"#000"} fontSize={20} mb={2} fontWeight="bold">
            Please enter code you received on your phone for 2FA Verification
          </Typography>
          <StyledTextArea
            onChange={handleChange}
            name={"code"}
            minRows={8}
            style={{ width: "100%" }}
          />
          <Box component={"form"} onSubmit={handleSubmit} marginTop={2}>
            <Typography marginY={1}>
              Please wait at least X minutes before resending.
            </Typography>

            <Box
              component={"div"}
              display="flex"
              justifyContent={"space-between"}
              alignItems={"center"}
              paddingY={2}
            >
              <Button
                type={"submit"}
                variant={"text"}
                sx={(theme) => ({
                  color: theme.palette.primary.main,
                })}
              >
                Resend
              </Button>
              <Button
                type={"submit"}
                variant={"contained"}
                color={"primary"}
                sx={{
                  width: "35%",
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default VerificationModal;
