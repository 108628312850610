import { Button, Menu, MenuItem } from "@mui/material";
import { IconMenuItem, NestedMenuItem } from "mui-nested-menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const Markets = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({}));
  const markets = [
    { title: "Markets", link: "/markets" },
    { title: "Gold", link: "/markets/gold" },
    { title: "Silver", link: "/markets/silver" },
    { title: "Platinum", link: "/markets/platinum" },
    { title: "Palladium", link: "/markets/palladium" },
    { title: "Copper", link: "/markets/copper" },
    { title: "Coins & Currency", link: "/markets/coinscurrency" },
  ];
  const _additionalMarkets = [
    { title: "Rhodium", link: "/markets/rhodium" },
    { title: "Iridium", link: "/markets/iridium" },
    { title: "Osmium", link: "/markets/osmium" },
    { title: "Rhenium", link: "/markets/rhenium" },
    { title: "Ruthenium", link: "/markets/ruthenium" },
    { title: "Beryllium", link: "/markets/beryllium" },
  ];

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        style={{
          color: "#000",
          textTransform: "capitalize",
          fontSize: 18,
          fontWeight: "bold",
        }}
      >
        Markets
      </Button>
      <StyledMenu
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {markets.map((market) => {
          return (
            <Link to={market.link}>
              <MenuItem onClick={handleClose}>{market.title}</MenuItem>
            </Link>
          );
        })}
        <NestedMenuItem
          sx={{ pl: 1 }}
          label={"Additional MKTS"}
          parentMenuOpen={open}
        >
          {_additionalMarkets.map((market) => {
            return (
              <Link to={market.link}>
                <IconMenuItem onClick={handleClose} label={market.title} />
              </Link>
            );
          })}
        </NestedMenuItem>
      </StyledMenu>
    </div>
  );
};
export default Markets;
