import React, { useState, useRef } from "react";
import { ReactComponent as UserDetailSVG } from "../../assets/settings/user-details.svg";
import { ReactComponent as ShippingInformationSVG } from "../../assets/settings/shipping-information.svg";
import { ReactComponent as SignInSecuritySVG } from "../../assets/settings/sign-in-security.svg";
import { ReactComponent as CommunicationSVG } from "../../assets/settings/communication.svg";
import { ReactComponent as MyAccountSVG } from "../../assets/settings/my-account.svg";
import { ReactComponent as HelpSVG } from "../../assets/settings/help.svg";
import { ReactComponent as UserSettingsSVG } from "../../assets/settings/user-settings.svg";
import { ReactComponent as ContactSVG } from "../../assets/settings/contact.svg";
import Hexagon from "../../components/Hexagon/Hexagon";
import {
  Box,
  Grid,
  List,
  ListItem,
  Typography,
  styled,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import VerificationModal from "../../components/Settings/Modals/VerificationModal";
import SaveModal from "../../components/Settings/Modals/SaveModal";
import IncorrectCodeModal from "../../components/Settings/Modals/IncorrectCodeModal";
import UserDetails from "./Dashboards/UserDetails";
import ContactDetails from "./Dashboards/ContactDetails";
import ShippingDetails from "./Dashboards/ShippingInfo";
import SignInSecurity from "./Dashboards/SignInSecurity";
import "./Settings.css";
import TFA from "./Dashboards/TFA";
import SecurityQuestions from "./Dashboards/SecurityQuestions";
import CommunicationPreferences from "./Dashboards/CommunicationPreferences";
import { AdminContainer } from "../../components/Styled/Globals";
import { useNavigate } from "react-router-dom";

const Link = styled(Typography)({
  color: "#5F99FF",
  textDecoration: "underline",
  cursor: "pointer",
});

const Settings = () => {
  const [openVerificationModal, setOpenVerificationModal] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [openIncorrectModal, setOpenIncorrectModal] = useState(false);

  const isXL = useMediaQuery(useTheme().breakpoints.up("xl"));
  const history = useNavigate();

  const userDetailsRef = useRef(null);
  const signInSecurityRef = useRef(null);
  const communicationRef = useRef(null);

  const _menus = [
    {
      text: "User Details",
      svg: (
        <UserDetailSVG
          style={{
            transform: `scale(${isXL ? "1.0" : "0.9"})`,
          }}
        />
      ),
      handler: () => userDetailsRef?.current?.scrollIntoView(),
    },
    {
      text: "Sign In & Security",
      svg: (
        <SignInSecuritySVG
          style={{
            transform: `scale(${isXL ? "1.0" : "0.9"})`,
          }}
        />
      ),
      handler: () => signInSecurityRef?.current?.scrollIntoView(),
    },
    {
      text: "Communication Prefrences",
      svg: (
        <CommunicationSVG
          style={{
            transform: `scale(${isXL ? "1.0" : "0.9"})`,
          }}
        />
      ),
      handler: () => communicationRef?.current?.scrollIntoView(),
    },
    {
      text: "My Account",
      svg: (
        <MyAccountSVG
          style={{
            transform: `scale(${isXL ? "1.0" : "0.9"})`,
          }}
        />
      ),
      handler: () => history("/account"),
    },
    {
      text: "Help",
      svg: (
        <HelpSVG
          style={{
            transform: `scale(${"1.0"})`,
          }}
        />
      ),
      handler: () => history("/help"),
    },
  ];

  const drawerWidth = isXL ? 280 : 200;

  return (
    <Box sx={{ display: "flex", mb: { md: -5, xl: -10 } }}>
      <Box
        component={"aside"}
        sx={{
          alignSelf: "start",
          position: "sticky",
          background: "#fff",
          top: 0,
          width: drawerWidth,
          height: "100vh",
          scrollbarWidth: "none" /* Firefox */,
          "-ms-overflow-style": "none" /* IE 10+ */,
          "::-webkit-scrollbar": {
            background: "#c4c4c4" /* Chrome/Safari/Webkit */,
            width: "0px",
          },
        }}
      >
        {_menus.map((menu, index) => {
          return (
            <List>
              <ListItem
                button
                key={index}
                sx={{
                  marginY: 1,
                  fontWeight: "bold",
                  borderRight: index === 0 ? "6px solid #5F99FF" : "",
                }}
                onClick={menu.handler}
              >
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  columnSpacing={5}
                >
                  <Grid item md={4}>
                    <Box
                      sx={(theme) => ({
                        [theme.breakpoints.up("xl")]: {
                          ml: 2,
                        },
                      })}
                    >
                      {menu.svg}
                    </Box>
                  </Grid>
                  <Grid item md={8}>
                    <Typography
                      sx={(theme) => ({
                        fontWeight: "bold",
                        fontSize: 14,
                        m: "auto",
                        [theme.breakpoints.up("xl")]: {
                          fontSize: 16,
                        },
                      })}
                    >
                      {menu.text}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          );
        })}
      </Box>
      <Box
        component="main"
        sx={(theme) => ({
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          pb: 10,
          [theme.breakpoints.up("xl")]: {
            pb: 15,
          },
        })}
      >
        <AdminContainer>
          <Hexagon
            title={
              <Box
                display="flex"
                justifyContent={"center"}
                margin={"auto"}
                width={"100%"}
                gap={2}
                alignItems={"center"}
              >
                <UserSettingsSVG />
                <Typography color={"primary"} fontWeight={"bold"} fontSize={28}>
                  Settings
                </Typography>
              </Box>
            }
          />
          <UserDetails
            UserDetailSVG={UserDetailSVG}
            Link={Link}
            bRef={userDetailsRef}
          />
          <ContactDetails ContactSVG={ContactSVG} />
          <ShippingDetails ShippingInformationSVG={ShippingInformationSVG} />
          <SignInSecurity
            SignInSecuritySVG={SignInSecuritySVG}
            Link={Link}
            bRef={signInSecurityRef}
          />
          <TFA Link={Link} />
          <SecurityQuestions />
          <CommunicationPreferences
            CommunicationSVG={CommunicationSVG}
            bRef={communicationRef}
          />
        </AdminContainer>
        <IncorrectCodeModal
          open={openIncorrectModal}
          handleClose={() => setOpenIncorrectModal(false)}
        />
        <SaveModal
          open={openSaveModal}
          handleClose={() => setOpenSaveModal(false)}
        />
        <VerificationModal
          open={openVerificationModal}
          handleClose={() => setOpenVerificationModal(false)}
        />
      </Box>
    </Box>
  );

  // return (
  //   <Box sx={{ display: "flex" }}>
  //     <Box
  //       component="nav"
  //       sx={{
  //         width: { sm: drawerWidth },
  //         flexShrink: { sm: 0 },
  //       }}
  //       aria-label="mailbox folders"
  //     >
  //       <Drawer
  //         variant="permanent"
  //         sx={{
  //           display: { xs: "none", sm: "block" },
  //           position: "relative",

  //           "& .MuiDrawer-paper": {
  //             boxSizing: "border-box",
  //             borderRight: 0,
  //             width: drawerWidth,
  //             scrollbarWidth: "none" /* Firefox */,
  //             "-ms-overflow-style": "none" /* IE 10+ */,
  //             "::-webkit-scrollbar": {
  //               background: "#c4c4c4" /* Chrome/Safari/Webkit */,
  //               width: "0px",
  //             },
  //           },
  //         }}
  //         open
  //       >
  //         <Box
  //           sx={{
  //             display: "block",
  //             m: "0px auto",
  //             pt: 2,
  //             pb: 0.3,
  //           }}
  //         >
  //           <Link to={"/"}>
  //             <img src={Logo} alt="bullion-logo" width={120} />
  //           </Link>
  //         </Box>
  //         <Divider />
  //         {_menus.map((menu, index) => {
  //           return (
  //             <List>
  //               <ListItem
  //                 button
  //                 key={index}
  //                 sx={{
  //                   paddingX: 5,
  //                   marginY: 1,
  //                   fontWeight: "bold",
  //                   display: "flex",
  //                   alignItems: "center",
  //                   borderRight: index === 0 ? "6px solid #5F99FF" : "",
  //                 }}
  //               >
  //                 <ListItemIcon sx={{ marginRight: 2 }}>
  //                   {menu.svg}
  //                 </ListItemIcon>
  //                 <Typography fontWeight={"bold"}>{menu.text}</Typography>
  //               </ListItem>
  //             </List>
  //           );
  //         })}
  //       </Drawer>
  //     </Box>
  //     <Box
  //       component="main"
  //       sx={{
  //         flexGrow: 1,
  //         p: 3,
  //         width: { sm: `calc(100% - ${drawerWidth}px)` },
  //       }}
  //     >
  //       <AdminContainer>
  //         <Hexagon
  //           title={
  //             <Box
  //               display="flex"
  //               justifyContent={"center"}
  //               margin={"auto"}
  //               width={"100%"}
  //               gap={2}
  //               alignItems={"center"}
  //             >
  //               <UserSettingsSVG />
  //               <Typography color={"primary"} fontWeight={"bold"} fontSize={28}>
  //                 Settings
  //               </Typography>
  //             </Box>
  //           }
  //         />
  //         <UserDetails UserDetailSVG={UserDetailSVG} Link={Link} />
  //         <ContactDetails ContactSVG={ContactSVG} />
  //         <ShippingDetails ShippingInformationSVG={ShippingInformationSVG} />
  //         <SignInSecurity SignInSecuritySVG={SignInSecuritySVG} Link={Link} />
  //         <TFA Link={Link} />
  //         <SecurityQuestions />
  //         <CommunicationPreferences CommunicationSVG={CommunicationSVG} />
  //       </AdminContainer>
  //       <IncorrectCodeModal
  //         open={openIncorrectModal}
  //         handleClose={() => setOpenIncorrectModal(false)}
  //       />
  //       <SaveModal
  //         open={openSaveModal}
  //         handleClose={() => setOpenSaveModal(false)}
  //       />
  //       <VerificationModal
  //         open={openVerificationModal}
  //         handleClose={() => setOpenVerificationModal(false)}
  //       />
  //     </Box>
  //   </Box>
  // );
};

export default Settings;

// User Details
