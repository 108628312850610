import React, { useState } from "react";
import { Box, Paper } from "@mui/material";
import { Heading, StyledButton } from "../../Styled/Globals";
import Templates from "./Templates";
import MessagingForm from "./MessagingForm";
import TemplateNameModal from "./TemplateNameModal";
const Messaging = ({ bRef }) => {
  const [data, setData] = useState({});
  const [openSaveModal, setOpenSaveModal] = useState(false);

  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

  return (
    <Box ref={bRef}>
      <TemplateNameModal
        open={openSaveModal}
        handleClose={(_) => setOpenSaveModal(false)}
      />
      <Box mb={1}>
        <Heading>Messaging</Heading>
      </Box>
      <Paper sx={{ py: 3, px: 5 }}>
        <MessagingForm
          data={data}
          setData={setData}
          onChange={onChange}
          variant={"normal"}
        />
        <Box
          display={"flex"}
          width={"100%"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          columnGap={4}
          mt={1}
        >
          <StyledButton
            sx={{
              fontSize: 14,
              fontWeight: "normal",
              height: "100%",
            }}
            onClick={(_) => setOpenSaveModal(true)}
          >
            Save
          </StyledButton>
          <StyledButton
            sx={{
              fontSize: 14,
              fontWeight: "normal",
              height: "100%",
            }}
          >
            Send
          </StyledButton>
        </Box>
      </Paper>
      <Templates />
    </Box>
  );
};

export default Messaging;
