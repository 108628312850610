import React, { useState } from "react";
import {
  Box,
  Container,
  IconButton,
  InputBase,
  Paper,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import SearchModal from "../components/CommonModals/SearchModal";
import SearchIcon from "@mui/icons-material/Search";
import helpImg from "../assets/help/help.png";
import HelpGrid from "../components/Help/HelpGrid";

const Help = () => {
  const isXL = useMediaQuery(useTheme().breakpoints.up("xl"));
  const [openSearchModal, setOpenSearchModal] = useState(false);

  return (
    <Box>
      <SearchModal
        open={openSearchModal}
        handleClose={(_) => setOpenSearchModal(false)}
      />
      <div
        style={{
          background: "#2152A9",
          minHeight: isXL ? "375px" : "300px",
          minWidth: "100%",
          position: "absolute",
          top: isXL ? "50%" : "55%",
          zIndex: -1,
        }}
      ></div>
      <Container
        sx={{
          paddingY: 2.3,
        }}
      >
        <img
          src={helpImg}
          alt="help-img"
          className="center-block "
          style={{
            height: "65vh",
          }}
        />
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            margin: "25px auto",
            background: "#fff",
          }}
        >
          <IconButton sx={{ p: "10px" }} aria-label="menu">
            <SearchIcon />
          </IconButton>
          <InputBase
            onClick={(_) => setOpenSearchModal(true)}
            sx={{ ml: 1, flex: 1, paddingY: 2 }}
            placeholder="Search here"
            inputProps={{ "aria-label": "search here" }}
          />
        </Paper>
      </Container>
      <Box
        component={"div"}
        sx={{
          marginTop: isXL ? 10 : 0,
        }}
      >
        <HelpGrid />
      </Box>
    </Box>
  );
};

export default Help;
