import { Box, Button, Typography } from "@mui/material";

import { Link } from "react-router-dom";
import OpenOrderTable from "./OpenOrderTable";
import React from "react";

function OpenOrder({ SVG, bRef }) {
  return (
    <Box ref={bRef}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          component={"div"}
          marginBottom={2}
          display="flex"
          alignItems={"center"}
          width={"100%"}
        >
          <SVG
            style={{
              marginRight: 20,
            }}
          />
          <Typography fontSize={30} fontWeight={"bold"}>
            Open Orders
          </Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          alginItems={"center"}
          width="100%"
        >
          <Button variant={"contained"} color={"primary"}>
            <Link to={"/account/openorders"}>
              <Typography
                color={"#fff"}
                sx={{
                  textTransform: "uppercase",
                }}
              >
                Full Dashboard
              </Typography>
            </Link>
          </Button>
        </Box>
      </Box>

      <Box component={"div"}>
        <OpenOrderTable
          rows={[
            {
              orderNumber: 1234567890,
              mkt: "Gold",
              ticker: "AAPL",
              bidAsk: 800,
              description: "Reference site about in lorem email",
              quantity: 200,
              price: 1000,
              total: 1000,
            },
            {
              orderNumber: 4356783514,
              mkt: "Platinum",
              ticker: "PLAT",
              bidAsk: 800,
              description: "Reference site about in lorem email",
              quantity: 500,
              price: 2000,
              total: 3500,
            },
            {
              orderNumber: 8473920582,
              mkt: "Silver",
              ticker: "SLVR",
              bidAsk: 830,
              description: "Reference site about in lorem email",
              quantity: 250,
              price: 1030,
              total: 1020,
            },
            {
              orderNumber: 2984769302,
              mkt: "Gold",
              ticker: "AAPL",
              bidAsk: 800,
              description: "Reference site about in lorem email",
              quantity: 200,
              price: 1000,
              total: 1000,
            },
            {
              orderNumber: 1385739584,
              mkt: "Gold",
              ticker: "AAPL",
              bidAsk: 800,
              description: "Reference site about in lorem email",
              quantity: 200,
              price: 1000,
              total: 1000,
            },
            {
              orderNumber: 9585746303,
              mkt: "Gold",
              ticker: "AAPL",
              bidAsk: 800,
              description: "Reference site about in lorem email",
              quantity: 200,
              price: 1000,
              total: 1000,
            },
          ]}
          headCells={[
            {
              id: "orderNumber",
              numeric: false,
              width: "10%",
              label: "Order#",
            },
            {
              id: "mkt",
              numeric: false,
              width: "5%",
              label: "MKT",
            },
            {
              id: "ticker",
              numeric: false,
              width: "2%",
              label: "Ticker",
            },
            {
              id: "bidAsk",
              numeric: false,
              width: "2%",
              label: "Bid/Ask",
            },
            {
              id: "description",
              numeric: false,
              width: "25%",
              label: "Description",
            },
            {
              id: "quantity",
              numeric: true,
              width: "5%",
              label: "Quantity",
            },
            {
              id: "price",
              numeric: true,
              width: "5%",
              label: "Price",
            },
            {
              id: "total",
              numeric: true,
              width: "5%",
              label: "Total",
            },
            {
              id: "options",
              numeric: false,
              width: "5%",
              label: "",
              disableSorting: true,
            },
            {
              id: "action",
              numeric: false,
              width: "15%",
              label: "Action",
              disableSorting: true,
              sx: {
                alignItems: "center",
                justifyContent: "center",
                fontSize: "large",
                color: "#fff",
                paddingY: 1,
              },
            },
          ]}
          tableTitle={null}
          loading={false}
        />
      </Box>
    </Box>
  );
}

export default OpenOrder;
