import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Modal,
  Paper,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { Close } from "@mui/icons-material";
import ScheduleForm from "./ScheduleForm";
import { SubHeading } from "../../../Styled/Globals";

const CallRequestModal = ({ open, handleClose, variant }) => {
  const [user, setUser] = useState(null);
  const [showFormModal, setShowFormModal] = useState(false);
  const [showDialog, setShowDialog] = useState(true);

  const UserButton = styled(Button)(({ theme }) => ({
    width: "100%",
  }));

  useEffect(() => {
    console.log("open", open);
    console.log("user", user);
    if (!user) {
      setShowDialog(true);
      setShowFormModal(false);
    } else {
      setShowDialog(false);
      setShowFormModal(true);
    }
  }, [open, user]);

  const onFormClose = () => {
    setShowFormModal(false);
    setUser(null);
    handleClose();
  };

  const onDialogClose = () => {
    setShowDialog(false);
    setUser(null);
    handleClose();
  };

  return (
    open && (
      <Box>
        <Modal open={showFormModal} onClose={onFormClose} className={"modal"}>
          <Box
            sx={{
              width: 850,
            }}
            className={"no-scroll-bar"}
          >
            <Paper>
              <ScheduleForm
                variant={"Call"}
                handleClose={onFormClose}
                user={user}
              />
            </Paper>
          </Box>
        </Modal>
        <Dialog onClose={onDialogClose} open={showDialog}>
          <DialogTitle>
            <Box className={"close-box"}>
              <SubHeading>Select User</SubHeading>
              <Close
                className="pointer"
                fontSize={"inherit"}
                onClick={onDialogClose}
              />
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box
              display={"flex"}
              width={200}
              flexDirection={"column"}
              rowGap={2}
            >
              <UserButton
                disableElevation
                onClick={(_) => {
                  setUser("buyer");
                }}
                variant={"contained"}
                color={"primary"}
              >
                Buyer
              </UserButton>
              <UserButton
                disableElevation
                onClick={(_) => setUser("seller")}
                variant={"contained"}
                color={"primary"}
              >
                Seller
              </UserButton>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    )
  );
};

export default CallRequestModal;
