import React, { useState } from "react";
import { ReactComponent as ShippingSVG } from "../../assets/shipping-and-settlement.svg";
import { ReactComponent as RequiresActionSVG } from "../../assets/shipping-and-settlement/requires-action.svg";
import { ReactComponent as AwaitingConfirmSVG } from "../../assets/shipping-and-settlement/awaiting-confirmation.svg";
import { ReactComponent as CancelledSVG } from "../../assets/shipping-and-settlement/cancelled.svg";
import { ReactComponent as ClosedSVG } from "../../assets/shipping-and-settlement/closed.svg";
import { ReactComponent as SettledSVG } from "../../assets/shipping-and-settlement/settled.svg";
import { ReactComponent as ShippedSVG } from "../../assets/shipping-and-settlement/shipped.svg";
import { ReactComponent as WaitingSVG } from "../../assets/shipping-and-settlement/waiting.svg";
import { Box, Typography } from "@mui/material";
import { StyledContainer } from "../../components/Styled/Globals";
import Hexagon from "../../components/Hexagon/Hexagon";
import PaperCard from "../../components/ShippingSettlements/PaperCard";
import FileClaimModal from "../../components/Arbitration/FileClaimModal/FileClaimModal";
import ThankYouModal from "../../components/Arbitration/ThankYouModal/ThankYouModal";
import LabelsModal from "../../components/ShippingSettlements/LabelsModal";
import DeliveryModal from "../../components/ShippingSettlements/DeliveryModal/DeliveryModal";
import PackagingSlip from "../../components/ShippingSettlements/PackagingSlip/PackagingSlip";

function ShippingSettlements() {
  const paperCards = [
    {
      title: "Requires Action",
      SVG: RequiresActionSVG,
      options: [
        {
          title: "Print Label & Packing Slip",
          handler: () => setOpenLabelsModal(true),
        },
        {
          title: "Arbitrate",
          handler: () => setOpenFileClaim(true),
        },
      ],
    },
    {
      title: "Closed",
      SVG: ClosedSVG,
    },
    {
      title: "Awaiting Tracking Number",
      SVG: WaitingSVG,
      options: [
        {
          title: "Provide Tracking Number",
          handler: () => setOpenTrackingModal(true),
        },
      ],
    },
    {
      title: "Shipped",
      SVG: ShippedSVG,
      options: [
        {
          title: "Report Delivery",
          handler: () => setOpenReportModal(true),
        },
      ],
    },
    {
      title: "Awaiting Confirmation",
      SVG: AwaitingConfirmSVG,
      options: [
        {
          title: "Report Delivery",
          handler: () => setOpenReportModal(true),
        },
      ],
    },
    {
      title: "Settled",
      SVG: SettledSVG,
      options: [
        {
          title: "Accept Delivery",
          handler: () => setOpenAcceptModal(true),
        },
        {
          title: "Arbitrate",
          handler: () => setOpenFileClaim(true),
        },
      ],
    },
    {
      title: "Cancelled",
      SVG: CancelledSVG,
      options: [
        {
          title: "Accept Delivery",
          handler: () => setOpenAcceptModal(true),
        },
        {
          title: "Arbitrate",
          handler: () => setOpenFileClaim(true),
        },
      ],
    },
  ];

  const [openFileClaim, setOpenFileClaim] = useState(false);
  const [openThankYou, setOpenThankYou] = useState(false);
  const [openLabelsModal, setOpenLabelsModal] = useState(false);
  const [openTrackingModal, setOpenTrackingModal] = useState(false);
  const [openReportModal, setOpenReportModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const [openPackagingSlip, setOpenPackagingSlip] = useState(false);

  return (
    <Box height={"100%"} pb={10}>
      <StyledContainer>
        <Hexagon
          width={450}
          title={
            <Box
              display="flex"
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={1.5}
            >
              <ShippingSVG />
              <Typography color={"primary"} fontWeight={"bold"} fontSize={26}>
                Shipping &#38; Settlement
              </Typography>
            </Box>
          }
        />
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          width={"100%"}
          rowGap={10}
          pt={2}
        >
          {paperCards.map((card) => {
            return (
              <PaperCard
                title={card.title}
                SVG={card.SVG}
                options={card?.options}
              />
            );
          })}
        </Box>
      </StyledContainer>
      <FileClaimModal
        open={openFileClaim}
        handleClose={() => setOpenFileClaim(false)}
        onSubmit={() => {
          setOpenThankYou(true);
          setOpenFileClaim(false);
        }}
      />
      {/* Thank You Modal */}
      <ThankYouModal
        open={openThankYou}
        handleClose={() => setOpenThankYou(false)}
      />
      {/* Labels Modal */}
      <LabelsModal
        open={openLabelsModal}
        handleClose={() => setOpenLabelsModal(false)}
      />

      {/* Tracking Modal */}
      <DeliveryModal
        variant={"tracking"}
        title={"Provide Tracking Number"}
        open={openTrackingModal}
        handleClose={() => setOpenTrackingModal(false)}
      />

      {/* Report Delivery Modal */}
      <DeliveryModal
        variant={"report"}
        title={"Report Delivery"}
        open={openReportModal}
        handleClose={() => setOpenReportModal(false)}
      />

      {/* Confirm Modal */}
      <DeliveryModal
        variant={"confirm"}
        title={"Confirm Delivery/Arbitrate"}
        open={openConfirmModal}
        handleClose={() => setOpenConfirmModal(false)}
      />

      {/* Confirm Modal */}
      <DeliveryModal
        variant={"accept"}
        title={"Accept Delivery/Arbitrate"}
        open={openAcceptModal}
        handleClose={() => setOpenAcceptModal(false)}
      />

      {/* Packaging Slip */}
      <PackagingSlip
        open={openPackagingSlip}
        handleClose={() => setOpenPackagingSlip(false)}
      />
    </Box>
  );
}

export default ShippingSettlements;
