import React from "react";
import Hexagon from "../../components/Hexagon/Hexagon";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Box,
  Breadcrumbs,
  Paper,
  Typography,
  Link as MUILink,
} from "@mui/material";
import { Link } from "react-router-dom";
import { StyledContainer } from "../../components/Styled/Globals";

const HelpArticle = () => {
  const _breadcrumbs = [
    <MUILink
      underline="hover"
      key="1"
      color="inherit"
      fontSize={20}
      fontWeight={"bold"}
    >
      <Link to={"/help"}>Help</Link>
    </MUILink>,
    <Typography key="2" color="primary" fontSize={20} fontWeight="bold">
      Title of Help Article Goes Here
    </Typography>,
  ];

  return (
    <Box height={"100%"} paddingBottom={20}>
      <StyledContainer>
        <Breadcrumbs
          sx={{ marginTop: 3 }}
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {_breadcrumbs}
        </Breadcrumbs>
        <Hexagon
          width={500}
          title={
            <Box
              display="flex"
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography fontWeight={"bold"} color={"primary"} fontSize={26}>
                Title of Help Article Goes Here{" "}
              </Typography>
            </Box>
          }
        />

        <Box>
          <Paper
            sx={{
              height: "100vh",
              padding: 10,
            }}
          >
            <Typography component={"p"} variant={"p"}>
              Filler text here
            </Typography>
          </Paper>
        </Box>
      </StyledContainer>
    </Box>
  );
};

export default HelpArticle;
