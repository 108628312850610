import React from "react";
import { Paper, Box, Modal, Button } from "@mui/material";
import Close from "@mui/icons-material/Close";
import { Heading } from "../../Styled/Globals";
import SearchField from "../../Global/SearchField";
import ListDataTable from "./ListDataTable";
import { sampleList } from "../../../constants/SampleData/lists";

export default function ListDataModal({ open, handleClose, title }) {
  return (
    <Modal className={"modal"} open={open} onClose={handleClose}>
      <Box
        sx={{
          width: "90%",
          maxWidth: "95%",
        }}
        className="no-scroll-bar"
      >
        <Paper sx={{ px: 3, py: 2 }}>
          <Box className="b-flex-end" pb={2}>
            <Close className="c-pointer" onClick={handleClose} />
          </Box>
          <ModalHeader title={title} />
          <Box pt={3}>
            <ListDataTable rows={[...sampleList]} />
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
}

const ModalHeader = ({ title }) => (
  <Box
    sx={{
      display: "flex",
      width: "100%",
      alignItems: "center",
    }}
  >
    <Box width={"100%"}>
      <Heading>{title}</Heading>
    </Box>
    <Box
      sx={{
        display: "flex",
        width: "100%",
        columnGap: 4,
      }}
    >
      <Button
        size={"small"}
        sx={{
          width: "35%",
        }}
      >
        Filter
      </Button>
      <SearchField width="350px" title={"Search"} noMargin />
    </Box>
  </Box>
);
