import React, { useState } from "react";
import { Box, Paper, Button, styled, Grid } from "@mui/material";
import { Heading } from "../../Styled/Globals";
import ListDataModal from "./ListDataModal";

function Lists() {
  const [title, setTitle] = useState(null);
  const [openListModal, setOpenListModal] = useState(false);

  const buttons = [
    {
      title: "Active Sellers",
      handler: null,
    },
    {
      title: "Active Buyer",
      handler: null,
    },
    {
      title: "Restricted Sellers",
      handler: null,
    },
    {
      title: "Restricted Buyer",
      handler: null,
    },

    {
      title: "Suspended Sellers",
      handler: null,
    },
    {
      title: "Suspended Buyer",
      handler: null,
    },
    {
      title: "Terminated Sellers",
      handler: null,
    },
    {
      title: "Terminated Buyer",
      handler: null,
    },
    {
      title: "Banned Trading Partners",
      handler: null,
      fullRow: true,
    },
  ];
  const StyledButton = styled(Button)(({ theme }) => ({
    width: "100%",
  }));
  return (
    <Box mt={5}>
      <ListDataModal
        open={openListModal}
        handleClose={(_) => setOpenListModal(false)}
        title={title}
      />
      <Box mb={2}>
        <Heading>See Lists</Heading>
      </Box>
      <Paper sx={{ px: 4, py: 2 }}>
        <Box>
          <Grid container spacing={2}>
            {buttons?.map((button) => (
              <Grid item md={button?.fullRow ? 12 : 6}>
                <StyledButton
                  variant={"contained"}
                  color={"primary"}
                  size={"small"}
                  onClick={(_) => {
                    setTitle(button?.title);
                    setOpenListModal(true);
                  }}
                >
                  {button?.title}
                </StyledButton>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}

export default Lists;
