import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import logo from "../../assets/bullion-logo.png";
import { ReactComponent as PasswordSVG } from "../../assets/auth/password.svg";
import "./AuthModals.css";

const PasswordResetModal = ({ open, handleClose }) => {
  const [data, setData] = useState({});

  const style = {
    position: "absolute",
    top: "50%",
    border: "none",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    overflowX: "hidden",
    display: "block",
  };
  const handleSignUp = (e) => {
    e.preventDefault();
  };

  const handleOnChange = (e) => {};

  return (
    <Modal open={open} onClose={handleClose}>
      <Box style={style}>
        <Paper sx={{ padding: 5 }}>
          <img src={logo} alt="bullion-logo" className="center-block " />
          <Box
            display={"flex"}
            margin="auto"
            mt={5}
            justifyContent={"space-evenly"}
            alignItems={"center"}
            width={"60%"}
          >
            <PasswordSVG />
            <Typography color={"#000"} fontSize={28} fontWeight="bold">
              Password Reset
            </Typography>
          </Box>
          <Box component={"form"} onSubmit={handleSignUp} paddingY={3}>
            <Typography fontSize={24} color={"#000"}>
              Enter your User Name and the 2FA code on your phone to reset your
              password.
            </Typography>
            <Grid marginTop={2} container spacing={2}>
              <Grid item md={4} display="flex" alignItems={"center"}>
                <Typography fontSize={22} fontWeight="bold">
                  User Name
                </Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  size={"small"}
                  fullWidth
                  type="text"
                  name={"middleName"}
                  value={data?.middleName}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item md={4} display="flex" alignItems={"center"}>
                <Typography fontSize={22} fontWeight="bold">
                  2FA Code{" "}
                </Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  size={"small"}
                  fullWidth
                  type="text"
                  name={"middleName"}
                  value={data?.middleName}
                  onChange={handleOnChange}
                />
              </Grid>
            </Grid>
            <Box
              component={"div"}
              display="flex"
              justifyContent={"flex-end"}
              alignItems={"center"}
              paddingY={2}
            >
              {/* BButon */}
              <Button
                variant={"contained"}
                style={{
                  color: "#fff",
                  display: "block",
                  height: "50px",
                  width: 200,
                  borderRadius: 10,
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: 24,
                }}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Paper>
        <Box
          sx={{
            bgcolor: "#2152a9",
            paddingTop: 7,
            paddingBottom: 2,
          }}
        >
          <Typography fontWeight={"bold"} textAlign="center" color={"#fff"}>
            Bullion MKTS, LLC 2022
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default PasswordResetModal;
