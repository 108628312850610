import { Box, Paper } from "@mui/material";
import React from "react";
import { Heading } from "../../Styled/Globals";
import MockUsers from "./MockUsers";
import TradeSimulations from "./TradeSimulations";

function SimulationManagement({ bRef }) {
  return (
    <Box ref={bRef}>
      <Box
        sx={{
          my: 2,
        }}
      >
        <Heading>Simulations</Heading>
      </Box>
      <Paper sx={{ px: 2, pt: 3, pb: 10 }}>
        <TradeSimulations />
        <br />
        <MockUsers />
      </Paper>
    </Box>
  );
}
export default SimulationManagement;
