import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CustomPagination from "../../Common/CustomPagination";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import moment from "moment";
import { styled } from "@mui/material";
import { visuallyHidden } from "@mui/utils";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    console.log(property, event);
    onRequestSort(event, property);
  };
  const tableStyling = {
    padding: "0px 0px",
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={{
              ...tableStyling,
              width: headCell?.width || 100,
              verticalAlign: "top",
              textAlign: "left",
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {/* {headCell.label} */}
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={(theme) => ({
                fontSize: "small",
                padding: "5px 0px",
                display: "flex",
                lineHeight: 1.5,
                width: "100%",
                justifyContent: "space-between",
                textDecoration: "underline",
                color: theme.palette.primary.dark,
                "& .MuiTableSortLabel-icon": {
                  margin: "0px",
                  fontSize: "small",
                },
                [theme.breakpoints.up("xl")]: {
                  fontSize: "medium",
                  "& .MuiTableSortLabel-icon": {
                    fontSize: "medium",
                  },
                },
                ...headCell?.sx,
              })}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function WithdrawlRequestsTable({ rows }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [tableData, setTableData] = React.useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const StyledTableCell = styled(TableCell)(({ theme, center }) => ({
    padding: "5px 0px",
    fontSize: "14px",
    textAlign: center ? "center" : "left",
    border: "none",
    [theme.breakpoints.up("xl")]: {
      fontSize: "16px",
    },
  }));

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, borderRadius: 2, p: 1 }}>
        <TableContainer
          sx={{
            m: "0px",
            width: "100%",
          }}
        >
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"small"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {
                // stableSort(rows, getComparator(order, orderBy))
                //   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                tableData.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row?.id}>
                      <StyledTableCell
                        component="td"
                        id={labelId}
                        scope="row"
                        sx={{ pl: 1 }}
                      >
                        {row?.userName}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        {row?.realName}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        {moment(row?.joinDate).format("MM/DD/YYYY")}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        ${row?.transactions}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        {moment(row?.lastTradeSettledDate).format("MM/DD/YYYY")}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        ${row?.settledFunds}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        ${row?.amountRequested}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        {row?.addressRequested}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={(_) => null}
                          size={"small"}
                          disableElevation
                          sx={{ fontSize: "16px" }}
                        >
                          Edit
                        </Button>
                      </StyledTableCell>
                    </TableRow>
                  );
                })
              }
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
      <CustomPagination
        rowsPerPage={rowsPerPage}
        rows={rows}
        setTableData={setTableData}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
}

const headCells = [
  {
    id: "userName",
    numeric: false,
    disablePadding: true,
    label: "User Name",
  },
  {
    id: "realName",
    numeric: false,
    disablePadding: true,
    label: "Real Name",
  },
  {
    id: "joinDate",
    numeric: false,
    disablePadding: true,
    label: "Join Date",
  },
  {
    id: "transactions",
    numeric: true,
    disablePadding: true,
    label: "Transactions",
  },
  {
    id: "lastTradeSettledDate",
    numeric: true,
    disablePadding: true,
    label: (
      <React.Fragment>
        Last Trade <br /> Settled Date
      </React.Fragment>
    ),
  },
  {
    id: "settledFunds",
    numeric: true,
    disablePadding: true,
    label: (
      <React.Fragment>
        Settled <br /> Funds
      </React.Fragment>
    ),
  },

  {
    id: "amountRequested",
    numeric: true,
    disablePadding: true,
    label: (
      <React.Fragment>
        Amount <br /> Requested
      </React.Fragment>
    ),
  },
  {
    id: "addressRequested",
    numeric: true,
    disablePadding: true,
    label: (
      <React.Fragment>
        Address <br /> Requested
      </React.Fragment>
    ),
  },
  {
    id: "action",
    numeric: false,
    disablePadding: true,
    disableSorting: true,
    label: "Action",
  },
];
