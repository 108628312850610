import React, { useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import AddressForm from "./AddressForm";

export default function ContactDetails({ ContactSVG }) {
  return (
    <Box>
      <Box component={"div"} my={5} display="flex" alignItems={"center"}>
        <ContactSVG
          style={{
            marginRight: 20,
          }}
        />
        <Typography fontSize={30} fontWeight={"bold"}>
          Contact Information
        </Typography>
      </Box>

      <Box component={"div"}>
        <Paper style={{ backgroundColor: "#fff", padding: "25px " }}>
          <AddressForm />
        </Paper>
      </Box>
    </Box>
  );
}
