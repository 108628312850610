import { Box, Divider, Grid } from "@mui/material";
import {
  DataHeading,
  DataTypography,
  SectionHeading,
} from "../Styled/TradeModal";

import React from "react";

function BasicSection({ data }) {
  const showPrice = (val) =>
    val?.toString().split("$")?.lenght > 0 ? val : `$${val}`;

  return (
    <Box>
      <SectionHeading>Basic</SectionHeading>
      <Divider />
      <Grid container spacing={2} pt={1}>
        <Grid item md={4}>
          <DataHeading>Bid/Ask</DataHeading>
          <DataTypography>{data?.bidAsk}</DataTypography>
        </Grid>
        <Grid item md={4}>
          <DataHeading>Order#</DataHeading>
          <DataTypography>
            {Math.floor(Math?.random() * 939394324)}
          </DataTypography>
        </Grid>
        <Grid item md={4}>
          <DataHeading>MKT</DataHeading>
          <DataTypography>{data?.mkt}</DataTypography>
        </Grid>
        <Grid item md={4}>
          <DataHeading>Ticker</DataHeading>
          <DataTypography>{data?.ticker}</DataTypography>
        </Grid>

        <Grid item md={8}>
          <DataHeading>Description</DataHeading>
          <DataTypography>{data?.description}</DataTypography>
        </Grid>
        <Grid item md={4}>
          <DataHeading>Price</DataHeading>
          <DataTypography>{showPrice(data?.price)}</DataTypography>
        </Grid>
        <Grid item md={4}>
          <DataHeading>Quantity</DataHeading>
          <DataTypography>{data?.quantity}</DataTypography>
        </Grid>
        <Grid item md={4}>
          <DataHeading>Total</DataHeading>
          <DataTypography>{showPrice(data?.total)}</DataTypography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default BasicSection;
