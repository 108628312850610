export const priceOps = ["Spot", "Ticker"];
export const transactionOps = ["100+", "10+", "1+", "Any"];
export const reputations = [
  "95% or better",
  "90% or better",
  "80% or better",
  "70% or better",
  "60% or better",
  "Below 50%",
  "Any",
];
export const timings = [
  "All or None",
  "Partial Okay",
  "Good Until Canceled",
  "Day",
];
export const orderTypes = [
  "Market",
  "Premium to Spot",
  "Buy Limit",
  "Sell Limit",
  "Buy Stop",
  "Sell Stop",
  "Stop-Loss",
  "Stop-Limit",
];

export const mkts = [
  "Gold",
  "Silver",
  "Platinum",
  "Palladium",
  "Copper",
  "Gold/Silver",
  "Coin & Currency",
  "Rhodium",
  "Iridium",
  "Osmium",
  "Rhenium",
  "Ruthenium",
  "Beryllium",
];
