import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { Heading, SubHeading } from "../../Styled/Globals";
import React, { useState } from "react";

import Close from "@mui/icons-material/Close";

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
}));

export default function EditUserModal({ open, handleClose }) {
  const [data, setData] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleClose();
  };

  const handleChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });

  return (
    <Modal className={"modal"} open={open} onClose={handleClose}>
      <Box
        className={"no-scroll-bar"}
        sx={(theme) => ({
          maxWidth: 550,
          [theme.breakpoints.up("xl")]: {
            maxWidth: 800,
          },
        })}
      >
        <Paper
          sx={(theme) => ({
            p: 2,
          })}
        >
          <Box sx={{ paddingBottom: 2 }} className={"close-box"}>
            <Heading>Edit Mock User Data</Heading>
            <Close className={"c-pointer"} onClick={handleClose} />
          </Box>
          <Box component={"form"} onSubmit={handleSubmit}>
            <Grid container columnSpacing={5} rowSpacing={2}>
              <Grid item xs={8}>
                <SubHeading sx={{ textDecoration: "underline" }}>
                  Mock User Information
                </SubHeading>
              </Grid>
              <Grid item xs={4}>
                <SubHeading sx={{ textDecoration: "underline" }}>
                  Edit Information
                </SubHeading>
              </Grid>
              <Grid item xs={8}>
                <Label>Display Name for Trade:</Label>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name={"name"}
                  value={data?.name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={8}>
                <Label>Join Date:</Label>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  type={"date"}
                  name={"joinDate"}
                  value={data?.joinDate}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={8}>
                <Label>Reputation:</Label>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name={"reputation"}
                  value={data?.reputation}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={8}>
                <Label># of Transactions:</Label>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name={"noOfTransactions"}
                  value={data?.noOfTransactions}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={8}>
                <Label># of Buyer Transactions:</Label>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name={"noOfBuyerTransactions"}
                  value={data?.noOfBuyerTransactions}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={8}>
                <Label># of Seller Transactions:</Label>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name={"noOfSellerTransactions"}
                  value={data?.noOfSellerTransactions}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={8}>
                <Label># of Arbitrations:</Label>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name={"noOfArbitrations"}
                  value={data?.noOfArbitrations}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={8}>
                <Label>Arbitrations to Transactions %:</Label>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  name={"arbitrationsToTransactions"}
                  value={data?.arbitrationsToTransactions}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  disableElevation
                  fullWidth
                  type={"submit"}
                  color={"primary"}
                  variant={"contained"}
                  sx={{ py: 2, textTransform: "uppercase" }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
}
