import "./MyAccount.css";

import {
  Box,
  Grid,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useRef } from "react";

import { AdminContainer } from "../../components/Styled/Globals";
import Arbitration from "./Arbitration/Arbitration";
import { ReactComponent as ArbitrationSVG } from "../../assets/my-account/arbitration.svg";
import ExternalSettings from "../../components/MyAccount/ExternalSettings";
import Funding from "./Funding/Funding";
import { ReactComponent as FundingSVG } from "../../assets/my-account/funding.svg";
import { ReactComponent as HelpSVG } from "../../assets/my-account/help.svg";
import Hexagon from "../../components/Hexagon/Hexagon";
import MultipleTrades from "./MultipleTrades/MultipleTrades";
import { ReactComponent as MultipleTradesSVG } from "../../assets/my-account/multiple-trades.svg";
import { ReactComponent as MyAccountSVG } from "../../assets/my-account/my-account.svg";
import Notifications from "./Notifications/Notifications";
import { ReactComponent as NotificationsSVG } from "../../assets/my-account/notifications.svg";
import OpenOrder from "./OpenOrder/OpenOrder";
import { ReactComponent as OpenOrdersSVG } from "../../assets/my-account/open-orders.svg";
import { ReactComponent as PoliciesSVG } from "../../assets/my-account/policies.svg";
import { ReactComponent as SettingsSVG } from "../../assets/my-account/settings.svg";
import { ReactComponent as ShippingAndSettlementSVG } from "../../assets/my-account/shipping-and-settlement.svg";
import ShippingSettlement from "./ShippingSettlement/ShippingSettlement";
import Transactions from "./Transactions/Transactions";
import { ReactComponent as TransactionsSVG } from "../../assets/my-account/transactions.svg";
import { useNavigate } from "react-router";

const MyAccount = () => {
  const notificationsRef = useRef(null);
  const transactionsRef = useRef(null);
  const openOrdersRef = useRef(null);
  const fundingRef = useRef(null);
  const multipleOrderManagmentRef = useRef(null);
  const shippingAndSettlementRef = useRef(null);
  const arbitrationRef = useRef(null);

  const navigate = useNavigate();

  const _menus = [
    {
      text: "Notifications",
      svg: <NotificationsSVG />,
      handler: () => notificationsRef.current.scrollIntoView(),
    },
    {
      text: "Transactions",
      svg: <TransactionsSVG />,
      handler: () => transactionsRef.current.scrollIntoView(),
    },
    {
      text: "Open Orders",
      svg: <OpenOrdersSVG />,
      handler: () => openOrdersRef.current.scrollIntoView(),
    },
    {
      text: "Funding",
      svg: <FundingSVG />,
      handler: () => fundingRef.current.scrollIntoView(),
    },
    {
      text: "Multiple Order Management",
      svg: <MultipleTradesSVG />,
      handler: () => multipleOrderManagmentRef.current.scrollIntoView(),
    },
    {
      text: "Shipping & Settlement",
      svg: <ShippingAndSettlementSVG />,
      handler: () => shippingAndSettlementRef.current.scrollIntoView(),
    },
    {
      text: "Arbitration",
      svg: <ArbitrationSVG />,
      handler: () => arbitrationRef.current.scrollIntoView(),
    },
    {
      text: "Policies",
      svg: <PoliciesSVG />,
      handler: () => navigate("/policies"),
    },
    {
      text: "Settings",
      svg: <SettingsSVG />,
      handler: () => navigate("/settings"),
    },
    { text: "Help", svg: <HelpSVG />, handler: () => navigate("/help") },
    { text: "", svg: <Box></Box>, handler: () => null },
    // { text: "Help", svg: <HelpSVG />, handler: () => navigate("/help") },
  ];

  const drawerWidth = useMediaQuery(useTheme().breakpoints.up("xl"))
    ? 280
    : 200;

  return (
    <Box sx={{ display: "flex", mb: { md: -5, xl: -10 } }}>
      <Box
        component={"aside"}
        sx={{
          alignSelf: "start",
          position: "sticky",
          background: "#fff",
          top: 0,
          width: drawerWidth,
          scrollbarWidth: "none" /* Firefox */,
          "-ms-overflow-style": "none" /* IE 10+ */,
          "::-webkit-scrollbar": {
            background: "#c4c4c4" /* Chrome/Safari/Webkit */,
            width: "0px",
          },
        }}
      >
        <List
          sx={(theme) => ({
            overflowY: " auto",
            height: "100vh",
            scrollbarWidth: "none" /* Firefox */,
            "-ms-overflow-style": "none" /* IE 10+ */,
            "::-webkit-scrollbar": {
              background: "#c4c4c4" /* Chrome/Safari/Webkit */,
              width: "0px",
            },
          })}
        >
          {_menus.map((menu, index) => {
            return (
              <List>
                <ListItem
                  button
                  key={index}
                  sx={{
                    marginY: 1,
                    fontWeight: "bold",
                    borderRight: index === 0 ? "6px solid #5F99FF" : "",
                  }}
                  onClick={menu?.handler}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    columnSpacing={5}
                  >
                    <Grid item md={4}>
                      <Box
                        sx={(theme) => ({
                          [theme.breakpoints.up("xl")]: {
                            ml: 2,
                          },
                        })}
                      >
                        {menu.svg}
                      </Box>
                    </Grid>
                    <Grid item md={8}>
                      <Typography
                        sx={(theme) => ({
                          fontWeight: "bold",
                          fontSize: 14,
                          m: "auto",
                          [theme.breakpoints.up("xl")]: {
                            fontSize: 16,
                          },
                        })}
                      >
                        {menu.text}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              </List>
            );
          })}
        </List>
      </Box>
      <Box
        component="main"
        sx={(theme) => ({
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          pb: 10,
          [theme.breakpoints.up("xl")]: {
            pb: 15,
          },
        })}
      >
        <AdminContainer>
          <Hexagon
            title={
              <Box
                display="flex"
                justifyContent={"center"}
                margin={"auto"}
                width={"100%"}
                gap={2}
                alignItems={"center"}
              >
                <MyAccountSVG />
                <Typography color={"primary"} fontWeight={"bold"} fontSize={28}>
                  My Account
                </Typography>
              </Box>
            }
          />
          <Notifications SVG={NotificationsSVG} bRef={notificationsRef} />
          <Transactions SVG={TransactionsSVG} bRef={transactionsRef} />
          <OpenOrder SVG={OpenOrdersSVG} bRef={openOrdersRef} />
          <Funding SVG={FundingSVG} bRef={fundingRef} />
          <MultipleTrades
            SVG={MultipleTradesSVG}
            bRef={multipleOrderManagmentRef}
          />
          <ShippingSettlement
            SVG={ShippingAndSettlementSVG}
            bRef={shippingAndSettlementRef}
          />
          <Arbitration SVG={ArbitrationSVG} bRef={arbitrationRef} />
          {/* Externals */}
          <Box marginTop={4} px={2}>
            <ExternalSettings
              SVG={PoliciesSVG}
              title={"Policies"}
              link={"/policies"}
            />
            <ExternalSettings
              SVG={SettingsSVG}
              title={"Settings"}
              link={"/settings"}
            />
            <ExternalSettings SVG={HelpSVG} title={"Help"} link={"/help"} />
          </Box>
        </AdminContainer>
      </Box>{" "}
    </Box>
  );

  // return (
  //   <Box>
  //     <Box sx={{ display: "flex" }}>
  //       <Box
  //         position="fixed"
  //         sx={{
  //           zIndex: (theme) => theme.zIndex.drawer + 1,
  //           left: 0,
  //           top: 0,
  //           width: drawerWidth,
  //           background: "#fff",
  //           display: "flex",
  //           borderRight: 0,
  //           boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%)",
  //         }}
  //       >
  //         <Box
  //           sx={{
  //             display: "block",
  //             m: "auto",
  //             pt: 2,
  //             pb: 0.3,
  //           }}
  //         >
  //           <Link to={"/"}>
  //             <img src={Logo} alt="bullion-logo" width={120} />
  //           </Link>
  //         </Box>
  //       </Box>
  //       <Box
  //         component="nav"
  //         sx={{
  //           width: { sm: drawerWidth },
  //           flexShrink: { sm: 0 },
  //         }}
  //         aria-label="mailbox folders"
  //       >
  //         <Drawer
  //           variant="permanent"
  //           sx={{
  //             display: { xs: "none", sm: "block" },
  //             flexShrink: 0,
  //             "& .MuiDrawer-paper": {
  //               boxSizing: "border-box",
  //               borderRight: 0,
  //               width: drawerWidth,
  //               scrollbarWidth: "none" /* Firefox */,
  //               "-ms-overflow-style": "none" /* IE 10+ */,
  //               "::-webkit-scrollbar": {
  //                 background: "#c4c4c4" /* Chrome/Safari/Webkit */,
  //                 width: "0px",
  //               },
  //             },
  //           }}
  //           open
  //         >
  //           <Box
  //             sx={(theme) => ({
  //               paddingTop: "75px",
  //             })}
  //           >
  //             <List>
  //               {_menus.map((menu, index) => {
  //                 return (
  //                   <List>
  //                     <ListItem
  //                       button
  //                       key={index}
  //                       sx={(theme) => ({
  //                         paddingX: 2,
  //                         marginY: 1,
  //                         fontWeight: "bold",
  //                         display: "flex",
  //                         alignItems: "center",
  //                         borderRight: index === 0 ? "6px solid #5F99FF" : "",
  //                         [theme.breakpoints.up("xl")]: {
  //                           paddingX: 5,
  //                         },
  //                       })}
  //                     >
  //                       <ListItemIcon
  //                         sx={(theme) => ({
  //                           marginRight: 1,
  //                           transform: "scale(0.85)",
  //                           [theme.breakpoints.up("xl")]: {
  //                             marginRight: 2,
  //                             transform: "scale(1)",
  //                           },
  //                         })}
  //                       >
  //                         {menu.svg}
  //                       </ListItemIcon>
  //                       <Typography
  //                         fontWeight={"bold"}
  //                         sx={(theme) => ({
  //                           fontSize: 15,
  //                           [theme.breakpoints.up("xl")]: {
  //                             fontSize: 18,
  //                           },
  //                         })}
  //                       >
  //                         {menu.text}
  //                       </Typography>
  //                     </ListItem>
  //                   </List>
  //                 );
  //               })}
  //             </List>
  //           </Box>
  //         </Drawer>
  //       </Box>
  //       <Box
  //         component="main"
  //         sx={{
  //           flexGrow: 1,
  //           p: 3,
  //           width: { sm: `calc(100% - ${drawerWidth}px)` },
  //         }}
  //       >
  //         <AdminContainer>
  //           <Hexagon
  //             title={
  //               <Box
  //                 display="flex"
  //                 justifyContent={"center"}
  //                 margin={"auto"}
  //                 width={"100%"}
  //                 gap={2}
  //                 alignItems={"center"}
  //               >
  //                 <MyAccountSVG />
  //                 <Typography
  //                   color={"primary"}
  //                   fontWeight={"bold"}
  //                   fontSize={28}
  //                 >
  //                   My Account
  //                 </Typography>
  //               </Box>
  //             }
  //           />
  //           <Notifications SVG={NotificationsSVG} />
  //           <Transactions SVG={TransactionsSVG} />
  //           <OpenOrder SVG={OpenOrdersSVG} />
  //           <Funding SVG={FundingSVG} />
  //           <MultipleTrades SVG={MultipleTradesSVG} />
  //           <ShippingSettlement SVG={ShippingAndSettlementSVG} />
  //           <Arbitration SVG={ArbitrationSVG} />

  //           {/* Externals */}
  //           <Box marginTop={4}>
  //             <ExternalSettings
  //               SVG={PoliciesSVG}
  //               title={"Policies"}
  //               link={"/policies"}
  //             />
  //             <ExternalSettings
  //               SVG={SettingsSVG}
  //               title={"Settings"}
  //               link={"/settings"}
  //             />
  //             <ExternalSettings SVG={HelpSVG} title={"Help"} link={"/help"} />
  //           </Box>
  //         </AdminContainer>
  //       </Box>
  //     </Box>
  //   </Box>
  // );

  // return (
  //   <Grid container sx={{ marginBottom: 2 }}>
  //     <Grid item md={2}>
  //       <Box
  //         position="relative"
  //         component={"div"}
  //         bgcolor={"#fff"}
  //         height={"110%"}
  //       >
  //         {_menus.map((menu, index) => {
  //           return (
  //             <List>
  //               <ListItem
  //                 button
  //                 key={index}
  //                 sx={{
  //                   paddingX: 5,
  //                   marginY: 1,
  //                   fontWeight: "bold",
  //                   display: "flex",
  //                   alignItems: "center",
  //                   borderRight: index === 0 ? "6px solid #5F99FF" : "",
  //                 }}
  //               >
  //                 <ListItemIcon sx={{ marginRight: 2 }}>
  //                   {menu.svg}
  //                 </ListItemIcon>
  //                 <Typography fontWeight={"bold"}>{menu.text}</Typography>
  //               </ListItem>
  //             </List>
  //           );
  //         })}
  //       </Box>
  //     </Grid>
  //     <Grid item md={10}>
  //       <Box
  //         sx={{
  //           paddingRight: "50px",
  //           paddingLeft: "50px",
  //           marginRight: "auto",
  //           marginLeft: "auto",
  //         }}
  //       >
  //         <Hexagon
  //           title={
  //             <Box
  //               display="flex"
  //               justifyContent={"center"}
  //               margin={"auto"}
  //               width={"100%"}
  //               gap={2}
  //               alignItems={"center"}
  //             >
  //               <MyAccountSVG />
  //               <Typography color={"primary"} fontWeight={"bold"} fontSize={28}>
  //                 My Account
  //               </Typography>
  //             </Box>
  //           }
  //         />
  //         <Notifications SVG={NotificationsSVG} />
  //         <Transactions SVG={TransactionsSVG} />
  //         <OpenOrder SVG={OpenOrdersSVG} />
  //         <Funding SVG={FundingSVG} />
  //         <MultipleTrades SVG={MultipleTradesSVG} />
  //         <ShippingSettlement SVG={ShippingAndSettlementSVG} />
  //         <Arbitration SVG={ArbitrationSVG} />

  //         {/* Externals */}
  //         <Box marginTop={4}>
  //           <ExternalSettings
  //             SVG={PoliciesSVG}
  //             title={"Policies"}
  //             link={"/policies"}
  //           />
  //           <ExternalSettings
  //             SVG={SettingsSVG}
  //             title={"Settings"}
  //             link={"/settings"}
  //           />
  //           <ExternalSettings SVG={HelpSVG} title={"Help"} link={"/help"} />
  //         </Box>
  //       </Box>
  //     </Grid>
  //   </Grid>
  // );
};

export default MyAccount;
