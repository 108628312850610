import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { DisabledTextField, StyledTextArea } from "../Styled/Globals";
import React, { useState } from "react";

import { Close } from "@mui/icons-material";

// import "./AuthModals.css";

const Label = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  [theme.breakpoints.up("xl")]: {
    fontSize: 22,
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  height: 65,
  width: "35%",
}));

const TextGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  width: "100%",
}));
const RequestCheckModal = ({ open, handleClose }) => {
  const [data, setData] = useState({});
  const [changeAddress, setChangeAddress] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    handleClose();
  };

  const handleOnChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });

  React.useEffect(() => setChangeAddress(false), [open]);

  return (
    <Modal open={open} onClose={handleClose} className={"modal"}>
      <Box
        className={"no-scroll-bar"}
        sx={(theme) => ({
          maxWidth: "65%",
        })}
      >
        <Paper sx={{ px: 5 }}>
          <Box className={"b-flex-end"}>
            <Close
              className={"c-pointer"}
              sx={(theme) => ({
                mb: -5,
                mr: -3,
              })}
              onClick={handleClose}
            />
          </Box>
          <Box component={"form"} onSubmit={handleSubmit}>
            <Grid marginTop={2} container spacing={2}>
              <Grid item md={6} display="flex" alignItems={"center"}>
                <Label>Cleared Funds Available For Withdrawal </Label>
              </Grid>
              <TextGrid item md={6}>
                <DisabledTextField
                  disabled={true}
                  size={"medium"}
                  sx={{
                    width: "75%",
                  }}
                  type={"text"}
                  name={"fundsAvailable"}
                  value={data?.fundsAvailable}
                  onChange={handleOnChange}
                />
              </TextGrid>
              <Grid item md={6} display="flex" alignItems={"center"}>
                <Label>Amount to Withdrawal via Check </Label>
              </Grid>
              <TextGrid item md={6}>
                <TextField
                  size={"medium"}
                  sx={{
                    width: "75%",
                  }}
                  type={"text"}
                  name={"withdrawViaCheck"}
                  value={data?.withdrawViaCheck}
                  onChange={handleOnChange}
                />
              </TextGrid>
              <Grid item md={6} display="flex" alignItems={"center"}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    height: "100%",
                  }}
                >
                  <Label>Confirm Address</Label>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    wdith: "100%",
                    justifyContent: "flex-end",
                    flexDirection: "column",
                  }}
                >
                  <Box width="100%" justifyContent={"flex-end"} display="flex">
                    <DisabledTextField
                      disabled={!changeAddress}
                      size={"medium"}
                      sx={{
                        width: "75%",
                      }}
                      type={"text"}
                      name={"confirmAddress"}
                      value={data?.confirmAddress}
                      onChange={handleOnChange}
                    />
                  </Box>
                  {!changeAddress && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Typography
                        color={"primary"}
                        sx={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={(_) => setChangeAddress(true)}
                      >
                        Edit
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item md={6} alignItems={"center"}>
                <Label mb={1}>Make Check Out To</Label>
                <Typography fontWeight={"bold"} fontSize={14}>
                  Note: If this differs materially than your real name or
                  business name you can expect to be contacted from us and asked
                  to confirm this check withdrawal before it is processed.
                </Typography>
              </Grid>
              <TextGrid item md={6}>
                <StyledTextArea
                  minRows={7}
                  type={"text"}
                  name={"checkTo"}
                  value={data?.checkTo}
                  onChange={handleOnChange}
                  style={{ width: "75%", padding: 7 }}
                />
              </TextGrid>
              <Grid item md={12}>
                <Typography fontWeight={"bold"} color={"primary.dark"}>
                  Please note that checks can take up to two weeks to be written
                  and arrive, however Bullion MKTS will print and mail a check
                  the next business day after your request and mail it that same
                  day. Most delays are related to the post office and/or the
                  distance from Bullion MKTS headquarters in St. Louis, MO.
                </Typography>
              </Grid>
            </Grid>
            <Box
              component={"div"}
              display="flex"
              justifyContent={"flex-end"}
              alignItems={"center"}
              paddingY={2}
            >
              <SubmitButton
                variant={"contained"}
                onClick={handleClose}
                color={"primary"}
              >
                REVIEW &#38; SUBMIT
              </SubmitButton>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default RequestCheckModal;
