import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  CircularProgress,
  styled,
} from "@mui/material";

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            sx={{
              paddingX: 0,
              paddingLeft: index === 0 && 1,
            }}
          >
            <Typography
              fontWeight={"bold"}
              fontSize={18}
              color={"primary.dark"}
            >
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable({ row, loading }) {
  const StyledTableCell = styled(TableCell)(({ theme, center }) => ({
    padding: "10px 0px",
    paddingRight: "5px",
    fontSize: "14px",
    textAlign: center ? "center" : "left",
    border: "none",
    [theme.breakpoints.up("xl")]: {
      fontSize: "16px",
    },
  }));

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }} elevation={0}>
        <Box display="flex" justifyContent={"center"} alignContent={"center"}>
          {loading ? (
            <CircularProgress />
          ) : (
            <TableContainer>
              <Table sx={{ minWidth: 750 }} size={"small"}>
                <EnhancedTableHead headCells={headCells} />
                <TableBody>
                  <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                    <StyledTableCell component="td" scope="row" sx={{ pl: 1 }}>
                      {row?.transactionNumber}
                    </StyledTableCell>
                    <StyledTableCell component="td" scope="row">
                      {row?.ticker}
                    </StyledTableCell>
                    <StyledTableCell component="td" scope="row">
                      {row?.description}
                    </StyledTableCell>{" "}
                    <StyledTableCell component="td" scope="row">
                      {row?.mkt}
                    </StyledTableCell>
                    <StyledTableCell component="td" scope="row">
                      {row?.quantity}
                    </StyledTableCell>
                    <StyledTableCell component="td" scope="row">
                      ${row?.price}
                    </StyledTableCell>
                    <StyledTableCell component="td" scope="row">
                      ${row?.total}
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Paper>
    </Box>
  );
}

const headCells = [
  {
    id: "transactionNumber",
    numeric: false,
    disablePadding: true,
    label: "Transaction#",
  },
  {
    id: "ticker",
    numeric: false,
    disablePadding: true,
    label: "Ticker",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Description",
  },
  {
    id: "mkt",
    numeric: false,
    disablePadding: true,
    label: "MKT",
  },

  {
    id: "quantity",
    numeric: true,
    disablePadding: true,
    label: "Quantity",
  },
  {
    id: "price",
    numeric: true,
    disablePadding: true,
    label: "Price",
  },
  {
    id: "total",
    numeric: true,
    disablePadding: true,
    label: "Total",
  },
];
