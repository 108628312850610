import { Box, Divider, Modal, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";

import { Close } from "@mui/icons-material";
import DetailsModal from "../CommonModals/TradeDetailsModal/TradeDetailsModal";
import OrderTable from "./OrderTable";
import { SubHeading } from "../Styled/Globals";

export default function ClosedTransactionsModal({ open, handleClose }) {
  const [selected, setSelected] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);

  const handleDetailsModal = (item) => {
    setOpenDetails(true);
    setSelected(item);
  };

  return (
    <React.Fragment>
      <DetailsModal
        data={selected}
        handleClose={(_) => setOpenDetails(false)}
        open={openDetails}
      />
      <Modal open={open} onClose={handleClose} className={"modal"}>
        <Box
          className={"no-scroll-bar"}
          sx={{
            maxWidth: "max-content",
          }}
        >
          <Paper>
            <Box className={"b-flex-end"} sx={{ p: 2 }}>
              <Close className="c-pointer" onClick={handleClose} />
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  p: 2,
                  pt: 0,
                }}
              >
                <SubHeading>
                  Closed Transactions for (XXXX Mock User)
                </SubHeading>
              </Box>
              <Divider />

              <Box p={2}>
                <OrderTable rows={orders} clickHandler={handleDetailsModal} />
              </Box>
            </Box>
          </Paper>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

const orders = [
  {
    orderTransactionNumber: 1234567890,
    status: "Pending",
    mkt: "Gold",
    ticker: "AAPL",
    bidAsk: "$800",
    description: "Selling my all assets",
    quantity: 200,
    price: "$1,000",
    total: "$1,000",
  },
  {
    orderTransactionNumber: 2244556677,
    status: "Pending",
    mkt: "Gold",
    ticker: "AAPL",
    bidAsk: "$800",
    description: "Selling my all assets",
    quantity: 200,
    price: "$1,000",
    total: "$1,000",
  },
  {
    orderTransactionNumber: 3873746251,
    status: "Pending",
    mkt: "Gold",
    ticker: "AAPL",
    bidAsk: "$800",
    description: "Selling my all assets",
    quantity: 200,
    price: "$1,000",
    total: "$1,000",
  },
  {
    orderTransactionNumber: 4433667755,
    status: "Pending",
    mkt: "Gold",
    ticker: "AAPL",
    bidAsk: "$800",
    description: "Selling my all assets",
    quantity: 200,
    price: "$1,000",
    total: "$1,000",
  },
  {
    orderTransactionNumber: 2938475610,
    status: "Pending",
    mkt: "Gold",
    ticker: "AAPL",
    bidAsk: "$800",
    description: "Selling my all assets",
    quantity: 200,
    price: "$1,000",
    total: "$1,000",
  },
];
