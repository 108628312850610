import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import CustomPagination from "../../../../Common/CustomPagination";
import { StyledButton } from "../../.././../Styled/Globals";
import TradeDetailsModal from "../../../../CommonModals/TradeDetailsModal/TradeDetailsModal";

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {props.headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            sx={{
              paddingX: 0,
            }}
          >
            <Typography
              fontWeight={"bold"}
              fontSize={14}
              color={"primary.dark"}
            >
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable({ rows, loading, variant }) {
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const headCells = [
    {
      id: "transactionNumber",
      numeric: false,
      disablePadding: true,
      label: `${variant} #`,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: true,
      label: "Status",
    },

    {
      id: "mkt",
      numeric: false,
      disablePadding: true,
      label: "MKT",
    },
    {
      id: "ticker",
      numeric: false,
      disablePadding: true,
      label: "Ticker",
    },
    {
      id: "bid-ask",
      numeric: false,
      disablePadding: true,
      label: "Bid/Ask",
    },
    {
      id: "description",
      numeric: false,
      disablePadding: true,
      label: "Description",
    },

    {
      id: "quantity",
      numeric: true,
      disablePadding: true,
      label: "Quantity",
    },
    {
      id: "price",
      numeric: true,
      disablePadding: true,
      label: "Price",
    },
    {
      id: "total",
      numeric: true,
      disablePadding: true,
      label: "Total",
    },
    {
      id: "details",
      numeric: false,
      disablePadding: true,
      label: "",
    },
  ];

  return (
    <Box mb={4} sx={{ width: "100%" }}>
      <Paper
        sx={{
          width: "100%",
          mb: 2,
          border: "1px solid #c4c4c4",
          borderRadius: 1,
        }}
        elevation={0}
      >
        <Box
          display="flex"
          justifyContent={"center"}
          alignContent={"center"}
          pt={3}
          px={3}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <TableContainer
              sx={{
                minHeight: "325px",
              }}
            >
              <Table sx={{ minWidth: 150, border: "none" }}>
                <EnhancedTableHead headCells={headCells} />
                <TableBody>
                  {tableData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          tabIndex={-1}
                          hover
                          key={row._id}
                          sx={{
                            border: "none",
                          }}
                        >
                          <TableCell
                            component="td"
                            scope="row"
                            sx={{
                              border: "none",
                              textAlign: "left",
                              fontSize: 14,
                            }}
                            padding={"none"}
                          >
                            {row?.id}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "none",
                              textAlign: "left",
                              py: 2,
                              px: 1,
                            }}
                            component="td"
                            padding={"none"}
                            scope="row"
                          >
                            {row?.status}
                          </TableCell>

                          <TableCell
                            sx={{
                              border: "none",
                              textAlign: "left",
                            }}
                            component="td"
                            padding={"none"}
                            scope="row"
                          >
                            {row?.mkt}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "none",
                              textAlign: "left",
                            }}
                            component="td"
                            padding={"none"}
                            scope="row"
                          >
                            {row?.ticker}
                          </TableCell>

                          <TableCell
                            sx={{
                              border: "none",
                              textAlign: "left",
                            }}
                            component="td"
                            padding={"none"}
                            scope="row"
                          >
                            {row?.bidAsk}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "none",
                              textAlign: "left",
                              minWidth: 100,
                            }}
                            component="td"
                            padding={"none"}
                            scope="row"
                          >
                            {row?.description}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "none",
                              textAlign: "left",
                            }}
                            component="td"
                            padding={"none"}
                            scope="row"
                          >
                            {row?.quantity}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "none",
                              textAlign: "left",
                            }}
                            component="td"
                            padding={"none"}
                            scope="row"
                          >
                            ${row?.price}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "none",
                              textAlign: "left",
                            }}
                            component="td"
                            padding={"none"}
                            scope="row"
                          >
                            ${row?.total}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "none",
                              textAlign: "left",
                            }}
                            component="td"
                            padding={"none"}
                            scope="row"
                          >
                            <StyledButton
                              sx={{
                                fontSize: 12,
                                fontWeight: "normal",
                                height: 30,
                              }}
                              onClick={() => setOpenDetailsModal(true)}
                            >
                              Details
                            </StyledButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
        <TradeDetailsModal
          open={openDetailsModal}
          handleClose={() => setOpenDetailsModal(false)}
        />
      </Paper>
      <CustomPagination
        rowsPerPage={rowsPerPage}
        rows={rows}
        setTableData={setTableData}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
}
