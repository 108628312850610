import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import React, { Fragment, useState } from "react";

import TimeButton from "./TimeButton";
import { useEffect } from "react";

const MarketControlTable = () => {
  const timeOptions = [
    "5 minutes",
    "10 minutes",
    "30 minutes",
    "45 minutes",
    "1 hour",
    "2 hours",
    "3 hours",
    "4 hours",
    "8 hours",
    "12 hours",
    "24 hours",
    "48 hours",
    "72 hours",
    "Until Restored",
  ];

  const restrictionOptions = [
    "Immediately",
    "15 minutes",
    "30 minutes",
    "45 minutes",
    "1 hour",
    "4 hours",
    "8 hours",
    "24 hours",
    "48 hours",
    "72 hours",
  ];
  const markets = [
    "all",
    "gold",
    "silver",
    "platinum",
    "palledium",
    "copper",
    "rhodium",
    "iridium",
    "osmium",
    "rhenium",
    "ruthenium",
    "berylium",
    "numismatic",
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  useEffect(() => {
    const obj = {};
    for (const market of markets) {
      obj[market] = {
        orders: "5 min",
        trades: "1 hour",
        delayMarket: "5 min",
        removeRestrictions: "1 hour",
      };
    }
    setSelectedTime(obj);
    //eslint-disable-next-line
  }, []);

  const onTimeSelect = (market, column, time) => {
    const obj = {
      ...selectedTime,
      [market]: {
        ...selectedTime[market],
        [column]:
          time.split(" ")[1]?.toLowerCase() === "minutes"
            ? time.split(" ")[0] + " min"
            : time,
      },
    };
    setSelectedTime({ ...obj });
  };

  return (
    <Paper
      sx={{
        px: 1.8,
        py: 1.5,
      }}
    >
      <Box>
        <TableHeader />
        <Grid
          container
          sx={(theme) => ({
            [theme.breakpoints.up("xl")]: {
              rowGap: 1,
            },
          })}
        >
          {markets.map((market, index) => {
            return (
              <Fragment key={index}>
                <Grid item md={1.5}>
                  <MarketLabel market={market} />
                </Grid>
                <Grid item md={1.9}>
                  {selectedTime && (
                    <StyledFormControlLabel
                      sx={{
                        width: "100%",
                      }}
                      control={<Checkbox />}
                      label={
                        <TimeButton
                          anchorEl={anchorEl}
                          setAnchorEl={setAnchorEl}
                          options={timeOptions}
                          id={market + "orders"}
                          selectedTime={selectedTime[market].orders}
                          column="orders"
                          onTimeSelect={(time) =>
                            onTimeSelect(market, "orders", time)
                          }
                        />
                      }
                    />
                  )}
                </Grid>
                <Grid item md={1.9}>
                  {/* <Box
                    display={"flex"}
                    justifyContent={"center"}
                    width={"100%"}
                  > */}
                  <Box
                    sx={{
                      display: "flex",
                      width: "80%",
                      height: "80%",
                      borderRadius: 5,
                      m: "auto",
                      bgcolor: "#c4c4c4",
                    }}
                  ></Box>
                  {/* </Box> */}
                </Grid>
                <Grid item md={1.9}>
                  {selectedTime && (
                    <StyledFormControlLabel
                      control={<Checkbox />}
                      label={
                        <TimeButton
                          anchorEl={anchorEl}
                          setAnchorEl={setAnchorEl}
                          options={timeOptions}
                          selectedTime={selectedTime[market].trades}
                          column="trades"
                          id={market + "trades"}
                          onTimeSelect={(time) =>
                            onTimeSelect(market, "trades", time)
                          }
                        />
                      }
                    />
                  )}
                </Grid>
                <Grid item md={1.9}>
                  {selectedTime && (
                    <StyledFormControlLabel
                      control={<Checkbox />}
                      label={
                        <Box display={"flex"} width={"100%"}>
                          <TimeButton
                            anchorEl={anchorEl}
                            setAnchorEl={setAnchorEl}
                            options={timeOptions}
                            selectedTime={selectedTime[market].delayMarket}
                            id={market + "delayMarket"}
                            column="delayMarket"
                            onTimeSelect={(time) =>
                              onTimeSelect(market, "delayMarket", time)
                            }
                          />
                        </Box>
                      }
                    />
                  )}
                </Grid>
                <Grid item md={1.9}>
                  {selectedTime && (
                    <StyledFormControlLabel
                      control={<Checkbox />}
                      label={
                        <TimeButton
                          anchorEl={anchorEl}
                          setAnchorEl={setAnchorEl}
                          options={restrictionOptions}
                          selectedTime={selectedTime[market].removeRestrictions}
                          column="removeRestrictions"
                          id={market + "removeRestrictions"}
                          onTimeSelect={(time) =>
                            onTimeSelect(market, "removeRestrictions", time)
                          }
                        />
                      }
                    />
                  )}
                </Grid>

                <Grid item md={1}>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    sx={(theme) => ({
                      textTransform: "capitalize",
                      height: "80%",
                      fontWeight: "normal",
                      fontSize: 10,
                      [theme.breakpoints.up("xl")]: {
                        fontSize: 18,
                      },
                    })}
                  >
                    Submit
                  </Button>
                </Grid>
              </Fragment>
            );
          })}
        </Grid>
      </Box>
    </Paper>
  );
};

export default MarketControlTable;

const TableHeading = ({ title }) => {
  return (
    <Box
      display={"flex"}
      height={"100%"}
      alignItems="flex-end"
      sx={(theme) => ({
        justifyContent: "center",
        [theme.breakpoints.up("xl")]: {
          pl: "18%",
          justifyContent: "flex-start",
        },
      })}
    >
      <Typography
        color="primary"
        sx={(theme) => ({
          fontWeight: "bold",
          textDecoration: "underline",
          [theme.breakpoints.up("xl")]: {
            fontSize: 21,
          },
        })}
      >
        {title}
      </Typography>
    </Box>
  );
};

const TableHeader = () => {
  return (
    <Grid container mb={0.5}>
      <Grid item md={1.5}></Grid>
      <Grid item md={1.9}>
        <TableHeading title={"Stop Orders"} />
      </Grid>
      <Grid item md={1.9}>
        <Box
          sx={(theme) => ({
            [theme.breakpoints.down("xl")]: {
              px: 3,
            },
            [theme.breakpoints.up("xl")]: {
              pr: 8,
            },
          })}
        >
          <Box
            display={"flex"}
            height={"100%"}
            alignItems="flex-end"
            sx={(theme) => ({
              justifyContent: "center",
              [theme.breakpoints.up("xl")]: {
                pl: "18%",
                justifyContent: "flex-start",
              },
            })}
          >
            <Typography
              color="primary"
              sx={(theme) => ({
                fontWeight: "bold",
                [theme.breakpoints.up("xl")]: {
                  fontSize: 21,
                },
              })}
            >
              Current <br />
              <span
                style={{
                  textDecoration: "underline",
                }}
              >
                Status
              </span>
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item md={1.9}>
        <TableHeading title={"Stop Trades"} />
      </Grid>
      <Grid item md={1.9}>
        <TableHeading title={"Delay Market"} />
      </Grid>
      <Grid item md={1.9}>
        <Box
          sx={(theme) => ({
            [theme.breakpoints.down("xl")]: {
              px: 4,
            },
            pr: 1,
          })}
        >
          <Box
            display={"flex"}
            height={"100%"}
            alignItems="flex-end"
            sx={(theme) => ({
              justifyContent: "center",
              [theme.breakpoints.up("xl")]: {
                pl: "18%",
                justifyContent: "flex-start",
              },
            })}
          >
            <Typography
              color="primary"
              sx={(theme) => ({
                fontWeight: "bold",
                [theme.breakpoints.up("xl")]: {
                  fontSize: 21,
                },
              })}
            >
              Remove <br />
              <span
                style={{
                  textDecoration: "underline",
                }}
              >
                Restrictions
              </span>
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid item md={1}></Grid>
    </Grid>
  );
};

const MarketLabel = ({ market }) => {
  return (
    <Box display={"flex"} alignItems={"center"} height={"100%"}>
      <Typography
        sx={(theme) => ({
          pl: 1.5,
          fontWeight: "bold",
          fontSize: 16,
          textTransform: "capitalize",
          [theme.breakpoints.up("xl")]: {
            fontSize: 18,
          },
        })}
      >
        {market}
      </Typography>
    </Box>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)({
  width: "98%",
});
