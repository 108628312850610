import React from "react";
import { Close } from "@mui/icons-material";
import { Box, Button, Modal, Paper } from "@mui/material";
import { Heading } from "../../Styled/Globals";

const ArticleTypeModal = ({ open, handleClose, title, handleSubmit }) => {
  // const style = {
  //   position: "absolute",
  //   top: "50%",
  //   border: "none",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   width: "65%",
  //   // height: "98%",
  //   overflowX: "hidden",
  //   display: "block",
  // };

  const types = [
    "Help",
    "History, Politics, Culture",
    "Types of Bullion & Numismatics",
  ];

  return (
    <Modal open={open} onClose={handleClose} className={"modal"}>
      <Box className={"no-scroll-bar"}>
        <Paper
          sx={{
            p: 3,
          }}
        >
          <Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              mb={3}
            >
              <Heading>Select Type of Article Type to {title}</Heading>
              <Close
                sx={{
                  cursor: "pointer",
                }}
                onClick={handleClose}
              />
            </Box>
            <Box
              display={"flex"}
              width={"100%"}
              alignItems={"center"}
              justifyContent={"space-between"}
              columnGap={4}
            >
              {types.map((type) => {
                return (
                  <Button
                    disableElevation
                    variant={"contained"}
                    sx={{
                      bgcolor: "primary.dark",
                      color: "#FFF",
                      height: 75,
                      width: 245,
                      fontSize: 18,
                      fontWeight: "bold",
                      px: 5,
                      textTransform: "none",
                    }}
                    onClick={() => {
                      handleSubmit(type);
                    }}
                  >
                    {type}
                  </Button>
                );
              })}
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default ArticleTypeModal;
