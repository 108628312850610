import { Box, Divider, Grid } from "@mui/material";
import React from "react";
import {
  DataHeading,
  DataTypography,
  SectionHeading,
} from "../Styled/TradeModal";
import moment from "moment";

function CounterPartySection() {
  return (
    <Box>
      <SectionHeading>Standard</SectionHeading>
      <Divider />
      <Grid container rowSpacing={2} pt={1}>
        <Grid item md={3}>
          <DataHeading>Order Type</DataHeading>
          <DataTypography>Gold</DataTypography>
        </Grid>
        <Grid item md={3}>
          <DataHeading>Timing</DataHeading>
          <DataTypography>
            {moment(Date.now()).format("hh:mma")}{" "}
          </DataTypography>
        </Grid>
        <Grid item md={3}></Grid>
        <Grid item md={3}>
          <DataHeading>Limit</DataHeading>
          <DataTypography>100</DataTypography>
        </Grid>
        <Grid item md={3}>
          <DataHeading>Stop</DataHeading>
          <DataTypography>$999.99</DataTypography>
        </Grid>
        <Grid item md={3}>
          <DataHeading>Reputation</DataHeading>
          <DataTypography>-</DataTypography>
        </Grid>
        <Grid item md={3}></Grid>

        <Grid item md={3}>
          <DataHeading># of Completed Transactions</DataHeading>
          <DataTypography>-</DataTypography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CounterPartySection;
