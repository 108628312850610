import { Box, Typography } from "@mui/material";
import { Heading, StyledContainer } from "../../components/Styled/Globals";
import React, { useState } from "react";

import { ReactComponent as GearsSVG } from "../../assets/gears.svg";
import Hexagon from "../../components/Hexagon/Hexagon";
import MarketSimulationsTable from "../../components/Tables/MarketSimulationsTable";
import MockUsersManagementTable from "../../components/AdminPanel/SimulationMangement/MockUsersManagementTable";
import SearchField from "../../components/Global/SearchField";
import SimulatedTradeForm from "../../components/MarketSimulations/SimulatedTradeForm/SimulatedTradeForm";
import SimulatedTradesTable from "../../components/MarketSimulations/SimulatedTradesTable";
import { sampleMockUsers } from "../../constants/SampleData/simulations";

const MarketSimulations = () => {
  const [formData, setFormData] = useState({});

  return (
    <Box height={"100%"}>
      <StyledContainer>
        <Hexagon
          width={390}
          title={
            <Box
              display="flex"
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={1}
            >
              <GearsSVG
                style={{
                  transform: "scale(0.9)",
                }}
              />
              <Typography color={"primary"} fontWeight={"bold"} fontSize={24}>
                Market Simulations
              </Typography>
            </Box>
          }
        />
        <Box my={1}>
          <Heading mb={1}>Markets</Heading>
          <MarketSimulationsTable rows={data} loading={false} />
        </Box>
        <Box>
          <Heading mt={2} mb={1}>
            Submit Simulated Trade
          </Heading>
          <SimulatedTradeForm data={formData} setData={setFormData} />
        </Box>

        {/* OPEN AND CLOSED SIMULATED TRADES */}
        <Box my={2}>
          <Box display={"flex"} justifyContent={"space-between"} my={4}>
            <Heading sx={{ width: "100%" }}>
              Open and Closed Simulated Trades
            </Heading>
            <SearchField title={"Search"} noMargin width={"65%"} />
          </Box>
          <SimulatedTradesTable rows={simulatedTradesData} />
        </Box>

        {/* MOCK USER LIST */}
        <Box my={2}>
          <Box display={"flex"} justifyContent={"space-between"} my={4}>
            <Heading sx={{ width: "100%" }}>Mock Users List</Heading>
            <SearchField title={"Search"} noMargin width={"65%"} />
          </Box>
          <MockUsersManagementTable rows={[...sampleMockUsers]} />
          {/* <MockUserTable rows={[...sampleMockUsers]} /> */}
          {/* <MockUserTable rows={Array.from(Array(10), () => mockUsersData)} /> */}
        </Box>
      </StyledContainer>
    </Box>
  );
};

const simulatedTradesData = [
  {
    orderTransactionNumber: Math.floor(Math.random() * 88658),
    status: "Pending",
    mkt: "Gold",
    ticker: "AAPL",
    bidAsk: "$800",
    description: "Selling my all assets",
    quantity: 200,
    price: "$1,000",
    total: "$1,000",
  },
  {
    orderTransactionNumber: Math.floor(Math.random() * 88658),
    status: "Pending",
    mkt: "Bronze",
    ticker: "AAPL",
    bidAsk: "$800",
    description: "Selling my all assets",
    quantity: 200,
    price: "$1,000",
    total: "$1,000",
  },
  {
    orderTransactionNumber: Math.floor(Math.random() * 88658),
    status: "Pending",
    mkt: "Copper",
    ticker: "AAPL",
    bidAsk: "$800",
    description: "Selling my all assets",
    quantity: 200,
    price: "$1,000",
    total: "$1,000",
  },
  {
    orderTransactionNumber: Math.floor(Math.random() * 88658),
    status: "Pending",
    mkt: "Platinum",
    ticker: "AAPL",
    bidAsk: "$800",
    description: "Selling my all assets",
    quantity: 200,
    price: "$1,000",
    total: "$1,000",
  },

  {
    orderTransactionNumber: Math.floor(Math.random() * 88658),
    status: "Pending",
    mkt: "Gold",
    ticker: "AAPL",
    bidAsk: "$800",
    description: "Selling my all assets",
    quantity: 200,
    price: "$1,000",
    total: "$1,000",
  },
  {
    orderTransactionNumber: Math.floor(Math.random() * 88658),
    status: "Pending",
    mkt: "Bronze",
    ticker: "AAPL",
    bidAsk: "$800",
    description: "Selling my all assets",
    quantity: 200,
    price: "$1,000",
    total: "$1,000",
  },
  {
    orderTransactionNumber: Math.floor(Math.random() * 88658),
    status: "Pending",
    mkt: "Copper",
    ticker: "AAPL",
    bidAsk: "$800",
    description: "Selling my all assets",
    quantity: 200,
    price: "$1,000",
    total: "$1,000",
  },
  {
    orderTransactionNumber: Math.floor(Math.random() * 88658),
    status: "Pending",
    mkt: "Platinum",
    ticker: "AAPL",
    bidAsk: "$800",
    description: "Selling my all assets",
    quantity: 200,
    price: "$1,000",
    total: "$1,000",
  },

  {
    orderTransactionNumber: Math.floor(Math.random() * 88658),
    status: "Pending",
    mkt: "Gold",
    ticker: "AAPL",
    bidAsk: "$800",
    description: "Selling my all assets",
    quantity: 200,
    price: "$1,000",
    total: "$1,000",
  },
  {
    orderTransactionNumber: Math.floor(Math.random() * 88658),
    status: "Pending",
    mkt: "Bronze",
    ticker: "AAPL",
    bidAsk: "$800",
    description: "Selling my all assets",
    quantity: 200,
    price: "$1,000",
    total: "$1,000",
  },
  {
    orderTransactionNumber: Math.floor(Math.random() * 88658),
    status: "Pending",
    mkt: "Copper",
    ticker: "AAPL",
    bidAsk: "$800",
    description: "Selling my all assets",
    quantity: 200,
    price: "$1,000",
    total: "$1,000",
  },
  {
    orderTransactionNumber: Math.floor(Math.random() * 88658),
    status: "Pending",
    mkt: "Platinum",
    ticker: "AAPL",
    bidAsk: "$800",
    description: "Selling my all assets",
    quantity: 200,
    price: "$1,000",
    total: "$1,000",
  },
];

const data = [
  {
    title: "$ in Market - Now",
    coinCurrency: "$1,000,000",
    gold: "$1,000,000",
    silver: "$1,000,000",
    platinum: "$1,000,000",
    palladium: "$1,000,000",
    copper: "$1,000,000",
    rhodium: "$1,000,000",
    iridium: "$1,000,000",
    osmium: "$1,000,000",
    rhenium: "$1,000,000",
    ruthenium: "$1,000,000",
    beryllium: "$1,000,000",
  },
  {
    title: "$ Traded - 24H",
    coinCurrency: "$1,000,000",
    gold: "$1,000,000",
    silver: "$1,000,000",
    platinum: "$1,000,000",
    palladium: "$1,000,000",
    copper: "$1,000,000",
    rhodium: "$1,000,000",
    iridium: "$1,000,000",
    osmium: "$1,000,000",
    rhenium: "$1,000,000",
    ruthenium: "$1,000,000",
    beryllium: "$1,000,000",
  },
  {
    title: "# Transactions - 24H",
    coinCurrency: "$1,000,000",
    gold: "$1,000,000",
    silver: "$1,000,000",
    platinum: "$1,000,000",
    palladium: "$1,000,000",
    copper: "$1,000,000",
    rhodium: "$1,000,000",
    iridium: "$1,000,000",
    osmium: "$1,000,000",
    rhenium: "$1,000,000",
    ruthenium: "$1,000,000",
    beryllium: "$1,000,000",
  },
  {
    title: "Volume - 24H",
    coinCurrency: "$1,000,000",
    gold: "$1,000,000",
    silver: "$1,000,000",
    platinum: "$1,000,000",
    palladium: "$1,000,000",
    copper: "$1,000,000",
    rhodium: "$1,000,000",
    iridium: "$1,000,000",
    osmium: "$1,000,000",
    rhenium: "$1,000,000",
    ruthenium: "$1,000,000",
    beryllium: "$1,000,000",
  },
];

export default MarketSimulations;
