import React, { useState } from "react";
import { Box, Divider, Modal, Paper } from "@mui/material";
import { Heading } from "../../Styled/Globals";
import SingleSettlementTable from "../../Tables/SingleSettlementTable";
import TrackingForm from "./TrackingForm";
import ReportForm from "./ReportForm";
import ConfirmForm from "./ConfirmModal";
import { Close } from "@mui/icons-material";

const DeliveryModal = ({ open, handleClose, title, variant }) => {
  const [formData, setFormData] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormData(null);
    handleClose();
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const data = {
    transactionNumber: 4567,
    mkt: "Gold",
    ticker: "AAPL",
    bidAsk: 800,
    description: "Selling all my assets",
    quantity: 200,
    price: 1000,
    total: 1000,
  };

  const renderForm = () => {
    switch (variant) {
      case "tracking":
        return (
          <TrackingForm
            formData={formData}
            setFormData={setFormData}
            handleSubmit={handleSubmit}
            onChange={onChange}
          />
        );

      case "report":
        return (
          <ReportForm
            formData={formData}
            setFormData={setFormData}
            handleSubmit={handleSubmit}
            onChange={onChange}
          />
        );

      case "confirm":
      case "accept":
        return (
          <ConfirmForm
            formData={formData}
            setFormData={setFormData}
            handleSubmit={handleSubmit}
            onChange={onChange}
          />
        );

      default:
        <Box></Box>;
        break;
    }
  };

  return (
    <Modal className={"modal"} open={open} onClose={handleClose}>
      <Box
        sx={{
          width: "75%",
        }}
        className={"no-scroll-bar"}
      >
        <Paper>
          <Box
            className={"close-box"}
            sx={{
              p: 2,
            }}
          >
            <Heading>{title}</Heading>
            <Close className={"c-pointer"} onClick={handleClose} />
          </Box>
          <Divider />
          <Box px={2} pt={2}>
            <Box border={"1px solid #c4c4c4"} borderRadius={1}>
              <SingleSettlementTable row={data} loading={false} />
            </Box>
            <Box>{renderForm()}</Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default DeliveryModal;
