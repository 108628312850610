import gold from "../assets/homepage/gold.png";
import coinsAndCurrency from "../assets/homepage/coins-and-currency.png";
import copper from "../assets/homepage/copper.png";
import palladium from "../assets/homepage/palladium.png";
import platinum from "../assets/homepage/platinum.png";
import silver from "../assets/homepage/silver.png";

export const additionalMarkets = [
  { title: "Rhodium", link: "/markets/rhodium" },
  { title: "Iridium", link: "/markets/iridium" },
  { title: "Osmium", link: "/markets/osmium" },
  { title: "Rhenium", link: "/markets/rhenium" },
  { title: "Ruthenium", link: "/markets/ruthenium" },
  { title: "Beryllium", link: "/markets/berrylium" },
];

export const metals = [
  {
    name: "Gold",
    img: gold,
    link: "/markets/gold",
  },
  {
    name: "Silver",
    img: silver,
    link: "/markets/silver",
  },
  {
    name: "Platinum",
    img: platinum,
    link: "/markets/platinum",
  },
  {
    name: "Palladium",
    img: palladium,
    link: "/markets/palladium",
  },
  {
    name: "Copper",
    img: copper,
    link: "/markets/copper",
  },
  {
    name: "Coins & Currency",
    img: coinsAndCurrency,
    link: "/markets/coinscurrency",
  },
];
