import {
  Box,
  CircularProgress,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";

import { ReactComponent as ArchiveSVG } from "../../assets/mark-as-read.svg";
import CustomPagination from "../Common/CustomPagination";
import { ReactComponent as MarkAsReadSVG } from "../../assets/mail-open.svg";
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import { ReactComponent as NotificationsSVG } from "../../assets/bell-icon.svg";
import PropTypes from "prop-types";
import { ReactComponent as UnArchiveSVG } from "../../assets/archive.svg";
import moment from "moment";
import { visuallyHidden } from "@mui/utils";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            sx={{
              padding: "5px 0px",
              paddingLeft: index === 0 && 2,
              width: headCell?.width || 100,
              verticalAlign: "inherit",
              textAlign: "left",
              background: headCell?.id === "action" && "#5f99ff",
              borderRadius: headCell?.id !== "action" && 10,
              borderTopRightRadius: 5,
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell?.disableSorting ? (
              <Box
                component={"div"}
                sx={(theme) => ({
                  width: "100%",
                  display: "flex",
                  color: theme?.palette?.primary?.main,
                  fontSize: "16px",
                  [theme.breakpoints.up("xl")]: {
                    fontSize: "medium",
                  },
                  ...headCell?.sx,
                })}
              >
                {headCell?.label}
              </Box>
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                sx={(theme) => ({
                  fontSize: "16px",
                  padding: "8px 0px",
                  display: "flex",
                  lineHeight: 1.5,
                  width: "100%",
                  justifyContent: "flex-start",
                  columnGap: 1,
                  color: theme?.palette?.primary?.main,
                  "& .MuiTableSortLabel-icon": {
                    margin: "0px",
                    fontSize: "small",
                  },
                  [theme.breakpoints.up("xl")]: {
                    fontSize: "medium",
                    "& .MuiTableSortLabel-icon": {
                      fontSize: "medium",
                    },
                  },
                  ...headCell?.sx,
                })}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({ rows, headCells, loading, dates }) {
  const [filteredData, setFilteredData] = useState([...rows]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tableData, setTableData] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [elIndex, setElIndex] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setElIndex(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  React.useState(() => setFilteredData([...rows]), [rows]);

  const filterData = React.useCallback(() => {
    let { startDate, endDate } = dates;
    startDate = moment(startDate, "MM/DD/YYYY");
    endDate = moment(endDate, "MM/DD/YYYY");

    const _data = [];
    rows?.forEach((row) => {
      const compareDate = moment(row?.date, "MM/DD/YYYY");
      if (compareDate?.isBetween(startDate, endDate)) {
        _data?.push(row);
      }
    });
    setFilteredData(_data);
    setTableData(_data);
  }, [dates, rows]);

  React.useEffect(() => {
    if (dates) filterData();
  }, [dates, filterData]);

  // const handleClick = (event, name) => {
  // 	const selectedIndex = selected.indexOf(name);
  // 	let newSelected = [];

  // 	if (selectedIndex === -1) {
  // 		newSelected = newSelected.concat(selected, name);
  // 	} else if (selectedIndex === 0) {
  // 		newSelected = newSelected.concat(selected.slice(1));
  // 	} else if (selectedIndex === selected.length - 1) {
  // 		newSelected = newSelected.concat(selected.slice(0, -1));
  // 	} else if (selectedIndex > 0) {
  // 		newSelected = newSelected.concat(
  // 			selected.slice(0, selectedIndex),
  // 			selected.slice(selectedIndex + 1)
  // 		);
  // 	}

  // 	setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const StyledTableCell = styled(TableCell)(({ theme, center }) => ({
    padding: "10px 0px",
    paddingRight: "5px",
    fontSize: "14px",
    textAlign: center ? "center" : "left",
    border: "none",
    [theme.breakpoints.up("xl")]: {
      fontSize: "16px",
    },
  }));

  return (
    <Box mb={4} sx={{ width: "100%" }}>
      <Box sx={{ width: "100%", bgcolor: "#fff", mb: 2, borderRadius: 4 }}>
        <Box display="flex" justifyContent={"center"} alignContent={"center"}>
          {loading ? (
            <CircularProgress />
          ) : (
            <TableContainer>
              <Table sx={{ width: "100%" }} size={"small"}>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  headCells={headCells}
                />
                <TableBody>
                  {filteredData.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={10}>
                        <Typography align="center">No Notifications</Typography>
                      </TableCell>
                    </TableRow>
                  ) : null}

                  {stableSort(tableData, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      // const objectKeys = Object.keys(row);
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row._id}
                          selected={isItemSelected}
                        >
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                            sx={{
                              paddingLeft: 2,
                            }}
                          >
                            TX{row?.notificationNumber}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {moment(row?.date).format("MM/DD/YYYY")}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.relatedTo}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.tradeNumber}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.subject}
                          </StyledTableCell>
                          <TableCell
                            component="td"
                            id={labelId}
                            scope="row"
                            sx={{
                              border: "none",
                            }}
                          >
                            <Box
                              display="flex"
                              justifyContent={"space-evenly"}
                              alignItems={"center"}
                            >
                              <NotificationsSVG className={"pointer"} />
                              <MoreVertIcon
                                className={"pointer"}
                                onClick={(e) => handleClick(e, index)}
                              />
                            </Box>
                            <Menu
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={open && elIndex === index}
                              onClose={handleClose}
                              sx={{
                                minWidth: 155,
                              }}
                            >
                              <MenuItem
                                onClick={handleClose}
                                sx={{
                                  width: 155,
                                }}
                              >
                                <Box
                                  display={"flex"}
                                  justifyContent={"left"}
                                  alignItems="center"
                                  width={"100%"}
                                >
                                  <ArchiveSVG style={{ marginRight: 10 }} />
                                  <Typography>Archive</Typography>
                                </Box>
                              </MenuItem>
                              <MenuItem
                                onClick={handleClose}
                                sx={{
                                  width: 155,
                                }}
                              >
                                <Box
                                  display={"flex"}
                                  justifyContent={"left"}
                                  alignItems="center"
                                  width={"100%"}
                                >
                                  <UnArchiveSVG style={{ marginRight: 10 }} />
                                  <Typography>Unarchive</Typography>
                                </Box>
                              </MenuItem>
                              <MenuItem onClick={handleClose}>
                                <Box
                                  display={"flex"}
                                  justifyContent={"left"}
                                  alignItems="center"
                                  width={"100%"}
                                >
                                  <MarkAsReadSVG style={{ marginRight: 10 }} />
                                  <Typography>Mark as Read</Typography>
                                </Box>
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Box>
      <CustomPagination
        rowsPerPage={rowsPerPage}
        rows={filteredData}
        setTableData={setTableData}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
}
