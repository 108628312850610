import { Box, Grid, Paper, TextField } from "@mui/material";
import { DataLabel, Heading, SubHeading } from "../../Styled/Globals";
import React, { useState } from "react";

import Chart from "react-apexcharts";
import DropdownButton from "../../Common/DropdownButton";
import FundsByMarketTable from "./FundsByMarketTable";
import WithdrawlRequestsTable from "./WithdrawlRequestsTable";

export default function FundsManagement({ bRef }) {
  return (
    <Box ref={bRef}>
      <Box
        sx={{
          mt: 3,
          mb: 2,
        }}
      >
        <Heading>Funds Management</Heading>
      </Box>
      <Paper
        sx={{
          p: 4,
        }}
      >
        <SubHeading>System Funds</SubHeading>
        <Grid container>
          <Grid item md={8}>
            <LeftGrid />
          </Grid>
          <Grid item md={4}>
            <RightGrid />
          </Grid>
        </Grid>

        <Box>
          <Box>
            <SubHeading>Funds By Market</SubHeading>
          </Box>
          <FundsByMarketTable />
        </Box>

        <Box>
          <Box>
            <SubHeading>Bullion MKTS Commissions</SubHeading>
          </Box>
          <div className="line">
            <Chart
              type="line"
              options={{
                chart: {
                  height: "100%",
                  type: "line",
                  zoom: {
                    enabled: true,
                  },
                },
                dataLabels: {
                  enabled: true,
                },
                stroke: {
                  curve: "straight",
                },
                title: {
                  align: "left",
                },
                grid: {
                  row: {
                    colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                    opacity: 0.1,
                  },
                },
                xaxis: {
                  categories: [
                    "09:00pm",
                    "10:00pm",
                    "11:00pm",
                    "12:00pm",
                    "01:00am",
                    "02:00am",
                    "03:00am",
                    "04:00am",
                    "05:00am",
                  ],
                  title: {
                    text: "Time",
                    style: {
                      fontSize: "large",
                      fontFamily: "Roboto",
                    },
                  },
                },
                yaxis: {
                  labels: {
                    formatter: function (value) {
                      return "$" + value;
                    },
                  },
                  title: {
                    text: "Funds",
                    style: {
                      fontSize: "large",
                      fontFamily: "Roboto",
                    },
                  },
                },
              }}
              series={[
                {
                  name: "Funds",
                  data: [0, 100, 410, 350, 510, 490, 640, 120, 263],
                },
              ]}
            />
          </div>
        </Box>
        <Box mt={2}>
          <Box>
            <SubHeading>Check Withdrawl Requests</SubHeading>
          </Box>
          <Box mt={2}>
            <WithdrawlRequestsTable rows={withdrawlRequestsData} />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

const LeftGrid = () => {
  const [timePeriod, setTimePeriod] = useState(null);
  const [data, setData] = useState(null);

  const handleChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });

  const handleTimePeriod = (time) => setTimePeriod(time);

  return (
    <Grid container alignItems={"center"} columnSpacing={2}>
      <Grid item md={4}>
        <DropdownButton
          customSX={{}}
          label={"Time Period"}
          id={"time-period-dropdown"}
          onSelect={handleTimePeriod}
          options={[
            "Last Hour",
            "Last 3 Hours",
            "Last 12 Hours",
            "Last 24 Hours",
            "Last 3 Days",
            "Last 7 Days",
            "Last Month",
            "YTD",
            "Last Year",
          ]}
          value={timePeriod}
        />
      </Grid>
      <Grid item md={8}>
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
          })}
        >
          <Box
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              columnGap: "25px",
              rowGap: "20px",
            })}
          >
            <Box>
              <DataLabel
                fontWeight={"bold"}
                sx={{ textDecoration: "underline" }}
              >
                Funds In
              </DataLabel>
              <TextField
                onChange={handleChange}
                value={data?.fundsIn}
                name={"fundsIn"}
              />
            </Box>
            <Box>
              <DataLabel
                fontWeight={"bold"}
                sx={{ textDecoration: "underline" }}
              >
                Funds Out
              </DataLabel>
              <TextField
                onChange={handleChange}
                value={data?.fundsOut}
                name={"fundsOut"}
              />
            </Box>
            <Box>
              <DataLabel
                fontWeight={"bold"}
                sx={{ textDecoration: "underline" }}
              >
                Ending Funds
              </DataLabel>
              <TextField
                onChange={handleChange}
                value={data?.endingFunds}
                name={"endingFunds"}
              />
            </Box>
          </Box>
          <Box>
            <DataLabel fontWeight={"bold"} sx={{ textDecoration: "underline" }}>
              Current Funds
            </DataLabel>
            <TextField
              onChange={handleChange}
              value={data?.currentFunds}
              name={"currentFunds"}
              fullWidth
            />
          </Box>
        </Box>
      </Grid>

      <Grid item md={12}>
        <div className="line">
          <Chart
            type="line"
            options={{
              chart: {
                height: "100%",
                type: "line",
                zoom: {
                  enabled: true,
                },
              },
              dataLabels: {
                enabled: true,
              },
              stroke: {
                curve: "straight",
              },
              title: {
                align: "left",
              },
              grid: {
                row: {
                  colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                  opacity: 0.1,
                },
              },
              xaxis: {
                categories: [
                  "09:00pm",
                  "10:00pm",
                  "11:00pm",
                  "12:00pm",
                  "01:00am",
                  "02:00am",
                  "03:00am",
                  "04:00am",
                  "05:00am",
                ],
                title: {
                  text: "Time",
                  style: {
                    fontSize: "large",
                    fontFamily: "Roboto",
                  },
                },
              },
              yaxis: {
                labels: {
                  formatter: function (value) {
                    return "$" + value;
                  },
                },
                title: {
                  text: "Funds",
                  style: {
                    fontSize: "large",
                    fontFamily: "Roboto",
                  },
                },
              },
            }}
            series={[
              {
                name: "Funds",
                data: [0, 50, 110, 450, 30, 590, 740, 220, 263],
              },
            ]}
          />
        </div>
      </Grid>
    </Grid>
  );
};

const RightGrid = () => {
  return (
    <Box>
      <div className="donut">
        <Chart
          series={[150, 100, 250, 330, 520, 140, 350, 630]}
          options={{
            series: [
              "12 Jan 2022",
              "15 Jan 2022",
              "29 Jan 2022",
              "25 Feb 2022",
              "15 Mar 2022",
              "19 Apr 2022",
              "07 May 2022",
            ],
            yaxis: {
              labels: {
                formatter: function (value) {
                  return "$" + value;
                },
              },
            },
            dataLabels: {
              enabled: false,
            },
            legend: {
              show: false,
            },
            labels: [
              "12 Jan 2022",
              "15 Jan 2022",
              "29 Jan 2022",
              "25 Feb 2022",
              "15 Mar 2022",
              "19 Apr 2022",
              "07 May 2022",
              "07 Jun 2022",
            ],
          }}
          type="donut"
        />
      </div>
      <div className="donut">
        <Chart
          series={[200, 500, 150, 530, 220, 540, 150, 330]}
          options={{
            series: [
              "12 Jan 2022",
              "15 Jan 2022",
              "29 Jan 2022",
              "25 Feb 2022",
              "15 Mar 2022",
              "19 Apr 2022",
              "07 May 2022",
            ],
            yaxis: {
              labels: {
                formatter: function (value) {
                  return "$" + value;
                },
              },
            },
            dataLabels: {
              enabled: false,
            },
            legend: {
              show: false,
            },
            labels: [
              "12 Jan 2022",
              "15 Jan 2022",
              "29 Jan 2022",
              "25 Feb 2022",
              "15 Mar 2022",
              "19 Apr 2022",
              "07 May 2022",
              "07 Jun 2022",
            ],
          }}
          type="donut"
        />
      </div>
    </Box>
  );
};

const withdrawlRequestsData = [
  {
    userName: "John_12",
    realName: "John",
    joinDate: Date.now(),
    transactions: 5000,
    lastTradeSettledDate: Date.now(),
    settledFunds: 500,
    amountRequested: 100,
    addressRequested: "Awaiting",
  },
  {
    userName: "John_12",
    realName: "John",
    joinDate: Date.now(),
    transactions: 5000,
    lastTradeSettledDate: Date.now(),
    settledFunds: 500,
    amountRequested: 100,
    addressRequested: "Awaiting",
  },
  {
    userName: "John_12",
    realName: "John",
    joinDate: Date.now(),
    transactions: 5000,
    lastTradeSettledDate: Date.now(),
    settledFunds: 500,
    amountRequested: 100,
    addressRequested: "Awaiting",
  },
  {
    userName: "John_12",
    realName: "John",
    joinDate: Date.now(),
    transactions: 5000,
    lastTradeSettledDate: Date.now(),
    settledFunds: 500,
    amountRequested: 100,
    addressRequested: "Awaiting",
  },
  {
    userName: "John_12",
    realName: "John",
    joinDate: Date.now(),
    transactions: 5000,
    lastTradeSettledDate: Date.now(),
    settledFunds: 500,
    amountRequested: 100,
    addressRequested: "Awaiting",
  },
  {
    userName: "John_12",
    realName: "John",
    joinDate: Date.now(),
    transactions: 5000,
    lastTradeSettledDate: Date.now(),
    settledFunds: 500,
    amountRequested: 100,
    addressRequested: "Awaiting",
  },
  {
    userName: "John_12",
    realName: "John",
    joinDate: Date.now(),
    transactions: 5000,
    lastTradeSettledDate: Date.now(),
    settledFunds: 500,
    amountRequested: 100,
    addressRequested: "Awaiting",
  },
  {
    userName: "John_12",
    realName: "John",
    joinDate: Date.now(),
    transactions: 5000,
    lastTradeSettledDate: Date.now(),
    settledFunds: 500,
    amountRequested: 100,
    addressRequested: "Awaiting",
  },
  {
    userName: "John_12",
    realName: "John",
    joinDate: Date.now(),
    transactions: 5000,
    lastTradeSettledDate: Date.now(),
    settledFunds: 500,
    amountRequested: 100,
    addressRequested: "Awaiting",
  },
  {
    userName: "John_12",
    realName: "John",
    joinDate: Date.now(),
    transactions: 5000,
    lastTradeSettledDate: Date.now(),
    settledFunds: 500,
    amountRequested: 100,
    addressRequested: "Awaiting",
  },
  {
    userName: "John_12",
    realName: "John",
    joinDate: Date.now(),
    transactions: 5000,
    lastTradeSettledDate: Date.now(),
    settledFunds: 500,
    amountRequested: 100,
    addressRequested: "Awaiting",
  },
  {
    userName: "John_12",
    realName: "John",
    joinDate: Date.now(),
    transactions: 5000,
    lastTradeSettledDate: Date.now(),
    settledFunds: 500,
    amountRequested: 100,
    addressRequested: "Awaiting",
  },
  {
    userName: "John_12",
    realName: "John",
    joinDate: Date.now(),
    transactions: 5000,
    lastTradeSettledDate: Date.now(),
    settledFunds: 500,
    amountRequested: 100,
    addressRequested: "Awaiting",
  },
];
