import { Box, Paper, Typography } from "@mui/material";
import React, { useRef } from "react";

import Hexagon from "../../components/Hexagon/Hexagon";
import { StyledContainer } from "../../components/Styled/Globals";
import Summary from "../../components/TickerMarket/Summary";
import TickerDetails from "../../components/TickerMarket/TickerDetails";
import { ReactComponent as TickerSVG } from "../../assets/ticker-symbol.svg";
import TradeTables from "../../components/TickerMarket/TradeTables";
import { sampleTransactions } from "../../constants/SampleData/transactions";

function TickerMarket() {
  const detailsRef = useRef(null);

  const handleTickerDetails = () => detailsRef.current?.scrollIntoView();

  return (
    <Box height={"100%"} mb={2}>
      <StyledContainer>
        <Hexagon
          title={
            <Box
              display="flex"
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={1}
            >
              <TickerSVG style={{ transform: "scale(0.7)" }} />
              <Typography color={"primary"} fontWeight={"bold"} fontSize={22}>
                (TICKER) Market
              </Typography>
            </Box>
          }
        />
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          columnGap={5}
          mt={1}
        >
          <Box>
            <Paper
              sx={{
                borderRadius: "5px",
                width: 250,
                height: 100,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography>Photo</Typography>
            </Paper>
          </Box>
          <Box
            width={250}
            justifyContent={"center"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <Typography
              fontSize={20}
              sx={{
                lineHeight: "100%",
              }}
            >
              {" "}
              Short Description of Market Goes Here and is Very Condensed{" "}
              <Typography
                component={"span"}
                sx={{
                  color: "#2232C2",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={handleTickerDetails}
              >
                See TICKER Details
              </Typography>
            </Typography>
          </Box>
        </Box>
        <Summary marketData={marketData} />
        <TradeTables rows={[...sampleTransactions]} title={"Open Orders"} />
        <TradeTables
          rows={[...sampleTransactions]}
          title={"Completed Trades"}
        />
        <TickerDetails bRef={detailsRef} />
      </StyledContainer>
    </Box>
  );
}

const marketData = [
  {
    title: "Market Price",
    value: 2000,
  },
  {
    title: "52 Week Range",
    value: null,
  },
  {
    title: "Market Size",
    value: null,
  },
  {
    title: "Bid",
    value: 1950,
  },
  {
    title: "52 Week Average",
    value: null,
  },
  {
    title: "24 Hour Volume",
    value: null,
  },
  {
    title: "Ask",
    value: 1980,
  },
  {
    title: "52 Week High",
    value: null,
  },
  {
    title: "Avg. Volume",
    value: null,
  },
  {
    title: "24 Hour Range",
    value: null,
  },
  {
    title: "52 Week Low",
    value: null,
  },
  {
    title: "Beta",
    value: null,
  },
];
export default TickerMarket;
