import { Box, Button, Modal, Paper, Typography } from "@mui/material";

import { Close } from "@mui/icons-material";
import { Heading } from "../Styled/Globals";
import React from "react";

export default function CancelTradeModal({ open, handleClose }) {
  return (
    <Modal className={"modal"} open={open} onClose={handleClose}>
      <Box
        className={"no-scroll-bar"}
        sx={{
          maxWidth: "35%",
        }}
      >
        <Paper
          sx={{
            p: 2,
          }}
        >
          <Box className={"b-flex-end"}>
            <Close className={"c-pointer"} onClick={handleClose} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: 2,
            }}
          >
            <Heading>Confirm Cancel of Simulated Trade</Heading>
            <Typography>
              Please confirm the cancellation of this simulated trade from the
              platform.
            </Typography>
            <Button
              disableElevation
              color={"primary"}
              variant={"contained"}
              onClick={handleClose}
              sx={{
                textTransform: "uppercase",
              }}
            >
              Confirm
            </Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
}
