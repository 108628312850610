import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  CircularProgress,
} from "@mui/material";

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            sx={
              headCell.id === "actions"
                ? {
                    backgroundColor: "#5f99ff",
                    justifyContent: "center",
                    borderTopRightRadius: 10,
                    paddingX: 1,
                    paddingY: 2,
                  }
                : {
                    paddingX: 0,
                    paddingY: 2,
                  }
            }
          >
            <Typography
              fontWeight={"bold"}
              fontSize={18}
              color={headCell.id === "actions" ? "#fff" : "primary"}
            >
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable({ rows, loading }) {
  const dense = false;

  return (
    <Box mb={4} sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }} elevation={0}>
        <Box
          px={2}
          display="flex"
          justifyContent={"center"}
          alignContent={"center"}
          py={3}
          // minHeight={350}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <TableContainer>
              <Table sx={{ minWidth: 750 }} size={dense ? "small" : "medium"}>
                <EnhancedTableHead headCells={headCells} />
                <TableBody>
                  {rows.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row._id}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          padding={"normal"}
                        >
                          {row?.transactionNumber}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          padding={"normal"}
                        >
                          {row?.ticker}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          padding={"normal"}
                        >
                          {row?.description}
                        </TableCell>{" "}
                        <TableCell
                          component="th"
                          scope="row"
                          padding={"normal"}
                        >
                          {row?.mkt}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          padding={"normal"}
                        >
                          {row?.quantity}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          padding={"normal"}
                        >
                          ${row?.price}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          padding={"normal"}
                        >
                          ${row?.total}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Paper>
    </Box>
  );
}

const headCells = [
  {
    id: "transactionNumber",
    numeric: false,
    disablePadding: true,
    label: "Transaction#",
  },
  {
    id: "ticker",
    numeric: false,
    disablePadding: true,
    label: "Ticker",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Description",
  },
  {
    id: "mkt",
    numeric: false,
    disablePadding: true,
    label: "MKT",
  },

  {
    id: "quantity",
    numeric: true,
    disablePadding: true,
    label: "Quantity",
  },
  {
    id: "price",
    numeric: true,
    disablePadding: true,
    label: "Price",
  },
  {
    id: "total",
    numeric: true,
    disablePadding: true,
    label: "Total",
  },
];
