import MarketComponent from "../../components/Markets/MarketComponent";
import React from "react";
import silverImg from "../../assets/metals/small/silver.png";

function SilverMarket() {
  const _bidData = [
    {
      orderNumber: 1010,
      mkt: "Silver",
      ticker: "SLV",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Silver",
      ticker: "SLV",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Silver",
      ticker: "SLV",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Silver",
      ticker: "SLV",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Silver",
      ticker: "SLV",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Silver",
      ticker: "SLV",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Silver",
      ticker: "SLV",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Silver",
      ticker: "SLV",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Silver",
      ticker: "SLV",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Silver",
      ticker: "SLV",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Silver",
      ticker: "SLV",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Silver",
      ticker: "SLV",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Silver",
      ticker: "SLV",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Silver",
      ticker: "SLV",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
  ];

  const _tradeData = [
    {
      orderNumber: 1010,
      mkt: "Silver",
      ticker: "SLV",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Silver",
      ticker: "SLV",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Silver",
      ticker: "SLV",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Silver",
      ticker: "SLV",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Silver",
      ticker: "SLV",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Silver",
      ticker: "SLV",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Silver",
      ticker: "SLV",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Silver",
      ticker: "SLV",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Silver",
      ticker: "SLV",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Silver",
      ticker: "SLV",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Silver",
      ticker: "SLV",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Silver",
      ticker: "SLV",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Silver",
      ticker: "SLV",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Silver",
      ticker: "SLV",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
  ];

  return (
    <React.Fragment>
      <MarketComponent
        title={"Silver"}
        img={silverImg}
        bidData={_bidData}
        tradeData={_tradeData}
      />
    </React.Fragment>
  );
}

export default SilverMarket;
