import { Box } from "@mui/material";
import { Heading } from "../../Styled/Globals";
import MockUserTable from "./MockUsersManagementTable";
import React from "react";
import SearchField from "../../Global/SearchField";
import { sampleMockUsers } from "../../../constants/SampleData/simulations";

function MockUsers() {
  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={3}
      >
        <Heading
          sx={{
            width: "100%",
            fontSize: 18,
          }}
        >
          Mock Users List{" "}
        </Heading>
        <SearchField title={"Search"} noMargin width="100%" />
      </Box>
      <Box>
        <MockUserTable rows={[...sampleMockUsers]} loading={false} />
      </Box>
    </Box>
  );
}

export default MockUsers;
