import {
  Box,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  styled,
  Button,
} from "@mui/material";
import React from "react";
import {
  DataBox,
  DataLabel as _DataLabel,
  StyledTextArea,
} from "../../Styled/Globals";
import { ReactComponent as CloudSVG } from "../../../assets/cloud.svg";

function ClaimForm({ formData, setFormData, onChange, handleSubmit }) {
  const DataLabel = styled(_DataLabel)(({ theme }) => ({
    fontWeight: "bold",
  }));
  const uploadRef = React.useRef(null);

  return (
    <Box component={"form"} onSubmit={(e) => handleSubmit(e)}>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <DataBox>
            <DataLabel>Select Cause for Arbitration</DataLabel>
            <TextField
              onChange={onChange}
              name={"filerCause"}
              value={formData?.filerCause}
              fullWidth
              type={"text"}
            />
          </DataBox>
        </Grid>
        <Grid item md={8}>
          <DataBox>
            <DataLabel>Your Desired Outcome</DataLabel>
            <TextField
              onChange={onChange}
              name={"filerDesiredOutcome"}
              value={formData?.filerDesiredOutcome}
              fullWidth
              type={"text"}
            />
          </DataBox>
        </Grid>
        <Grid item md={4}>
          <DataBox>
            <DataLabel>Upload Pictures, Documents, or Images</DataLabel>
            <Box
              display={"flex"}
              justifyContent={"start"}
              alignItems={"center"}
              width={"100%"}
              height={"100%"}
              border={"1px solid #c4c4c4"}
              borderRadius={1}
              px={2}
              py={1.5}
              className={"pointer"}
              onClick={(e) => uploadRef?.current?.click()}
            >
              <label htmlFor="upload-photo">
                <input
                  style={{ display: "none" }}
                  id="upload-photo"
                  name="upload-photo"
                  type="file"
                  ref={uploadRef}
                />
                <Box
                  display={"flex"}
                  width={"100%"}
                  height={"100%"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  columnGap={1.5}
                >
                  <CloudSVG style={{ transform: "scale(0.9)" }} />
                  <Typography
                    sx={(theme) => ({
                      color: theme?.palette.primary.main,
                      textDecoration: "underline",
                      fontWeight: "bold",
                      fontSize: "medium !important",
                    })}
                  >
                    Upload Now
                  </Typography>
                </Box>
              </label>
            </Box>
          </DataBox>
        </Grid>
        <Grid item md={12}>
          <StyledTextArea
            minRows={5}
            placeholder={
              "Please type your description of what happened here. Please be 100% truthful and avoid name calling, exaggeration, or hyperbole. Think of this like a court room where no judge wants to hear how much you hate what happened or the other party, but only the facts of the case. "
            }
            style={{
              padding: 15,
              width: "100%",
            }}
          />
        </Grid>
        <Grid item md={12}>
          {/* Checkboxes */}
          <Box display={"flex"} flexDirection={"column"}>
            <FormControlLabel
              control={<Checkbox />}
              label={
                "My statements are the truth to the best of my knowledge and the provided documents and images are legitimate."
              }
            />
            <FormControlLabel
              sx={{ mt: -1 }}
              control={<Checkbox />}
              label="I understand that if I am found to be habitually violating Bullion MKTS Policies that I will be suspended or banned from the platform."
            />
            <FormControlLabel
              control={
                <Checkbox
                  sx={(theme) => ({
                    mt: -6,
                  })}
                />
              }
              label={
                "I understand that for a quick resolution that I must answer all questions regarding Arbitration within 24 hours or I will lose an Arbitration by default. I understand and agree that the opposing party of the trade will be provided a copy of my statement, all documents I provide, and my desired outcome in the interest of transparency. I appreciate that the same will be provided to me from the opposing party."
              }
            />
            <FormControlLabel
              control={<Checkbox />}
              label="I agree to abide legally by the decision of the Arbitration as I agreed when I became a User of Bullion MKTS."
            />
          </Box>
        </Grid>
        <Grid item md={12}>
          <Box
            width={"100%"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            display={"flex"}
            pb={2}
          >
            <Button
              variant={"contained"}
              color={"primary"}
              type={"submit"}
              sx={{
                px: 4,
                py: 1,
              }}
            >
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ClaimForm;
