import MarketComponent from "../../components/Markets/MarketComponent";
import React from "react";
import rutheniumImg from "../../assets/metals/small/ruthenium.png";

function RutheniumMarket() {
  const _bidData = [
    {
      orderNumber: 1010,
      mkt: "Ruthenium",
      ticker: "RTM",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Ruthenium",
      ticker: "RTM",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Ruthenium",
      ticker: "RTM",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Ruthenium",
      ticker: "RTM",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Ruthenium",
      ticker: "RTM",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Ruthenium",
      ticker: "RTM",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Ruthenium",
      ticker: "RTM",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Ruthenium",
      ticker: "RTM",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Ruthenium",
      ticker: "RTM",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Ruthenium",
      ticker: "RTM",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Ruthenium",
      ticker: "RTM",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Ruthenium",
      ticker: "RTM",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Ruthenium",
      ticker: "RTM",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Ruthenium",
      ticker: "RTM",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
  ];

  const _tradeData = [
    {
      orderNumber: 1010,
      mkt: "Ruthenium",
      ticker: "RTM",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Ruthenium",
      ticker: "RTM",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Ruthenium",
      ticker: "RTM",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Ruthenium",
      ticker: "RTM",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Ruthenium",
      ticker: "RTM",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Ruthenium",
      ticker: "RTM",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Ruthenium",
      ticker: "RTM",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Ruthenium",
      ticker: "RTM",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Ruthenium",
      ticker: "RTM",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Ruthenium",
      ticker: "RTM",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Ruthenium",
      ticker: "RTM",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Ruthenium",
      ticker: "RTM",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Ruthenium",
      ticker: "RTM",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Ruthenium",
      ticker: "RTM",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
  ];

  return (
    <React.Fragment>
      <MarketComponent
        title={"Ruthenium"}
        img={rutheniumImg}
        bidData={_bidData}
        tradeData={_tradeData}
      />
    </React.Fragment>
  );
}

export default RutheniumMarket;
