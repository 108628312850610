import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { StyledButton, StyledContainer } from "../../components/Styled/Globals";

import FilterListIcon from "@mui/icons-material/FilterList";
import FilterModal from "./FilterModal";
import Hexagon from "../../components/Hexagon/Hexagon";
import HomeMarqee from "../HomePage/HomeMarqee";
import { Link } from "react-router-dom";
import MarketTradeModal from "../CommonModals/MarketTradeModal";
import MarketsTable from "../../components/Markets/MarketsTable";
import { ReactComponent as OpenOrdersSVG } from "../../assets/open-orders.svg";
import SearchField from "../Global/SearchField";
import { useNavigate } from "react-router";
import { useTheme } from "@mui/material/styles";

// import TradeForm from "./TradeForm";

function MarketComponent({ title, img, bidData, tradeData }) {
  const navigate = useNavigate();

  const _headcells = [
    {
      id: "orderNumber",
      numeric: true,
      disablePadding: true,
      label: "Order#",
    },
    {
      id: "mkt",
      numeric: false,
      disablePadding: true,
      label: "MKT",
    },
    {
      id: "ticker",
      numeric: false,
      disablePadding: true,
      label: "Ticker",
    },
    {
      id: "bid-ask",
      numeric: false,
      disablePadding: true,
      label: "Bid/Ask",
    },
    {
      id: "quantity",
      numeric: true,
      disablePadding: true,
      label: "Quantity",
    },
    {
      id: "price",
      numeric: true,
      disablePadding: true,
      label: "Price",
    },
    {
      id: "details",
      numeric: false,
      disablePadding: true,
      label: "Details",
    },
  ];

  // const [data, setData] = useState({
  //   mkt: title.toLowerCase(),
  // });
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [marketTradeModal, setMarketTradeModal] = useState(false);
  const theme = useTheme();
  const isXL = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <Box height={"100%"}>
      <FilterModal
        open={openFilterModal}
        handleClose={() => setOpenFilterModal(false)}
      />
      <HomeMarqee />

      <StyledContainer>
        <Hexagon
          width={400}
          title={
            <Box
              display="flex"
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box component={"div"} marginRight={2}>
                <img src={img} alt={title} style={{ maxWidth: "60px" }} />
              </Box>
              <Typography color={"primary"} fontWeight={"bold"} fontSize={26}>
                {title} Market
              </Typography>
            </Box>
          }
        />

        <Box
          display={"flex"}
          width={"100%"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          columnGap={10}
          marginTop={3}
        >
          <SearchField
            noMargin
            width={"33.33%"}
            height={"100%"}
            title={"Search Description/Ticker"}
            style={{ width: "100" }}
          />

          <Box display={"flex"} width={"33.33%"} flexDirection={"column"}>
            <Paper
              sx={{
                px: 5,
                // py: 1,
                height: isXL ? 75 : 63,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                fontSize={20}
                fontWeight={"bold"}
                sx={{ textDecoration: "underline" }}
                textAlign={"end"}
              >
                Your Cleared Funds
              </Typography>
              <Typography color={"primary"} textAlign={"end"}>
                $000,000,000.00
              </Typography>
            </Paper>
            <br />
            <Button
              startIcon={null}
              variant={"contained"}
              color={"primary"}
              sx={{
                height: isXL ? 75 : 63,
                width: "100%",
                fontSize: isXL ? 20 : 16,
              }}
              onClick={() => setMarketTradeModal(true)}
            >
              Make A Trade
            </Button>
          </Box>
          {/* <Link
            to="/openorders"
            sx={{
              width: "33.33%",
            }}
          > */}
          <Button
            variant="contained"
            sx={{
              width: "33.33%",

              backgroundColor: "#fff",
              height: isXL ? 75 : 63,
              fontSize: isXL ? 20 : 16,
              fontWeight: "bold",
              color: "#000",
              ":hover": {
                backgroundColor: "#fff",
              },
            }}
            startIcon={<OpenOrdersSVG style={{ transform: "scale(0.8)" }} />}
            onClick={(_) => navigate("/account/openorders")}
          >
            OPEN ORDERS
          </Button>
          {/* </Link> */}
        </Box>

        <MarketTradeModal
          market={title.toLowerCase}
          open={marketTradeModal}
          handleClose={() => setMarketTradeModal(false)}
        />

        {/* TODO: add start icon */}
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        ></Box>

        {/* <TradeForm data={data} setData={setData} /> */}
        <Typography
          component={"p"}
          variant={"redText"}
          textAlign={"center"}
          my={3}
        >
          Click on any Active Bid or Ask to make a trade.
        </Typography>
        <Box>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Box display={"flex"} justifyContent={"center"}>
                <Box
                  component={"div"}
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"flex-end"}
                >
                  <Typography
                    textAlign={"center"}
                    fontSize={28}
                    color={"text.dark"}
                    fontWeight={"bold"}
                    mb={3}
                  >
                    Active Bids &#38; Asks
                  </Typography>
                </Box>
                <Box
                  component={"div"}
                  width={"50%"}
                  display={"flex"}
                  justifyContent={"flex-end"}
                >
                  <StyledButton
                    onClick={() => setOpenFilterModal(true)}
                    sx={{ width: 120 }}
                  >
                    <Box
                      display={"flex"}
                      fontSize={20}
                      width={"100%"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <FilterListIcon style={{ marginRight: 5 }} /> Filter
                    </Box>
                  </StyledButton>
                </Box>
              </Box>
              <MarketsTable
                rows={bidData}
                headCells={_headcells}
                tableTitle={null}
                loading={false}
              />
            </Grid>
            <Grid item md={6}>
              <Box display={"flex"} justifyContent={"center"}>
                <Box
                  component={"div"}
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"flex-end"}
                >
                  <Typography
                    textAlign={"center"}
                    fontSize={28}
                    color={"text.dark"}
                    fontWeight={"bold"}
                    mb={3}
                  >
                    Completed Trades
                  </Typography>
                </Box>
                <Box
                  component={"div"}
                  width={"50%"}
                  display={"flex"}
                  justifyContent={"flex-end"}
                >
                  <StyledButton
                    onClick={() => setOpenFilterModal(true)}
                    sx={{ width: 120 }}
                  >
                    <Box
                      display={"flex"}
                      fontSize={20}
                      width={"100%"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <FilterListIcon style={{ marginRight: 5 }} /> Filter
                    </Box>
                  </StyledButton>
                </Box>
              </Box>
              <MarketsTable
                rows={tradeData}
                headCells={_headcells}
                tableTitle={null}
                loading={false}
              />
            </Grid>
          </Grid>
        </Box>
      </StyledContainer>
    </Box>
  );
}

export default MarketComponent;
