import { Box, Grid, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import React, { Fragment, useState } from "react";
import { StyledButton, StyledTextArea } from "../../Styled/Globals";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import AttachmentFile from "../../Styled/AttachmentFile";
import TemplateListModal from "./TemplateListModal";

function MessagingForm({ variant, data, setData, onChange }) {
  const [openLoadTemplate, setOpenLoadTemplate] = useState(false);

  const handleLoadTemplate = (template) => {
    setData({ ...template });
    setOpenLoadTemplate(false);
  };

  //eslint-disable-next-line
  const [files, setFiles] = useState([
    { name: "type.pdf", type: "pdf" },
    { name: "typenew.pdf", type: "pdf" },
    { name: "typenew_1.pdf", type: "pdf" },
  ]);

  return (
    <Fragment>
      {/* Load Template Modal */}
      <TemplateListModal
        title={"Load"}
        open={openLoadTemplate}
        clickHandler={handleLoadTemplate}
        handleClose={() => setOpenLoadTemplate(false)}
      />
      <Grid container rowGap={2}>
        {variant === "normal" && (
          <>
            {" "}
            <Grid md={3}>
              <TextField
                size={"small"}
                name={"to"}
                value={data?.to}
                label={"To"}
                type={"text"}
                fullWidth
                onChange={onChange}
              />
            </Grid>
            <Grid md={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label={"Time to be Sent"}
                  inputFormat="MM/dd/yyyy"
                  onChange={(e) => {}}
                  size={"small"}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size={"small"}
                      className="calender-icon"
                      fullWidth
                      sx={(theme) => ({
                        ml: 2,
                      })}
                      label={"Time to be Sent"}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid md={6}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-end"}
                width={"100%"}
              >
                <StyledButton
                  sx={(theme) => ({
                    height: "100%",
                    fontWeight: "normal",
                    fontSize: 14,
                    width: "50%",
                  })}
                  onClick={(_) => setOpenLoadTemplate(true)}
                >
                  Load Template
                </StyledButton>
              </Box>
            </Grid>{" "}
          </>
        )}
        {variant === "template" && (
          <>
            <Grid item md={5}>
              <TextField
                size={"small"}
                name={"to"}
                value={data?.to}
                label={"To"}
                type={"text"}
                sx={{
                  width: "75%",
                }}
                onChange={onChange}
              />
            </Grid>
            <Grid item md={0.2}></Grid>
            <Grid md={6.8} item display={"flex"} justifyContent={"flex-end"}>
              <TextField
                size={"small"}
                name={"templateName"}
                value={data?.templateName}
                label={"Template Name"}
                type={"text"}
                sx={{
                  width: "75%",
                }}
                onChange={onChange}
              />
            </Grid>
          </>
        )}
        <Grid md={12}>
          <TextField
            size={"small"}
            name={"subject"}
            sx={{
              width: variant === "template" ? "100%" : "70%",
            }}
            placeholder={"Subject Line Goes Here"}
            type={"text"}
            onChange={onChange}
            value={data?.subject}
          />
        </Grid>

        <Grid item md={12}>
          <StyledTextArea
            label={"Body of text"}
            placeholder={"Body of text"}
            name={"body"}
            value={data?.body}
            onChange={onChange}
            style={{
              width: "100%",
              height: 150,
              padding: 10,
              color: "#000",
            }}
          />
        </Grid>
        <Grid md={12}>
          <Box
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              gap: 5,
            })}
          >
            {files.map((file, index) => (
              <AttachmentFile key={index} name={file.name} type={file.type} />
            ))}
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default MessagingForm;
