import {
  Box,
  Button,
  Divider,
  Modal,
  Paper,
  TextField,
  styled,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import { StyledTextArea, SubHeading } from "../../../Styled/Globals";

import AttachmentFile from "../../../Styled/AttachmentFile";
import { Close } from "@mui/icons-material";
import SingleArbitrationTable from "./SingleArbitrationTable";

const RequestResponseModal = ({ open, handleClose, variant }) => {
  // eslint-disable-next-line
  const [data, setData] = useState({
    desiredOutcome: "Dummy desired outcome",
    cause: "Dummy cause outcome",
    statement: "This is just a dummy statement...",
  });
  // const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

  const HeaderButton = styled(Button)(({ theme }) => ({
    fontSize: "14px !important",
    fontWeight: "normal",
    [theme.breakpoints.up("xl")]: {
      fontSize: "16px !important",
    },
  }));

  const LabelHeading = styled(SubHeading)(({ theme }) => ({
    fontSize: 12,
    color: "#5f99ff",
    [theme.breakpoints.up("xl")]: {
      fontSize: "16px !important",
    },
  }));

  return (
    <Modal className={"modal"} open={open} onClose={handleClose}>
      <Box
        className={"no-scroll-bar"}
        sx={(theme) => ({
          width: "80%",
          [theme.breakpoints.up("xl")]: {
            width: "90%",
          },
        })}
      >
        <Paper>
          <Box px={4} py={1.5} className={"close-box"}>
            <SubHeading>
              {variant === "filer"
                ? "Filer's Arbitration Complaint and Requests"
                : "Response from Other Party"}
            </SubHeading>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent="flex-end"
              columnGap={2}
            >
              <Fragment>
                <HeaderButton
                  disableElevation
                  variant={"contained"}
                  color={"primary"}
                  size={"medium"}
                  onClick={null}
                >
                  User Name
                </HeaderButton>
                <HeaderButton
                  disableElevation
                  variant={"contained"}
                  color={"primary"}
                  size={"medium"}
                  onClick={null}
                >
                  Buyer/Seller
                </HeaderButton>
              </Fragment>

              <Close
                className={"pointer"}
                onClick={handleClose}
                fontSize={"small"}
              />
            </Box>
          </Box>
          <Divider />
          <Box sx={{ px: 4 }}>
            <SubHeading
              sx={{
                fontSize: 14,
                py: 1,
                textDecoration: "underline",
                color: "#5f99ff",
              }}
            >
              Transaction Details
            </SubHeading>
            <Box border={"1px solid #c4c4c4"} borderRadius={1}>
              <SingleArbitrationTable
                row={{
                  id: 456362,
                  transactionData: Date.now(),
                  mkt: "Gold",
                  ticker: "GLD",
                  description: "Similar compare",
                  price: 500,
                  total: 500,
                  dateFiled: Date.now(),
                  type: "Gold",
                  cause: "-",
                  status: "Completed",
                }}
                loading={false}
              />
            </Box>
            <Box
              py={2}
              display={"flex"}
              flexDirection={"column"}
              rowGap={1}
              sx={(theme) => ({
                [theme.breakpoints.up("xl")]: {
                  rowGap: 3,
                },
              })}
            >
              <Box>
                <Box mb={2}>
                  <SubHeading
                    sx={{
                      textDecoration: "underline",
                      color: "#5f99ff",
                    }}
                  >
                    {variant === "filer"
                      ? "Filer's Complaint Statement"
                      : "Other Party's Complaint Statement"}
                  </SubHeading>
                </Box>
                <StyledTextArea
                  // placeholder={
                  //   variant === "filer"
                  //     ? "Filer's Complaint Statement"
                  //     : "Other Party's Complaint Statement"
                  // }
                  minRows={7}
                  style={{
                    width: "100%",
                    padding: 5,
                  }}
                  name={"statement"}
                  readOnly={true}
                  value={data?.statement}
                />
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                columnGap={5}
              >
                {variant === "filer" && (
                  <Box width="100%">
                    <LabelHeading>Select Cause For Arbitration</LabelHeading>
                    <TextField
                      // label={"Select Cause for Arbitration"}
                      name={"cause"}
                      variant={"outlined"}
                      value={data?.cause}
                      fullWidth
                      size={"small"}
                      readOnly={true}
                      type={"text"}
                    />
                  </Box>
                )}
                <Box width={"100%"}>
                  <LabelHeading>Select Cause For Arbitration</LabelHeading>
                  <TextField
                    size={"small"}
                    name={"desiredOutcome"}
                    value={data?.desiredOutcome}
                    readOnly={true}
                    sx={{
                      width: variant === "filer" ? "100%" : "50%",
                    }}
                    fullWidth={variant === "filer"}
                    type={"text"}
                  />
                </Box>
              </Box>
              <Box>
                <SubHeading>Attachments</SubHeading>
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    columnGap: "50px",
                    mt: 1,
                  })}
                >
                  <AttachmentFile type="pdf" name="pdf_file_1.pdf" />
                  <AttachmentFile type="pdf" name="pdf_file_1.pdf" />
                  <AttachmentFile type="pdf" name="pdf_file_1.pdf" />
                </Box>
              </Box>
            </Box>
            <Box
              display={"flex"}
              width={"100%"}
              justifyContent={"flex-end"}
              alignItems={"center"}
              pb={1}
            >
              <Button
                disableElevation
                onClick={handleClose}
                variant={"contained"}
                color={"primary"}
                size={"small"}
                sx={{
                  width: "15%",
                }}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default RequestResponseModal;
