import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Link as MUILink,
  Breadcrumbs,
} from "@mui/material";
import { StyledButton, StyledContainer } from "../../components/Styled/Globals";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Hexagon from "../../components/Hexagon/Hexagon";
import SearchField from "../../components/Global/SearchField";
import historyImg from "../../assets/knowledge/history-large.png";
import FilterListIcon from "@mui/icons-material/FilterList";
import goldImg from "../../assets/knowledge/history-gold.png";
import FilterModal from "../../components/Common/FilterModal";

function HistoryPoliticsCulture() {
  const _breadcrumbs = [
    <MUILink
      underline="hover"
      key="1"
      color="text.secondary"
      fontSize={20}
      textTransform="uppercase"
    >
      <Link to={"/knowledge"}>Knowledge</Link>
    </MUILink>,
    <Typography key="2" color="primary" fontSize={20} fontWeight="bold">
      History, Politics, Culture
    </Typography>,
  ];
  let _articles = [
    {
      mkt: "Gold",
      img: goldImg,
      title: "Title of Article Goes Here and Continues",
      description:
        "Brief descripton of what the article covers that is no longer than say two short sentences. This would be the second sentence like that right here",
    },
    {
      mkt: "Gold",

      img: goldImg,
      title: "Title of Article Goes Here and Continues",
      description:
        "Brief descripton of what the article covers that is no longer than say two short sentences. This would be the second sentence like that right here",
    },
    {
      mkt: "Gold",
      img: goldImg,
      title: "Title of Article Goes Here and Continues",
      description:
        "Brief descripton of what the article covers that is no longer than say two short sentences. This would be the second sentence like that right here",
    },
  ];

  const [openFilter, setOpenFilter] = useState(false);

  return (
    <Box height={"100%"} position={"relative"}>
      <FilterModal open={openFilter} handleClose={() => setOpenFilter(false)} />
      <Box className={"blue-bg-history"}></Box>
      <Box paddingX={"150px"}>
        <Breadcrumbs
          sx={{ marginY: 5 }}
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {_breadcrumbs}
        </Breadcrumbs>
        <Hexagon
          width={480}
          title={
            <Box
              display="flex"
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography color={"primary"} fontWeight={"bold"} fontSize={26}>
                History, Politics, Culture
              </Typography>
            </Box>
          }
        />
        <StyledContainer>
          <img
            src={historyImg}
            alt={"history"}
            className={"center-block w-100"}
            style={{ paddingBottom: 25 }}
          />
          <SearchField title={"Enter Search Query Here"} />
        </StyledContainer>

        <Box marginTop={10}>
          <StyledButton
            sx={{ width: 175, mb: 10 }}
            onClick={() => setOpenFilter(true)}
          >
            <Box
              display={"flex"}
              fontSize={20}
              width={"100%"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <FilterListIcon /> Apply Filter
            </Box>
          </StyledButton>
        </Box>

        <Box marginBottom={25}>
          <Grid container spacing={4}>
            {_articles.map((article) => {
              return (
                <Grid item md={4}>
                  <Card sx={{ maxWidth: 345, position: "relative" }}>
                    <Button
                      sx={{
                        position: "absolute",
                        background: "#fff",
                        top: "20px",
                        left: "20px",
                        textTransform: "capitalize",
                        fontWeight: "bold",
                        color: "#000",
                        ":hover": {
                          color: "#fff",
                          background: "#5f99ff",
                        },
                      }}
                    >
                      {article.mkt}
                    </Button>
                    <CardMedia
                      component="img"
                      image={article.img}
                      alt="green iguana"
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        fontWeight={"bold"}
                        fontSize={22}
                        component="div"
                      >
                        {article.title}
                      </Typography>
                      <Typography variant="body2">
                        {article.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default HistoryPoliticsCulture;
