import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

function TrackingForm({ formData, setFormData, onChange, handleSubmit }) {
  return (
    <Box
      component={"form"}
      display={"flex"}
      flexDirection={"column"}
      onSubmit={handleSubmit}
    >
      <Typography fontSize={22}>
        Providing a Tracking Number is a part of settlement and an expectation
        of a seller.
      </Typography>

      <Box component={"div"} display={"flex"} columnGap={5} my={4}>
        <Box width={"100%"}>
          <Autocomplete
            placeholder={"Choose Shipper"}
            id="shipper-autocomplete"
            options={["Shipper 1", "Shipper 2"]}
            getOptionLabel={(shipper) => `${shipper}`}
            value={formData?.shipper}
            onChange={(e, shipper, reason) => {
              reason === "selectOption"
                ? setFormData({
                    ...formData,
                    shipper,
                  })
                : setFormData({
                    ...formData,
                    shipper: "",
                  });
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label={"Choose Shipper"}
                  variant="outlined"
                  value={formData?.shipper}
                />
              );
            }}
          />
        </Box>
        <Box width={"100%"}>
          <TextField
            label={"Provide Tracking Number Here"}
            value={formData?.trackingNumber}
            name={"trackingNumber"}
            fullWidth
            type={"text"}
            onChange={onChange}
          />
        </Box>
      </Box>
      <Box
        width={"100%"}
        alignItems={"center"}
        justifyContent={"flex-end"}
        display={"flex"}
        pb={5}
      >
        <Button
          variant={"contained"}
          color={"primary"}
          sx={{
            py: 2,
            width: "25%",
            textTransform: "uppercase",
          }}
          disableElevation
          type={"submit"}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
}

export default TrackingForm;
