import React, { useState } from "react";
import {
  Box,
  Modal,
  Divider,
  Button,
  TextField,
  Container,
  Paper,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { SubHeading } from "../../Styled/Globals";

function TemplateNameModal({ open, handleClose }) {
  const [data, setData] = useState(null);
  return (
    <Modal open={open} onClose={handleClose} className={"modal"}>
      <Paper
        sx={{
          py: 2,
          width: 500,
        }}
      >
        <Container>
          <Box className={"close-box"}>
            <SubHeading>Enter Template Name</SubHeading>
            <Close
              onClick={handleClose}
              fontSize="small"
              sx={{
                cursor: "pointer",
              }}
            />
          </Box>
        </Container>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Container>
          <Box
            component={"form"}
            onSubmit={(e) => {
              e.preventDefault();
              handleClose();
            }}
            display={"flex"}
            flexDirection={"column"}
            rowGap={1}
          >
            <TextField
              name="name"
              type="text"
              size="small"
              label="Template Name"
              required
              onChange={(e) => setData({ ...data, name: e.target.value })}
            />
            <Box display={"flex"} justifyContent="flex-end" alignItems="center">
              <Button
                variant={"contained"}
                color="primary"
                size={"small"}
                type="submit"
                sx={{
                  width: "25%",
                  textTransform: "initial",
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Container>
      </Paper>
    </Modal>
  );
}

export default TemplateNameModal;
