import Close from "@mui/icons-material/Close";
import { Box, Grid, Modal, Paper } from "@mui/material";
import React from "react";
import { ReactComponent as ProfileIcon } from "../../../assets/profile-icon.svg";
import { Heading } from "../../Styled/Globals";
import ProfileInfo from "./ProfileInfo";

import "./ProfileViewModal.css";

const ProfileViewModal = ({ open, handleClose }) => {
  // const style = {
  //   position: "absolute",
  //   top: "50%",
  //   border: "none",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   width: 500,
  //   height: "100%",
  //   overflowX: "hidden",
  //   display: "block",
  // };
  const paperStyle = {
    background: "linear-gradient(180deg, #ECF3FF 18%, #fff 0%)",
  };

  return (
    <Modal className={"modal"} open={open} onClose={handleClose}>
      <Box className={"no-scroll-bar"} sx={{ width: 500 }}>
        <Paper style={paperStyle} sx={{ padding: 5, position: "relative" }}>
          <Close
            onClick={handleClose}
            sx={{
              fontSize: "medium",
              position: "absolute",
              top: 5,
              right: 5,
              cursor: "pointer",
            }}
          />

          <Box display={"grid"} justifyContent={"center"}>
            <Box
              component={"div"}
              height={120}
              width={120}
              marginTop={3}
              borderRadius={"50%"}
              bgcolor={"#fff"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <ProfileIcon style={{ transform: "scale(1.3)" }} />
            </Box>
          </Box>
          <Box>
            <Heading textAlign={"center"}>User Name</Heading>
            <Grid container columnSpacing={10}>
              <Grid item md={6}>
                <ProfileInfo title={"User Type"} data={"Buyer"} />
              </Grid>
              <Grid item md={6}>
                <ProfileInfo title={"Join Date"} data={"16 Jun, 2022"} />
              </Grid>
              <Grid item md={6}>
                <ProfileInfo title={"Reputation"} data={"-"} />
              </Grid>
              <Grid item md={6}>
                <ProfileInfo title={"# of Transactions"} data={"$ 5000"} />
              </Grid>
              <Grid item md={6}>
                <ProfileInfo title={"Seller Transactions"} data={"$ 500"} />
              </Grid>
              <Grid item md={6}>
                <ProfileInfo title={"Buyer Transactions"} data={"$ 1000"} />
              </Grid>
              <Grid item md={6}>
                <ProfileInfo title={"# of Arbitrations"} data={"-"} />
              </Grid>
              <Grid item md={6}>
                <ProfileInfo title={"# of Arbitrations Lost"} data={"-"} />
              </Grid>
              <Grid item md={6}>
                <ProfileInfo
                  title={"Arbitration to Transactions%"}
                  data={"-"}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default ProfileViewModal;
