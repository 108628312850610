import React, { Fragment } from "react";
import { Box, Typography } from "@mui/material";

export default function MockUserForm({ fields, data, setData, onChange }) {
  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: 1.5,
          mt: 2,
        }}
      >
        <Box display={"flex"}>
          <Box width={"40%"}></Box>
          <Box width={"30%"}>
            <Typography
              textAlign={"center"}
              sx={(theme) => ({
                fontWeight: "bold",
                fontSize: 16,
                [theme.breakpoints.up("xl")]: {
                  fontSize: 18,
                },
              })}
            >
              Order Creator
            </Typography>
          </Box>
          <Box width={"30%"}>
            <Typography
              textAlign={"center"}
              sx={(theme) => ({
                fontWeight: "bold",
                fontSize: 16,
                [theme.breakpoints.up("xl")]: {
                  fontSize: 18,
                },
              })}
            >
              Matched Order Creator
            </Typography>
          </Box>
        </Box>
        {fields?.map((field) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 1,
            }}
          >
            <Box width={"40%"}>
              <Typography
                sx={(theme) => ({
                  fontSize: 14.5,
                  [theme.breakpoints.up("xl")]: {
                    fontSize: 16,
                  },
                })}
              >
                {field?.title}
              </Typography>
            </Box>
            <Box width={"30%"}>{field?.fieldOne}</Box>
            <Box width={"30%"}>{field?.fieldTwo}</Box>
          </Box>
        ))}
      </Box>
    </Fragment>
  );
}
