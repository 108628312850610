import { Box, Button, Typography } from "@mui/material";

import { Link } from "react-router-dom";
import React from "react";
import TransactionsTable from "./TransactionsTable";
import { sampleTransactions } from "../../../constants/SampleData/transactions";

function Transactions({ SVG, bRef }) {
  return (
    <Box ref={bRef}>
      <Box display="flex" alignItems={"center"}>
        <Box
          component={"div"}
          marginBottom={2}
          display="flex"
          alignItems={"center"}
          width={"100%"}
        >
          <SVG
            style={{
              marginRight: 20,
            }}
          />
          <Typography fontSize={30} fontWeight={"bold"}>
            Transactions
          </Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          alginItems={"center"}
          width="100%"
        >
          <Button variant={"contained"} color={"primary"}>
            <Link to={"/transactions"}>
              <Typography
                color={"#fff"}
                sx={{
                  textTransform: "uppercase",
                }}
              >
                Full Dashboard
              </Typography>
            </Link>
          </Button>
        </Box>
      </Box>
      <Box component={"div"}>
        <TransactionsTable
          rows={[...sampleTransactions]}
          headCells={[
            {
              id: "status",
              numeric: false,
              width: "2%",
              label: "Status",
            },
            {
              id: "action",
              numeric: false,
              label: "Action",
              width: "18%",
              sx: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "large",
                color: "#fff",
              },
            },
            {
              id: "mkt",
              numeric: false,
              width: "5%",
              label: "MKT",
              sx: {
                paddingLeft: "2px",
              },
            },
            {
              id: "ticker",
              numeric: false,
              width: "5%",
              label: "Ticker",
            },
            {
              id: "bidAsk",
              numeric: false,
              width: "2%",
              label: "Bid/Ask",
            },
            {
              id: "description",
              numeric: false,
              width: "30%",
              label: "Description",
            },
            {
              id: "quantity",
              numeric: true,
              width: "3%",
              label: "Quantity",
            },
            {
              id: "price",
              numeric: true,
              width: "2%",
              label: "Price",
            },
            {
              id: "total",
              numeric: false,
              width: "2%",
              label: "Total",
            },
          ]}
          tableTitle={null}
          loading={false}
        />
      </Box>
    </Box>
  );
}

export default Transactions;
