import {
  Box,
  Button,
  Grid,
  InputBase,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { StyledContainer } from "../Styled/Globals";
// import gab from "../../assets/social-icons/gab.svg";
import { ReactComponent as GabLogo } from "../../assets/social-icons/gab.svg";
import { ReactComponent as YouTubeLogo } from "../../assets/social-icons/youtube.svg";
import { ReactComponent as LinkedInLogo } from "../../assets/social-icons/linkedin.svg";
import { ReactComponent as InstagramLogo } from "../../assets/social-icons/instagram.svg";
import { ReactComponent as FacebookLogo } from "../../assets/social-icons/facebook.svg";
import { ReactComponent as TwitterLogo } from "../../assets/social-icons/twitter.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  const _listOne = [
    { title: "Markets", link: "/markets" },
    { title: "Gold", link: "/markets/gold" },
    { title: "Silver", link: "/markets/silver" },
    { title: "Platinum", link: "/markets/platinum" },
    { title: "Palladium", link: "/markets/palladium" },
    { title: "Copper", link: "/markets/copper" },
    { title: "Coin & Currency", link: "/markets/coinscurrency" },
    { title: "Additional Markets", link: "/markets" },
  ];

  const _listTwo = [
    { title: "How it Works", link: "/howitworks" },
    { title: "B MKTS Blog", link: "/knowledge/history-politics-culture" },
    { title: "Knowledge", link: "/knowledge" },
    { title: "Policies", link: "/policies" },
  ];

  const _listThree = [
    { title: "About Us", link: "/about" },
    { title: "Contact Us", link: "/contact" },
    { title: "Partnerships", link: "/partnerships" },
    { title: "User Agreement", link: "/user" },
    { title: "Privacy Agreement", link: "/privacy" },
    { title: "Terms & Conditions", link: "/terms-and-conditions" },
  ];

  const _socialIcons = [
    <GabLogo />,
    <YouTubeLogo />,
    <LinkedInLogo />,
    <InstagramLogo />,
    <FacebookLogo />,
    <TwitterLogo />,
  ];

  return (
    <Box
      component={"footer"}
      sx={{
        // zIndex: (theme) => theme.zIndex.drawer + 2,
        position: "relative",
      }}
    >
      <Box className="footer-upper" component={"div"}>
        <StyledContainer>
          <Grid container gap={6} sx={{ pt: 10, pb: 3 }}>
            <Grid item sm={2} md={2}>
              <List>
                {_listOne.map((item) => {
                  return (
                    <ListItem>
                      <ListItemText sx={{ color: "#fff" }}>
                        <Link style={{ color: "#fff" }} to={item.link}>
                          {item.title}
                        </Link>
                      </ListItemText>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
            <Grid item sm={2} md={2}>
              <List>
                {_listTwo.map((item) => {
                  return (
                    <ListItem>
                      <ListItemText sx={{ color: "#fff" }}>
                        <Link style={{ color: "#fff" }} to={item.link}>
                          {item.title}
                        </Link>
                      </ListItemText>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
            <Grid item sm={2} md={2}>
              <List>
                {_listThree.map((item) => {
                  return (
                    <ListItem>
                      <ListItemText sx={{ color: "#fff" }}>
                        <Link style={{ color: "#fff" }} to={item.link}>
                          {item.title}
                        </Link>
                      </ListItemText>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
            <Grid item sm={2} md={4} display={"flex"} flexDirection="column">
              <Typography
                color={"#fff"}
                variant={"p"}
                component={"p"}
                className="footer-subscribe"
              >
                Get bullion news, essays, and marketplace summaries sent to your
                inbox weekly.
              </Typography>
              <InputBase
                sx={{
                  background: "#fff",
                  borderRadius: 1,
                  padding: 1,
                  marginY: 2,
                }}
                fullWidth
                size={"small"}
              />
              <Button
                variant="contained"
                sx={{
                  px: 5,
                  fontWeight: "bold",
                  color: "#fff",
                  alignSelf: "flex-end",
                  borderRadius: 2,
                  textTransform: "capitalize",
                }}
              >
                Subscribe
              </Button>
            </Grid>
          </Grid>
        </StyledContainer>
      </Box>
      <Box className={"footer-bottom"}>
        <Box
          display={"flex"}
          flexDirection="column"
          color={"#fff"}
          textAlign={"center"}
          rowGap={2}
          paddingTop={5}
        >
          <Typography>Follow Us</Typography>
          <Box
            display="flex"
            width={220}
            margin={"auto"}
            alignItems={"center"}
            justifyContent="space-between"
          >
            {_socialIcons.map((Icon) => Icon)}
          </Box>
          <Typography marginTop={2} marginBottom={2} fontWeight={"bold"}>
            Bullion MKTS, LLC 2022
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
