import React from "react";
import { Modal, Box, Paper } from "@mui/material";
import { Close } from "@mui/icons-material";

function ImageModal({ open, handleClose, src, alt = "bullion-mkts" }) {
  return (
    open && (
      <Modal open={open} onClose={handleClose} className={"modal"}>
        <Box className={"no-scroll-bar"}>
          <Paper sx={{ p: 1, position: "relative" }}>
            {/* <Box
              display={"flex"}
              alignItems={"flex-start"}
              justifyContent={"flex-end"}
            > */}
            <Close
              onClick={handleClose}
              sx={{
                position: "absolute",
                fontSize: "small",
                right: "1%",
                top: "2%",
                cursor: "pointer",
              }}
            />
            {/* </Box> */}
            <img
              src={src}
              alt={alt}
              style={{
                display: "block",
                width: "100%",
                margin: "auto",
              }}
            />
          </Paper>
        </Box>
      </Modal>
    )
  );
}

export default ImageModal;
