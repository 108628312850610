import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Link as MUILink,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import FilterListIcon from "@mui/icons-material/FilterList";
import FilterModal from "../../components/Common/FilterModal";
import Hexagon from "../../components/Hexagon/Hexagon";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SearchField from "../../components/Global/SearchField";
import { StyledContainer } from "../../components/Styled/Globals";
import bullionNumismaticTypesImg from "../../assets/knowledge/bullion-numismatic-types.png";
import copperImg from "../../assets/knowledge/history-copper.png";
import goldImg from "../../assets/knowledge/history-gold.png";
import plattiniumImg from "../../assets/knowledge/history-plattinium.png";

function BullionNumismaticTypes() {
  const _breadcrumbs = [
    <MUILink
      underline="hover"
      key="1"
      color="text.secondary"
      fontSize={20}
      textTransform="uppercase"
    >
      <Link to={"/knowledge"}>Knowledge</Link>
    </MUILink>,
    <Typography key="2" color="primary" fontSize={20} fontWeight="bold">
      Bullion &#38; Numismatic Types (Tickers)
    </Typography>,
  ];
  let _articles = [
    {
      mkt: "Gold",
      img: goldImg,
      title: "GLD",
      description:
        "Brief descripton of what the article covers that is no longer than say two short sentences. This would be the second sentence like that right here",
    },
    {
      mkt: "Platinum",
      title: "PLTD",
      img: plattiniumImg,
      description:
        "Brief descripton of what the article covers that is no longer than say two short sentences. This would be the second sentence like that right here",
    },
    {
      mkt: "Copper",
      img: copperImg,
      title: "COPR",
      description:
        "Brief descripton of what the article covers that is no longer than say two short sentences. This would be the second sentence like that right here",
    },
  ];

  const [openFilter, setOpenFilter] = useState(false);

  return (
    <Box height={"100%"} sx={{ overflowX: "hidden" }}>
      <FilterModal open={openFilter} handleClose={() => setOpenFilter(false)} />
      <StyledContainer>
        <Breadcrumbs
          sx={{ mt: 2, mb: 4 }}
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {_breadcrumbs}
        </Breadcrumbs>
        <Hexagon
          title={
            <Box
              display="flex"
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography color={"primary"} fontWeight={"bold"} fontSize={26}>
                Bullion &#38; Numismatic Types
              </Typography>
            </Box>
          }
        />
        <Box
          sx={(theme) => ({
            position: "relative",
          })}
        >
          <Box
            sx={(theme) => ({
              position: "absolute",
              bottom: -50,
              bgcolor: theme.palette.primary.dark,
              height: "50%",
              width: "120%",
              left: -150,
              zIndex: -1,
            })}
          >
            .
          </Box>
          <img
            src={bullionNumismaticTypesImg}
            alt={"history"}
            style={{
              padding: "25px 0px",
              maxHeight: "69vh",
              maxWidth: "100%",
              margin: "auto",
              display: "block",
            }}
          />

          <SearchField title={"Enter Search Query Here"} />
        </Box>
        <Box marginTop={10}>
          <Button
            variant={"contained"}
            color={"primary"}
            sx={{ width: 175, mb: 10 }}
            onClick={() => setOpenFilter(true)}
          >
            <Box
              display={"flex"}
              fontSize={20}
              width={"100%"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <FilterListIcon /> Apply Filter
            </Box>
          </Button>
        </Box>

        <Box marginBottom={25}>
          <Grid container spacing={4}>
            {_articles.map((article) => {
              return (
                <Grid item md={4}>
                  <Card sx={{ maxWidth: 345 }}>
                    <CardMedia
                      component="img"
                      image={article.img}
                      alt="green iguana"
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        fontWeight={"bold"}
                        fontSize={22}
                        component="div"
                      >
                        {article.title}
                      </Typography>
                      <Typography sx={{ mb: 1.5, fontWeight: "bold" }}>
                        {article.mkt}
                      </Typography>
                      <Typography variant="body2">
                        {article.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </StyledContainer>
    </Box>
  );
}

export default BullionNumismaticTypes;
