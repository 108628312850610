import { Box, Divider, Modal, Paper, Typography } from "@mui/material";
import { DataLabel, Heading, SubHeading } from "../../Styled/Globals";
import React, { useState } from "react";

import Close from "@mui/icons-material/Close";
import ScheduleForm from "./ScheduleForm";
import SingleTransactionTable from "../SingleTransactionTable/SingleTransactionTable";

const ScheduleModal = ({ open, handleClose, data, onSubmit, scheduleData }) => {
  const [formData, setFormData] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  return (
    <Modal className={"modal"} open={open} onClose={handleClose}>
      <Box
        sx={{
          width: "90%",
          maxWidth: "95%",
        }}
        className={"no-scroll-bar"}
      >
        <Paper
          sx={(theme) => ({
            pb: 2,
          })}
        >
          <Box component={"div"} className={"b-relative"}>
            <Heading>{scheduleData?.title}</Heading>
            <Close onClick={handleClose} className={"c-absolute"} />
          </Box>
          <Divider />
          <Box
            sx={(theme) => ({
              px: 4,
              py: 1,
              display: "flex",
              flexDirection: "column",
              rowGap: 1,
            })}
          >
            <Typography>{scheduleData?.description}</Typography>
            <SubHeading>Transaction Details</SubHeading>
            <Box>
              <SingleTransactionTable loading={false} data={data} />
            </Box>
            <Box py={2}>
              <DataLabel fontWeight={"bold"}>
                Please select all the dates and times that work for you. Please
                select at least two of the dates and time periods.
              </DataLabel>
            </Box>
            <Box>
              <ScheduleForm
                formData={formData}
                setFormData={setFormData}
                onChange={onChange}
                handleSubmit={handleSubmit}
              />
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default ScheduleModal;
