import React, { useState, useEffect } from "react";
import { Autocomplete, Box, Modal, Paper, TextField } from "@mui/material";
import { StyledButton } from "../../Styled/Globals";

const ArticleSearchModal = ({ open, handleClose, type }) => {
  const style = {
    position: "absolute",
    top: "50%",
    border: "none",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "65%",
    // height: "98%",
    overflowX: "hidden",
    display: "block",
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   handleClose();
  // };

  const [articles, setArticles] = useState([
    `${type} Article 1`,
    `${type} Article 2`,
    `${type} Article 3`,
    `${type} Article 4`,
  ]);

  useEffect(() => {
    setArticles([
      `${type} Article 1`,
      `${type} Article 2`,
      `${type} Article 3`,
      `${type} Article 4`,
    ]);
  }, [type]);

  const [selectedArticle, setSelectedArticle] = useState("");

  return (
    <Modal open={open} onClose={handleClose}>
      <Box style={style} className={"no-scroll-bar"}>
        <Paper>
          <Box
            sx={{
              py: 5,
              px: 10,
            }}
          >
            {" "}
            <Autocomplete
              placeholder="Articles"
              id="articles-autocomplete"
              options={articles}
              getOptionLabel={(article) => `${article}`}
              value={selectedArticle}
              onChange={(e, article, reason) =>
                reason === "selectOption"
                  ? setSelectedArticle(article)
                  : setSelectedArticle("")
              }
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label="Search Articles"
                    variant="outlined"
                    value={selectedArticle}
                  />
                );
              }}
            />
          </Box>
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <StyledButton
              sx={{
                width: "100%",
              }}
            >
              Select
            </StyledButton>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default ArticleSearchModal;
