import { Paper, Typography } from "@mui/material";

import React from "react";

export default function ClearedFunds() {
  return (
    <Paper
      sx={(theme) => ({
        px: 5,
        // py: 1,
        height: 63,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        [theme.breakpoints.up("xl")]: {
          height: 75,
        },
      })}
    >
      <Typography
        fontSize={20}
        fontWeight={"bold"}
        sx={{ textDecoration: "underline" }}
        textAlign={"end"}
      >
        Your Cleared Funds
      </Typography>
      <Typography color={"primary"} textAlign={"end"}>
        $000,000,000.00
      </Typography>
    </Paper>
  );
}
