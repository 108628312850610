import { Box, Paper } from "@mui/material";
import { Heading, SubHeading } from "../../Styled/Globals";

import NewMarketActions from "./NewMarketActions";
import NewMarketRequestsTable from "./NewMarketRequestsTable";
import React from "react";

const NewMarketRequests = ({ bRef }) => {
  return (
    <Box ref={bRef}>
      <Box
        sx={{
          my: 3,
        }}
      >
        <Heading>New Market Requests</Heading>
      </Box>
      <Paper
        sx={{
          px: 4,
          pt: 2,
          pb: 1,
          borderRadius: 5,
        }}
      >
        <Box
          sx={{
            py: 2,
          }}
        >
          <SubHeading>New Market Requests</SubHeading>
        </Box>
        <Box>
          <NewMarketRequestsTable rows={marketRequests} loading={false} />
        </Box>
      </Paper>
      <NewMarketActions />
    </Box>
  );
};

const marketRequests = [
  {
    requester: "John",
    mkt: "GLD",
    description: "Similar Product is best",
    mint: 20,
    style: "Square",
    weight: 1.9,
    purity: "Original",
    year: Date.now(),
    condition: "Good",
  },
  {
    requester: "John",
    mkt: "GLD",
    description: "Similar Product is best",
    mint: 20,
    style: "Square",
    weight: 1.9,
    purity: "Original",
    year: Date.now(),
    condition: "Poor",
  },
  {
    requester: "John",
    mkt: "GLD",
    description: "Similar Product is best",
    mint: 20,
    style: "Square",
    weight: 1.9,
    purity: "Original",
    year: Date.now(),
    condition: "Fair",
  },
  {
    requester: "John",
    mkt: "GLD",
    description: "Similar Product is best",
    mint: 20,
    style: "Square",
    weight: 1.9,
    purity: "Original",
    year: Date.now(),
    condition: "Good",
  },
  {
    requester: "John",
    mkt: "GLD",
    description: "Similar Product is best",
    mint: 20,
    style: "Square",
    weight: 1.9,
    purity: "Original",
    year: Date.now(),
    condition: "Poor",
  },
  {
    requester: "John",
    mkt: "GLD",
    description: "Similar Product is best",
    mint: 20,
    style: "Square",
    weight: 1.9,
    purity: "Original",
    year: Date.now(),
    condition: "Fair",
  },
];

export default NewMarketRequests;
