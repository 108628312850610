import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  Paper,
  styled,
} from "@mui/material";
import { Heading, SubHeading } from "../../../../Styled/Globals";
import React, { useEffect, useState } from "react";

import Close from "@mui/icons-material/Close";
import TransactionOrderTable from "./TransactionOrderTable";

const UserButton = styled(Button)(({ theme }) => ({
  width: "100%",
}));
const UserHistoryModal = ({ open, handleClose }) => {
  const [user, setUser] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (!user) {
      setOpenDialog(true);
      setOpenModal(false);
    } else {
      setOpenDialog(false);
      setOpenModal(true);
    }
  }, [user]);

  useEffect(() => {
    setUser(null);
  }, [open]);

  return (
    open && (
      <Box>
        <Dialog onClose={handleClose} open={openDialog}>
          <DialogTitle>
            <Box className={"close-box"}>
              <SubHeading>Select User</SubHeading>
              <Close
                className="pointer"
                fontSize={"inherit"}
                onClick={handleClose}
              />
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box
              display={"flex"}
              width={200}
              flexDirection={"column"}
              rowGap={2}
            >
              <UserButton
                disableElevation
                onClick={(_) => {
                  setUser("buyer");
                }}
                variant={"contained"}
                color={"primary"}
              >
                Buyer
              </UserButton>
              <UserButton
                disableElevation
                onClick={(_) => setUser("seller")}
                variant={"contained"}
                color={"primary"}
              >
                Seller
              </UserButton>
            </Box>
          </DialogContent>
        </Dialog>
        <Modal open={openModal} className={"modal"} onClose={handleClose}>
          <Box
            className={"no-scroll-bar"}
            sx={(theme) => ({
              width: 900,
            })}
          >
            <Paper>
              <Box
                sx={(theme) => ({
                  px: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                })}
              >
                <SubHeading sx={{ fontSize: 18, textTransform: "capitalize" }}>
                  {user} Orders
                </SubHeading>
                <Close
                  onClick={handleClose}
                  sx={(theme) => ({
                    cursor: "pointer",
                    fontSize: "small",
                  })}
                />
              </Box>
              <Box
                sx={(theme) => ({
                  px: 2,
                  pb: 2,
                })}
              >
                <Divider />
              </Box>
              <Box
                sx={(theme) => ({
                  px: 2,
                })}
              >
                <Box>
                  <TransactionOrderTable
                    variant={"Order"}
                    loading={false}
                    rows={[...userData]}
                  />
                </Box>
              </Box>
              <Box px={2} pt={4}>
                <SubHeading sx={{ fontSize: 18, textTransform: "capitalize" }}>
                  {user} Transactions
                </SubHeading>
              </Box>
              <Box
                sx={(theme) => ({
                  px: 2,
                })}
              >
                <Divider />
              </Box>
              <Box px={2} py={2}>
                <Box>
                  <TransactionOrderTable
                    variant={"Transactions"}
                    loading={false}
                    rows={[...userData]}
                  />
                </Box>
              </Box>
            </Paper>
          </Box>
        </Modal>
      </Box>
    )
  );
};

const userData = [
  {
    id: 131214,
    status: "Awaiting",
    mkt: "Gold",
    ticker: "GLD",
    bidAsk: "Bid",
    description: "Jim likes GOLD",
    quantity: 200,
    price: 5000,
    total: 5500,
  },
  {
    id: 131214,
    status: "Awaiting",
    mkt: "Gold",
    ticker: "GLD",
    bidAsk: "Bid",
    description: "Jim likes GOLD",
    quantity: 200,
    price: 5000,
    total: 5500,
  },
  {
    id: 131214,
    status: "Awaiting",
    mkt: "Gold",
    ticker: "GLD",
    bidAsk: "Bid",
    description: "Jim likes GOLD",
    quantity: 200,
    price: 5000,
    total: 5500,
  },
  {
    id: 131214,
    status: "Awaiting",
    mkt: "Gold",
    ticker: "GLD",
    bidAsk: "Bid",
    description: "Jim likes GOLD",
    quantity: 200,
    price: 5000,
    total: 5500,
  },
  {
    id: 131214,
    status: "Awaiting",
    mkt: "Gold",
    ticker: "GLD",
    bidAsk: "Bid",
    description: "Jim likes GOLD",
    quantity: 200,
    price: 5000,
    total: 5500,
  },
  {
    id: 131214,
    status: "Awaiting",
    mkt: "Gold",
    ticker: "GLD",
    bidAsk: "Bid",
    description: "Jim likes GOLD",
    quantity: 200,
    price: 5000,
    total: 5500,
  },
];

export default UserHistoryModal;
