import {
  Autocomplete,
  Box,
  Grid,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import {
  DataLabel,
  LimitBox,
  LimitField,
  SubHeading,
} from "../../Styled/Globals";
import React, { useState } from "react";

import ReputationModal from "../../Markets/ReputationModal";

function BasicStandard({ data, setData, onChange }) {
  const [openReputationModal, setOpenReputationModal] = useState(false);

  return (
    <Box>
      <ReputationModal
        open={openReputationModal}
        handleClose={() => setOpenReputationModal(false)}
      />
      <SubHeading>Basic &#38; Standard</SubHeading>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <DataLabel>Choose Ticker</DataLabel>
          <Autocomplete
            id="ticker-autocomplete"
            options={["Spot", "Ticker"]}
            getOptionLabel={(ticker) => `${ticker}`}
            value={data?.ticker}
            onChange={(e, ticker, reason) =>
              reason === "selectOption"
                ? setData({
                    ...data,
                    ticker,
                  })
                : setData({
                    ...data,
                    ticker: "",
                  })
            }
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  variant="outlined"
                  value={data?.ticker}
                  required
                />
              );
            }}
          />
          <Box mt={2}>
            <Link onClick={() => setOpenReputationModal(true)}>
              Find Ticket
            </Link>
          </Box>
        </Grid>
        <Grid item md={6}>
          <DataLabel>Bid/Ask</DataLabel>
          <Autocomplete
            id="bid-ask-autocomplete"
            options={["Bid", "Ask"]}
            getOptionLabel={(bidAsk) => `${bidAsk}`}
            value={data?.bidAsk}
            onChange={(e, bidAsk, reason) =>
              reason === "selectOption"
                ? setData({
                    ...data,
                    bidAsk,
                  })
                : setData({
                    ...data,
                    bidAsk: "",
                  })
            }
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  variant="outlined"
                  value={data?.bidAsk}
                  required
                />
              );
            }}
          />
        </Grid>
        <Grid item md={6}>
          <DataLabel>Enter Price</DataLabel>
          <TextField
            name="price"
            fullWidth
            type="number"
            variant="outlined"
            value={Number(data?.price)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item md={6}>
          <DataLabel>Enter Quantity</DataLabel>
          <TextField
            fullWidth
            type="number"
            name="quantity"
            variant="outlined"
            value={Number(data?.quantity)}
          />
        </Grid>
        <Grid item md={6}>
          <DataLabel>Timing</DataLabel>
          <TextField
            fullWidth
            type="number"
            variant="outlined"
            name="timing"
            value={Number(data?.timing)}
          />
        </Grid>
        <Grid item md={6}>
          <DataLabel>Limit</DataLabel>
          <LimitBox sx={(theme) => ({ height: "60%" })}>
            <Box width={"100%"}></Box>
            <LimitField>
              <TextField
                name="stopInitial"
                size="medium"
                variant="standard"
                value={data?.stopInitial}
                sx={{
                  px: 1,
                }}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </LimitField>
            <LimitField
              sx={{
                borderRight: "none",
              }}
            >
              <TextField
                name="stopEnd"
                size="medium"
                sx={{
                  borderRightColor: "#fff",
                  px: 1,
                }}
                variant="standard"
                value={data?.stopEnd}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </LimitField>
          </LimitBox>
        </Grid>
        <Grid item md={6}>
          <DataLabel>Stop</DataLabel>
          <LimitBox sx={(theme) => ({ height: "60%" })}>
            <Box width={"100%"}></Box>
            <LimitField>
              <TextField
                name="stopInitial"
                size="medium"
                variant="standard"
                value={data?.stopInitial}
                sx={{
                  px: 1,
                }}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </LimitField>
            <LimitField
              sx={{
                borderRight: "none",
              }}
            >
              <TextField
                name="stopEnd"
                size="medium"
                sx={{
                  borderRightColor: "#fff",
                  px: 1,
                }}
                variant="standard"
                value={data?.stopEnd}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </LimitField>
          </LimitBox>
        </Grid>
        <Grid item md={6}>
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <DataLabel style={{ fontWeight: "bold" }}>
              Reputation &#38; Completed Transactions
            </DataLabel>
            <Typography
              onClick={() => setOpenReputationModal(true)}
              sx={{ textDecoration: "underline", cursor: "pointer" }}
            >
              <Link>What's this?</Link>
            </Typography>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            columnGap={5}
          >
            <TextField
              type="number"
              fullWidth
              onChange={onChange}
              variant="outlined"
              label={"Reputation"}
              name="reputation"
              value={data?.reputation}
            />

            <TextField
              type="number"
              fullWidth
              onChange={onChange}
              variant="outlined"
              label={"# of Completed Transactions"}
              name="transactions"
              value={data?.transactions}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default BasicStandard;
