import { Box, Paper } from "@mui/material";
import React, { useState } from "react";

import ArbitrationManagementTable from "./ArbitrationManagementTable";
import DetailsModal from "./Actions/DetailsModal";
import { Heading } from "../../Styled/Globals";
import SearchField from "../../Global/SearchField";
import { sampleArbitrations } from "../../../constants/SampleData/arbitrations";

function ArbirtationManagement({ bRef }) {
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const handleRowClick = (row) => {
    setSelectedData(row);
    setOpenDetailsModal(true);
  };

  return (
    <Box ref={bRef}>
      <DetailsModal
        open={openDetailsModal}
        handleClose={(_) => setOpenDetailsModal(false)}
        data={selectedData}
      />

      <Paper
        sx={{
          p: 4,
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Heading
            sx={{
              width: "100%",
            }}
          >
            Arbitration
          </Heading>
          <SearchField title={"Search"} noMargin width={"100%"} />
        </Box>
        <Box mt={2}>
          <ArbitrationManagementTable
            onRowClick={handleRowClick}
            rows={[...sampleArbitrations]}
            loading={false}
          />
        </Box>
      </Paper>
    </Box>
  );
}

export default ArbirtationManagement;
