import {
  Autocomplete,
  Box,
  CircularProgress,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";

import TradeDetailsModal from "../../CommonModals/TradeDetailsModal/TradeDetailsModal";

function EnhancedTableHead({ headCells }) {
  return (
    <TableHead>
      <TableRow className="table-no-padding">
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            sx={{
              width: "max-content",
            }}
          >
            <Typography
              fontWeight={"bold"}
              fontSize={16}
              color={headCell.id === "actions" ? "#fff" : "primary.dark"}
            >
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "5px",
}));

export default function EnhancedTable({
  rows,
  headCells,
  data,
  setData,
  onChange,
  tableTitle,
  loading,
}) {
  const [openDetailsModal, setOpenDetailsModal] = useState(false);

  return (
    <Box mb={4} sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Box
          display="flex"
          justifyContent={"center"}
          alignContent={"center"}
          py={3}
          px={0.5}
          // minHeight={350}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <TableContainer>
              <Table size={"medium"}>
                <EnhancedTableHead
                  rowCount={rows.length}
                  headCells={headCells}
                />
                <TableBody>
                  {Object.keys(rows).map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    // const objectKeys = Object.keys(row);
                    return (
                      <TableRow role="checkbox" tabIndex={-1} key={row._id}>
                        <StyledTableCell
                          style={{
                            width: 150,
                          }}
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          <Autocomplete
                            id="ticker-autocomplete"
                            options={["GLD", "SLV"]}
                            getOptionLabel={(ticker) => `${ticker}`}
                            value={row?.ticker}
                            onChange={(e, ticker, reason) => {
                              console.log("row", row);
                              reason === "selectOption"
                                ? setData({
                                    ...rows,
                                    [row]: {
                                      ...rows[row],
                                      ticker,
                                    },
                                  })
                                : setData({
                                    ...rows,
                                    [row]: {
                                      ...rows[row],
                                      ticker: "",
                                    },
                                  });
                            }}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  value={row?.ticker}
                                  required
                                />
                              );
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          <Autocomplete
                            id="bid-ask-autocomplete"
                            options={["Bid", "Ask"]}
                            getOptionLabel={(bidAsk) => `${bidAsk}`}
                            value={row?.bidAsk}
                            onChange={(e, bidAsk, reason) =>
                              reason === "selectOption"
                                ? setData({
                                    ...rows,
                                    [row]: {
                                      ...rows[row],
                                      bidAsk,
                                    },
                                  })
                                : setData({
                                    ...rows,
                                    [row]: {
                                      ...rows[row],
                                      bidAsk: "",
                                    },
                                  })
                            }
                            renderInput={(params) => {
                              return (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  value={row?.bidAsk}
                                  required
                                />
                              );
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          <TextField
                            name="price"
                            onChange={(e) => onChange(e, index, rows[row])}
                            type="number"
                            variant="outlined"
                            value={row?.price}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          <TextField
                            onChange={(e) => onChange(e, index, rows[row])}
                            type="number"
                            variant="outlined"
                            name="quantity"
                            value={row?.quantity}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          <TextField
                            onChange={(e) => onChange(e, index, rows[row])}
                            variant="outlined"
                            name="orderType"
                            value={row?.orderType}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          <TextField
                            onChange={(e) => onChange(e, index, rows[row])}
                            variant="outlined"
                            name="timing"
                            value={row?.timing}
                          />{" "}
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          <TextField
                            onChange={(e) => onChange(e, index, rows[row])}
                            variant="outlined"
                            name="limit"
                            value={row?.limit}
                          />{" "}
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          <TextField
                            onChange={(e) => onChange(e, index, rows[row])}
                            variant="outlined"
                            name="stop"
                            value={row?.stop}
                          />{" "}
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          <TextField
                            onChange={(e) => onChange(e, index, rows[row])}
                            variant="outlined"
                            name="transactions"
                            value={row?.transactions}
                          />{" "}
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          <TextField
                            onChange={(e) => onChange(e, index, rows[row])}
                            variant="outlined"
                            name="spotPlusDollar"
                            value={row?.spotPlusDollar}
                          />{" "}
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          <TextField
                            onChange={(e) => onChange(e, index, rows[row])}
                            variant="outlined"
                            name="spotXPercentage"
                            value={row?.spotXPercentage}
                          />{" "}
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          <TextField
                            onChange={(e) => onChange(e, index, rows[row])}
                            variant="outlined"
                            name="floor"
                            value={row?.floor}
                          />{" "}
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          <TextField
                            onChange={(e) => onChange(e, index, rows[row])}
                            variant="outlined"
                            name="ceiling"
                            value={row?.ceiling}
                          />{" "}
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          <TextField
                            onChange={(e) => onChange(e, index, rows[row])}
                            variant="outlined"
                            type={"number"}
                            name="priceBasedOn"
                            value={row?.priceBasedOn}
                          />{" "}
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
      <TradeDetailsModal
        open={openDetailsModal}
        handleClose={() => setOpenDetailsModal(false)}
      />
    </Box>
  );
}
