import "./NewOrderModal.css";

import { Box, Button, Divider, Grid, Modal, Paper } from "@mui/material";
import React, { useState } from "react";

import BasicStandard from "./BasicStandard";
import { Close } from "@mui/icons-material";
import DynamicPricing from "./DynamicPricing";
import { Heading } from "../../Styled/Globals";
import OrderInformation from "./OrderInformation";
import ReviewTradeModal from "../../Markets/ReviewTradeModal";

const NewOrderModal = ({
  open,
  handleClose,
  title,
  order,
  cancel,
  editData,
}) => {
  const [data, setData] = useState({});
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

  return (
    <Modal open={open} className={"modal"} onClose={handleClose}>
      <Box
        className={"no-scroll-bar"}
        sx={(theme) => ({
          width: "min(1500px,85%)",
        })}
      >
        <Paper sx={{ pb: 2 }}>
          <Box
            component={"div"}
            width={"100%"}
            sx={(theme) => ({
              pt: 1,
              px: 4,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            })}
          >
            <Heading>{title}</Heading>
            <Close
              onClick={handleClose}
              sx={(theme) => ({
                cursor: "pointer",
                fontSize: "small",
              })}
            />
          </Box>
          <Divider />
          <Box px={4}>
            <OrderInformation />
            <BasicStandard data={data} setData={setData} onChange={onChange} />
            <DynamicPricing data={data} setData={setData} onChange={onChange} />
            <Grid item md={12}>
              <Box
                display={"flex"}
                width={"100%"}
                justifyContent={"flex-end"}
                alignItems={"center"}
              >
                <Button
                  disableElevation
                  variant={"contained"}
                  color={"primary"}
                  sx={{ width: "25%" }}
                  onClick={() => setOpenReviewModal(true)}
                >
                  Review &#38; Submit
                </Button>
              </Box>
            </Grid>
          </Box>
        </Paper>
        <ReviewTradeModal
          data={data}
          setData={setData}
          open={openReviewModal}
          handleClose={() => setOpenReviewModal(false)}
          onSubmit={handleClose}
        />
      </Box>
    </Modal>
  );
};

export default NewOrderModal;
