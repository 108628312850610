import React from "react";
import {
  Box,
  FormControlLabel,
  Typography,
  styled,
  Checkbox,
} from "@mui/material";
export default function MarketEffect({ data, setData }) {
  const RadioLabel = styled(Typography)(({ theme }) => ({
    fontSize: 14,
  }));

  return (
    <Box rowGap={1} mb={1} display={"flex"} flexDirection={"column"}>
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              size={"small"}
              checked={data?.included === false}
              onChange={(e) => setData({ ...data, included: false })}
            />
          }
          label={
            <RadioLabel>
              Not included in B MKT price statistics, volume, and other metrics.
            </RadioLabel>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              size={"small"}
              checked={data?.included === true}
              onChange={(e) => setData({ ...data, included: true })}
            />
          }
          label={
            <RadioLabel>
              Include in B MKT price statistics, volume, and other metrics.
            </RadioLabel>
          }
        />
      </Box>
      <Box display={"flex"}>
        <FormControlLabel
          control={
            <Checkbox
              size={"small"}
              checked={data?.matchedOrder === false}
              onChange={(e) => setData({ ...data, matchedOrder: false })}
            />
          }
          label={<RadioLabel>{"Unfilled Order"}</RadioLabel>}
        />
        <FormControlLabel
          control={
            <Checkbox
              size={"small"}
              checked={data?.matchedOrder === true}
              onChange={(e) => setData({ ...data, matchedOrder: true })}
            />
          }
          label={<RadioLabel>{"Matched Order"}</RadioLabel>}
        />
      </Box>
    </Box>
  );
}
