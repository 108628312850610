import React from "react";
import {
  Box,
  Typography,
  Link as MUILink,
  Breadcrumbs,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import { StyledContainer } from "../../components/Styled/Globals";
import NotificationsTable from "../../components/Notifications/NotificationsTable";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import "./Notifications.css";
import SearchField from "../../components/Global/SearchField";
import { sampleNotifications } from "../../constants/SampleData/notifications";

const ArchivedNotifications = () => {
  const _breadcrumbs = [
    <MUILink
      underline="hover"
      key="1"
      color="inherit"
      fontSize={20}
      fontWeight={"bold"}
    >
      <Link to={"/notifications"}>Notifications</Link>
    </MUILink>,
    <Typography key="2" color="primary" fontSize={20} fontWeight="bold">
      Archives
    </Typography>,
  ];

  const _headcells = [
    {
      id: "notificationNumber",
      label: "Notification#",
      numeric: true,
      width: "5%",
    },
    {
      id: "date",
      numeric: false,
      label: "Date",
      width: "5%",
    },
    {
      id: "relatedTo",
      numeric: false,
      label: "Related To",
      width: "7%",
    },
    {
      id: "tradeNumber",
      numeric: true,
      label: "Trade#",
      width: "5%",
    },
    {
      id: "subject",
      numeric: false,
      label: "Subject",
      width: "40%",
    },
    {
      id: "action",
      numeric: false,
      label: "Action",
      disableSorting: true,
      width: "10%",
      sx: {
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
        fontSize: "large",
      },
    },
  ];

  const [dates, setDates] = React.useState({
    startDate: new Date("01-01-2000"),
    endDate: new Date(Date.now()),
  });

  return (
    <Box height={"100%"}>
      <StyledContainer>
        <Breadcrumbs
          sx={{ my: 1 }}
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {_breadcrumbs}
        </Breadcrumbs>

        {/* Archived */}
        <Box>
          <Box
            marginBottom={2}
            display={"flex"}
            alignItems="center"
            justifyContent={"flex-end"}
          >
            <SearchField title={"Search..."} noMargin width={"35%"} />
          </Box>
          <Box
            component={"div"}
            display="flex"
            justifyContent={"left"}
            columnGap={10}
            alignItems={"center"}
            marginBottom={2}
          >
            <Typography fontSize={20} fontWeight={"bold"}>
              Date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="From Date"
                inputFormat="MM/dd/yyyy"
                value={dates?.startDate}
                onChange={(startDate) => setDates({ ...dates, startDate })}
                renderInput={(params) => (
                  <TextField
                    className="calender-icon"
                    size="small"
                    value={dates?.startDate}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
            <Typography fontWeight={"bold"} fontSize={20}>
              to
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="To Date"
                inputFormat="MM/dd/yyyy"
                value={dates?.endDate}
                onChange={(endDate) => setDates({ ...dates, endDate })}
                renderInput={(params) => (
                  <TextField
                    className="calender-icon"
                    size="small"
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box component={"div"}>
            <NotificationsTable
              rows={[...sampleNotifications]}
              dates={dates}
              headCells={_headcells}
              tableTitle={null}
              loading={false}
            />
          </Box>
        </Box>
      </StyledContainer>
    </Box>
  );
};

export default ArchivedNotifications;
