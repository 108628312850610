import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Modal,
  Paper,
} from "@mui/material";
import React, { useState } from "react";

import Close from "@mui/icons-material/Close";
import { Heading } from "../../Styled/Globals";
import SearchField from "../../Global/SearchField";
import VerifyMultipleOrdersTable from "../VerifyMultipleOrdersModal/VerifyMultipleOrdersTable";

const CancelOrderSelectionModal = ({
  open,
  handleClose,
  cancelParent,
  data,
}) => {
  const [agree, setAgree] = useState(false);

  const handleSubmit = () => {
    cancelParent();
    handleClose();
  };

  return (
    <Modal open={open} className={"modal"} onClose={handleClose}>
      <Box className={"no-scroll-bar"}>
        <Paper>
          <Box
            component={"div"}
            sx={{
              px: 4,
              py: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Heading>Cancel Multiple Order(s)</Heading>
            <Close onClick={handleClose} sx={{ fontSize: "small" }} />
          </Box>
          <Divider />
          <Box sx={{ px: 4 }}>
            <Box
              display={"flex"}
              justifyContent={"flex-end"}
              width={"100%"}
              my={2}
            >
              <SearchField title={"Search"} noMargin width={500} />
            </Box>
            <Box>
              <VerifyMultipleOrdersTable
                editable={false}
                rows={data}
                headCells={_headcells}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                control={<Checkbox onChange={() => setAgree(!agree)} />}
                label="I agree to cancel these trades. I acknowledge that its possible that these trades were filled during this cancellation request and accept that that is the nature of a market order."
              />
            </Box>
            <Box
              justifyContent={"flex-end"}
              display={"flex"}
              width={"100%"}
              pb={2}
            >
              <Button
                variant={"contained"}
                color={"primary"}
                onClick={handleSubmit}
                disabled={!agree}
              >
                Submit{" "}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

const _headcells = [
  {
    id: "ticker",
    numeric: false,
    disablePadding: true,
    label: "Ticker",
  },

  {
    id: "bid-ask",
    numeric: false,
    disablePadding: true,
    label: "Bid/Ask",
  },

  {
    id: "price",
    numeric: true,
    disablePadding: true,
    label: "Price",
  },
  {
    id: "quantity",
    numeric: true,
    disablePadding: true,
    label: "Quantity",
  },
  {
    id: "orderType",
    numeric: false,
    disablePadding: true,
    label: "Order Type",
  },
  {
    id: "timing",
    numeric: false,
    disablePadding: true,
    label: "Timing",
  },
  {
    id: "limit",
    numeric: true,
    disablePadding: true,
    label: "Limit",
  },
  {
    id: "stop",
    numeric: true,
    disablePadding: true,
    label: "Stop",
  },
  {
    id: "transactions",
    numeric: true,
    disablePadding: true,
    label: "Transactions",
  },
  {
    id: "spotPlusDollar",
    numeric: true,
    disablePadding: true,
    label: "Spot + $#",
  },
  {
    id: "spotXPercentage",
    numeric: true,
    disablePadding: true,
    label: "Spot X (1+%)",
  },
  {
    id: "floor",
    numeric: true,
    disablePadding: true,
    label: "Floor",
  },
  {
    id: "ceiling",
    numeric: true,
    disablePadding: true,
    label: "Ceiling",
  },
  {
    id: "priceBasedOn",
    numeric: true,
    disablePadding: true,
    label: "Price Based On",
  },
];

export default CancelOrderSelectionModal;
