import { Box, Checkbox, Divider, FormControlLabel, Grid } from "@mui/material";
import { Heading, StyledButton, SubHeading } from "../../Styled/Globals";
import React, { useState } from "react";

import UserTimeButton from "./UserTimeButton";

const UserActions = () => {
  const [actions, setActions] = useState([
    {
      // title: "To Market Pages",
      title: "Limit Access",
      actions: [
        { title: "To Market Pages", time: null },
        { title: "To Transactions Page", time: null },
        { title: "To Funding", time: null },
        { title: "No Logging In", time: null },
      ],
    },
    {
      title: "Limit Abilities",
      actions: [
        { title: "Block Orders", time: null },
        { title: "Block Transactions", time: null },
        { title: "Block Withdrawals", time: null },
        { title: "Block Funding", time: null },
      ],
    },
    {
      title: "Restore Access",
      actions: [
        { title: "To Market Pages", time: null },
        { title: "To Funding", time: null },
        { title: "Block Withdrawals", time: null },
        { title: "No Logging In", time: null },
      ],
    },
    {
      title: "Restore Abilities",
      actions: [
        { title: "Block Orders", time: null },
        { title: "Block Transactions", time: null },
        { title: "Block Withdrawals", time: null },
        { title: "Block Funding", time: null },
      ],
    },
  ]);

  const timeOptions = [
    "4 hours",
    "12 hours",
    "24 hours",
    "36 hours",
    "72 hours",
    "Until Restored",
  ];

  const onTimeSelect = (time, title, action) => {
    const newArray = actions.map((__action) => {
      if (__action.title === action.title) {
        __action.actions = __action?.actions?.map((__inner) => {
          if (__inner?.title === title) {
            __inner.time = time;
          }
          return __inner;
        });
      }
      return __action;
    });
    setActions(newArray);
  };

  return (
    <Box>
      <Box pb={1}>
        <Heading>Actions</Heading>
      </Box>
      <Divider />
      <Grid container spacing={4}>
        {actions.map((action, index) => {
          return (
            <Grid item md={3} key={index}>
              <SubHeading sx={{ my: 1, textAlign: "center" }}>
                {action?.title}
              </SubHeading>
              <Box display={"flex"} flexDirection={"column"} rowGap={2}>
                {action?.actions.map((_action) => {
                  return (
                    <FormControlLabel
                      sx={{
                        width: "98%",
                      }}
                      control={<Checkbox />}
                      label={
                        <UserTimeButton
                          key={_action.time}
                          options={timeOptions}
                          id={_action.title}
                          selectedTime={_action.title}
                          extras={_action?.time}
                          bgcolor={"primary"}
                          // selectedTime={selectedTime[market].orders}
                          // column="orders"
                          onTimeSelect={(time) =>
                            onTimeSelect(time, _action.title, action)
                          }
                        />
                      }
                    />
                  );
                  //   <FormControlLabel
                  //     control={<Checkbox />}
                  //     label={
                  //       <Box
                  //         sx={{
                  //           width: 180,
                  //           height: 40,
                  //           bgcolor: "#5F99FF",
                  //           color: "#fff",
                  //           borderRadius: 2,
                  //           textTransform: "none",
                  //           textAlign: "left",
                  //           px: 1,
                  //           ":hover": {
                  //             bgcolor: "primary.dark",
                  //           },
                  //         }}
                  //         display={"flex"}
                  //         alignItems={"center"}
                  //         fontSize={14}
                  //         fontWeight={"bold"}
                  //       >
                  //         {_action}
                  //       </Box>
                  //     }
                  //   />
                  // );
                })}
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <Box
        display={"flex"}
        width={"100%"}
        alignItems={"center"}
        justifyContent={"flex-end"}
        mt={2}
        pb={2}
      >
        <StyledButton
          sx={(theme) => ({
            fontWeight: "normal",
            fontSize: 18,
            height: 50,
            width: "20%",
            [theme.breakpoints.up("xl")]: {
              height: 65,
              width: "25%",
            },
            borderRadius: 3,
          })}
        >
          Submit
        </StyledButton>
      </Box>
    </Box>
  );
};

export default UserActions;
