import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";

import CustomPagination from "../../Common/CustomPagination";
import PropTypes from "prop-types";
import moment from "moment";
import { visuallyHidden } from "@mui/utils";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        borderRadius: 10,
      }}
    >
      <TableRow sx={{}}>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            sx={{
              padding: "0px 0px",
              pl: index === 0 && 1,
              width: headCell?.width || 100,
              verticalAlign: "bottom",
              textAlign: "left",
              background: headCell?.id === "action" && "#5f99ff",
              borderRadius: 10,
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell?.disableSorting ? (
              <Box
                component={"div"}
                sx={(theme) => ({
                  width: "100%",
                  display: "flex",
                  color: theme?.palette?.primary?.main,
                  fontSize: "small",
                  [theme.breakpoints.up("xl")]: {
                    fontSize: "medium",
                  },
                  ...headCell?.sx,
                })}
              >
                {headCell?.label}
              </Box>
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                sx={(theme) => ({
                  fontSize: "small",
                  padding: "5px 0px",
                  display: "flex",
                  lineHeight: 1.5,
                  width: "100%",
                  justifyContent: "flex-start",
                  color: theme?.palette?.primary?.main,
                  "& .MuiTableSortLabel-icon": {
                    margin: "0px",
                    fontSize: "small",
                  },
                  [theme.breakpoints.up("xl")]: {
                    fontSize: "medium",
                    "& .MuiTableSortLabel-icon": {
                      fontSize: "medium",
                    },
                  },
                  ...headCell?.sx,
                })}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function ArchivedArbitrationsTable({ rows, loading }) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [page, setPage] = useState(0);
  const dense = true;
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tableData, setTableData] = useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const StyledTableCell = styled(TableCell)(({ theme, center }) => ({
    padding: "10px 0px",
    paddingRight: "5px",
    fontSize: "14px",
    textAlign: center ? "center" : "left",
    border: "none",
    [theme.breakpoints.up("xl")]: {
      fontSize: "16px",
    },
  }));
  return (
    <Box sx={{ width: "100%", bgcolor: "#fff", mb: 2, borderRadius: 10 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid #c4c4c4",
          borderRadius: 5,
          mb: 1,
        }}
        // minHeight={350}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <TableContainer sx={{ minHeight: "350px" }}>
            <Table sx={{ minWidth: 750 }} size={dense ? "small" : "medium"}>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                headCells={headCells}
              />
              <TableBody>
                {rows.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={10}>
                      <Typography align="center">
                        No Arbitrations Found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : null}

                {stableSort(tableData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    // const objectKeys = Object.keys(row);
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row._id}
                      >
                        <StyledTableCell
                          component="td"
                          id={labelId}
                          scope="row"
                          sx={{ pl: 1 }}
                        >
                          {row?.transactionNumber}
                        </StyledTableCell>

                        <StyledTableCell
                          component="td"
                          id={labelId}
                          scope="row"
                        >
                          {moment(row?.date).format("MM/DD/YYYY")}
                        </StyledTableCell>
                        <StyledTableCell
                          component="td"
                          id={labelId}
                          scope="row"
                        >
                          {row?.mkt}
                        </StyledTableCell>
                        <StyledTableCell
                          component="td"
                          id={labelId}
                          scope="row"
                        >
                          {row?.ticker}
                        </StyledTableCell>
                        <StyledTableCell
                          component="td"
                          id={labelId}
                          scope="row"
                        >
                          {row?.description}
                        </StyledTableCell>

                        <StyledTableCell
                          component="td"
                          id={labelId}
                          scope="row"
                        >
                          {row?.quantity}
                        </StyledTableCell>
                        <StyledTableCell
                          component="td"
                          id={labelId}
                          scope="row"
                        >
                          ${row?.price}
                        </StyledTableCell>
                        <StyledTableCell
                          component="td"
                          id={labelId}
                          scope="row"
                        >
                          ${row?.total}
                        </StyledTableCell>
                        <StyledTableCell
                          component="td"
                          id={labelId}
                          scope="row"
                        >
                          {moment(row?.dateFiled).format("MM/DD/YYYY")}
                        </StyledTableCell>
                        <StyledTableCell
                          component="td"
                          id={labelId}
                          scope="row"
                        >
                          {row?.type}
                        </StyledTableCell>
                        <StyledTableCell
                          component="td"
                          id={labelId}
                          scope="row"
                        >
                          {row?.cause}
                        </StyledTableCell>
                        <StyledTableCell
                          component="td"
                          id={labelId}
                          scope="row"
                        >
                          {row?.status}
                        </StyledTableCell>
                        <StyledTableCell
                          component="td"
                          id={labelId}
                          scope="row"
                        >
                          {moment(row?.dateArchived).format("MM/DD/YYYY")}
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
      <CustomPagination
        rowsPerPage={rowsPerPage}
        rows={rows}
        setTableData={setTableData}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
}
const headCells = [
  {
    id: "transactionNumber",
    numeric: false,
    width: "1%",
    label: "Transaction#",
  },
  {
    id: "date",
    numeric: false,
    width: "2%",
    label: "Transaction Date",
  },
  {
    id: "mkt",
    numeric: false,
    width: "2%",
    label: "MKT",
  },
  {
    id: "ticker",
    numeric: false,
    width: "2%",
    label: "Ticker",
  },
  {
    id: "description",
    numeric: false,
    width: "15%",
    label: "Description",
  },
  {
    id: "quantity",
    numeric: false,
    width: "1%",
    label: "Quantity",
  },
  {
    id: "price",
    numeric: true,
    width: "1%",
    label: "Price",
  },
  {
    id: "total",
    numeric: true,
    width: "1%",
    label: "Total",
  },
  {
    id: "dateFiled",
    numeric: false,
    width: "2%",
    label: "Date Filed",
  },
  {
    id: "type",
    numeric: false,
    width: "2%",
    label: "Type",
  },
  {
    id: "cause",
    numeric: false,
    width: "10%",
    label: "Cause",
  },
  {
    id: "status",
    numeric: false,
    width: "2%",
    label: "Status",
  },
  {
    id: "dateArchived",
    numeric: false,
    width: "5%",
    label: "Date Archived",
  },
];
