import { Box, Typography } from "@mui/material";
import React from "react";
import { Heading } from "../../Styled/Globals";

function ProfileInfo({ title, data }) {
  return (
    <Box>
      <Typography color={"primary"}>{title}</Typography>
      <Heading sx={{ fontSize: 22 }}>{data}</Heading>
    </Box>
  );
}

export default ProfileInfo;
