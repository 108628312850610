import { Box, Button } from "@mui/material";
import React, { useState } from "react";

import FilterListIcon from "@mui/icons-material/FilterList";
import FilterModal from "./FilterModal";
import { Heading } from "../Styled/Globals";
import OpenOrderTable from "../Tables/TradesTable";
import SearchField from "../Global/SearchField";
import TradeDetailsModal from "../CommonModals/TradeDetailsModal/TradeDetailsModal";

const TradeTables = ({ title, rows }) => {
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedData, setSelectedData] = useState(false);

  const handleDetailsModal = (data) => {
    setSelectedData(data);
    setOpenDetailsModal(true);
  };

  return (
    <Box my={4}>
      <TradeDetailsModal
        open={openDetailsModal}
        handleClose={(_) => setOpenDetailsModal(false)}
        data={selectedData}
      />{" "}
      <FilterModal
        open={openFilterModal}
        handleClose={() => setOpenFilterModal(false)}
      />
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        columnGap={10}
      >
        <Heading
          sx={{
            color: "primary.dark",
            textAlign: "left",
            my: 1,
            width: "100%",
          }}
        >
          {title}
        </Heading>
        <SearchField title={"Search"} noMargin width="100%" />
        <Box
          component={"div"}
          width={"100%"}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          <Button
            size="small"
            variant={"contained"}
            color={"primary"}
            sx={(theme) => ({ px: 2 })}
            onClick={() => setOpenFilterModal(true)}
          >
            <Box
              display={"flex"}
              fontSize={20}
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              columnGap={1}
            >
              <FilterListIcon /> Filter
            </Box>
          </Button>
        </Box>
      </Box>
      <Box my={3}>
        <OpenOrderTable
          headCells={headCells}
          noOptions
          rows={[...rows]}
          dense={false}
          onDetailSelect={handleDetailsModal}
        />
      </Box>
    </Box>
  );
};

const headCells = [
  {
    id: "transactionNumber",
    numeric: true,
    width: "3%",
    label: "Order#",
  },
  {
    id: "mkt",
    numeric: false,
    width: "5%",
    label: "MKT",
  },
  {
    id: "ticker",
    numeric: false,
    width: "2%",
    label: "Ticker",
  },
  {
    id: "bidAsk",
    numeric: false,
    width: "2%",
    label: "Bid/Ask",
  },
  {
    id: "description",
    numeric: false,
    width: "35%",
    label: "Description",
  },
  {
    id: "quantity",
    numeric: true,
    width: "5%",
    label: "Quantity",
  },
  {
    id: "price",
    numeric: true,
    width: "5%",
    label: "Price",
  },
  {
    id: "total",
    numeric: true,
    width: "5%",
    label: "Total",
  },
  {
    id: "details",
    numeric: false,
    width: "2%",
    label: "Details",
    disableSorting: true,
    sx: {
      alignItems: "center",
    },
  },
];

export default TradeTables;
