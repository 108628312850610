import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Modal,
  Paper,
} from "@mui/material";

import { Close } from "@mui/icons-material";
import React from "react";
import SearchField from "../../Global/SearchField";
import { StyledButton } from "../../Styled/Globals";
import { SubHeading } from "../../Styled/Globals";

const RemoveMarketModal = ({
  open,
  handleClose,
  handleSubmit,
  data,
  setData,
  onChange,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    border: "none",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    // height: "98%",
    overflowX: "hidden",
    display: "block",
  };

  const markets = ["Sample text here", "Sample text here", "Sample text here"];

  return (
    <Modal open={open} onClose={handleClose}>
      <Box style={style} className={"no-scroll-bar"}>
        <Paper>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              px: 4,
              py: 1,
            }}
          >
            <SubHeading sx={{ width: "100%" }}>Remove Market</SubHeading>
            <Close
              fontSize={"small"}
              className={"pointer"}
              onClick={handleClose}
            />
          </Box>
          <Divider />
          <Box px={4}>
            <SearchField title={"Search by Ticker"} />
            <Box
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              px={3}
              py={0.5}
              border={"1px solid #c4c4c4"}
              borderRadius={1}
            >
              {markets.map((market) => {
                return (
                  <FormControlLabel control={<Checkbox />} label={market} />
                );
              })}
            </Box>
          </Box>
          <Box
            py={2}
            px={4}
            justifyContent={"flex-end"}
            display={"flex"}
            alignItems={"center"}
          >
            <StyledButton
              onClick={handleClose}
              disableElevation
              sx={{
                width: 200,
                fontSize: 20,
              }}
            >
              Submit
            </StyledButton>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default RemoveMarketModal;
