import { styled } from "@mui/system";
import { Button } from "@mui/material";

export const AuthModalButton = styled(Button)({
  color: "#fff",
  display: "block",
  //   height: "40px",
  textTransform: "none",
  fontWeight: "bold",
  fontSize: 20,
  width: "190px",
});
