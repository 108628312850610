import { Box, Modal, Paper } from "@mui/material";

import ActionButtons from "./ActionButtons";
import { Close } from "@mui/icons-material";
import React from "react";
import SingleArbitrationTable from "./SingleArbitrationTable";
import { SubHeading } from "../../../Styled/Globals";

const DetailsModal = ({ open, handleClose, data }) => {
  const [row, setRow] = React.useState(null);

  React.useEffect(() => {
    setRow({ ...data });
    console.log(data);
  }, [open, data]);

  // const row = {
  //   id: 456362,
  //   transactionData: Date.now(),
  //   mkt: "Gold",
  //   ticker: "GLD",
  //   description: "Similar compare",
  //   price: 500,
  //   total: 500,
  //   dateFiled: Date.now(),
  //   type: "Gold",
  //   cause: "-",
  //   status: "Completed",
  // }

  return (
    <Modal open={open} className={"modal"} onClose={handleClose}>
      <Box
        sx={{
          width: "98%",
        }}
        className={"no-scroll-bar"}
      >
        <Paper>
          <Box sx={{ px: 4, pt: 1 }}>
            <Box className={"close-box"} mb={0.5}>
              <SubHeading>Arbitration Case Details</SubHeading>
              <Close
                onClick={handleClose}
                className={"pointer"}
                fontSize={"inherit"}
              />
            </Box>
            <Box
              border={"1px solid #c4c4c4"}
              borderRadius={1}
              paddingBottom={1}
            >
              <SingleArbitrationTable row={row} loading={false} />
            </Box>
            <Box py={2}>
              <ActionButtons data={row} />
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default DetailsModal;
