import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  Button,
} from "@mui/material";
import React from "react";

function ConfirmForm({ formData, setFormData, onChange, handleSubmit }) {
  return (
    <Box
      component={"form"}
      display={"flex"}
      flexDirection={"column"}
      onSubmit={handleSubmit}
    >
      <Box
        component={"div"}
        display={"flex"}
        flexDirection={"column"}
        rowGap={3}
        mb={4}
      >
        <Box width={"100%"}>
          <FormControlLabel
            control={<Checkbox />}
            label={
              <Typography fontSize={20} mt={3}>
                <Typography
                  component={"span"}
                  fontWeight={"bold"}
                  fontSize={22}
                >
                  SETTLE TRADE.
                </Typography>{" "}
                The trade has been delivered and matches the ticker and
                description as well as the pictures or video sent to me prior to
                shipping.
              </Typography>
            }
          />
        </Box>
        <Box width={"100%"}>
          <FormControlLabel
            control={<Checkbox />}
            label={
              <Typography fontSize={20}>
                <Typography
                  component={"span"}
                  fontWeight={"bold"}
                  fontSize={22}
                >
                  ARBITRATE.
                </Typography>{" "}
                I want to Arbitrate this trade.
              </Typography>
            }
          />
        </Box>
      </Box>
      <Box
        width={"100%"}
        alignItems={"center"}
        justifyContent={"flex-end"}
        display={"flex"}
        pb={5}
      >
        <Button
          variant={"contained"}
          color={"primary"}
          sx={{
            py: 2,
            width: "25%",
            textTransform: "uppercase",
          }}
          disableElevation
          type={"submit"}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
}

export default ConfirmForm;
