import { Box, Divider, Modal, Paper, Typography } from "@mui/material";
import { Heading, SubHeading } from "../../Styled/Globals";
import React, { useState } from "react";

import ClaimForm from "./ClaimForm";
import Close from "@mui/icons-material/Close";
import SingleTransactionTable from "../SingleTransactionTable/SingleTransactionTable";

const FileClaimModal = ({ open, handleClose, data, onSubmit }) => {
  const [formData, setFormData] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  return (
    <Modal className={"modal"} open={open} onClose={handleClose}>
      <Box
        style={{
          maxWidth: "95%",
        }}
        className={"no-scroll-bar"}
      >
        <Paper>
          <Box
            className={"b-flex-end"}
            sx={{
              pr: 1,
              pt: 1,
            }}
          >
            <Close onClick={handleClose} className={"c-pointer"} />
          </Box>
          <Box
            component={"div"}
            width={"100%"}
            display={"flex"}
            justifyContent={"center"}
            sx={{
              px: 4,
              pb: 1,
            }}
          >
            <Heading>File Arbitration Claim</Heading>
          </Box>
          <Divider />
          <Box sx={{ px: 4 }}>
            <Box py={1}>
              <SubHeading>Transaction Details</SubHeading>
            </Box>
            <Box
              sx={{
                broder: "1px solid #ccc",
                borderRadius: 5,
              }}
            >
              <SingleTransactionTable loading={false} data={data} />
            </Box>
            <Typography
              sx={(theme) => ({
                fontSize: "medium",
                py: 2,
              })}
            >
              Note: If this is not the transaction you which to arbitrate,
              please go back to the list of Recent Transactions and select
              “Arbitrate” from the proper transaction row to arbitrate the
              proper transaction.
            </Typography>

            <Box>
              <ClaimForm
                formData={formData}
                setFormData={setFormData}
                onChange={onChange}
                handleSubmit={handleSubmit}
              />
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default FileClaimModal;
