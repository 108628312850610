import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";

import CustomPagination from "../../../components/Common/CustomPagination";
import DeliveryModal from "../../../components/ShippingSettlements/DeliveryModal/DeliveryModal";
import FileClaimModal from "../../../components/Arbitration/FileClaimModal/FileClaimModal";
import LabelsModal from "../../../components/ShippingSettlements/LabelsModal";
import PropTypes from "prop-types";
import { visuallyHidden } from "@mui/utils";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            sx={{
              padding: "4px 0px",
              paddingLeft: index === 0 && 2,
              borderRadius: index === 0 && 10,

              width: headCell?.width || 100,
              verticalAlign: "bottom",
              textAlign: "left",
              background: headCell?.id === "action" && "#5f99ff",
              borderTopRightRadius: 5,
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell?.disableSorting ? (
              <Box
                component={"div"}
                sx={(theme) => ({
                  width: "100%",
                  display: "flex",
                  color: theme?.palette?.primary?.main,
                  fontSize: "16px",
                  [theme.breakpoints.up("xl")]: {
                    fontSize: "medium",
                  },
                  ...headCell?.sx,
                })}
              >
                {headCell?.label}
              </Box>
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                sx={(theme) => ({
                  fontSize: "small",
                  padding: "5px 0px",
                  display: "flex",
                  lineHeight: 1.5,
                  width: "100%",
                  justifyContent: "flex-start",
                  columnGap: 0.5,
                  color: theme?.palette?.primary?.main,
                  "& .MuiTableSortLabel-icon": {
                    margin: "0px",
                    fontSize: "small",
                  },
                  [theme.breakpoints.up("xl")]: {
                    fontSize: "medium",
                    "& .MuiTableSortLabel-icon": {
                      fontSize: "medium",
                    },
                  },
                  ...headCell?.sx,
                })}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const StyledTableCell = styled(TableCell)(({ theme, center }) => ({
  padding: "10px 0px",
  paddingRight: "5px",
  fontSize: "14px",
  textAlign: center ? "center" : "left",
  border: "none",
  [theme.breakpoints.up("xl")]: {
    fontSize: "16px",
  },
}));

const StyledActionButton = styled(Button)(({ theme }) => ({
  fontSize: "small !important",
  padding: "5px 8px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "medium !important",
  },
}));

export default function TransactionsTable({
  rows,
  headCells,
  tableTitle,
  loading,
  showDetails = false,
  showId = false,
  onDetailSelect,
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tableData, setTableData] = useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [openArbitrate, setOpenArbitrate] = useState(false);
  const [openLabels, setOpenLabels] = useState(false);
  const [openTracking, setOpenTracking] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleAction = (_data) => {
    console.log("action", _data?.action);
    setSelected(_data);
    switch (_data?.action?.toLowerCase()) {
      case "arbitrate":
        setOpenArbitrate(true);
        break;
      case "print label & packing slip":
        setOpenLabels(true);
        break;
      case "enter tracking number":
        setOpenTracking(true);
        break;
      case "report delivery":
        setOpenReport(true);
        break;
      default:
        console.log("no action found", _data?.action);
        break;
    }
  };

  const handleClose = (_) => {
    setOpenArbitrate(false);
    setOpenLabels(false);
    setOpenTracking(false);
    setOpenReport(false);
    setSelected(null);
  };

  return (
    <Box mb={4} sx={{ width: "100%" }}>
      <FileClaimModal
        open={openArbitrate}
        data={selected}
        handleClose={handleClose}
      />
      <LabelsModal open={openLabels} handleClose={handleClose} />
      <DeliveryModal
        variant={"tracking"}
        title={"Enter Tracking Number"}
        open={openTracking}
        handleClose={handleClose}
      />
      <DeliveryModal
        variant={"report"}
        title={"Report Delivery"}
        open={openReport}
        handleClose={handleClose}
      />

      <Paper sx={{ width: "100%", mb: 2, borderRadius: 3 }}>
        <Box display="flex" justifyContent={"center"} alignContent={"center"}>
          {loading ? (
            <CircularProgress />
          ) : (
            <TableContainer
              sx={{
                minHeight: "360px",
              }}
            >
              <Table sx={{ width: "100%" }} size={"small"}>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  headCells={headCells}
                />
                <TableBody>
                  {rows.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={10}>
                        <Typography align="center">No Transactions</Typography>
                      </TableCell>
                    </TableRow>
                  ) : null}

                  {stableSort(tableData, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      //   const objectKeys = Object.keys(row);
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row._id}
                        >
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                            sx={{
                              pl: 2,
                              pr: 1,
                            }}
                          >
                            {row?.status}
                          </StyledTableCell>
                          {showId && (
                            <StyledTableCell
                              component="td"
                              id={labelId}
                              scope="row"
                            >
                              {row?.transactionNumber}
                            </StyledTableCell>
                          )}
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            <StyledActionButton
                              disableElevation
                              variant={"contained"}
                              color={"primary"}
                              onClick={(_) => handleAction(row)}
                            >
                              {row?.action}
                            </StyledActionButton>
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                            sx={{
                              paddingLeft: "2px",
                            }}
                          >
                            {row?.mkt}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.ticker}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.bidAsk}
                          </StyledTableCell>

                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.description}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.quantity}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            ${row?.price}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            ${row?.total}
                          </StyledTableCell>
                          {showDetails && (
                            <StyledTableCell
                              component={"td"}
                              id={labelId}
                              scope="row"
                            >
                              <StyledActionButton
                                variant={"contained"}
                                color={"primary"}
                                size={"small"}
                                disableElevation
                                onClick={(_) => onDetailSelect(row)}
                              >
                                Details
                              </StyledActionButton>
                            </StyledTableCell>
                          )}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
      <CustomPagination
        rowsPerPage={rowsPerPage}
        rows={rows}
        setTableData={setTableData}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
}
