import { IconButton, InputBase, Paper, useMediaQuery } from "@mui/material";

import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "@mui/material/styles";

function SearchField({
  title,
  icon,
  width = "unset",
  height = "unset",
  noMargin = false,
  handleSubmit,
}) {
  const theme = useTheme();
  const isXL = useMediaQuery(theme.breakpoints.up("xl"));

  console.log("height ====", height);

  return (
    <Paper
      component="form"
      elevation={0}
      sx={{
        border: "1px solid #ccc",
        width: width,
        height: height !== "unset" ? height : isXL ? 50 : 35,
        p: isXL ? "2px 4px" : "1px 2px",
        display: "flex",
        alignItems: "center",
        margin: noMargin ? "" : "25px auto",
        background: "#fff",
      }}
      onSubmit={(e) => {
        e?.preventDefault();
        handleSubmit(e);
      }}
    >
      <IconButton sx={{ p: isXL ? "10px" : "5px" }} aria-label="menu">
        {icon || <SearchIcon />}
      </IconButton>
      <InputBase
        sx={{
          ml: 1,
          flex: 1,
          paddingY: 2,
          color: "#000",
          "& input::placeholder": {
            color: "#000",
            fontSize: isXL ? "18px" : "14px",
          },
          fontSize: isXL ? "18px" : "14px",
        }}
        placeholder={title}
        inputProps={{ "aria-label": "search description ticker" }}
      />
    </Paper>
  );
}

export default SearchField;
