import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import Close from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useRef } from "react";

// import "./AuthModals.css";

const FilterModal = ({ open, handleClose }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const formRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleClose();
  };

  const handleReset = (e) => {
    e.preventDefault();
    setLoading(true);
    setData({
      mkt: "",
      type: "",
      mint: "",
      style: "",
      weight: "",
      purity: "",
      condition: "",
      year: "",
    });
    formRef?.current.reset();
    setLoading(false);
  };

  const handleOnChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });

  return (
    <Modal open={open} className={"modal"} onClose={handleClose}>
      <Box
        sx={(theme) => ({
          width: 850,
        })}
        className={"no-scroll-bar"}
      >
        <Paper>
          <Box
            sx={(theme) => ({
              px: 2,
              py: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            })}
          >
            <Typography color={"#000"} fontSize={26} fontWeight="bold">
              <FilterListIcon /> Filter
            </Typography>
            <Box>
              <Close
                sx={{
                  cursor: "pointer",
                }}
                fontSize={"small"}
                onClick={handleClose}
              />
            </Box>
          </Box>
          <Divider />
          <Box
            component={"form"}
            ref={formRef}
            onSubmit={handleSubmit}
            sx={(theme) => ({
              px: 2,
              py: 4,
            })}
            key={loading}
          >
            <Grid container columnSpacing={2} rowSpacing={4}>
              <Grid item md={4}>
                <TextField
                  label={"MKT"}
                  fullWidth
                  type="text"
                  name={"mkt"}
                  value={data?.mkt}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label={"Type"}
                  fullWidth
                  type="text"
                  name={"type"}
                  value={data?.type}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label={"Mint"}
                  fullWidth
                  type="text"
                  name={"mint"}
                  value={data?.mint}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item md={4}>
                <Autocomplete
                  placeholder="Style"
                  id="style-autocomplete"
                  options={["Style 1", "Style 2", "Style 3"]}
                  getOptionLabel={(style) => `${style}`}
                  onChange={(e, style, reason) =>
                    reason === "selectOption"
                      ? setData({
                          ...data,
                          style,
                        })
                      : setData({
                          ...data,
                          style: "",
                        })
                  }
                  value={data?.style}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label="Style"
                        variant="outlined"
                        value={data?.style}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label={"Weight"}
                  fullWidth
                  type="text"
                  name={"weight"}
                  value={data?.weight}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label={"Purity"}
                  fullWidth
                  type="text"
                  name={"purity"}
                  value={data?.purity}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label={"Condition"}
                  fullWidth
                  type="text"
                  name={"condition"}
                  value={data?.condition}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  label={"Year"}
                  fullWidth
                  type="text"
                  name={"year"}
                  value={data?.year}
                  onChange={handleOnChange}
                />
              </Grid>
            </Grid>

            <Box
              component={"div"}
              mt={5}
              display="flex"
              columnGap={4}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <Button
                onClick={handleReset}
                type={"submit"}
                variant={"contained"}
                disableElevation
                sx={(theme) => ({
                  color: "#5f99ff",
                  display: "block",
                  width: 200,
                  bgcolor: "#fff",
                  border: "2px solid #5f99ff",
                  outline: "none",
                  textTransform: "none",
                  fontWeight: "bold",
                  borderRadius: 2,
                  ":hover": {
                    bgcolor: "#5f99ff",
                    color: "#fff",
                  },
                })}
              >
                Reset
              </Button>
              <Button
                onClick={handleClose}
                type={"submit"}
                variant={"contained"}
                disableElevation
                sx={(theme) => ({
                  color: "#fff",
                  display: "block",
                  border: "2px solid #5f99ff",
                  width: 200,
                  borderRadius: 2,
                  textTransform: "none",
                  fontWeight: "bold",
                  ":hover": {
                    bgcolor: "#5f99ff",
                    color: "#fff",
                  },
                })}
              >
                Apply
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default FilterModal;
