import { Box } from "@mui/material";
import { Heading } from "../../Styled/Globals";
import OpenTradesManagementTable from "./OpenTradesManagementTable";
import React from "react";
import SearchField from "../../Global/SearchField";
import { sampleSimulations } from "../../../constants/SampleData/simulations";

function TradeSimulations() {
  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={3}
      >
        <Heading
          sx={{
            width: "100%",
            fontSize: 18,
          }}
        >
          Open and Closed Simulated Trades
        </Heading>
        <SearchField title={"Search"} noMargin width="100%" />
      </Box>
      <Box>
        <OpenTradesManagementTable
          rows={[...sampleSimulations]}
          loading={false}
        />
      </Box>
    </Box>
  );
}

export default TradeSimulations;
