import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  Button,
} from "@mui/material";
import React from "react";

function ReportForm({ formData, setFormData, onChange, handleSubmit }) {
  return (
    <Box
      component={"form"}
      display={"flex"}
      flexDirection={"column"}
      onSubmit={handleSubmit}
    >
      <Box component={"div"} display={"flex"} columnGap={5} mb={4}>
        <Box width={"100%"}>
          <FormControlLabel
            control={<Checkbox />}
            label={
              <Typography fontSize={20} mt={3}>
                The delivery has arrived per my tracking number provided. I
                recognize that falsely reporting a delivery may negatively
                affect any Arbitration that arises from this trade if the
                delivery did not actually occur per the carrier when I report
                it.
              </Typography>
            }
          />
        </Box>
      </Box>
      <Box
        width={"100%"}
        alignItems={"center"}
        justifyContent={"flex-end"}
        display={"flex"}
        pb={5}
      >
        <Button
          variant={"contained"}
          color={"primary"}
          sx={{
            py: 2,
            width: "25%",
            textTransform: "uppercase",
          }}
          disableElevation
          type={"submit"}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
}

export default ReportForm;
