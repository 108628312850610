import { Button, Typography } from "@mui/material";
import { Heading, StyledContainer } from "../../components/Styled/Globals";
import React, { useState } from "react";

import { ReactComponent as ArbitrationSVG } from "../../assets/arbitration.svg";
import ArhivedModal from "../../components/Arbitration/Archived/ArhivedModal";
import { Box } from "@mui/system";
import CurrentArbitrationsTable from "../../components/Arbitration/CurrentArbitrationsTable";
import EvidenceModal from "../../components/Arbitration/EvidenceModal/EvidenceModal";
import FileClaimModal from "../../components/Arbitration/FileClaimModal/FileClaimModal";
import Hexagon from "../../components/Hexagon/Hexagon";
import QuestionAnswerModal from "../../components/Arbitration/QuestionAnswerModal/QuestionAnswerModal";
import RecentTransactionsTable from "../../components/Tables/RecentTransactionsTable";
import ResponseClaimModal from "../../components/Arbitration/ResponseClaimModal/ResponseClaimModal";
import ScheduleModal from "../../components/Arbitration/ScheduleModal/ScheduleModal";
import SearchField from "../../components/Global/SearchField";
import ThankYouModal from "../../components/Arbitration/ThankYouModal/ThankYouModal";
import { sampleArbitrations } from "../../constants/SampleData/arbitrations";
import { sampleRecentTransactions } from "../../constants/SampleData/transactions";

const Arbitration = () => {
  const [openArchived, setOpenArchived] = useState(false);
  const [openResponseClaimModal, setOpenResponseClaimModal] = useState(false);
  const [openThankYouModal, setOpenThankYouModal] = useState(false);
  const [openQuestionAnswerModal, setOpenQuestionAnswerModal] = useState(false);
  const [openFileClaimModal, setOpenFileClaimModal] = useState(false);
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [openEvidenceModal, setOpenEveidenceModal] = useState(false);
  const [scheduleData, setScheduleData] = useState({
    title: "Schedule Hearing",
    description:
      "The Arbitrator at Bullion MKTS has requested that you schedule an Arbitration Hearing. An Arbitration Hearing lasts 30 minutes or less. Each partyhas the opportunity to spend 5 minutes explaining their case. The Arbitrator then asks questions of each party for 5-10 minutes. Each party may closewith a 2 minute response to what the other party said or clear up anything they want.",
  });

  const [selectedData, setSelectedData] = useState(null);

  const handleArbitrate = (row) => {
    setSelectedData(row);
    setOpenFileClaimModal(true);
  };

  const handleDataSelect = (data) => setSelectedData(data);

  React.useEffect(
    () => console.log("selected Row", selectedData),
    [selectedData]
  );

  const handleMenuSelect = (id) => {
    switch (id) {
      case 1:
        setOpenResponseClaimModal(true);
        break;
      case 2:
        setOpenQuestionAnswerModal(true);
        break;
      case 3:
        setOpenEveidenceModal(true);
        break;
      case 4:
        setScheduleData({
          title: "Schedule Call",
          description:
            "The Arbitrator at Bullion MKTS has requested that you schedule a Phone Call to discuss the situation. An Arbitration Phone Call lasts 30 minutes or less.",
        });
        setOpenScheduleModal(true);
        break;
      case 5:
        setScheduleData({
          title: "Schedule Hearing",
          description:
            "The Arbitrator at Bullion MKTS has requested that you schedule an Arbitration Hearing. An Arbitration Hearing lasts 30 minutes or less. Each partyhas the opportunity to spend 5 minutes explaining their case. The Arbitrator then asks questions of each party for 5-10 minutes. Each party may closewith a 2 minute response to what the other party said or clear up anything they want.",
        });
        setOpenScheduleModal(true);
        break;
      case 6:
        console.log("handle archive");
        break;
      default:
        console.log("out of range selected", id);
        break;
    }
  };

  return (
    <Box height={"100%"} pb={10}>
      {/* MODALS */}
      <ArhivedModal
        open={openArchived}
        handleClose={(_) => setOpenArchived(false)}
      />
      <ResponseClaimModal
        open={openResponseClaimModal}
        handleClose={() => setOpenResponseClaimModal(false)}
        onSubmit={() => {
          setOpenResponseClaimModal(false);
          setOpenThankYouModal(true);
        }}
        data={selectedData}
      />

      <ThankYouModal
        open={openThankYouModal}
        handleClose={() => setOpenThankYouModal(false)}
      />

      <QuestionAnswerModal
        open={openQuestionAnswerModal}
        handleClose={() => setOpenQuestionAnswerModal(false)}
        onSubmit={() => {
          setOpenQuestionAnswerModal(false);
          setOpenThankYouModal(true);
        }}
        data={selectedData}
      />

      <FileClaimModal
        open={openFileClaimModal}
        handleClose={() => setOpenFileClaimModal(false)}
        onSubmit={() => {
          setOpenFileClaimModal(false);
          setOpenThankYouModal(true);
        }}
        data={selectedData}
      />

      <ScheduleModal
        open={openScheduleModal}
        handleClose={() => setOpenScheduleModal(false)}
        onSubmit={() => {
          setOpenScheduleModal(false);
        }}
        scheduleData={scheduleData}
        data={selectedData}
      />

      <EvidenceModal
        open={openEvidenceModal}
        handleClose={() => setOpenEveidenceModal(false)}
        onSubmit={() => {
          setOpenEveidenceModal(false);
        }}
        data={selectedData}
      />

      <StyledContainer>
        <Hexagon
          title={
            <Box
              display="flex"
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={1}
            >
              <ArbitrationSVG
                style={{
                  transform: "scale(0.7)",
                }}
              />
              <Typography color={"primary"} fontWeight={"bold"} fontSize={28}>
                Arbitration
              </Typography>
            </Box>
          }
        />
        <Box
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          my={5}
        >
          <Heading>Current Arbitrations</Heading>
          <SearchField noMargin title={"Search"} width={400} />
        </Box>
        <Box>
          <CurrentArbitrationsTable
            rows={[...sampleArbitrations]}
            onMenuSelect={handleMenuSelect}
            handleDataSelect={handleDataSelect}
          />
          <Box className={"b-flex-end"}>
            <Button
              onClick={(_) => setOpenArchived(true)}
              size={"small"}
              variant={"contained"}
              color={"primary"}
              disableElevation
            >
              Archives
            </Button>
          </Box>
        </Box>
        <br />
        <Box
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
          my={2}
        >
          <Heading>Recent Transactions</Heading>
          <SearchField noMargin title={"Search"} width={400} />
        </Box>
        <Box>
          <RecentTransactionsTable
            rows={[...sampleRecentTransactions]}
            loading={false}
            handleArbitrate={handleArbitrate}
          />
        </Box>
      </StyledContainer>
    </Box>
  );
};

export default Arbitration;
