import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import Close from "@mui/icons-material/Close";
import { Heading } from "../../../components/Styled/Globals";
import SingleOpenOrderTable from "../../../components/Tables/SingleOpenOrderTable";

export default function CancelOpenOrderModal({ open, handleClose, data }) {
  const [confirm, setConfirm] = useState(false);

  useEffect(() => setConfirm(false), [open]);

  return (
    <Modal open={open} onClose={handleClose} className={"modal"}>
      <Box
        className={"no-scroll-bar"}
        sx={{
          width: "75%",
        }}
      >
        <Paper sx={{ py: 1 }}>
          <Box className={"close-box"} sx={{ px: 2 }}>
            <Heading>Cancel Trade</Heading>
            <Close className={"c-pointer"} onClick={handleClose} />
          </Box>
          <Divider />
          <Box
            sx={{
              px: 2,
            }}
          >
            <Box sx={{ py: 2 }}>
              <SingleOpenOrderTable data={data} />
            </Box>
            <Box py={1}>
              <FormControlLabel
                sx={{
                  width: "100%",
                }}
                onChange={(_) => setConfirm((val) => !val)}
                control={<Checkbox value={confirm === true} sx={{ mt: -3 }} />}
                label={
                  <Typography
                    sx={{
                      fontWeight: 500,
                    }}
                  >
                    I agree to cancel this trade. I acknowledge that its
                    possible that this trade was filled during this cancellation
                    request and accept that that is the nature of a market
                    order.
                  </Typography>
                }
              />
            </Box>
            <Box className={"b-flex-end"} pb={1}>
              <Button
                variant={"contained"}
                color={"primary"}
                disableElevation
                onClick={handleClose}
                disabled={!confirm}
                sx={{
                  textTransform: "uppercase",
                  py: 2,
                  width: "25%",
                  borderRadius: 2,
                }}
              >
                Cancel Trade
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
}
