import { Box, Paper, Typography } from "@mui/material";

import Hexagon from "../components/Hexagon/Hexagon";
import React from "react";
import { StyledContainer } from "../components/Styled/Globals";
import { ReactComponent as UserAgreementSVG } from "../assets/user-agreement.svg";

const UserAgreement = () => {
  return (
    <Box height={"100%"} paddingBottom={20}>
      <StyledContainer>
        <Hexagon
          title={
            <Box
              justifyContent={"center"}
              alignItems={"center"}
              columnGap={1.5}
              display="flex"
              width={"100%"}
            >
              <UserAgreementSVG />
              <Typography
                fontWeight={"bold"}
                color={"primary"}
                sx={(theme) => ({
                  [theme.breakpoints.up("xl")]: {
                    fontSize: 26,
                  },
                })}
              >
                Terms &#38; Conditions
              </Typography>
            </Box>
          }
        />

        <Box mt={5}>
          <Paper
            sx={{
              height: "100vh",
              padding: 10,
            }}
          >
            <Typography component={"p"} variant={"p"}>
              Filler text here
            </Typography>
          </Paper>
        </Box>
      </StyledContainer>
    </Box>
  );
};

export default UserAgreement;
