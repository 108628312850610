import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import React, { useState } from "react";

import CustomPagination from "../Common/CustomPagination";
import PropTypes from "prop-types";
import { StyledTableButton } from "../Styled/Globals";
import { alpha } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";

function EnhancedTableHead(props) {
  const { order, orderBy, headCells } = props;
  const theme = useTheme();
  const isXL = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            sx={
              headCell.id === "actions"
                ? {
                    backgroundColor: "#5f99ff",
                    justifyContent: "center",
                    paddingY: isXL ? 1 : 0,
                    paddingX: isXL ? 1 : 0,

                    borderTopRightRadius: 10,
                  }
                : {
                    paddingY: isXL ? 1 : 0,
                    paddingX: isXL ? 1 : 0,
                  }
            }
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <Typography
              fontWeight={"bold"}
              fontSize={isXL ? 18 : 14}
              color={headCell.id === "actions" ? "#fff" : "primary.dark"}
              textAlign="center"
            >
              {headCell.label}
            </Typography>
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={
                headCell.id === "actions"
                  ? {
                      justifyContent: "center",
                      width: "100%",
                      backgroundColor: "#5f99ff",
                      paddingX: 3,
                      paddingY: 0,
                    }
                  : {}
              }
            >
              <Typography
                fontWeight={"bold"}
                fontSize={isXL ? 16 : 14}
                color={headCell.id === "actions" ? "#fff" : "primary.dark"}
              >
                {headCell.label}
              </Typography>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  rows,
  headCells,
  // actions,

  tableTitle,
  loading,
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [tableData, setTableData] = useState([]);
  const dense = true;
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  // 	const selectedIndex = selected.indexOf(name);
  // 	let newSelected = [];

  // 	if (selectedIndex === -1) {
  // 		newSelected = newSelected.concat(selected, name);
  // 	} else if (selectedIndex === 0) {
  // 		newSelected = newSelected.concat(selected.slice(1));
  // 	} else if (selectedIndex === selected.length - 1) {
  // 		newSelected = newSelected.concat(selected.slice(0, -1));
  // 	} else if (selectedIndex > 0) {
  // 		newSelected = newSelected.concat(
  // 			selected.slice(0, selectedIndex),
  // 			selected.slice(selectedIndex + 1)
  // 		);
  // 	}

  // 	setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const theme = useTheme();
  const isXL = useMediaQuery(theme.breakpoints.up("xl"));

  const StyledTableCell = styled(TableCell)({
    textAlign: "center",
    fontSize: isXL ? 16 : 14,
  });

  return (
    <Box mb={4} sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Box
          px={1}
          display="flex"
          justifyContent={"center"}
          alignContent={"center"}
          py={3}
          // minHeight={350}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <TableContainer
              sx={{
                minHeight: rowsPerPage >= 10 ? "520px" : "283px",
              }}
            >
              <Table sx={{ minWidth: 500 }} size={dense ? "small" : "medium"}>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  headCells={headCells}
                />
                <TableBody>
                  {rows.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={10}>
                        <Typography align="center">No Bids</Typography>
                      </TableCell>
                    </TableRow>
                  ) : null}

                  {tableData.map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    // const objectKeys = Object.keys(row);
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row._id}
                        selected={isItemSelected}
                      >
                        <StyledTableCell
                          component="th"
                          padding={isXL ? "normal" : "checkbox"}
                          id={labelId}
                          scope="row"
                        >
                          {row?.mkt}
                        </StyledTableCell>

                        <StyledTableCell
                          component="th"
                          padding={isXL ? "normal" : "checkbox"}
                          id={labelId}
                          scope="row"
                        >
                          {row?.ticker}
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          padding={isXL ? "normal" : "checkbox"}
                          id={labelId}
                          scope="row"
                        >
                          {row?.bidAsk}
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          padding={isXL ? "normal" : "checkbox"}
                          id={labelId}
                          scope="row"
                        >
                          {row?.quantity}
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          padding={isXL ? "normal" : "checkbox"}
                          id={labelId}
                          scope="row"
                        >
                          ${row?.price}
                        </StyledTableCell>

                        <StyledTableCell
                          component="th"
                          padding={isXL ? "none" : "checkbox"}
                          id={labelId}
                          scope="row"
                          sx={{
                            cursor: "pointer",
                            py: "5px !important",
                            textAlign: "center",
                          }}
                        >
                          <StyledTableButton>Details</StyledTableButton>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
      <CustomPagination
        rowsPerPage={rowsPerPage}
        rows={rows}
        setTableData={setTableData}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
}
