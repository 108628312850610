import { Box, Paper } from "@mui/material";
import React from "react";
import { Heading } from "../../Styled/Globals";
import SearchField from "../../Global/SearchField";
import UserDetails from "./UserDetails";
import UserActions from "./UserActions";

const ManageUsers = ({ bRef }) => {
  return (
    <Box ref={bRef}>
      <Box mb={2}>
        <Heading>Manage Users</Heading>
      </Box>
      <Paper
        sx={{
          padding: 4,
        }}
      >
        <Box display={"flex"} width={"100%"}>
          <SearchField title={"Find User"} noMargin width="50%" />
        </Box>
        <UserDetails />
        <UserActions />
      </Paper>
    </Box>
  );
};

export default ManageUsers;
