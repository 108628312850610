import React, { Fragment, useState } from "react";
import { Box, Grid, TextField, Button } from "@mui/material";
import { DataBox, DataLabel } from "../../../components/Styled/Globals";

export default function AddressForm() {
  const [data, setData] = useState(null);
  const handleChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });

  return (
    <Fragment>
      <Grid container columnSpacing={8} rowSpacing={2}>
        <Grid item md={4}>
          <DataBox>
            <DataLabel>First</DataLabel>
            <TextField
              size={"small"}
              fullWidth
              type="text"
              name={"first"}
              value={data?.first}
              onChange={handleChange}
            />
          </DataBox>
        </Grid>
        <Grid item md={4}>
          <DataBox>
            <DataLabel>Middle</DataLabel>
            <TextField
              size={"small"}
              type="text"
              name={"middle"}
              value={data?.middle}
              fullWidth
              onChange={handleChange}
            />
          </DataBox>
        </Grid>
        <Grid item md={4}>
          <DataBox>
            <DataLabel>Last</DataLabel>
            <TextField
              size={"small"}
              type="text"
              name={"last"}
              value={data?.last}
              onChange={handleChange}
              fullWidth
            />
          </DataBox>
        </Grid>
        <Grid item md={12}>
          <DataBox>
            <DataLabel>Address 1</DataLabel>
            <TextField
              size={"small"}
              fullWidth
              type="text"
              name={"addressOne"}
              value={data?.addressOne}
              onChange={handleChange}
            />
          </DataBox>
        </Grid>
        <Grid item md={12}>
          <DataBox>
            <DataLabel>Address 2</DataLabel>
            <TextField
              size={"small"}
              fullWidth
              type="text"
              name={"addressTwo"}
              value={data?.addressTwo}
              onChange={handleChange}
            />
          </DataBox>
        </Grid>
        <Grid item md={4}>
          <DataBox>
            <DataLabel>City</DataLabel>
            <TextField
              size={"small"}
              type="text"
              name={"city"}
              value={data?.city}
              onChange={handleChange}
              fullWidth
            />
          </DataBox>
        </Grid>
        <Grid item md={4}>
          <DataBox>
            <DataLabel>State</DataLabel>
            <TextField
              size={"small"}
              type="text"
              name={"state"}
              value={data?.state}
              onChange={handleChange}
              fullWidth
            />
          </DataBox>
        </Grid>
        <Grid item md={4}>
          <DataBox>
            <DataLabel>Zip</DataLabel>
            <TextField
              size={"small"}
              type="text"
              name={"zip"}
              value={data?.zip}
              onChange={handleChange}
              fullWidth
            />
          </DataBox>
        </Grid>
        <Grid item md={8}>
          <DataBox>
            <DataLabel>Email Address</DataLabel>
            <TextField
              size={"small"}
              name={"email"}
              type={"email"}
              value={data?.email}
              onChange={handleChange}
              fullWidth
            />
          </DataBox>
        </Grid>
        <Grid item md={4}>
          <DataBox>
            <DataLabel>Phone #</DataLabel>
            <TextField
              size={"small"}
              name={"phone"}
              type={"text"}
              value={data?.phone}
              onChange={handleChange}
              fullWidth
            />
          </DataBox>
        </Grid>
      </Grid>
      <Box className={"b-flex-end"}>
        <Button
          sx={{
            width: "20%",
            mt: 2,
          }}
          variant={"contained"}
          color={"primary"}
          onClick={null}
        >
          Save
        </Button>
      </Box>
    </Fragment>
  );
}
