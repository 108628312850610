import { Box, Divider, Modal, Paper } from "@mui/material";

import { Close } from "@mui/icons-material";
import React from "react";
import SimulatedTradeForm from "./SimulatedTradeForm/SimulatedTradeForm";
import { SubHeading } from "../Styled/Globals";

export default function EditOrderModal({
  open,
  handleClose,
  order,
  variant = "Edit",
}) {
  const [data, setData] = React.useState(null);

  React.useEffect(() => setData({ ...order }), [order]);

  return (
    <Modal open={open} onClose={handleClose} className={"modal"}>
      <Box
        className={"no-scroll-bar"}
        sx={{
          maxWidth: "90%",
        }}
      >
        <Paper>
          <Box sx={{ py: 1, px: 2 }} className={"close-box"}>
            <SubHeading>{variant} Open Order for (XXXX Mock User)</SubHeading>
            <Close className={"c-pointer"} onClick={handleClose} />
          </Box>
          <Divider />
          <Box sx={{ px: 1, py: 1 }}>
            <SimulatedTradeForm data={data} setData={setData} />
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
}
