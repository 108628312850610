import { Box, Typography } from "@mui/material";

import { ReactComponent as PDFLogo } from "../../assets/attachments/pdf.svg";
import React from "react";

export default function AttachmentFile({ name, type = "pdf" }) {
  const [icon, setIcon] = React.useState(null);

  React.useState(() => {
    // const _url = "../../assets/attachments/";
    // setIcon(_url + type + ".jpg");
    switch (type) {
      case "pdf":
        setIcon(PDFLogo);
        break;
      default:
        setIcon(null);
    }
  }, []);

  const RenderLogo = () => {
    return type === "pdf" ? <PDFLogo /> : null;
  };

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        columnGap: "10px",
        padding: "10px 20px",
        alignItems: "center",
        borderRadius: "5px",
        border: "1px solid #c4c4c4",
        cursor: "pointer",
        ":hover": {
          borderColor: theme.palette.primary.main,
          transition: "all ease-in-out 300ms",
        },
      })}
    >
      <Box sx={(theme) => ({ display: "flex", alignItems: "center" })}>
        {RenderLogo()}
      </Box>
      <Box>
        <Typography>{name}</Typography>
      </Box>
    </Box>
  );
}
