import React from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import { Link } from "react-router-dom";
import { additionalMarkets, metals } from "../../constants/markets";
// import { StyledContainer } from "../Styled/Globals";
const MetalsGrid = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isXL = useMediaQuery(useTheme().breakpoints.up("xl"));

  return (
    <Paper elevation={0}>
      <Box paddingY={2}>
        <Typography
          variant={"h5"}
          component={"h5"}
          textAlign={"center"}
          fontWeight="bold"
          color={"primary.dark"}
        >
          MKTS
        </Typography>
        <Divider
          style={{
            margin: "5px auto",
            width: "75%",
          }}
        />
        <Container>
          <Grid container rowGap={5}>
            {metals.map((metal) => {
              return (
                <React.Fragment>
                  <Grid
                    item
                    md={4}
                    style={{
                      alignContent: "center",
                    }}
                  >
                    <Link to={metal.link}>
                      <img
                        src={metal.img}
                        alt=""
                        style={{
                          display: "block",
                          margin: "8px auto",
                          width: isXL ? 125 : 95,
                        }}
                      />
                      <Typography
                        component={"p"}
                        sx={(theme) => ({
                          textAlign: "center",
                          fontSize: 18,
                          fontWeight: "bold",
                          [theme.breakpoints.up("xl")]: {
                            fontSize: 20,
                          },
                        })}
                      >
                        {metal.name}
                      </Typography>
                    </Link>
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
          <Button
            id="demo-positioned-button"
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            variant={"contained"}
            style={{
              color: "#fff",
              margin: "auto",
              marginTop: "25px",
              display: "block",
              height: "50px",
              textTransform: "none",
              fontWeight: "bold",
              fontSize: 20,
            }}
          >
            Additional Markets
          </Button>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {additionalMarkets.map((metal) => (
              <Link to={metal.link}>
                <MenuItem sx={{ width: 155 }} onClick={handleClose}>
                  {metal.title}
                </MenuItem>
              </Link>
            ))}
          </Menu>
        </Container>
      </Box>
    </Paper>
  );
};

export default MetalsGrid;
