import React from "react";
import { Paper, Modal, Box, Button, Typography, styled } from "@mui/material";
import { Heading } from "../Styled/Globals";
import SearchField from "../Global/SearchField";
import { Close } from "@mui/icons-material";

export default function MockUserModal({ open, handleClose }) {
  //eslint-disable-next-line
  const [results, setResults] = React.useState([
    "User 1",
    "User 2",
    "User 3",
    "User 4",
  ]);

  const [selected, setSelected] = React.useState(null);

  const ResultElement = styled(Box)(({ theme, selected }) => ({
    width: "100%",
    cursor: "pointer",
    padding: 3,
    background: selected ? "#ccc" : null,
    ":hover": {
      background: "#c4c4c4",
    },
  }));

  return (
    <Modal className={"modal"} open={open} onClose={handleClose}>
      <Box sx={{ width: "35%" }} className={"no-scroll-bar"}>
        <Paper sx={{ p: 2, pt: 1 }}>
          <Box className={"b-flex-end"}>
            <Close className={"c-pointer"} onClick={handleClose} />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box width={"100%"} mb={0.5}>
              <Heading>Choose Existing Mock User</Heading>
            </Box>
            <SearchField width="100%" noMargin title={"Search for Mock User"} />
            <Box my={1} width={"100%"}>
              {results.map((user) => {
                return (
                  <ResultElement
                    onClick={(_) => setSelected(user)}
                    selected={user === selected}
                  >
                    <Typography>{user}</Typography>
                  </ResultElement>
                );
              })}
            </Box>
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={handleClose}
              fullWidth
            >
              Save
            </Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
}
