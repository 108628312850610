export const sampleTransactions = [
  {
    transactionNumber: Math.floor(Math.random() * 59595),
    status: "Closed",
    action: "Arbitrate",
    mkt: "Gold",
    ticker: "AAPL",
    bidAsk: "Bid",
    description:
      "Adipisicing ipsum dolor irure laboris sunt voluptate ipsum qui nostrud duis eu commodo cillum occaecat.",
    quantity: Math.floor(Math.random() * 2424),
    price: Math.floor(Math.random() * 933),
    total: Math.floor(Math.random() * 9393),
  },
  {
    transactionNumber: Math.floor(Math.random() * 59595),
    status: "Awaiting Confirmation",
    action: "Print Label & Packing Slip",
    mkt: "Platinium",
    ticker: "TSLA",
    bidAsk: "Ask",
    description:
      "Sint minim ad nostrud duis elit magna non quis nulla cupidatat consequat minim.",
    quantity: Math.floor(Math.random() * 2424),
    price: Math.floor(Math.random() * 933),
    total: Math.floor(Math.random() * 9393),
  },
  {
    transactionNumber: Math.floor(Math.random() * 59595),
    status: "Awaiting Tracking Number",
    action: "Enter Tracking Number",
    mkt: "Gold",
    ticker: "AAPL",
    bidAsk: "Bid",
    description: "Excepteur sit voluptate consequat non duis.",
    quantity: Math.floor(Math.random() * 2424),
    price: Math.floor(Math.random() * 933),
    total: Math.floor(Math.random() * 9393),
  },
  {
    transactionNumber: Math.floor(Math.random() * 59595),
    status: "Shipped",
    action: "Report Delivery",
    mkt: "Platinium",
    ticker: "TSLA",
    bidAsk: "Ask",
    description:
      "Do minim ullamco consequat sunt cupidatat nisi mollit do labore tempor deserunt commodo sunt.",
    quantity: Math.floor(Math.random() * 2424),
    price: Math.floor(Math.random() * 933),
    total: Math.floor(Math.random() * 9393),
  },
  {
    transactionNumber: Math.floor(Math.random() * 59595),
    status: "Settled",
    action: "Arbitrate",
    mkt: "Gold",
    ticker: "AAPL",
    bidAsk: "Bid",
    description: "Pariatur aliqua Lorem sit aute consectetur.",
    quantity: Math.floor(Math.random() * 2424),
    price: Math.floor(Math.random() * 933),
    total: Math.floor(Math.random() * 9393),
  },
  {
    transactionNumber: Math.floor(Math.random() * 59595),
    status: "Cancelled",
    action: "Arbitrate",
    mkt: "Gold",
    ticker: "AAPL",
    bidAsk: "Bid",
    description: "Pariatur aliqua Lorem sit aute consectetur.",
    quantity: Math.floor(Math.random() * 2424),
    price: Math.floor(Math.random() * 933),
    total: Math.floor(Math.random() * 9393),
  },
];

export const sampleRecentTransactions = [
  {
    status: "Pending",
    transactionNumber: Math.floor(Math.random() * 59595),
    date: new Date("02-02-2018"),
    ticker: "GOLD",
    mkt: "Gold",
    description: "Commodo et sit do commodo.",
    quantity: Math.floor(Math.random() * 2424),
    price: Math.floor(Math.random() * 933),
    total: Math.floor(Math.random() * 9393),
  },
  {
    status: "Pending",
    transactionNumber: Math.floor(Math.random() * 59595),
    date: new Date("03-02-2018"),
    ticker: "PLAT",
    mkt: "Platinum",
    description:
      "Culpa voluptate duis proident aliquip esse consequat officia consectetur cillum.",
    quantity: Math.floor(Math.random() * 2424),
    price: Math.floor(Math.random() * 933),
    total: Math.floor(Math.random() * 9393),
  },
  {
    status: "Pending",
    transactionNumber: Math.floor(Math.random() * 59595),
    date: new Date("04-02-2014"),
    ticker: "SLVR",
    mkt: "Silver",
    description:
      "Consectetur incididunt reprehenderit est incididunt laborum quis eiusmod quis Lorem.",
    quantity: Math.floor(Math.random() * 2424),
    price: Math.floor(Math.random() * 933),
    total: Math.floor(Math.random() * 9393),
  },
  {
    status: "Pending",
    transactionNumber: Math.floor(Math.random() * 59595),
    date: new Date("05-07-2015"),
    ticker: "TSLA",
    mkt: "Gold",
    description: "Duis occaecat et incididunt ad veniam pariatur.",
    quantity: Math.floor(Math.random() * 2424),
    price: Math.floor(Math.random() * 933),
    total: Math.floor(Math.random() * 9393),
  },
  {
    status: "Pending",
    transactionNumber: Math.floor(Math.random() * 59595),
    date: new Date("01-09-2020"),
    ticker: "AAPL",
    mkt: "Gold",
    description:
      "Cillum exercitation laboris do aute quis aliquip do adipisicing elit.",
    quantity: Math.floor(Math.random() * 2424),
    price: Math.floor(Math.random() * 933),
    total: Math.floor(Math.random() * 9393),
  },
  {
    status: "Pending",
    transactionNumber: Math.floor(Math.random() * 59595),
    date: new Date("05-05-2022"),
    ticker: "AAPL",
    mkt: "Gold",
    description: "Proident officia qui mollit magna laboris commodo amet.",
    quantity: Math.floor(Math.random() * 2424),
    price: Math.floor(Math.random() * 933),
    total: Math.floor(Math.random() * 9393),
  },
];
