import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import moment from "moment";

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            valign={"top"}
            sx={{
              verticalAlign: "top",
              paddingX: 2,
              paddingY: 1,
              maxWidth: 100,
            }}
          >
            <Typography
              fontWeight={"bold"}
              fontSize={14}
              textAlign={"start"}
              color={"primary.dark"}
            >
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TemplatesTable({ rows, loading }) {
  // eslint-disable-next-line
  const [page, setPage] = useState(0);
  // eslint-disable-next-line
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }} elevation={0}>
        <Box display="flex" justifyContent={"center"} alignContent={"center"}>
          {loading ? (
            <CircularProgress />
          ) : (
            <TableContainer>
              <Table sx={{ minWidth: 150, border: "none" }}>
                <EnhancedTableHead headCells={headCells} />
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          tabIndex={-1}
                          hover
                          key={row._id}
                          sx={{
                            border: "none",
                          }}
                        >
                          <TableCell
                            component="td"
                            scope="row"
                            sx={{
                              border: "none",
                              textAlign: "left",
                              fontSize: 14,
                              paddingX: 2,
                              py: 1,
                            }}
                            padding={"none"}
                          >
                            {row?.sentTo}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "none",
                              paddingX: 2,
                              textAlign: "left",
                            }}
                            component="td"
                            padding={"none"}
                            scope="row"
                          >
                            {row?.subject}
                          </TableCell>
                          <TableCell
                            sx={{
                              paddingX: 2,
                              border: "none",
                              textAlign: "left",
                            }}
                            component="td"
                            padding={"none"}
                            scope="row"
                          >
                            {row?.templateName}
                          </TableCell>
                          <TableCell
                            sx={{
                              paddingX: 2,
                              border: "none",
                              textAlign: "left",
                            }}
                            component="td"
                            padding={"none"}
                            scope="row"
                          >
                            {moment(row?.daySent).format("DD MMMM, YYYY")}
                          </TableCell>
                          <TableCell
                            sx={{
                              paddingX: 2,
                              border: "none",
                              textAlign: "left",
                            }}
                            component="td"
                            padding={"none"}
                            scope="row"
                          >
                            {moment(row?.timeSent).format("h:mma")}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
    </Box>
  );
}

const headCells = [
  {
    id: "sentTo",
    numeric: false,
    disablePadding: true,
    label: "Sent To",
  },
  {
    id: "subject",
    numeric: false,
    disablePadding: true,
    label: "Subject",
  },
  {
    id: "templateName",
    numeric: false,
    disablePadding: true,
    label: "Template Name",
  },
  {
    id: "daySent",
    numeric: false,
    disablePadding: true,
    label: `Day Sent`,
  },
  {
    id: "timeSent",
    numeric: false,
    disablePadding: true,
    label: "Time Sent",
  },
];
