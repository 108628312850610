import {
  Box,
  Button,
  Chip,
  Divider,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider, TimePicker } from "@mui/lab";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Close } from "@mui/icons-material";
import React from "react";
import { SubHeading } from "../../../Styled/Globals";
import { styled } from "@mui/system";

function ScheduleFrom({ variant, handleClose, user }) {
  const OptionHeading = styled(Typography)(({ theme }) => ({
    fontSize: 16,
    [theme.breakpoints.up("xl")]: {
      fontSize: 18,
    },
    color: "#2152A9",
  }));

  const isXL = useMediaQuery(useTheme().breakpoints.up("xl"));

  return (
    <React.Fragment>
      <Box sx={{ px: 4, py: 1 }} className={"close-box"}>
        <Box>
          <Box display={"flex"} alignItems={"center"} columnGap={1}>
            <SubHeading>
              Request a {variant}{" "}
              {/* <Box
              component={"span"}
              textTransform={"capitalize"}
              bgcolor={"background.dark"}
              px={2}
              alignItems={"center"}
              borderRadius={3}
              color="#fff"
              >
              <Typography
                mb={5}
                component={"span"}
                fontSize={"small"}
                textAlign={"center"}
                >
                {user}
                </Typography>
            </Box> */}
            </SubHeading>
            {user && (
              <Chip
                label={user}
                size="small"
                sx={{
                  backgroundColor: "background.dark",
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              />
            )}
          </Box>
          <Typography fontSize={14}>
            Provide the Following Dates and Time Periods Choices
          </Typography>
        </Box>

        <Close
          className={"pointer"}
          fontSize={"inherit"}
          onClick={handleClose}
        />
      </Box>
      <Divider />
      <Box
        component={"form"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        rowGap={5}
        px={4}
        py={2}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <OptionHeading>Option 1</OptionHeading>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Date"
              inputFormat="MM/dd/yyyy"
              onChange={(e) => {}}
              renderInput={(params) => (
                <TextField
                  className="calender-icon"
                  {...params}
                  size={isXL ? "medium" : "small"}
                />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              onChange={(e) => {}}
              renderInput={(params) => (
                <TextField
                  className="calender-icon"
                  {...params}
                  size={isXL ? "medium" : "small"}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <OptionHeading>Option 2</OptionHeading>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Date"
              inputFormat="MM/dd/yyyy"
              onChange={(e) => {}}
              renderInput={(params) => (
                <TextField
                  className="calender-icon"
                  {...params}
                  size={isXL ? "medium" : "small"}
                />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              onChange={(e) => {}}
              renderInput={(params) => (
                <TextField
                  className="calender-icon"
                  {...params}
                  size={isXL ? "medium" : "small"}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <OptionHeading>Option 3</OptionHeading>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Date"
              inputFormat="MM/dd/yyyy"
              onChange={(e) => {}}
              renderInput={(params) => (
                <TextField
                  className="calender-icon"
                  {...params}
                  size={isXL ? "medium" : "small"}
                />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              onChange={(e) => {}}
              renderInput={(params) => (
                <TextField
                  className="calender-icon"
                  {...params}
                  size={isXL ? "medium" : "small"}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
      </Box>
      <Box
        width={"100%"}
        px={4}
        pb={2}
        alignItems={"center"}
        justifyContent={"flex-end"}
        display={"flex"}
      >
        <Button
          sx={(theme) => ({
            width: "15%",
          })}
          disableElevation
          variant={"contained"}
          color={"primary"}
          type={"submit"}
          onClick={handleClose}
        >
          Send
        </Button>
      </Box>
    </React.Fragment>
  );
}

export default ScheduleFrom;
