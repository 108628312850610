import { Box, Divider, Grid } from "@mui/material";
import React from "react";
import {
  DataHeading,
  DataTypography,
  SectionHeading,
} from "../Styled/TradeModal";

function CounterPartySection() {
  return (
    <Box>
      <SectionHeading>Counter Party</SectionHeading>
      <Divider />
      <Grid container spacing={2} pt={1}>
        <Grid item md={4}>
          <DataHeading>Reputation</DataHeading>
          <DataTypography>-</DataTypography>
        </Grid>
        <Grid item md={4}>
          <DataHeading># of Transactions</DataHeading>
          <DataTypography>4563745274784</DataTypography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CounterPartySection;
