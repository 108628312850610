import React from "react";
import StarIcon from "@mui/icons-material/Star";
import scale from "../../assets/homepage/scale.png";
import { Link } from "react-router-dom";
import { StyledContainer } from "../Styled/Globals";
import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const SecondGrid = ({ setOpenSignUp }) => {
  const trades = [
    {
      starColor: "#ffd700",
      text: "Innovative bid/ask marketplace that organizes precious metals physcial trading around weight, quality, type, and mint with a goal of finding the TRUE market price of precious metals and not the LBMA, Bullion Bank or Central Bank artificial price.",
    },
    {
      starColor: "#c0c0c0",
      text: "Lower fees for sellers drives prices lower for buyers.",
    },

    {
      starColor: "#e5e4e2",
      text: "Funds are 100% available on platform when a trade occurs so zero payment issues for sellers.",
    },
    {
      starColor: "#b87333",
      text: "Protective process for settlement, including seller delayed payments based on experience, credibility, and other factors and documentation required during the process to minimize the chances of fraud.",
    },
    {
      starColor: "#c1c1bb",
      text: "Focused exclusively on precious metals trading in North America, reducing shipping fees, lowering costs for buyers and sellers, and reducing the probability of fraud (mostly from Asia).",
    },
  ];

  const isXL = useMediaQuery(useTheme().breakpoints.up("xl"));

  return (
    <Box
      bgcolor={"white"}
      sx={(theme) => ({
        py: 2,
        [theme.breakpoints.up("xl")]: {
          pt: 2,
          pb: 4,
        },
      })}
    >
      <StyledContainer>
        <Typography
          variant={"h4"}
          component={"h4"}
          sx={(theme) => ({
            fontWeight: "bold",
            textAlign: "center",
            fontSize: 26,
            my: 1,
            [theme.breakpoints.up("xl")]: {
              fontSize: 37,
              my: 2,
            },
          })}
        >
          Why trade precious metals and numismatics on Bullion MKTS?
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={7}>
            <List>
              {trades.map((trade, index) => {
                return (
                  <React.Fragment>
                    <Box
                      display={"flex"}
                      alignContent={"center"}
                      sx={(theme) => ({
                        margin: "10px 0",
                        [theme.breakpoints.up("xl")]: {
                          margin: "20px 0px",
                        },
                      })}
                    >
                      <Box
                        display="flex"
                        flexDirection={"row"}
                        alignItems={"flex-start"}
                      >
                        <StarIcon
                          style={{
                            color: trade.starColor,
                            fontSize: "70px",
                            margin: "0 10px",
                          }}
                        />
                      </Box>
                      <Box display={"flex"} alignItems={"center"}>
                        <Typography
                          sx={(theme) => ({
                            textAlign: "justify",
                            fontSize: 16,
                            [theme.breakpoints.up("xl")]: {
                              fontSize: 18,
                            },
                          })}
                        >
                          {trade.text}
                        </Typography>
                      </Box>
                    </Box>
                    {index + 1 !== trades.length && (
                      <Divider
                        style={{
                          borderColor: "#609bfe",
                          width: "75px",
                          margin: "auto",
                        }}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </List>
          </Grid>
          <Grid item md={5}>
            <Box
              display="flex"
              flexDirection={"column"}
              justifyContent={"space-between"}
              height={"100%"}
              width="100%"
            >
              <Box width={"100%"}>
                <img
                  src={scale}
                  alt="scale"
                  style={{
                    display: "block",
                    width: isXL ? "75%" : "60%",
                    margin: "50px auto",
                  }}
                  // className="center-block "
                />
              </Box>
              <Box
                sx={(theme) => ({
                  mt: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                })}
              >
                <Link
                  to="/howitworks"
                  className="body-link"
                  style={{
                    textAlign: "center",
                    marginBottom: "10px",
                  }}
                >
                  Learn More
                </Link>
                <Button
                  variant={"contained"}
                  sx={{
                    color: "#fff",
                    width: "400px",
                    margin: "auto",
                    // margin: "125px auto",
                    display: "block",
                    height: "50px",
                    textTransform: "none",
                    fontWeight: "bold",
                    fontSize: "20px !important",
                  }}
                  fullWidth
                  disableElevation
                  onClick={() => setOpenSignUp(true)}
                >
                  Sign Up
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </StyledContainer>
    </Box>
  );
};

export default SecondGrid;
