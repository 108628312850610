import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import {
  DataBox,
  DataLabel,
  DisabledTextField,
} from "../../../components/Styled/Globals";
import React, { useState } from "react";

import EditInfoModal from "./EditInfoModal";

const UserDetails = ({ UserDetailSVG, Link, bRef }) => {
  const [data, setData] = useState({
    username: "User 1",
    accountType: "Buyer",
  });
  const handleChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });

  const [openEdit, setOpenEdit] = useState(false);
  const [editVariant, setEditVariant] = useState(null);
  const [editValue, setEditValue] = useState(null);

  const handleEditUserName = () => {
    setEditVariant("username");
    setOpenEdit(true);
    setEditValue(data?.username);
  };

  const handleEditAccountType = () => {
    setEditVariant("account-type");
    setOpenEdit(true);
    setEditValue(data?.accountType);
  };

  const handleEditSubmit = (_val) => {
    setData({
      ...data,
      [editVariant === "username" ? "username" : "accountType"]: _val,
    });
    setEditVariant(null);
    setOpenEdit(false);
  };

  return (
    <Box ref={bRef}>
      <EditInfoModal
        variant={editVariant}
        open={openEdit}
        handleClose={(_) => setOpenEdit(false)}
        handleSubmit={handleEditSubmit}
        value={editValue}
      />

      <Box component={"div"} marginY={2} display="flex" alignItems={"center"}>
        <UserDetailSVG
          style={{
            marginRight: 20,
          }}
        />
        <Typography fontSize={30} fontWeight={"bold"}>
          User Details
        </Typography>
      </Box>

      <Box component={"div"}>
        <Paper style={{ backgroundColor: "#fff", padding: "25px " }}>
          <Grid container columnSpacing={8}>
            <Grid item md={4}>
              <DataBox>
                <DataLabel>User Name</DataLabel>
                <TextField
                  size={"small"}
                  fullWidth
                  type="text"
                  value={data?.username}
                  name={"username"}
                  readOnly={true}
                  sx={{ cursor: "not-allowed" }}
                />
              </DataBox>
              <Link
                textAlign={"end"}
                paddingTop={1}
                onClick={handleEditUserName}
              >
                Edit
              </Link>
            </Grid>
            <Grid item md={4}>
              <DataBox>
                <DataLabel>Date Of Birth</DataLabel>
                <TextField
                  size={"small"}
                  fullWidth
                  type="date"
                  name={"dob"}
                  value={data?.dob}
                  onChange={handleChange}
                />
              </DataBox>
            </Grid>
            <Grid item md={4}>
              <DataBox>
                <DataLabel># of Completed Transactions</DataLabel>
                <DisabledTextField
                  disabled={true}
                  size={"small"}
                  type="number"
                  name={"completedTransactions"}
                  value={data?.completedTransactions}
                  fullWidth
                />
              </DataBox>
            </Grid>
            <Grid item md={4}>
              <DataBox>
                <DataLabel>Account Type</DataLabel>
                <Autocomplete
                  name={"accountType"}
                  size={"small"}
                  options={["Buyer", "Seller", "Trader"]}
                  onChange={(e, accountType, reason) =>
                    reason === "selectOption"
                      ? setData({ ...data, accountType })
                      : setData({ ...data, accountType: "" })
                  }
                  readOnly={true}
                  value={data?.accountType}
                  renderInput={(params) => (
                    <TextField
                      size={"small"}
                      type="text"
                      value={data?.accountType}
                      fullWidth
                      {...params}
                    />
                  )}
                />
                <Link
                  textAlign={"end"}
                  paddingTop={1}
                  onClick={handleEditAccountType}
                >
                  Edit
                </Link>
              </DataBox>
            </Grid>
            <Grid item md={4}>
              <DataBox>
                <DataLabel>Reputation</DataLabel>
                <DisabledTextField
                  disabled={true}
                  size={"small"}
                  type="text"
                  value={data?.reputation}
                  name={"reputation"}
                  onChange={handleChange}
                  fullWidth
                />
              </DataBox>
            </Grid>
            <Grid item md={4}>
              <DataBox>
                <DataLabel>Seller Level</DataLabel>
                <DisabledTextField
                  disabled={true}
                  size={"small"}
                  type="text"
                  value={data?.sellerLevel}
                  name={"sellerLevel"}
                  onChange={handleChange}
                  fullWidth
                />
              </DataBox>
            </Grid>
          </Grid>
          <Box
            display={"flex"}
            width={"100%"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={null}
              sx={{
                width: "20%",
              }}
            >
              Save
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default UserDetails;
