import { Box, Grid, Typography, Button } from "@mui/material";
import React from "react";
import { Heading, StyledTextArea } from "../../Styled/Globals";
import { ReactComponent as CloudSVG } from "../../../assets/cloud.svg";

function EvidenceForm({ formData, setFormData, onChange, handleSubmit }) {
  const uploadRef = React.useRef(null);

  const uploadButton = () => {
    return (
      <Box
        display={"flex"}
        justifyContent={"start"}
        alignItems={"center"}
        width={"100%"}
        height={"100%"}
        border={"1px solid #c4c4c4"}
        borderRadius={1}
        px={2}
        py={1.5}
        className={"pointer"}
        onClick={(e) => uploadRef?.current?.click()}
      >
        <label htmlFor="upload-photo">
          <input
            style={{ display: "none" }}
            id="upload-photo"
            name="upload-photo"
            type="file"
            ref={uploadRef}
          />
          <Box
            display={"flex"}
            width={"100%"}
            height={"100%"}
            alignItems={"center"}
            justifyContent={"center"}
            columnGap={1.5}
          >
            <CloudSVG style={{ transform: "scale(0.9)" }} />
            <Typography
              sx={(theme) => ({
                color: theme?.palette.primary.main,
                textDecoration: "underline",
                fontWeight: "bold",
                fontSize: "medium !important",
              })}
            >
              Upload Now
            </Typography>
          </Box>
        </label>
      </Box>
    );
  };

  return (
    <Box component={"form"} onSubmit={(e) => handleSubmit(e)}>
      <Grid container spacing={4}>
        <Grid item md={12}>
          <Box display={"flex"} flexDirection={"column"}>
            <Heading
              sx={{
                textAlign: "center",
              }}
            >
              Requested Evidence
            </Heading>
            <Box border={"1px solid #c4c4c4"} height={100} width={"100%"}></Box>
          </Box>
        </Grid>
        <Grid item md={3}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            height={"100%"}
            width={"100%"}
            rowGap={3}
          >
            {uploadButton()}
            {uploadButton()}
            {uploadButton()}
          </Box>
        </Grid>
        <Grid item md={9}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <StyledTextArea
              minRows={6.5}
              placeholder={"Notes for attachments"}
              style={{
                padding: 10,
                width: "100%",
              }}
            />
            <Box className={"b-flex-end"}>
              <Button
                color={"primary"}
                variant={"contained"}
                type={"submit"}
                sx={{
                  px: 2,
                  py: 1,
                }}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default EvidenceForm;
