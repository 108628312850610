import React from "react";
import { Box, Modal, Paper, Typography } from "@mui/material";
import Close from "@mui/icons-material/Close";

const ThankYouModal = ({ open, handleClose }) => {
  return (
    <Modal className={"modal"} open={open} onClose={handleClose}>
      <Box
        sx={{
          width: 590,
        }}
        className={"no-scroll-bar"}
      >
        <Paper
          sx={{
            p: 2,
            position: "relative",
          }}
        >
          <Close className={"c-absolute"} onClick={handleClose} />
          <Box pt={2}>
            <Typography component={"p"}>
              Thank you for filing out the Arbitration claim. We will get back
              to you with any questions shortly. Please be advised that you must
              address any questions or document requested within 24 hours or the
              Arbitration will be ruled in the other party's favor.
            </Typography>
            <br />
            <Typography fontWeight={"bold"}>Bullion MKTS</Typography>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default ThankYouModal;
