import { Box, Paper, Typography, useTheme, useMediaQuery } from "@mui/material";
import React from "react";
import Hexagon from "../components/Hexagon/Hexagon";
import { StyledContainer } from "../components/Styled/Globals";
import { ReactComponent as ContactUsSVG } from "../assets/contact-us.svg";
import { Link } from "react-router-dom";

import "../Css/ContactUs.css";

const ContactUs = () => {
  const isXL = useMediaQuery(useTheme().breakpoints.up("xl"));

  return (
    <Box className="contact-us">
      <StyledContainer>
        <Hexagon
          width={isXL ? 500 : 370}
          title={
            <Box
              display="flex"
              width={"100%"}
              justifyContent={"center"}
              // padding={8}
              alignItems={"center"}
            >
              <ContactUsSVG
                style={{
                  transform: "scale(0.8)",
                }}
              />
              <Typography
                fontSize={isXL ? 30 : 29}
                marginLeft={1}
                fontWeight="bold"
                color={"primary"}
              >
                Contact Us
              </Typography>
            </Box>
          }
        />

        <Paper elevation={1} sx={{ mt: 3 }}>
          <Box
            display="flex"
            flexDirection={"column"}
            rowGap={2}
            padding={7}
            fontSize={25}
          >
            <Typography component={"p"} variant={"p"}>
              Bullion MKTS welcomes conversations with anyone who has a unique
              and value-add proposal for the MKTS platform.
            </Typography>
            <Typography component={"p"} variant={"p"}>
              If you are an existing user and need Help, please go to our{" "}
              <Link to={"/help"}>Help</Link> page.
            </Typography>
            <Typography component={"p"} variant={"p"}>
              We're open to conversations regarding any of the following:
            </Typography>
            <ul
              style={{
                rowGap: 5,
                color: "#000",
                "& > *": {
                  padding: 25,
                },
              }}
              className={"list-disc"}
            >
              <li
                className={"list-disc"}
                style={{ color: "#000", listStyleType: "disc" }}
              >
                Selllers Looking to Join and Bring Their Reputation &#38;
                Transactions With Them
              </li>
              <li className={"list-disc"}>
                Partnerships (see our Partnerships page for more information)
              </li>
              <li className={"list-disc"}>
                Affiliate Relationship Opportunities
              </li>
              <li className={"list-disc"}>Sponsorshps/Media Opportunities</li>
              <li className={"list-disc"}>Speaking Opportunities</li>
            </ul>
            <Typography component={"p"} variant={"p"}>
              Please contact us at{" "}
              <Typography
                variant={"a"}
                component={"a"}
                className={"mail-link"}
                href="mailto:info@bullionmkts.com"
              >
                info@bullionmkts.com
              </Typography>{" "}
              and be sure to add to the subject line the purpose of your
              message.
            </Typography>
            <Typography component={"p"} variant={"p"}>
              Someone from Bullion MKTS will contact you as-soon-as-possible.
            </Typography>
            <Typography component={"p"} variant={"p"}>
              Please do not use the Help system for business related inquiries
              as you will not receive a response.
            </Typography>
            <Typography component={"p"} variant={"p"}>
              Thank You.
            </Typography>
            <Typography component={"p"} variant={"p"} fontWeight="bold">
              Bullion MKTS{" "}
            </Typography>
          </Box>
        </Paper>
      </StyledContainer>
    </Box>
  );
};

export default ContactUs;
