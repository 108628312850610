import React, { Fragment, useState } from "react";
import { Box, Typography, Paper, Grid, TextField } from "@mui/material";
import ChangePasswordModal from "../../../components/Settings/Modals/ChangePasswordModal";
import { DataBox, DataLabel } from "../../../components/Styled/Globals";

export default function SignInSecurity({ SignInSecuritySVG, Link, bRef }) {
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  return (
    <Fragment>
      <ChangePasswordModal
        open={openChangePasswordModal}
        handleClose={() => setOpenChangePasswordModal(false)}
      />
      <Box
        component={"div"}
        my={2}
        display="flex"
        alignItems={"center"}
        ref={bRef}
      >
        <SignInSecuritySVG
          style={{
            marginRight: 20,
          }}
        />
        <Typography fontSize={30} fontWeight={"bold"}>
          Sign In &#38; Security
        </Typography>
      </Box>
      <Paper style={{ backgroundColor: "#fff", padding: "25px " }}>
        <Grid container spacing={5}>
          <Grid item md={4}>
            <DataBox>
              <DataLabel>Change User Name</DataLabel>
              <TextField
                size={"small"}
                fullWidth
                type={"password"}
                name={"middleName"}
                onChange={() => {}}
              />
              <Link paddingY={1} textAlign={"end"}>
                Change
              </Link>
            </DataBox>
          </Grid>
          <Grid item md={4}>
            <DataBox>
              <DataLabel>Password</DataLabel>
              <TextField
                size={"small"}
                fullWidth
                type="password"
                name={"middleName"}
                onChange={() => {}}
              />
              <Link
                onClick={() => setOpenChangePasswordModal(true)}
                paddingY={1}
                textAlign={"end"}
                sx={{ cursor: "pointer" }}
              >
                Change
              </Link>
            </DataBox>
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
}
