import { Box, Grid, Paper } from "@mui/material";
import React, { useState } from "react";

import { Heading } from "../../Styled/Globals";
import SearchField from "../../Global/SearchField";
import SearchModal from "./SearchModal";
import TransactionButtons from "./TransactionButtons";
import TransactionTable from "./TransactionTable";

const OrderTransactions = ({ bRef }) => {
  const [openSearch, setOpenSearch] = useState(false);

  const handleSearch = () => setOpenSearch(true);

  return (
    <Box ref={bRef}>
      <SearchModal
        open={openSearch}
        handleClose={(_) => setOpenSearch(false)}
      />
      <Box
        sx={{
          mt: 3,
          mb: 2,
        }}
      >
        <Heading>Orders &#38; Transactions</Heading>
      </Box>
      <Paper
        sx={{
          p: 4,
        }}
      >
        <Box display={"flex"} columnGap={4}>
          <SearchField
            width={350}
            title={"Find Order by User"}
            noMargin
            handleSubmit={handleSearch}
          />
          <SearchField
            width={350}
            title={"Find Transaction by User"}
            handleSubmit={handleSearch}
            noMargin
          />
        </Box>
        <Box>
          <Grid container spacing={4}>
            <Grid item md={3.5}>
              <TransactionButtons />
            </Grid>
            <Grid item md={8.5}>
              <Box mt={5}>
                <TransactionTable loading={false} rows={tableData} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

const tableData = [
  {
    id: 4563745,
    ticker: "Gold",
    mkt: "GLD",
    quantity: 400,
    price: 5000,
    total: 5000,
    action: "Details/Actions",
  },
  {
    id: 4563745,
    ticker: "Gold",
    mkt: "GLD",
    quantity: 400,
    price: 5000,
    total: 5000,
    action: "Edit",
  },
  {
    id: 4563745,
    ticker: "Gold",
    mkt: "GLD",
    quantity: 400,
    price: 5000,
    total: 5000,
    action: "Details/Actions",
  },
  {
    id: 4563745,
    ticker: "Gold",
    mkt: "GLD",
    quantity: 400,
    price: 5000,
    total: 5000,
    action: "Edit",
  },
  {
    id: 4563745,
    ticker: "Gold",
    mkt: "GLD",
    quantity: 400,
    price: 5000,
    total: 5000,
    action: "Details/Actions",
  },
  {
    id: 4563745,
    ticker: "Gold",
    mkt: "GLD",
    quantity: 400,
    price: 5000,
    total: 5000,
    action: "Edit",
  },
  {
    id: 4563745,
    ticker: "Gold",
    mkt: "GLD",
    quantity: 400,
    price: 5000,
    total: 5000,
    action: "Details/Actions",
  },
  {
    id: 4563745,
    ticker: "Gold",
    mkt: "GLD",
    quantity: 400,
    price: 5000,
    total: 5000,
    action: "Edit",
  },
  {
    id: 4563745,
    ticker: "Gold",
    mkt: "GLD",
    quantity: 400,
    price: 5000,
    total: 5000,
    action: "Details/Actions",
  },
  {
    id: 4563745,
    ticker: "Gold",
    mkt: "GLD",
    quantity: 400,
    price: 5000,
    total: 5000,
    action: "Edit",
  },
];

export default OrderTransactions;
