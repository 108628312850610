import React from "react";
import {
  Modal,
  Box,
  Paper,
  Divider,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import { Heading } from "../../../components/Styled/Globals";
import BasicForm from "../../../components/MarketSimulations/SimulatedTradeForm/BasicForm";

export default function EditOpenOrderModal({ open, handleClose, data }) {
  return (
    <Modal className={"modal"} open={open} onClose={handleClose}>
      <Box
        className={"no-scroll-bar"}
        sx={{
          maxWidth: "65%",
        }}
      >
        <Paper>
          <Box
            className={"close-box"}
            sx={{
              px: 2,
              py: 1,
            }}
          >
            <Heading>Edit Trade</Heading>
            <Close className={"c-pointer"} onClick={handleClose} />
          </Box>
          <Divider />
          <Box
            sx={{
              px: 2,
              py: 1,
            }}
          >
            <BasicForm formData={data} />
            <Box py={2}>
              <FormControlLabel
                sx={{
                  width: "100%",
                }}
                control={<Checkbox sx={{ mt: -6 }} />}
                label={
                  "I recognize and agree that this trade may be filled during the editing of the trade process and that I remain contractually liable for the trade if this occured. I also recognize and agree that this modified or edited trade represents an order that I am legally and contractually liable to fulfill if the order meets a corresponding order and a trade occurs."
                }
              />
            </Box>
            <Box>
              <Button
                variant={"contained"}
                color={"primary"}
                disableElevation
                fullWidth
                sx={{
                  py: 2,
                }}
                onClick={handleClose}
              >
                REVIEW &#38; SUBMIT
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
}
