import { Box, Divider, Modal, Paper } from "@mui/material";

import { Close } from "@mui/icons-material";
import React from "react";
import SearchField from "../../Global/SearchField";
import { SubHeading } from "../../Styled/Globals";
import TemplatesTable from "./TemplatesTable";

const EditTemplate = ({
  open,
  handleClose,
  title,
  clickHandler,
  handleSubmit,
}) => {
  return (
    <Modal open={open} onClose={handleClose} className={"modal"}>
      <Box className={"no-scroll-bar"} width={700}>
        <Paper>
          <Box
            display={"flex"}
            alignItems="center"
            justifyContent={"flex-end"}
            p={1}
          >
            <Close fontSize="small" className="pointer" onClick={handleClose} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              px: 4,
              pb: 2,
              alignItems: "center",
            }}
          >
            <SubHeading sx={{ width: "100%" }}>{title} Template</SubHeading>
            <SearchField
              width={"100%"}
              noMargin
              title={"Search"}
              height={"45px"}
            />
          </Box>
          <Divider />
          <Box px={4} py={2}>
            <Box border={"1px solid #c4c4c4"} borderRadius={1}>
              <TemplatesTable
                variant={"load"}
                clickHandler={(data) => clickHandler(data)}
                rows={templatesData}
                loading={false}
              />
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

const templatesData = [
  {
    templateName: "Buy Gold",
    subject: "Buy Gold for me",
    dateCreated: Date.now(),
  },
  {
    templateName: "Buy Gold",
    subject: "Buy Gold for me",
    dateCreated: Date.now(),
  },
  {
    templateName: "Buy Gold",
    subject: "Buy Gold for me",
    dateCreated: Date.now(),
  },
  {
    templateName: "Buy Gold",
    subject: "Buy Gold for me",
    dateCreated: Date.now(),
  },
  {
    templateName: "Buy Gold",
    subject: "Buy Gold for me",
    dateCreated: Date.now(),
  },
  {
    templateName: "Buy Gold",
    subject: "Buy Gold for me",
    dateCreated: Date.now(),
  },
];

export default EditTemplate;
