import { Box, Button, Modal, Paper, Typography } from "@mui/material";

import Close from "@mui/icons-material/Close";
import React from "react";
import { SubHeading } from "../../Styled/Globals";

export default function RemoveUserModal({ open, handleClose }) {
  const handleConfirm = (_) => handleClose();

  return (
    <Modal className={"modal"} open={open} onClose={handleClose}>
      <Box
        className={"no-scroll-bar"}
        sx={(theme) => ({
          maxWidth: 550,
          [theme.breakpoints.up("xl")]: {
            maxWidth: 800,
          },
        })}
      >
        <Paper
          sx={(theme) => ({
            p: 2,
          })}
        >
          <Box sx={{ paddingBottom: 2 }} className={"close-box"}>
            <SubHeading>Confirm Removal of (XXXX) Mock User</SubHeading>
            <Close className={"c-pointer"} onClick={handleClose} />
          </Box>
          <Box>
            <Typography
              sx={(theme) => ({
                fontSize: "large",
                mb: 2,
              })}
            >
              Please confirm the removal of this user from the platform.
            </Typography>
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={handleConfirm}
              disableElevation
              fullWidth
              sx={(theme) => ({ textTransform: "uppercase" })}
            >
              Confirm
            </Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
}
