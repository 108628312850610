import React, { useState } from "react";
import { Box, Paper, Grid, TextField, Typography } from "@mui/material";
import { StyledButton } from "../../Styled/Globals";

function PriceNotificationForm({ onEditExisting }) {
  const [data, setData] = useState({});

  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

  return (
    <Box my={5}>
      <Paper
        sx={{
          p: 5,
        }}
      >
        <Grid container spacing={4}>
          <Grid item md={6}>
            <TextField
              fullWidth
              name={"ticker"}
              value={data?.ticker}
              label={"Find Ticker"}
              onChange={onChange}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              fullWidth
              name={"name"}
              value={data?.name}
              label={"Notification Name"}
              onChange={onChange}
            />
          </Grid>
          <Grid item md={10}>
            <Box>
              <Typography fontSize={18} fontWeight={"bold"} mb={3}>
                Choose one of three choices
              </Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  type={"number"}
                  name={"priceAbove"}
                  value={data?.priceAbove}
                  label={"Price Above"}
                  onChange={onChange}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  type={"number"}
                  name={"priceBelow"}
                  value={data?.priceBelow}
                  label={"Price Below"}
                  onChange={onChange}
                />
              </Grid>

              <Grid item md={12}>
                {/* Price Range Container */}

                <Box>
                  <Typography fontWeight={"bold"} mb={3}>
                    Price Range
                  </Typography>
                </Box>
                <Grid container spacing={4}>
                  <Grid item md={5.5}>
                    <TextField
                      fullWidth
                      type={"number"}
                      name={"low"}
                      value={data?.low}
                      onChange={onChange}
                    />
                  </Grid>
                  <Grid item md={1}>
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      height={"100%"}
                    >
                      <Typography fontWeight={"bold"}>to</Typography>
                    </Box>
                  </Grid>
                  <Grid item md={5.5}>
                    <TextField
                      fullWidth
                      type={"number"}
                      name={"high"}
                      value={data?.high}
                      onChange={onChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <StyledButton
                sx={{
                  height: 75,
                  width: 300,
                  fontSize: 18,
                }}
                onClick={() => onEditExisting()}
              >
                Edit Existing Notifications
              </StyledButton>
              <StyledButton
                sx={{
                  height: 75,
                  width: 300,
                  fontSize: 22,
                }}
                onClick={() => setData(null)}
              >
                Save{" "}
              </StyledButton>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default PriceNotificationForm;
