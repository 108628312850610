import {
  Box,
  Button,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import React, { useState } from "react";

import { StyledContainer } from "../Styled/Globals";
import protectionInfoGraphics from "../../assets/homepage/protection-info-graphics.png";
import ImageModal from "../CommonModals/ImageModal";

function ProtectionGrid({ setOpenSignUp }) {
  const isXL = useMediaQuery(useTheme().breakpoints.up("xl"));
  const [openImg, setOpenImg] = useState(false);

  return (
    <StyledContainer>
      <ImageModal
        open={openImg}
        handleClose={(_) => setOpenImg(false)}
        src={protectionInfoGraphics}
        alt={"protections info graphics"}
      />
      <Box sx={(theme) => ({ margin: "auto", textAlign: "center", pt: 2 })}>
        <Typography
          color={"primary"}
          variant={"h4"}
          sx={(theme) => ({
            fontSize: 33,
            fontWeight: "bold",
            width: "fit-content",
            margin: "auto",
            [theme.breakpoints.up("xl")]: {
              fontSize: 40,
            },
          })}
        >
          Buyer &#38; Seller Protections
          <Divider
            sx={(theme) => ({
              borderColor: "#609bfe",
              blockSize: "fit-content",
              margin: "10px auto",
              [theme.breakpoints.up("xl")]: {
                margin: "15px auto",
              },
            })}
          />
        </Typography>

        <Box
          sx={(theme) => ({
            margin: "15px auto",
            width: 650,
            [theme.breakpoints.up("xl")]: {
              margin: "20px auto",
              width: 900,
            },
          })}
        >
          <Typography
            component={"h5"}
            variant={"h5"}
            sx={(theme) => ({
              color: "#404040",
              fontWeight: "bold",
              textAlign: "center",
              fontSize: 18,
              [theme.breakpoints.up("xl")]: {
                fontSize: 22,
              },
            })}
          >
            Bullion MKTS, along with its Policies, was designed after much
            research to materially reduce if not elliminate the vast majority of
            potential problems. <br /> See{" "}
            <Link
              to={"/protections"}
              style={{
                color: "#5f99ff",
              }}
            >
              Protections
            </Link>{" "}
            for more information.
          </Typography>
        </Box>
      </Box>
      <img
        src={protectionInfoGraphics}
        alt="protectionInfoGraphics"
        className="center-block pointer"
        width={isXL ? "60%" : "55%"}
        onClick={(_) => setOpenImg(true)}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
        paddingY={2}
      >
        <Box
          width={"50%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent="flex-end"
          gap={5}
        >
          <Link
            to="/protections"
            className="body-link"
            style={{
              textAlign: "center",
            }}
          >
            Learn More
          </Link>
          <Button
            variant={"contained"}
            color={"primary"}
            style={{
              textTransform: "none",
              fontWeight: "bold",
              color: "#fff",
              width: 350,
              display: "block",
              height: "50px",
            }}
            onClick={() => setOpenSignUp(true)}
            disableElevation
          >
            Sign Up
          </Button>
        </Box>
      </Box>
    </StyledContainer>
  );
}

export default ProtectionGrid;
