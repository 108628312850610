import { Typography, styled } from "@mui/material";

export const MainHeading = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: 22,
  [theme.breakpoints.up("xl")]: {
    fontSize: 28,
  },
}));

export const SectionHeading = styled(Typography)({
  fontSize: 16,
  fontWeight: "bold",
  color: "#000",
  marginTop: "7px",
});

export const DataHeading = styled(Typography)(({ theme }) => ({
  color: "#5F99FF",
  // margin: "16px 0",
  fontWeight: "bold",
  fontSize: 11.5,
  [theme.breakpoints.up("xl")]: {
    fontSize: 14,
  },
}));

export const DataTypography = styled(Typography)(({ theme }) => ({
  fontSize: 12.5,
  fontWeight: "bold",
  color: "#000",
  [theme.breakpoints.up("xl")]: {
    fontSize: 16,
  },
}));
