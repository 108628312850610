import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Modal,
  Paper,
} from "@mui/material";
import React, { useState } from "react";

import Close from "@mui/icons-material/Close";
import { Heading } from "../../Styled/Globals";
import NewMultipleOrdersTable from "./NewMultipleOrdersTable";
import VerifyMultipleOrdersModal from "../VerifyMultipleOrdersModal/VerifyMultipleOrdersModal";

const NewMultipleOrdersModal = ({ open, handleClose }) => {
  const [dataCount, setDataCount] = useState(1);
  const [data, setData] = useState({
    1: {
      _id: Date.now(),
      ticker: "",
      bidAsk: "",
      price: 0,
      quantity: 0,
      orderType: "",
      timing: "",
      limit: 0,
      stop: 0,
      transactions: 0,
      spotPlusDollar: "",
      spotXPercentage: "",
      floor: 0,
      ceiling: 0,
      priceBasedOn: 0,
    },
  });
  const [openReviewModal, setOpenReviewModal] = useState(false);
  //   const [data, setData] = useState({});

  const addRow = () => {
    setData({
      ...data,
      [dataCount + 1]: {
        _id: Date.now(),
        ticker: "",
        bidAsk: "",
        price: 0,
        quantity: 0,
        orderType: "",
        timing: "",
        limit: 0,
        stop: 0,
        transactions: 0,
        spotPlusDollar: "",
        spotXPercentage: "",
        floor: 0,
        ceiling: 0,
        priceBasedOn: 0,
      },
    });
    setDataCount(dataCount + 1);
  };

  const onChange = (e, index, row) => {
    setData({
      ...data,
      [index + 1]: {
        ...row,
        [e.target.name]: e.target.value,
      },
    });
  };

  return (
    <Modal open={open} className={"modal"} onClose={handleClose}>
      <Box sx={{ width: "min(100%, 1900px)" }} className={"no-scroll-bar"}>
        <Paper>
          <Box
            component={"div"}
            sx={(theme) => ({
              px: 2,
              pt: 2,
              pb: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            })}
          >
            <Heading>Create Multiple New Order</Heading>
            <Close
              onClick={handleClose}
              sx={(theme) => ({
                cursor: "pointer",
                fontSize: "small",
              })}
            />
          </Box>
          <Divider />
          <Box
            sx={(theme) => ({
              px: 2,
              mt: 4,
            })}
          >
            <NewMultipleOrdersTable
              rows={data}
              setData={setData}
              headCells={_headcells}
              onChange={onChange}
            />
            <Box
              sx={{
                my: 3,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box width={"90%"}>
                <FormControlLabel
                  control={<Checkbox sx={{ mt: -3 }} />}
                  label="I agree that if any pf these trade orders find a counter party to the trade that I am forming a legal contract with the counter party. I am expected to deliver either currency as a buyer or physical bullion as described above as a seller for each transaction that occurs. Breech of contract is subject to legal action and suspension or termination from Bullion MKTS."
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="I agree that any trades on Bullion MKTS are subject to Arbitration by either party and that Arbitration decisions are final."
                />
              </Box>
              <Box
                sx={(theme) => ({
                  width: "10%",
                  display: "flex",
                  flexDirection: "column",
                })}
              >
                <Button
                  sx={{
                    bgcolor: "#35952D",
                    ":hover": {
                      bgcolor: "#5CB85C",
                    },
                  }}
                  disableElevation
                  onClick={() => addRow()}
                >
                  Add More
                </Button>
              </Box>
            </Box>
            <Box justifyContent={"flex-end"} display={"flex"} pb={2}>
              <Button
                disableElevation
                variant={"contained"}
                color={"primary"}
                sx={{
                  width: "20%",
                  textTransform: "uppercase",
                  alignItems: "center",
                }}
                onClick={() => setOpenReviewModal(true)}
              >
                Review &#38; Submit
              </Button>
            </Box>
          </Box>
        </Paper>
        <VerifyMultipleOrdersModal
          data={data}
          open={openReviewModal}
          handleSubmit={(_) => {
            setOpenReviewModal(false);
            handleClose();
          }}
          handleClose={() => setOpenReviewModal(false)}
        />
      </Box>
    </Modal>
  );
};

const _headcells = [
  {
    id: "ticker",
    numeric: false,
    disablePadding: true,
    label: "Ticker",
  },

  {
    id: "bid-ask",
    numeric: false,
    disablePadding: true,
    label: "Bid/Ask",
  },

  {
    id: "price",
    numeric: true,
    disablePadding: true,
    label: "Price",
  },
  {
    id: "quantity",
    numeric: true,
    disablePadding: true,
    label: "Quantity",
  },
  {
    id: "orderType",
    numeric: false,
    disablePadding: true,
    label: "Order Type",
  },
  {
    id: "timing",
    numeric: false,
    disablePadding: true,
    label: "Timing",
  },
  {
    id: "limit",
    numeric: true,
    disablePadding: true,
    label: "Limit",
  },
  {
    id: "stop",
    numeric: true,
    disablePadding: true,
    label: "Stop",
  },
  {
    id: "transactions",
    numeric: true,
    disablePadding: true,
    label: (
      <Box>
        Reputation/
        <br /># of Transactions
      </Box>
    ),
  },
  {
    id: "spotPlusDollar",
    numeric: true,
    disablePadding: true,
    label: "Spot + $#",
  },
  {
    id: "spotXPercentage",
    numeric: true,
    disablePadding: true,
    label: "Spot X (1+%)",
  },
  {
    id: "floor",
    numeric: true,
    disablePadding: true,
    label: "Floor",
  },
  {
    id: "ceiling",
    numeric: true,
    disablePadding: true,
    label: "Ceiling",
  },
  {
    id: "priceBasedOn",
    numeric: true,
    disablePadding: true,
    label: "Price Based On",
  },
];

export default NewMultipleOrdersModal;
