import {
  Box,
  Container,
  Divider,
  Grid,
  Link as MuiLink,
  Paper,
  Typography,
} from "@mui/material";

import { Link } from "react-router-dom";
import React from "react";
import callImg from "../../assets/help/call.png";
import chatImg from "../../assets/help/chat.png";
import emailImg from "../../assets/help/email.png";

const HelpGrid = () => {
  const helpLinks = [
    {
      title: "How it works",
      link: "/howitworks",
    },
    {
      title: "Policies",
      link: "/policies",
    },
    {
      title: "Buyer & Seller Protections",
      link: "/protections",
    },
    {
      title: "Tickers",
      link: "/knowledge/markets/ticker",
    },
    {
      title: "After a Trade",
      link: "/",
    },
    {
      title: "Settlement Process",
      link: "/ss",
    },
    {
      title: "Article 1",
      link: "/help/article",
    },
    {
      title: "Article 2",
      link: "/help/article",
    },
    {
      title: "Article 3",
      link: "/help/article",
    },
  ];

  return (
    <Container
      sx={{
        mt: 5,
      }}
    >
      <Typography
        color={"primary.dark"}
        sx={(theme) => {
          return {
            mb: 3,
            fontWeight: "bold",
            fontSize: 26,
            [theme.breakpoints.up("xl")]: {
              fontSize: 30,
            },
          };
        }}
      >
        Self Help
      </Typography>
      <Grid container spacing={4} pb={5}>
        {helpLinks.map((help) => {
          return (
            <Grid item sm={12} md={4} width={"100%"}>
              <Link className="dark-link" to={help.link}>
                <Paper
                  sx={{
                    padding: 7,
                    textAlign: "center",
                    bgcolor: "#fff",
                  }}
                  elevation={5}
                >
                  <Typography fontWeight={"bold"} fontSize={18}>
                    {help.title}
                  </Typography>
                </Paper>
              </Link>
            </Grid>
          );
        })}
      </Grid>
      <Divider />
      <Box pb={10} pt={3}>
        <Typography
          color={"primary.dark"}
          sx={(theme) => {
            return {
              mb: 3,
              fontWeight: "bold",
              fontSize: 26,
              [theme.breakpoints.up("xl")]: {
                fontSize: 30,
              },
            };
          }}
        >
          In-Person Help
        </Typography>
        <Grid container spacing={4}>
          <Grid item sm={12} md={4}>
            <InPersonHelpBox
              src={chatImg}
              text={
                <Typography
                  fontSize={20}
                  variant="p"
                  component={"p"}
                  textAlign="center"
                >
                  Chat is available
                  <Typography
                    fontWeight={"bold"}
                    variant="span"
                    component={"span"}
                    color={"primary"}
                  >
                    {" "}
                    Monday-Saturday{" "}
                  </Typography>
                  from
                  <Typography
                    fontWeight={"bold"}
                    variant="span"
                    component={"span"}
                    color={"primary"}
                  >
                    {" "}
                    8am to 5pm{" "}
                  </Typography>
                  Central Time.It may take a few minutes for us to join.
                </Typography>
              }
              btnText="Chat"
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <InPersonHelpBox
              src={emailImg}
              text={
                <>
                  <Typography
                    fontSize={20}
                    variant="p"
                    component={"p"}
                    textAlign="center"
                  >
                    Email is available 24/7 but it may take up to 24 hours for
                    us to get back to you. We will respond as quickly as
                    possible.
                  </Typography>
                  <Typography
                    textAlign={"center"}
                    sx={{
                      textDecoration: "underline",
                    }}
                  >
                    <MuiLink
                      style={{
                        textAlign: "center",
                        fontSize: 20,
                      }}
                      href="mailto:help@bullionmkts.com"
                    >
                      help@bullionmkts.com
                    </MuiLink>
                  </Typography>
                </>
              }
              btnAction={() =>
                (window.location.href = "mailto:help@bullionmkts.com")
              }
              btnText={"Email"}
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <InPersonHelpBox
              src={callImg}
              btnText={"Phone"}
              text={
                <Typography
                  fontSize={20}
                  variant="p"
                  component={"p"}
                  textAlign="center"
                >
                  If you want to call us you can from
                  <Typography
                    fontWeight={"bold"}
                    variant="span"
                    component={"span"}
                    color={"primary"}
                  >
                    {" "}
                    M-F 8am-5pm{" "}
                  </Typography>
                  Central Time but we may be busy or on the other line. Please
                  leave a message if we are notable to take your call. We will
                  get back to you ASAP.
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default HelpGrid;

const InPersonHelpBox = ({ src, text, btnText, btnAction }) => (
  <Box display={"flex"} flexDirection={"column"} rowGap={4}>
    <img src={src} alt={text} width={"50%"} className="center-block" />
    <Box
      sx={(theme) => ({
        height: 120,
      })}
    >
      {text}
    </Box>
    <MuiLink
      style={{
        color: "#fff",
        cursor: "pointer",
      }}
    >
      <Paper
        sx={{
          padding: 7,
          backgroundColor: "background.dark",
          mt: 2,
        }}
        elevation={5}
        onClick={btnAction || null}
      >
        <Typography
          variant={"p"}
          component={"p"}
          fontWeight={900}
          fontSize={38}
          textAlign="center"
          color={"#fff"}
        >
          {btnText}
        </Typography>
      </Paper>
    </MuiLink>
  </Box>
);
