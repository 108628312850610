import { Box, Button, Divider, Modal, Paper } from "@mui/material";
import React, { useState } from "react";

import { Close } from "@mui/icons-material";
import { SubHeading } from "../../../Styled/Globals";
import { styled } from "@mui/lab/node_modules/@mui/system";

const StatusModal = ({ open, handleClose }) => {
  const DarkButton = styled(Button)(({ theme, selected }) => ({
    backgroundColor: selected ? "#fff" : "#2152A9",
    fontSize: selected ? "18px !important" : "16px !important",
    color: selected ? "#2152A9" : "#fff",
    width: "100%",
    border: selected ? "4px solid #2152A9" : null,
    ":hover": {
      backgroundColor: selected ? "#fff" : "#609bfe",
      color: selected ? "#609bfe" : "#fff",
      border: selected ? "4px solid #609bfe" : null,
    },
  }));

  const [selected, setSelected] = useState(null);

  const buttons = [
    "Filed",
    "In Review",
    "Statement Requested",
    "Documents Requested",
    "Call Requested",
    "On Hold",
    "Arbitration Hearing",
    "Decision Pending",
    "Decided",
  ];

  React.useEffect(() => setSelected(null), [open]);

  return (
    <Modal open={open} onClose={handleClose} className="modal">
      <Box width={400} className={"no-scroll-bar"}>
        <Paper>
          <Box sx={{ px: 4, py: 1 }} className={"close-box"}>
            <SubHeading>Manually Change Status</SubHeading>
            <Close onClick={handleClose} className={"c-pointer"} />
          </Box>
          <Divider />
          <Box
            px={4}
            py={2}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            rowGap={1.5}
          >
            {buttons.map((button) => (
              <DarkButton
                variant={"contained"}
                size={"small"}
                onClick={(_) => setSelected(button)}
                selected={selected === button}
              >
                {button}
              </DarkButton>
            ))}
            <Button
              color={"primary"}
              disableElevation
              variant={"contained"}
              sx={(theme) => ({
                width: "100%",
                mt: 1,
                fontSize: "20px !important",
                [theme.breakpoints.up("xl")]: {
                  fontSize: "22px !important",
                },
              })}
              onClick={handleClose}
            >
              Save
            </Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default StatusModal;
