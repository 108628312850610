import { Close } from "@mui/icons-material";
import { Box, Modal, Paper, Typography, styled } from "@mui/material";
import React from "react";

const FundingInfoModal = ({ open, handleClose }) => {
  const ModalTypo = styled(Typography)(({ theme }) => ({
    fontSize: 20,
    [theme.breakpoints.up("xl")]: {
      fontSize: 21,
    },
  }));

  return (
    <Modal open={open} onClose={handleClose} className={"modal"}>
      <Box sx={(theme) => ({ maxWidth: "50%" })} className={"no-scroll-bar"}>
        <Paper sx={{ px: 5, py: 2 }}>
          <Box className="b-flex-end">
            <Close
              className={"c-pointer"}
              onClick={handleClose}
              sx={{
                mr: -1,
              }}
            />
          </Box>
          <ModalTypo component={"p"}>
            If you would like to avoid fees and your acquisitions being recorded
            in the banking system, please mail a money order or check to fund
            your account to the below address. Please include your full name and
            account number.
          </ModalTypo>
          <br />
          <ModalTypo component={"p"}>
            You will receive a notification of when your money order or check
            arrives and then receive an additional notification when your funds
            are available for trading.
          </ModalTypo>
          <br />
          <ModalTypo component={"p"}>
            Please make your payment out to Bullion MKTS LLC.
          </ModalTypo>
          <br />
          <ModalTypo component={"p"} fontWeight={"bold"}>
            Address to Send Payments to:
          </ModalTypo>
          <ModalTypo component={"p"}>Bullion MKTS, LLC</ModalTypo>
          <ModalTypo component={"p"}>P.O. Box 2122</ModalTypo>
          <ModalTypo component={"p"}>Saint Louis, MO 63158-2122</ModalTypo>
        </Paper>
      </Box>
    </Modal>
  );
};

export default FundingInfoModal;
