import { Box, Button, Divider, Modal, Paper } from "@mui/material";
import { StyledTextArea, SubHeading } from "../../../Styled/Globals";

import { Close } from "@mui/icons-material";
import React from "react";

const ArbitrationNotes = ({ open, handleClose }) => {
  return (
    <Modal open={open} className={"modal"} onClose={handleClose}>
      <Box
        sx={{
          width: 650,
        }}
        className={"no-scroll-bar"}
      >
        <Paper>
          <Box className={"close-box"} sx={{ px: 4, py: 1 }}>
            <SubHeading>Arbitrators Notes</SubHeading>
            <Close className={"c-pointer"} onClick={handleClose} />
          </Box>
          <Divider />
          <Box px={4} mt={2} mb={1}>
            <StyledTextArea
              name={"notes"}
              minRows={10}
              style={{
                width: "100%",
                padding: 10,
              }}
            />
          </Box>
          <Box
            px={4}
            pb={2}
            display={"flex"}
            width={"100%"}
            justifyContent={"flex-end"}
          >
            <Box width={"50%"} display={"flex"} justifyContent={"flex-end"}>
              <Button
                disableElevation
                variant={"contained"}
                width={"100%"}
                onClick={handleClose}
                sx={{
                  textTransform: "initial",
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default ArbitrationNotes;
