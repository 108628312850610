import { Box, Button, Divider, Modal, Paper } from "@mui/material";

import Close from "@mui/icons-material/Close";
import { Heading } from "../../Styled/Globals";
import PriceNotificationsTable from "./PriceNotificationsTable";
import React from "react";

const ManagePriceNotificationsModal = ({ open, handleClose }) => {
  return (
    <Modal open={open} className={"modal"} onClose={handleClose}>
      <Box sx={{ width: "min(90%, 1900px)" }} className={"no-scroll-bar"}>
        <Paper sx={{ width: "100%" }}>
          <Box
            component={"div"}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              px: 4,
              py: 2,
            }}
          >
            <Heading>Manage Price Notifications</Heading>
            <Close
              onClick={handleClose}
              sx={(theme) => ({
                cursor: "pointer",
                fontSize: "small",
              })}
            />
          </Box>
          <Divider />
          <Box sx={{ px: 4, py: 2 }}>
            <PriceNotificationsTable
              headCells={_headcells}
              rows={_data}
              loading={false}
              tableTitle={null}
            />
            <Box justifyContent={"flex-end"} display={"flex"} width={"100%"}>
              <Button
                disableElevation
                variant={"contained"}
                color={"primary"}
                sx={{ width: "25%" }}
                onClick={() => handleClose()}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

const _data = [
  {
    name: "Stoploss",
    ticker: "GLD",
    mkt: "GOLD",
    description: "Old is Gold",
    priceAbove: 5000,
    priceBelow: 4500,
    low: 4500,
    high: 5000,
  },
  {
    name: "Stoploss",
    ticker: "GLD",
    mkt: "GOLD",
    description: "Old is Gold",
    priceAbove: 5000,
    priceBelow: 4500,
    low: 4500,
    high: 5000,
  },
  {
    name: "Stoploss",
    ticker: "GLD",
    mkt: "GOLD",
    description: "Old is Gold",
    priceAbove: 5000,
    priceBelow: 4500,
    low: 4500,
    high: 5000,
  },
  {
    name: "Stoploss",
    ticker: "GLD",
    mkt: "GOLD",
    description: "Old is Gold",
    priceAbove: 5000,
    priceBelow: 4500,
    low: 4500,
    high: 5000,
  },
  {
    name: "Stoploss",
    ticker: "GLD",
    mkt: "GOLD",
    description: "Old is Gold",
    priceAbove: 5000,
    priceBelow: 4500,
    low: 4500,
    high: 5000,
  },
];

const _headcells = [
  {
    id: "notification-name",
    numeric: false,
    disablePadding: true,
    label: "Notification Name",
  },
  {
    id: "ticker",
    numeric: false,
    disablePadding: true,
    label: "Ticker",
  },
  {
    id: "mkt",
    numeric: false,
    disablePadding: true,
    label: "MKT",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Description",
  },
  {
    id: "price-above",
    numeric: false,
    disablePadding: true,
    label: "Price Above",
  },
  {
    id: "price-below",
    numeric: false,
    disablePadding: true,
    label: "Price Below",
  },
  {
    id: "range-low",
    numeric: true,
    disablePadding: true,
    label: "Low",
  },
  {
    id: "range-high",
    numeric: true,
    disablePadding: true,
    label: "High",
  },
  {
    id: "edit",
    numeric: false,
    disablePadding: true,
    label: "",
  },
  {
    id: "delete",
    numeric: false,
    disablePadding: true,
    label: "",
  },
];

export default ManagePriceNotificationsModal;
