import { Box, Button, Typography } from "@mui/material";

import { Link } from "react-router-dom";
import React from "react";
import ShippingSettlementTable from "./ShippingSettlementTable";

function ShippingSettlement({ SVG, bRef }) {
  return (
    <Box ref={bRef}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          component={"div"}
          marginBottom={2}
          display="flex"
          alignItems={"center"}
          width={"100%"}
        >
          <SVG
            style={{
              marginRight: 20,
            }}
          />
          <Typography fontSize={30} fontWeight={"bold"}>
            Shipping &#38; Settlement
          </Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          alginItems={"center"}
          width="100%"
        >
          <Button variant={"contained"} color={"primary"}>
            <Link to={"/ss"}>
              <Typography
                color={"#fff"}
                sx={{
                  textTransform: "uppercase",
                }}
              >
                Full Dashboard
              </Typography>
            </Link>
          </Button>
        </Box>
      </Box>

      <Box component={"div"}>
        <ShippingSettlementTable
          rows={[
            {
              transactionNumber: Math.floor(Math.random() * 10000),
              mkt: "Gold",
              ticker: "AAPL",
              description: "Selling all my assets",
              quantity: Math.floor(Math.random() * 50),
              price: Math.floor(Math.random() * 1000),
              total: Math.floor(Math.random() * 1000),
            },
            {
              transactionNumber: Math.floor(Math.random() * 10000),
              mkt: "Gold",
              ticker: "AAPL",
              description: "Selling all my assets",
              quantity: Math.floor(Math.random() * 50),
              price: Math.floor(Math.random() * 1000),
              total: Math.floor(Math.random() * 1000),
            },
            {
              transactionNumber: Math.floor(Math.random() * 10000),
              mkt: "Gold",
              ticker: "AAPL",
              description: "Selling all my assets",
              quantity: Math.floor(Math.random() * 50),
              price: Math.floor(Math.random() * 1000),
              total: Math.floor(Math.random() * 1000),
            },
            {
              transactionNumber: Math.floor(Math.random() * 10000),
              mkt: "Gold",
              ticker: "AAPL",
              description: "Selling all my assets",
              quantity: Math.floor(Math.random() * 50),
              price: Math.floor(Math.random() * 1000),
              total: Math.floor(Math.random() * 1000),
            },
            {
              transactionNumber: Math.floor(Math.random() * 10000),
              mkt: "Gold",
              ticker: "AAPL",
              description: "Selling all my assets",
              quantity: Math.floor(Math.random() * 50),
              price: Math.floor(Math.random() * 1000),
              total: Math.floor(Math.random() * 1000),
            },
            {
              transactionNumber: Math.floor(Math.random() * 10000),
              mkt: "Gold",
              ticker: "AAPL",
              description: "Selling all my assets",
              quantity: Math.floor(Math.random() * 50),
              price: Math.floor(Math.random() * 1000),
              total: Math.floor(Math.random() * 1000),
            },
            {
              transactionNumber: Math.floor(Math.random() * 10000),
              mkt: "Gold",
              ticker: "AAPL",
              description: "Selling all my assets",
              quantity: Math.floor(Math.random() * 50),
              price: Math.floor(Math.random() * 1000),
              total: Math.floor(Math.random() * 1000),
            },
          ]}
          headCells={[
            {
              id: "transactionNumber",
              numeric: false,
              label: "Transaction#",
              width: "7%",
            },
            {
              id: "ticker",
              numeric: false,
              label: "Ticker",
              width: "7%",
            },
            {
              id: "description",
              numeric: false,
              label: "Description",
              width: "20%",
            },
            {
              id: "mkt",
              numeric: false,
              label: "MKT",
              width: "7%",
            },

            {
              id: "quantity",
              numeric: true,
              label: "Quantity",
              width: "7%",
            },
            {
              id: "price",
              numeric: true,
              label: "Price",
              width: "7%",
            },
            {
              id: "total",
              numeric: true,
              label: "Total",
              width: "7%",
            },
            {
              id: "options",
              numeric: false,
              label: "",
              disableSorting: true,
              width: "5%",
            },
            {
              id: "action",
              numeric: false,
              label: "Action",
              disableSorting: true,
              width: "20%",
              sx: {
                alignItems: "center",
                justifyContent: "center",
                fontSize: "large",
                color: "#fff",
                paddingY: 1,
              },
            },
          ]}
          tableTitle={null}
          loading={false}
        />
      </Box>
    </Box>
  );
}

export default ShippingSettlement;
