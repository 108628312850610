import React from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
} from "@mui/material";
import { StyledCheckboxLabel } from "../../../components/Styled/Forms";
import { Heading } from "../../../components/Styled/Globals";

export default function SecurityQuestions() {
  return (
    <Box my={2}>
      <Heading mb={2}>Security Questions</Heading>
      <Paper style={{ backgroundColor: "#fff", padding: "25px " }}>
        <Typography variant={"p"} component={"p"} fontSize={20}>
          Select Your Security Question and Your Correct Answer
        </Typography>
        <Grid container spacing={5}>
          <Grid item md={12}>
            <Box
              display={"grid"}
              sx={{ margin: "15px auto", fontWeight: "bold" }}
              className={"security-questions"}
              rowGap={1}
            >
              <FormControlLabel
                control={<Checkbox />}
                label={
                  <StyledCheckboxLabel>
                    What was the first type of car you drove?
                  </StyledCheckboxLabel>
                }
              />
              <TextField
                size={"small"}
                type="text"
                name={"middleName"}
                sx={{
                  width: "85%",
                  margin: "auto",
                }}
                value={""}
                onChange={() => {}}
              />
              <FormControlLabel
                control={<Checkbox />}
                label={
                  <StyledCheckboxLabel>
                    What is your favorite musician's name?
                  </StyledCheckboxLabel>
                }
              />
              <TextField
                size={"small"}
                type="text"
                name={"middleName"}
                sx={{
                  width: "85%",
                  margin: "auto",
                }}
                value={""}
                onChange={() => {}}
              />
              <FormControlLabel
                control={<Checkbox />}
                label={
                  <StyledCheckboxLabel>
                    What is the name of your favorite teacher?
                  </StyledCheckboxLabel>
                }
              />
              <TextField
                size={"small"}
                type="text"
                name={"middleName"}
                sx={{
                  width: "85%",
                  margin: "auto",
                }}
                value={""}
                onChange={() => {}}
              />
            </Box>
          </Grid>
        </Grid>
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent={"end"}
          width={"100%"}
        >
          <Button variant={"contained"} color={"primary"} sx={{ width: 200 }}>
            Save
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
