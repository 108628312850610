// import usePagination from "../../hooks/usePagination";

import { Box, MenuItem, Pagination, Select } from "@mui/material";
import React, { useState } from "react";

import service from "../../servicies/index";
import { useEffect } from "react";

export default function CustomPagination({
  rowsPerPage,
  setTableData,
  rows,
  handleChangePage,
  handleChangeRowsPerPage,
  setCurrentData,
}) {
  // const [perPage, setPerPage] = useState([]);
  let [pagination, setPagination] = useState({
    count: 0,
    from: 0,
    to: rowsPerPage,
  });
  const [paginationCount, setPaginationCount] = useState(0);

  const handleChange = (e, page = 1, perPage = null) => {
    const _perPage = perPage || rowsPerPage;
    console.log("_perPage", _perPage);
    const from = (page - 1) * _perPage;
    const to = (page - 1) * _perPage + _perPage;
    setPagination({ ...pagination, from, to });
  };

  useEffect(() => {
    service
      .getData({ rows, from: pagination.from, to: pagination.to })
      .then((res) => {
        setTableData(res.data);
        setPagination({ ...pagination, count: res.count });
      });

    //eslint-disable-next-line
  }, [pagination.from, pagination.to, rowsPerPage]);

  useEffect(() => {
    const _count = Math.ceil(pagination.count / rowsPerPage);
    console.log("pages changed", _count);
    setPaginationCount(_count);
  }, [rows, pagination, rowsPerPage]);

  useEffect(() => {
    setPagination({ ...pagination, count: rows.length });
    // handleChange(null, 1, rowsPerPage);
    // handleChange(null, 2, rowsPerPage);
  }, [rows]);

  const rowsChange = (e) => {
    handleChangeRowsPerPage(e);
    handleChange(e, 1, e.target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        px: 5,
      }}
      key={paginationCount}
    >
      <Box>
        Showing 1 - {rowsPerPage} out of {rows.length}
      </Box>
      <Pagination
        shape="rounded"
        onChange={handleChange}
        count={paginationCount}
        sx={(theme) => ({
          "& .Mui-selected": {
            background: "#fff !important",
            color: theme.palette.primary.main,
          },
        })}
      />
      {/* <Pagination
        count={count}
        size="large"
        page={page}
        variant="outlined"
        shape="rounded"
        onChange={handleChange}
      /> */}
      <Box>
        Show rows{" "}
        <Select
          variant="outlined"
          size="small"
          value={rowsPerPage}
          onChange={rowsChange}
        >
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={15}>15</MenuItem>
          <MenuItem value={20}>20</MenuItem>
        </Select>
      </Box>
    </Box>
  );
}
