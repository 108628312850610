import { Box, Modal, Paper } from "@mui/material";

import { Close } from "@mui/icons-material";
import OpenOrderTable from "../../../views/MyAccount/OpenOrder/OpenOrderTable";
import React from "react";
import SearchField from "../../Global/SearchField";
import { SubHeading } from "../../Styled/Globals";
import TransactionsTable from "../../Tables/TransactionsTable";

export default function UserOrdersModal({
  open,
  handleClose,
  variant = "orders",
}) {
  return (
    <Modal className={"modal"} open={open} onClose={handleClose}>
      <Box
        className={"no-scroll-bar"}
        sx={(theme) => ({
          width: "max(80%, 900px)",
        })}
      >
        <Paper
          sx={(theme) => ({
            p: 2,
          })}
        >
          <Box>
            <Box
              sx={(theme) => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              })}
            >
              <SubHeading>
                {" "}
                {variant === "orders"
                  ? "Outstanding Orders for (XXX) User"
                  : "Transactions for (XXX) User"}{" "}
              </SubHeading>
              <Close
                fontSize="small"
                onClick={handleClose}
                sx={{ cursor: "pointer" }}
              />
            </Box>
            <Box
              sx={(theme) => ({
                display: "flex",
                justifyContent: "flex-end",
                mb: 2,
              })}
            >
              <SearchField title={"Search"} width={"35%"} noMargin />
            </Box>
            <Box>
              {variant === "orders" ? (
                <OpenOrderTable
                  rows={[
                    {
                      orderNumber: 1234567890,
                      mkt: "Gold",
                      ticker: "AAPL",
                      bidAsk: 800,
                      description: "Reference site about in lorem email",
                      quantity: 200,
                      price: 1000,
                      total: 1000,
                    },
                    {
                      orderNumber: 4356783514,
                      mkt: "Platinum",
                      ticker: "PLAT",
                      bidAsk: 800,
                      description: "Reference site about in lorem email",
                      quantity: 500,
                      price: 2000,
                      total: 3500,
                    },
                    {
                      orderNumber: 8473920582,
                      mkt: "Silver",
                      ticker: "SLVR",
                      bidAsk: 830,
                      description: "Reference site about in lorem email",
                      quantity: 250,
                      price: 1030,
                      total: 1020,
                    },
                    {
                      orderNumber: 2984769302,
                      mkt: "Gold",
                      ticker: "AAPL",
                      bidAsk: 800,
                      description: "Reference site about in lorem email",
                      quantity: 200,
                      price: 1000,
                      total: 1000,
                    },
                    {
                      orderNumber: 1385739584,
                      mkt: "Gold",
                      ticker: "AAPL",
                      bidAsk: 800,
                      description: "Reference site about in lorem email",
                      quantity: 200,
                      price: 1000,
                      total: 1000,
                    },
                    {
                      orderNumber: 9585746303,
                      mkt: "Gold",
                      ticker: "AAPL",
                      bidAsk: 800,
                      description: "Reference site about in lorem email",
                      quantity: 200,
                      price: 1000,
                      total: 1000,
                    },
                  ]}
                  headCells={[
                    {
                      id: "orderNumber",
                      numeric: false,
                      width: "10%",
                      label: "Order#",
                    },
                    {
                      id: "mkt",
                      numeric: false,
                      width: "5%",
                      label: "MKT",
                    },
                    {
                      id: "ticker",
                      numeric: false,
                      width: "2%",
                      label: "Ticker",
                    },
                    {
                      id: "bidAsk",
                      numeric: false,
                      width: "2%",
                      label: "Bid/Ask",
                    },
                    {
                      id: "description",
                      numeric: false,
                      width: "25%",
                      label: "Description",
                    },
                    {
                      id: "quantity",
                      numeric: true,
                      width: "5%",
                      label: "Quantity",
                    },
                    {
                      id: "price",
                      numeric: true,
                      width: "5%",
                      label: "Price",
                    },
                    {
                      id: "total",
                      numeric: true,
                      width: "5%",
                      label: "Total",
                    },
                    {
                      id: "options",
                      numeric: false,
                      width: "5%",
                      label: "",
                      disableSorting: true,
                    },
                    {
                      id: "action",
                      numeric: false,
                      width: "15%",
                      label: "Action",
                      disableSorting: true,
                      sx: {
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "large",
                        color: "#fff",
                        paddingY: 1,
                      },
                    },
                  ]}
                  tableTitle={null}
                  loading={false}
                />
              ) : (
                <TransactionsTable loading={false} rows={_data} />
              )}
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
}
const _data = [
  {
    status: "Pending",
    action: "Arbitrate",
    mkt: "Gold",
    ticker: "AAPL",
    bidAsk: 800,
    description: "Selling my all assets",
    quantity: 200,
    price: 1000,
    total: 1000,
  },
  {
    status: "Success",
    action: "Print Label & Packing Slip",
    mkt: "Platinium",
    ticker: "TSLA",
    bidAsk: 800,
    description: "Selling my all assets",
    quantity: 200,
    price: 1000,
    total: 1000,
  },
  {
    status: "Pending",
    action: "Enter Tracking Number",
    mkt: "Gold",
    ticker: "AAPL",
    bidAsk: 800,
    description: "Selling my all assets",
    quantity: 200,
    price: 1000,
    total: 1000,
  },
  {
    status: "Success",
    action: "Report Delivery",
    mkt: "Platinium",
    ticker: "TSLA",
    bidAsk: 800,
    description: "Selling my all assets",
    quantity: 200,
    price: 1000,
    total: 1000,
  },
  {
    status: "Pending",
    action: "Arbitrate",
    mkt: "Gold",
    ticker: "AAPL",
    bidAsk: 800,
    description: "Selling my all assets",
    quantity: 200,
    price: 1000,
    total: 1000,
  },
  {
    status: "Pending",
    action: "Arbitrate",
    mkt: "Gold",
    ticker: "AAPL",
    bidAsk: 800,
    description: "Selling my all assets",
    quantity: 200,
    price: 1000,
    total: 1000,
  },
];
