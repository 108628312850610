import MarketComponent from "../../components/Markets/MarketComponent";
import React from "react";
import iridiumImg from "../../assets/metals/small/iridium.png";

function IridiumMarket() {
  const _bidData = [
    {
      orderNumber: 1010,
      mkt: "Iridium",
      ticker: "IRD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Iridium",
      ticker: "IRD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Iridium",
      ticker: "IRD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Iridium",
      ticker: "IRD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Iridium",
      ticker: "IRD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Iridium",
      ticker: "IRD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Iridium",
      ticker: "IRD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Iridium",
      ticker: "IRD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Iridium",
      ticker: "IRD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Iridium",
      ticker: "IRD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Iridium",
      ticker: "IRD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Iridium",
      ticker: "IRD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Iridium",
      ticker: "IRD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Iridium",
      ticker: "IRD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
  ];

  const _tradeData = [
    {
      orderNumber: 1010,
      mkt: "Iridium",
      ticker: "IRD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Iridium",
      ticker: "IRD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Iridium",
      ticker: "IRD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Iridium",
      ticker: "IRD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Iridium",
      ticker: "IRD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Iridium",
      ticker: "IRD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Iridium",
      ticker: "IRD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Iridium",
      ticker: "IRD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Iridium",
      ticker: "IRD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Iridium",
      ticker: "IRD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Iridium",
      ticker: "IRD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Iridium",
      ticker: "IRD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Iridium",
      ticker: "IRD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Iridium",
      ticker: "IRD",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
  ];

  return (
    <React.Fragment>
      <MarketComponent
        title={"Iridium"}
        img={iridiumImg}
        bidData={_bidData}
        tradeData={_tradeData}
      />
    </React.Fragment>
  );
}

export default IridiumMarket;
