import MarketComponent from "../../components/Markets/MarketComponent";
import React from "react";
import coinsCurrency from "../../assets/metals/small/coins-and-currency.png";

function CoinCurrencyMarket() {
  const data = [
    {
      orderNumber: Math.floor(Math.random() * 99999),
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: Math.floor(Math.random() * 24244252),
      price: Math.floor(Math.random() * 24244252),
    },
    {
      orderNumber: Math.floor(Math.random() * 99999),
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: Math.floor(Math.random() * 24244252),
      price: Math.floor(Math.random() * 24244252),
    },
    {
      orderNumber: Math.floor(Math.random() * 99999),
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: Math.floor(Math.random() * 24244252),
      price: Math.floor(Math.random() * 24244252),
    },
    {
      orderNumber: Math.floor(Math.random() * 99999),
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: Math.floor(Math.random() * 24244252),
      price: Math.floor(Math.random() * 24244252),
    },
    {
      orderNumber: Math.floor(Math.random() * 99999),
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: Math.floor(Math.random() * 24244252),
      price: Math.floor(Math.random() * 24244252),
    },
    {
      orderNumber: Math.floor(Math.random() * 99999),
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: Math.floor(Math.random() * 24244252),
      price: Math.floor(Math.random() * 24244252),
    },
    {
      orderNumber: Math.floor(Math.random() * 99999),
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: Math.floor(Math.random() * 24244252),
      price: Math.floor(Math.random() * 24244252),
    },
    {
      orderNumber: Math.floor(Math.random() * 99999),
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: Math.floor(Math.random() * 24244252),
      price: Math.floor(Math.random() * 24244252),
    },
    {
      orderNumber: Math.floor(Math.random() * 99999),
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: Math.floor(Math.random() * 24244252),
      price: Math.floor(Math.random() * 24244252),
    },
    {
      orderNumber: Math.floor(Math.random() * 99999),
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: Math.floor(Math.random() * 24244252),
      price: Math.floor(Math.random() * 24244252),
    },
    {
      orderNumber: Math.floor(Math.random() * 99999),
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: Math.floor(Math.random() * 24244252),
      price: Math.floor(Math.random() * 24244252),
    },
    {
      orderNumber: Math.floor(Math.random() * 99999),
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: Math.floor(Math.random() * 24244252),
      price: Math.floor(Math.random() * 24244252),
    },
    {
      orderNumber: Math.floor(Math.random() * 99999),
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: Math.floor(Math.random() * 24244252),
      price: Math.floor(Math.random() * 24244252),
    },
    {
      orderNumber: Math.floor(Math.random() * 99999),
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: Math.floor(Math.random() * 24244252),
      price: Math.floor(Math.random() * 24244252),
    },
  ];

  return (
    <React.Fragment>
      <MarketComponent
        title={"Coin & Currency"}
        img={coinsCurrency}
        bidData={data}
        tradeData={data}
      />
    </React.Fragment>
  );
}

export default CoinCurrencyMarket;
