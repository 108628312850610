import { Box, Divider, Modal, Paper, Typography } from "@mui/material";
import React, { useState } from "react";

import SearchField from "../../Global/SearchField";
import SearchTable from "./SearchTable";
import { SubHeading } from "../../Styled/Globals";
import TradeDetailsModal from "../../CommonModals/TradeDetailsModal/TradeDetailsModal";

export default function SearchModal({ open, handleClose }) {
  const [selectedData, setSelectedData] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);

  const handleDetails = (data) => {
    setSelectedData(data);
    setOpenDetails(true);
  };

  const handleDetailsClose = (_) => {
    setSelectedData(null);
    setOpenDetails(false);
  };

  return (
    <Modal className={"modal"} open={open} onClose={handleClose}>
      <Box
        className={"no-scroll-bar"}
        sx={{
          width: "min(1500px,90%)",
        }}
      >
        <TradeDetailsModal
          data={selectedData}
          open={openDetails}
          handleClose={handleDetailsClose}
        />
        <Paper sx={(theme) => ({})}>
          {" "}
          <Box
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              py: 2,
              px: 4,
            })}
          >
            <Box>
              <SubHeading>Search Results for "XXXX"</SubHeading>
              <Typography>User Name: XXXX</Typography>
            </Box>
            <Box width={"50%"}>
              <SearchField
                width={"100%"}
                noMargin
                title={"Search"}
                handleSubmit={null}
              />
            </Box>
          </Box>
          <Divider />
          <Box
            sx={(theme) => ({
              px: 4,
              pt: 2,
              pb: 1,
            })}
          >
            <SearchTable
              onDetailSelect={handleDetails}
              headCells={[
                {
                  id: "id",
                  numeric: false,
                  width: "2%",
                  label: (
                    <React.Fragment>
                      Order/ <br /> Transaction #
                    </React.Fragment>
                  ),
                },
                {
                  id: "ticker",
                  numeric: false,
                  label: "Ticker",
                  width: "5%",
                },
                {
                  id: "mkt",
                  numeric: false,
                  width: "5%",
                  label: "MKT",
                },
                {
                  id: "quantity",
                  numeric: true,
                  width: "3%",
                  label: "Quantity",
                },
                {
                  id: "price",
                  numeric: true,
                  width: "2%",
                  label: "Price",
                },
                {
                  id: "total",
                  numeric: false,
                  width: "2%",
                  label: "Total",
                },
                {
                  id: "progress",
                  numeric: false,
                  width: "2%",
                  label: "Progress",
                },
                {
                  id: "status",
                  numeric: false,
                  width: "2%",
                  label: "Status",
                },
                {
                  id: "action",
                  numeric: false,
                  width: "5%",
                  label: "",
                  disableSorting: true,
                },
              ]}
              rows={_data}
            />
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
}
const _data = [
  {
    id: Math.floor(Math.random() * 4563745),
    ticker: "GLD",
    mkt: "Gold",
    quantity: Math.floor(Math.random() * 99),
    price: Math.floor(Math.random() * 9999),
    total: Math.floor(Math.random() * 999999),
    progress: "Incomplete",
    status: "Incomplete",
  },
  {
    id: Math.floor(Math.random() * 4563745),
    ticker: "GLD",
    mkt: "Gold",
    quantity: Math.floor(Math.random() * 99),
    price: Math.floor(Math.random() * 9999),
    total: Math.floor(Math.random() * 999999),
    progress: "Incomplete",
    status: "Incomplete",
  },
  {
    id: Math.floor(Math.random() * 4563745),
    ticker: "GLD",
    mkt: "Gold",
    quantity: Math.floor(Math.random() * 99),
    price: Math.floor(Math.random() * 9999),
    total: Math.floor(Math.random() * 999999),
    progress: "Incomplete",
    status: "Incomplete",
  },
  {
    id: Math.floor(Math.random() * 4563745),
    ticker: "GLD",
    mkt: "Gold",
    quantity: Math.floor(Math.random() * 99),
    price: Math.floor(Math.random() * 9999),
    total: Math.floor(Math.random() * 999999),
    progress: "Incomplete",
    status: "Incomplete",
  },
  {
    id: Math.floor(Math.random() * 4563745),
    ticker: "GLD",
    mkt: "Gold",
    quantity: Math.floor(Math.random() * 99),
    price: Math.floor(Math.random() * 9999),
    total: Math.floor(Math.random() * 999999),
    progress: "Incomplete",
    status: "Incomplete",
  },
  {
    id: Math.floor(Math.random() * 4563745),
    ticker: "GLD",
    mkt: "Gold",
    quantity: Math.floor(Math.random() * 99),
    price: Math.floor(Math.random() * 9999),
    total: Math.floor(Math.random() * 999999),
    progress: "Incomplete",
    status: "Incomplete",
  },
  {
    id: Math.floor(Math.random() * 4563745),
    ticker: "GLD",
    mkt: "Gold",
    quantity: Math.floor(Math.random() * 99),
    price: Math.floor(Math.random() * 9999),
    total: Math.floor(Math.random() * 999999),
    progress: "Incomplete",
    status: "Incomplete",
  },
];
