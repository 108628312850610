import {
  AttachmentsGrid,
  StyledTextArea,
  SubHeading,
} from "../../../Styled/Globals";
import {
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Paper,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider, TimePicker } from "@mui/lab";
import React, { useState } from "react";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import AttachmentFile from "../../../Styled/AttachmentFile";
import { Close } from "@mui/icons-material";

const AskQuestionModal = ({ open, handleClose }) => {
  const [questions, setQuestions] = useState([{}, {}]);

  //eslint-disable-next-line
  const [files, setFiles] = useState([
    {
      name: "file_1.pdf",
      type: "pdf",
    },
    {
      name: "file_2.pdf",
      type: "pdf",
    },
    {
      name: "file_3.pdf",
      type: "pdf",
    },
  ]);

  const transactionNumber = Math.floor(Math.random() * 100000);

  return (
    <Modal open={open} onClose={handleClose} className={"modal"}>
      <Box
        sx={{
          maxWidth: 850,
          maxHeight: "100%",
        }}
        className={"no-scroll-bar"}
      >
        <Paper>
          <Box sx={{ px: 4, paddingY: 2 }} className={"close-box"}>
            <SubHeading>Ask Question(s)</SubHeading>
            <Close className={"c-pointer"} onClick={handleClose} />
          </Box>
          <Divider />
          <Box px={4} py={1.5}>
            <Grid container rowSpacing={2} columnSpacing={4}>
              <Grid item md={4}>
                <TextField name={"to"} label={"To"} fullWidth size={"small"} />
              </Grid>
              <Grid item md={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Date"
                    inputFormat="MM/dd/yyyy"
                    onChange={(e) => {}}
                    renderInput={(params) => (
                      <TextField
                        className="calender-icon"
                        {...params}
                        size={"small"}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    onChange={(e) => {}}
                    renderInput={(params) => (
                      <TextField
                        className="calender-icon"
                        {...params}
                        size={"small"}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={12}>
                <TextField
                  name={"question"}
                  label={
                    "Question for Arbitrated Transaction# " + transactionNumber
                  }
                  sx={{ mt: 1 }}
                  readOnly={true}
                  value={"This is a test question with test transaction number"}
                  fullWidth
                  size={"small"}
                />
              </Grid>
              <Grid item md={12}>
                <StyledTextArea
                  name={"evidence"}
                  placeholder={
                    "Admin writes documentary evidence requested here"
                  }
                  minRows={4}
                  style={{
                    width: "100%",
                    padding: 10,
                  }}
                />
              </Grid>
              {questions.map((question) => (
                <Grid item md={12}>
                  <StyledTextArea
                    name={"question"}
                    placeholder={"Admin writes questions here"}
                    minRows={4}
                    style={{
                      width: "100%",
                      padding: 10,
                    }}
                  />
                </Grid>
              ))}
              <Grid md={12}>
                <Box
                  display={"flex"}
                  width={"100%"}
                  justifyContent={"flex-end"}
                  mt={2}
                >
                  <Button
                    disableElevation
                    variant={"contained"}
                    color={"primary"}
                    size={"small"}
                    sx={{
                      fontSize: "small !important",
                    }}
                    // sx={{
                    //   width: 100,
                    //   height: "100%",
                    //   fontWeight: "normal",
                    //   fontSize: 12,
                    // }}
                    onClick={() => setQuestions([...questions, {}])}
                  >
                    Add More
                  </Button>
                </Box>
              </Grid>
              <Grid item md={12}>
                <AttachmentsGrid>
                  {files.map((file, index) => (
                    <AttachmentFile
                      name={file?.name}
                      type={file?.type}
                      key={index}
                    />
                  ))}
                </AttachmentsGrid>
              </Grid>
            </Grid>
          </Box>
          <Box
            px={4}
            pb={2}
            display={"flex"}
            width={"100%"}
            justifyContent={"flex-end"}
          >
            <Button
              disableElevation
              variant={"contained"}
              color={"primary"}
              sx={{
                width: 150,
              }}
              onClick={handleClose}
            >
              Send
            </Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default AskQuestionModal;
