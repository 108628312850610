import { Box, Button, Divider, Grid, Modal, Paper } from "@mui/material";
import React, { useState } from "react";
import { StyledTextArea, SubHeading } from "../../../Styled/Globals";

import { Close } from "@mui/icons-material";
import DropdownButton from "../../../Common/DropdownButton";

const MarketModal = ({
  open,
  handleClose,
  title,
  variant = "new",
  data,
  setData,
  onChange,
}) => {
  const [buttons, setButtons] = useState([
    {
      title: "MKT",
      value: null,
    },
    {
      title: "Mint",
      value: null,
    },
    {
      title: "Style",
      value: null,
    },
    {
      title: "Weight",
      value: null,
    },
    {
      title: "Purity",
      value: null,
    },
    {
      title: "Condition",
      value: null,
    },
    {
      title: "Year",
      value: null,
    },
  ]);

  const onButtonSelect = (val, title) => {
    const newArray = buttons.map((btn) => {
      if (btn.title === title) {
        btn.value = val;
      }
      return btn;
    });
    setButtons(newArray);
  };

  React.useEffect(() => {
    console.log("market request data", data);
  }, [open, data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleClose();
  };

  return (
    <Modal className={"modal"} open={open} onClose={handleClose}>
      <Box
        sx={{
          width: 800,
        }}
        className={"no-scroll-bar"}
      >
        <Paper>
          <Box
            className={"close-box"}
            sx={{
              px: 4,
              py: 1,
            }}
          >
            <SubHeading sx={{ width: "100%" }}>{title}</SubHeading>
            <Close
              fontSize={"small"}
              className={"pointer"}
              onClick={handleClose}
            />
          </Box>
          <Divider />
          <Box px={4} pt={2}>
            <Box>
              <Grid container columnSpacing={4} rowSpacing={2}>
                <Grid item md={12}>
                  <StyledTextArea
                    name={"description"}
                    placeholder={"Description of Market"}
                    onChange={onChange}
                    value={data?.description}
                    style={{
                      width: "100%",
                      height: 150,
                      padding: 10,
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <StyledTextArea
                    name={"shortDescription"}
                    placeholder={"Short Description of Market"}
                    onChange={onChange}
                    value={data?.description}
                    style={{
                      width: "95%",
                      height: 80,
                      padding: 10,
                      color: "#000",
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <Box display="flex" justifyContent={"flex-end"}>
                    <StyledTextArea
                      name={"historyOfItem"}
                      placeholder={"History of Item"}
                      onChange={onChange}
                      style={{
                        width: "95%",
                        height: 80,
                        padding: 10,
                        color: "#000",
                      }}
                    />
                  </Box>
                </Grid>
                {buttons.map((button) => {
                  return (
                    <Grid item md={4}>
                      <DropdownButton
                        id={button.title}
                        options={[
                          "Option 1",
                          "Option 2",
                          "Option 3",
                          "Option 4",
                        ]}
                        customSX={{
                          height: 55,
                        }}
                        label={button.title}
                        value={button.value}
                        onSelect={(val) => onButtonSelect(val, button.title)}
                      />
                    </Grid>
                  );
                })}
                <Grid item md={4}>
                  <Button
                    aria-controls={open ? "demo-positioned-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    disableElevation
                    variant="contained"
                    id={"demo-customized-button"}
                    sx={(theme) => ({
                      bgcolor: "primary.dark",
                      textTransform: "capitalize",
                      fontWeight: "normal",
                      width: "100%",
                      [theme.breakpoints.up("xl")]: {
                        fontSize: `20px !important`,
                        minHeight: "58px",
                        py: 0.1,
                      },
                      [theme.breakpoints.down("xl")]: {
                        fontSize: `18px !important`,
                        padding: "1px",
                        minHeight: "47px",
                        minWidth: "115px",
                      },
                    })}
                  >
                    Upload Pic
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box
            px={4}
            pb={2}
            justifyContent={"flex-end"}
            display={"flex"}
            alignItems={"center"}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: "25%",
                textTransform: "initial",
              }}
              onClick={handleSubmit}
            >
              {variant === "new" ? title : "Close"}
            </Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default MarketModal;
