import { TextField, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";

function AuthLabel({ ...props }) {
  const theme = useTheme();

  return (
    <TextField
      inputProps={{
        style: {
          fontSize: useMediaQuery(theme.breakpoints.down("xl")) ? 14 : 16,
        },
      }} // font size of input text
      InputLabelProps={{
        style: {
          fontSize: useMediaQuery(theme.breakpoints.down("xl")) ? 14 : 16,
        },
      }} // font size of input label
      variant="outlined"
      size={"small"}
      fullWidth
      {...props}
    />
  );
}

export default AuthLabel;
