import {
  Box,
  Button,
  Divider,
  Modal,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import { StyledTextArea, SubHeading } from "../../../Styled/Globals";

import { Close } from "@mui/icons-material";
import DropdownButton from "../../../Common/DropdownButton";

const MakeRullingModal = ({ open, handleClose }) => {
  const SelectorButton = styled(Button)(({ theme, selected }) => ({
    backgroundColor: selected ? "#2958ac" : "#5F99FF",

    textTransform: "initial",
  }));

  const [data, setData] = useState({});

  React.useEffect(() => {
    setData(null);
  }, [open]);

  return (
    <Modal open={open} onClose={handleClose} className={"modal"}>
      <Box
        sx={{
          width: 800,
        }}
        className={"no-scroll-bar"}
      >
        <Paper>
          <Box className={"close-box"} sx={{ px: 4, py: 1 }}>
            <Box>
              <SubHeading>Make Ruling</SubHeading>
              <Typography>Ruling in Favor of:</Typography>
            </Box>
            <Close
              fontSize="small"
              onClick={handleClose}
              className={"pointer"}
            />
          </Box>
          <Divider />
          <Box
            py={2}
            justifyContent={"center"}
            alignItems={"center"}
            display={"flex"}
            columnGap={5}
          >
            <SelectorButton
              variant={"contained"}
              selected={data?.favor === "buyer"}
              onClick={(_) => setData({ ...data, favor: "buyer" })}
              sx={{
                width: 250,
              }}
            >
              Buyer
            </SelectorButton>
            <SelectorButton
              variant={"contained"}
              selected={data?.favor === "seller"}
              onClick={(_) => setData({ ...data, favor: "seller" })}
              sx={{
                width: 250,
              }}
            >
              Seller
            </SelectorButton>
          </Box>
          <Box
            px={4}
            display={"flex"}
            flexDirection={"column"}
            rowGap={2}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <StyledTextArea
              placeholder="Outcome Listed Here"
              name={"outcome"}
              onChange={(e) => setData({ ...data, outcome: e.target.value })}
              style={{
                width: "100%",
                height: 50,
                padding: 10,
              }}
            />
            <StyledTextArea
              placeholder="Written Statement from Arbitrator goes here"
              onChange={(e) => setData({ ...data, statement: e.target.value })}
              name={"statement"}
              style={{
                width: "100%",
                height: 120,
                padding: 10,
              }}
            />
            <Box width={"35%"}>
              <DropdownButton
                onSelect={(val) => setData({ ...data, transactionEffect: val })}
                id={"transactionEffect"}
                value={data?.transactionEffect}
                options={["Xxx", "Xxxx", "Xxx", "Xxx", "xxx"]}
                label={"Transaction Effect"}
              />
            </Box>
          </Box>
          <Box
            px={4}
            py={2}
            display={"flex"}
            width={"100%"}
            justifyContent={"flex-end"}
          >
            <Button
              disableElevation
              sx={{
                width: 150,
                textTransform: "initial",
              }}
              variant={"contained"}
              color={"primary"}
              onClick={handleClose}
            >
              Save
            </Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default MakeRullingModal;
