import { Box, Button, Paper, Tab as TabMUI, Tabs, styled } from "@mui/material";

import Chart from "react-apexcharts";
import { Heading } from "../Styled/Globals";
import React from "react";

const Tab = styled(TabMUI)(({ theme }) => ({
  alignItems: "flex-start",
  width: "100%",
  textAlign: "left",
}));

const Analysis = ({ bRef }) => {
  const [tab, setTab] = React.useState(0);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
        style={{ width: "100%" }}
      >
        {value === index && <Box sx={{ p: 3, width: "100%" }}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newTab) => {
    setTab(newTab);
  };

  return (
    <Box ref={bRef}>
      <Heading>Analysis</Heading>
      <Box display={"flex"} width={"100%"}>
        <Paper
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              bgcolor: "background.paper",
              display: "flex",
            }}
          >
            <Tabs
              orientation="vertical"
              value={tab}
              onChange={handleChange}
              sx={{
                borderRight: 1,
                borderColor: "divider",
              }}
            >
              <Tab
                sx={{ alignSelf: "flex-start" }}
                label="Google Analytics"
                {...a11yProps(0)}
              />
              <Tab label="Basic Statistics" {...a11yProps(1)} />
              <Tab label="# of Orders" {...a11yProps(2)} />
              <Tab label="# of Transactions" {...a11yProps(3)} />
              <Tab label="Time for Order to Find Match" {...a11yProps(4)} />
              <Tab label="# of New Arbitrations" {...a11yProps(5)} />
              <Tab label="# of New Seller Arbitrations" {...a11yProps(6)} />
            </Tabs>
            <TabPanel value={tab} index={0}>
              <DummyChart />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <DummyChart />
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <DummyChart />
            </TabPanel>
            <TabPanel value={tab} index={3}>
              <DummyChart />
            </TabPanel>
            <TabPanel value={tab} index={4}>
              <DummyChart />
            </TabPanel>
            <TabPanel value={tab} index={5}>
              <DummyChart />
            </TabPanel>
            <TabPanel value={tab} index={6}>
              <DummyChart />
            </TabPanel>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

const DummyChart = () => {
  const buttons = [
    {
      title: "24H",
      action: null,
    },
    {
      title: "48H",
      action: null,
    },
    {
      title: "7D",
      action: null,
    },
    {
      title: "14D",
      action: null,
    },
    {
      title: "30D",
      action: null,
    },
    {
      title: "YTD",
      action: null,
    },
    {
      title: "Year",
      action: null,
    },
    {
      title: "All",
      action: null,
    },
  ];

  const DarkButton = styled(Button)(({ theme }) => ({
    color: "#000",
    backgroundColor: "#c4c4c4",
    fontSize: "14px",
    border: "1px solid #c4c4c4",

    ":hover": {
      backgroundColor: "#fff",
      border: "1px solid #c4c4c4",
    },
  }));

  return (
    <Box>
      <Box>
        <Box sx={{ display: "flex", columnGap: 2, justifyContent: "flex-end" }}>
          {buttons.map((button) => (
            <Button
              disableElevation
              size={"small"}
              onClick={button?.action}
              color={"primary"}
              variant={"contained"}
              sx={{
                p: 0.5,
                fontSize: "14px",
              }}
            >
              {button.title}
            </Button>
          ))}
        </Box>
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            width: 100,
            rowGap: 2,
          })}
        >
          <DarkButton>MKT</DarkButton>
          <DarkButton>Ticker</DarkButton>
        </Box>
      </Box>
      <Chart
        type="line"
        options={{
          chart: {
            height: "100%",
            width: "100%",
            type: "line",
            zoom: {
              enabled: true,
            },
          },
          dataLabels: {
            enabled: true,
          },
          stroke: {
            curve: "straight",
          },
          title: {
            align: "left",
          },
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.1,
            },
          },
          xaxis: {
            categories: [
              "09:00pm",
              "10:00pm",
              "11:00pm",
              "12:00pm",
              "01:00am",
              "02:00am",
              "03:00am",
              "04:00am",
              "05:00am",
            ],
            title: {
              text: "Time",
              style: {
                fontSize: "large",
                fontFamily: "Roboto",
              },
            },
          },

          yaxis: {
            labels: {
              formatter: function (value) {
                return "$" + value;
              },
            },
            title: {
              text: "Funds",
              style: {
                fontSize: "large",
                fontFamily: "Roboto",
              },
            },
          },
        }}
        series={[
          {
            name: "Funds",
            data: [0, 50, 110, 450, 30, 590, 740, 220, 263],
          },
        ]}
      />
    </Box>
  );
};

export default Analysis;
