import MarketComponent from "../../components/Markets/MarketComponent";
import React from "react";
import platinum from "../../assets/metals/small/platinum.png";

function PlatinumMarket() {
  const _bidData = [
    {
      orderNumber: 1010,
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
  ];

  const _tradeData = [
    {
      orderNumber: 1010,
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
    {
      orderNumber: 1010,
      mkt: "Platinum",
      ticker: "PLT",
      bidAsk: "Bid",
      quantity: 200,
      price: 1000,
    },
  ];

  return (
    <React.Fragment>
      <MarketComponent
        title={"Platinum"}
        img={platinum}
        bidData={_bidData}
        tradeData={_tradeData}
      />
    </React.Fragment>
  );
}

export default PlatinumMarket;
