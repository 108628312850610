import {
  Box,
  CircularProgress,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";

import CustomPagination from "../../../components/Common/CustomPagination";
import EvidenceModal from "../../../components/Arbitration/EvidenceModal/EvidenceModal";
import { ReactComponent as GearSVG } from "../../../assets/gears.svg";
import PropTypes from "prop-types";
import QuestionAnswerModal from "../../../components/Arbitration/QuestionAnswerModal/QuestionAnswerModal";
import ResponseClaimModal from "../../../components/Arbitration/ResponseClaimModal/ResponseClaimModal";
import ScheduleModal from "../../../components/Arbitration/ScheduleModal/ScheduleModal";
import ThankYouModal from "../../../components/Arbitration/ThankYouModal/ThankYouModal";
import moment from "moment";
import { visuallyHidden } from "@mui/utils";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            sx={{
              padding: "4px 0px",
              pl: index === 0 && 2,
              borderRadius: index === 0 && 10,
              width: headCell?.width || 100,
              verticalAlign: "bottom",
              textAlign: "left",
              background: headCell?.id === "action" && "#5f99ff",
              borderTopRightRadius: 5,
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell?.disableSorting ? (
              <Box
                component={"div"}
                sx={(theme) => ({
                  width: "100%",
                  display: "flex",
                  color: theme?.palette?.primary?.main,
                  fontSize: "small",
                  [theme.breakpoints.up("xl")]: {
                    fontSize: "medium",
                  },
                  ...headCell?.sx,
                })}
              >
                {headCell?.label}
              </Box>
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                sx={(theme) => ({
                  fontSize: "small",
                  padding: "5px 0px",
                  display: "flex",
                  lineHeight: 1.5,
                  width: "100%",
                  justifyContent: "flex-start",
                  color: theme?.palette?.primary?.main,
                  "& .MuiTableSortLabel-icon": {
                    margin: "0px",
                    fontSize: "small",
                  },
                  [theme.breakpoints.up("xl")]: {
                    fontSize: "medium",
                    "& .MuiTableSortLabel-icon": {
                      fontSize: "medium",
                    },
                  },
                  ...headCell?.sx,
                })}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  rows,
  headCells,
  tableTitle,
  loading,
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tableData, setTableData] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuIndex, setMenuIndex] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event, index) => {
    setMenuIndex(index);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setMenuIndex(null);
    setAnchorEl(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  // 	const selectedIndex = selected.indexOf(name);
  // 	let newSelected = [];

  // 	if (selectedIndex === -1) {
  // 		newSelected = newSelected.concat(selected, name);
  // 	} else if (selectedIndex === 0) {
  // 		newSelected = newSelected.concat(selected.slice(1));
  // 	} else if (selectedIndex === selected.length - 1) {
  // 		newSelected = newSelected.concat(selected.slice(0, -1));
  // 	} else if (selectedIndex > 0) {
  // 		newSelected = newSelected.concat(
  // 			selected.slice(0, selectedIndex),
  // 			selected.slice(selectedIndex + 1)
  // 		);
  // 	}

  // 	setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const StyledTableCell = styled(TableCell)(({ theme, center }) => ({
    padding: "10px 0px",
    paddingRight: "5px",
    fontSize: "14px",
    textAlign: center ? "center" : "left",
    border: "none",
    [theme.breakpoints.up("xl")]: {
      fontSize: "16px",
    },
  }));

  const [openResponseClaimModal, setOpenResponseClaimModal] = useState(false);
  const [openQuestionAnswerModal, setOpenQuestionAnswerModal] = useState(false);
  const [openEvidenceModal, setOpenEveidenceModal] = useState(false);
  const [openThankYouModal, setOpenThankYouModal] = useState(false);
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [scheduleData, setScheduleData] = useState(null);

  const handleDataSelect = (_row) => setSelected(_row);

  const handleMenuSelect = (id) => {
    switch (id) {
      case 1:
        setOpenResponseClaimModal(true);
        break;
      case 2:
        setOpenQuestionAnswerModal(true);
        break;
      case 3:
        setOpenEveidenceModal(true);
        break;
      case 4:
        setScheduleData({
          title: "Schedule Call",
          description:
            "The Arbitrator at Bullion MKTS has requested that you schedule a Phone Call to discuss the situation. An Arbitration Phone Call lasts 30 minutes or less.",
        });
        setOpenScheduleModal(true);
        break;
      case 5:
        setScheduleData({
          title: "Schedule Hearing",
          description:
            "The Arbitrator at Bullion MKTS has requested that you schedule an Arbitration Hearing. An Arbitration Hearing lasts 30 minutes or less. Each partyhas the opportunity to spend 5 minutes explaining their case. The Arbitrator then asks questions of each party for 5-10 minutes. Each party may closewith a 2 minute response to what the other party said or clear up anything they want.",
        });
        setOpenScheduleModal(true);
        break;
      case 6:
        console.log("handle archive");
        break;
      default:
        console.log("out of range selected", id);
        break;
    }
  };

  const actionMenus = [
    {
      id: 1,
      title: "Proivde Statement",
    },
    {
      id: 2,
      title: "Answer Question(s)",
    },
    {
      id: 3,
      title: "Provide Evidence",
    },
    {
      id: 4,
      title: "Schedule Call",
    },
    {
      id: 5,
      title: "Schedule Hearing",
    },
    {
      id: 6,
      title: "Archive",
    },
  ];

  return (
    <Box mb={4} sx={{ width: "100%" }}>
      <ResponseClaimModal
        open={openResponseClaimModal}
        handleClose={() => setOpenResponseClaimModal(false)}
        onSubmit={() => {
          setOpenResponseClaimModal(false);
          setOpenThankYouModal(true);
        }}
        data={selected}
      />

      <ThankYouModal
        open={openThankYouModal}
        handleClose={() => setOpenThankYouModal(false)}
      />

      <QuestionAnswerModal
        open={openQuestionAnswerModal}
        handleClose={() => setOpenQuestionAnswerModal(false)}
        onSubmit={() => {
          setOpenQuestionAnswerModal(false);
          setOpenThankYouModal(true);
        }}
        data={selected}
      />

      {/* <FileClaimModal
        open={openFileClaimModal}
        handleClose={() => setOpenFileClaimModal(false)}
        onSubmit={() => {
          setOpenFileClaimModal(false);
          setOpenThankYouModal(true);
        }}
        data={selected}
      /> */}

      <ScheduleModal
        open={openScheduleModal}
        handleClose={() => setOpenScheduleModal(false)}
        onSubmit={() => {
          setOpenScheduleModal(false);
        }}
        scheduleData={scheduleData}
        data={selected}
      />

      <EvidenceModal
        open={openEvidenceModal}
        handleClose={() => setOpenEveidenceModal(false)}
        onSubmit={() => {
          setOpenEveidenceModal(false);
        }}
        data={selected}
      />

      <Paper sx={{ width: "100%", bgcolor: "#fff", mb: 2, borderRadius: 4 }}>
        <Box
          display="flex"
          justifyContent={"center"}
          alignContent={"center"}
          // minHeight={350}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <TableContainer
              sx={{
                minHeight: "411px",
              }}
            >
              <Table sx={{ minWidth: 750 }} size={"small"}>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  headCells={headCells}
                />
                <TableBody>
                  {rows.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={10}>
                        <Typography align="center">No Arbitrations</Typography>
                      </TableCell>
                    </TableRow>
                  ) : null}

                  {stableSort(tableData, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      // const objectKeys = Object.keys(row);
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row._id}
                          sx={{ height: "1px" }}
                        >
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                            sx={{ pl: 2 }}
                          >
                            {row?.transactionNumber}
                          </StyledTableCell>

                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {moment(row?.date).format("MM/DD/YYYY")}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.mkt}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.ticker}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.description}
                          </StyledTableCell>

                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.quantity}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            ${row?.price}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            ${row?.total}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {moment(row?.dateFiled).format("MM/DD/YYYY")}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.type}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.cause}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.status}
                          </StyledTableCell>
                          <TableCell
                            component="td"
                            id={labelId}
                            scope="row"
                            sx={{
                              // display: "flex",
                              // alignItems: "center",
                              // justifyContent: "center",
                              // border: "none",
                              height: "inherit",
                              border: "none",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                height: "100%",
                                alignItems: "center",
                              }}
                            >
                              <GearSVG
                                className={"pointer"}
                                onClick={(e) => handleClick(e, labelId)}
                              />
                            </Box>
                            <Menu
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={open && labelId === menuIndex}
                              onClose={handleClose}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                              sx={{
                                minWidth: 155,
                              }}
                            >
                              <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open && menuIndex === labelId}
                                onClose={handleClose}
                                MenuListProps={{
                                  "aria-labelledby": "basic-button",
                                }}
                                sx={{
                                  minWidth: 155,
                                  width: "100%",
                                }}
                              >
                                {actionMenus?.map((menu) => (
                                  <MenuItem
                                    onClick={(_) => {
                                      handleDataSelect(row);
                                      handleMenuSelect(menu?.id);
                                      handleClose();
                                    }}
                                  >
                                    <Box
                                      display={"flex"}
                                      justifyContent={"left"}
                                      alignItems="center"
                                      width={"100%"}
                                    >
                                      <Typography
                                        sx={(theme) => ({
                                          fontSize: "small",
                                          [theme.breakpoints.up("xl")]: {
                                            fontSize: "medium",
                                          },
                                        })}
                                      >
                                        {menu?.title}
                                      </Typography>
                                    </Box>
                                  </MenuItem>
                                ))}
                              </Menu>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
      <CustomPagination
        rowsPerPage={rowsPerPage}
        rows={rows}
        setTableData={setTableData}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
}
