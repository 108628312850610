import { Box, Modal, Paper, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Close from "@mui/icons-material/Close";

const ReputationModal = ({ open, handleClose }) => {
  return (
    <Modal className={"modal"} open={open} onClose={handleClose}>
      <Box
        className={"no-scroll-bar"}
        sx={{
          width: 700,
        }}
      >
        <Paper sx={{ padding: 5 }}>
          <Box className={"b-flex-end"}>
            <Close
              onClick={handleClose}
              className={"c-pointer"}
              sx={{
                mr: -3,
                mt: -5,
              }}
            />
          </Box>
          <Typography component={"p"}>
            <Typography component={"p"}>
              The Reputation and # of Completed Transactions options are
              requirements that the other side of the trade you are submitting
              has a certain level of Reputation and/or # of Transactions.
            </Typography>
            <br />
            <Typography component={"p"}>
              Please appreciate that this can be a very limited factor in terms
              of your trade finding its match. New buyers and sellers may not
              have a reputation or completed transactions yet, but please
              remember the{" "}
              <Link
                to={"/protections"}
                style={{
                  color: "#5F99FF",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                Protections
              </Link>{" "}
              that are build into the Bullion MKTS system.
            </Typography>
            <br />
            <Typography component={"p"}>
              Reputation and completed transactions selections may come with a
              premium or a discount depending on what choices make on reputation
              and what side of a trade you are on.
            </Typography>
          </Typography>
        </Paper>
      </Box>
    </Modal>
  );
};

export default ReputationModal;
