import { Box } from "@mui/material";
import React, { useState } from "react";
import { CardButton } from "../../Styled/Globals";
import MarketModal from "./NewMarketModal/MarketModal";
import RemoveMarketModal from "./RemoveMarketModal";

const NewMarketActions = () => {
  const [openMarketModal, setOpenMarketModal] = useState(false);
  const [openRemoveMarketModal, setOpenRemoveMarketModal] = useState(false);
  const [title, setTitle] = useState("");
  const [data, setData] = useState({});

  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

  const buttons = [
    {
      title: "Create New Market",
      action: () => {
        setTitle("Create Market");
        setOpenMarketModal(true);
      },
    },
    {
      title: "Edit Market",
      action: () => {
        setTitle("Edit Market");
        setOpenMarketModal(true);
      },
    },
    { title: "Remove Market", action: () => setOpenRemoveMarketModal(true) },
  ];

  return (
    <Box display={"flex"} width={"100%"} my={5} columnGap={4}>
      {buttons.map((button) => {
        return (
          <CardButton onClick={button?.action}>{button?.title}</CardButton>
        );
      })}
      <RemoveMarketModal
        onChange={onChange}
        data={data}
        setData={setData}
        open={openRemoveMarketModal}
        handleClose={() => setOpenRemoveMarketModal(false)}
        handleSubmit={null}
      />
      <MarketModal
        open={openMarketModal}
        title={title}
        handleClose={() => setOpenMarketModal(false)}
        onChange={onChange}
        data={data}
        setData={setData}
      />
    </Box>
  );
};

export default NewMarketActions;
