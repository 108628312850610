import { Box, Grid, Typography, styled } from "@mui/material";

import React from "react";
import moment from "moment";

function OrderInformation() {
  const DataHeading = styled(Typography)((theme) => ({
    color: "#5f99ff",
    fontWeight: "bold",
    fontSize: 14,
  }));
  const DataTypography = styled(Typography)({
    fontSize: 16,
    fontWeight: "bold",
  });
  return (
    <Box
      sx={(theme) => ({
        p: 2,
        my: 3,
      })}
      border={"1px solid #ccc"}
      borderRadius={2}
    >
      <Grid container rowSpacing={2}>
        <Grid item md={2.4}>
          <DataHeading>Ticker</DataHeading>
          <DataTypography>GLD</DataTypography>
        </Grid>
        <Grid item md={2.4}>
          <DataHeading>Bid/Ask</DataHeading>
          <DataTypography>Bid</DataTypography>
        </Grid>
        <Grid item md={2.8}>
          <DataHeading>Enter Price</DataHeading>
          <DataTypography>$5000</DataTypography>
        </Grid>
        <Grid item md={2.8}>
          <DataHeading>Ente Quantity</DataHeading>
          <DataTypography>200</DataTypography>
        </Grid>
        <Grid item md={1.4}>
          <DataHeading>Timing</DataHeading>
          <DataTypography>{moment(Date.now()).format("h:mma")}</DataTypography>
        </Grid>
        <Grid item md={2.4}>
          <DataHeading>Limit</DataHeading>
          <DataTypography>10%</DataTypography>
        </Grid>
        <Grid item md={2.4}>
          <DataHeading>Stop</DataHeading>
          <DataTypography>10%</DataTypography>
        </Grid>
        <Grid item md={2.8}>
          <DataHeading>Reputation</DataHeading>
          <DataTypography>-</DataTypography>
        </Grid>
        <Grid item md={4.4}>
          <DataHeading># of Completed Transactions</DataHeading>
          <DataTypography>-</DataTypography>
        </Grid>
        <Grid item md={2.4}>
          <DataHeading>Basis + $#</DataHeading>
          <DataTypography>-</DataTypography>
        </Grid>
        <Grid item md={2.4}>
          <DataHeading>Floor</DataHeading>
          <DataTypography>-</DataTypography>
        </Grid>
        <Grid item md={2.8}>
          <DataHeading>Basis X (1_%)</DataHeading>
          <DataTypography>-</DataTypography>
        </Grid>
        <Grid item md={2.8}>
          <DataHeading>Ceiling</DataHeading>
          <DataTypography>-</DataTypography>
        </Grid>
        <Grid item md={1.4}>
          <DataHeading>Price Based on</DataHeading>
          <DataTypography>-</DataTypography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default OrderInformation;
