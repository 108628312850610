export const sampleNotifications = [
  {
    notificationNumber: Math.floor(Math.random() * 23456789),
    date: new Date("01-02-2022"),
    relatedTo: "Selling",
    tradeNumber: Math.floor(Math.random() * 2345678),
    subject:
      "Consectetur labore anim labore cillum cupidatat anim sint mollit.",
  },
  {
    notificationNumber: Math.floor(Math.random() * 23456789),
    date: new Date("02-03-2022"),
    relatedTo: "Buying",
    tradeNumber: Math.floor(Math.random() * 2345678),
    subject:
      "In exercitation anim et cupidatat anim do irure exercitation et reprehenderit excepteur deserunt minim velit.",
  },
  {
    notificationNumber: Math.floor(Math.random() * 23456789),
    date: new Date("03-04-2022"),
    relatedTo: "Selling",
    tradeNumber: Math.floor(Math.random() * 2345678),
    subject:
      "Velit occaecat nulla fugiat reprehenderit et officia nulla est labore veniam sit id.",
  },
  {
    notificationNumber: Math.floor(Math.random() * 23456789),
    date: new Date("04-05-2022"),
    relatedTo: "Buying",
    tradeNumber: Math.floor(Math.random() * 2345678),
    subject: "Reference site about Lorem epsum...",
  },
  {
    notificationNumber: Math.floor(Math.random() * 23456789),
    date: new Date("05-06-2022"),
    relatedTo: "Selling",
    tradeNumber: Math.floor(Math.random() * 2345678),
    subject: "Ullamco sint irure eu fugiat deserunt.",
  },
  {
    notificationNumber: Math.floor(Math.random() * 23456789),
    date: new Date("06-02-2022"),
    relatedTo: "Selling",
    tradeNumber: Math.floor(Math.random() * 2345678),
    subject:
      "Consectetur labore anim labore cillum cupidatat anim sint mollit.",
  },
  {
    notificationNumber: Math.floor(Math.random() * 23456789),
    date: new Date("07-03-2022"),
    relatedTo: "Buying",
    tradeNumber: Math.floor(Math.random() * 2345678),
    subject:
      "In exercitation anim et cupidatat anim do irure exercitation et reprehenderit excepteur deserunt minim velit.",
  },
  {
    notificationNumber: Math.floor(Math.random() * 23456789),
    date: new Date("08-04-2022"),
    relatedTo: "Selling",
    tradeNumber: Math.floor(Math.random() * 2345678),
    subject:
      "Velit occaecat nulla fugiat reprehenderit et officia nulla est labore veniam sit id.",
  },
  {
    notificationNumber: Math.floor(Math.random() * 23456789),
    date: new Date("09-05-2022"),
    relatedTo: "Buying",
    tradeNumber: Math.floor(Math.random() * 2345678),
    subject: "Reference site about Lorem epsum...",
  },
  {
    notificationNumber: Math.floor(Math.random() * 23456789),
    date: new Date("10-06-2022"),
    relatedTo: "Selling",
    tradeNumber: Math.floor(Math.random() * 2345678),
    subject: "Ullamco sint irure eu fugiat deserunt.",
  },
  {
    notificationNumber: Math.floor(Math.random() * 23456789),
    date: new Date("11-02-2022"),
    relatedTo: "Selling",
    tradeNumber: Math.floor(Math.random() * 2345678),
    subject:
      "Consectetur labore anim labore cillum cupidatat anim sint mollit.",
  },
  {
    notificationNumber: Math.floor(Math.random() * 23456789),
    date: new Date("12-03-2022"),
    relatedTo: "Buying",
    tradeNumber: Math.floor(Math.random() * 2345678),
    subject:
      "In exercitation anim et cupidatat anim do irure exercitation et reprehenderit excepteur deserunt minim velit.",
  },
  {
    notificationNumber: Math.floor(Math.random() * 23456789),
    date: new Date("01-04-2021"),
    relatedTo: "Selling",
    tradeNumber: Math.floor(Math.random() * 2345678),
    subject:
      "Velit occaecat nulla fugiat reprehenderit et officia nulla est labore veniam sit id.",
  },
  {
    notificationNumber: Math.floor(Math.random() * 23456789),
    date: new Date("01-05-2020"),
    relatedTo: "Buying",
    tradeNumber: Math.floor(Math.random() * 2345678),
    subject: "Reference site about Lorem epsum...",
  },
  {
    notificationNumber: Math.floor(Math.random() * 23456789),
    date: new Date("01-06-2022"),
    relatedTo: "Selling",
    tradeNumber: Math.floor(Math.random() * 2345678),
    subject: "Ullamco sint irure eu fugiat deserunt.",
  },
  {
    notificationNumber: Math.floor(Math.random() * 23456789),
    date: new Date("01-02-2022"),
    relatedTo: "Selling",
    tradeNumber: Math.floor(Math.random() * 2345678),
    subject:
      "Consectetur labore anim labore cillum cupidatat anim sint mollit.",
  },
  {
    notificationNumber: Math.floor(Math.random() * 23456789),
    date: new Date("01-03-2022"),
    relatedTo: "Buying",
    tradeNumber: Math.floor(Math.random() * 2345678),
    subject:
      "In exercitation anim et cupidatat anim do irure exercitation et reprehenderit excepteur deserunt minim velit.",
  },
  {
    notificationNumber: Math.floor(Math.random() * 23456789),
    date: new Date("01-04-2022"),
    relatedTo: "Selling",
    tradeNumber: Math.floor(Math.random() * 2345678),
    subject:
      "Velit occaecat nulla fugiat reprehenderit et officia nulla est labore veniam sit id.",
  },
  {
    notificationNumber: Math.floor(Math.random() * 23456789),
    date: new Date("01-05-2022"),
    relatedTo: "Buying",
    tradeNumber: Math.floor(Math.random() * 2345678),
    subject: "Reference site about Lorem epsum...",
  },
  {
    notificationNumber: Math.floor(Math.random() * 23456789),
    date: new Date("01-06-2022"),
    relatedTo: "Selling",
    tradeNumber: Math.floor(Math.random() * 2345678),
    subject: "Ullamco sint irure eu fugiat deserunt.",
  },
  {
    notificationNumber: Math.floor(Math.random() * 23456789),
    date: new Date("01-02-2022"),
    relatedTo: "Selling",
    tradeNumber: Math.floor(Math.random() * 2345678),
    subject:
      "Consectetur labore anim labore cillum cupidatat anim sint mollit.",
  },
  {
    notificationNumber: Math.floor(Math.random() * 23456789),
    date: new Date("01-03-2022"),
    relatedTo: "Buying",
    tradeNumber: Math.floor(Math.random() * 2345678),
    subject:
      "In exercitation anim et cupidatat anim do irure exercitation et reprehenderit excepteur deserunt minim velit.",
  },
  {
    notificationNumber: Math.floor(Math.random() * 23456789),
    date: new Date("01-04-2022"),
    relatedTo: "Selling",
    tradeNumber: Math.floor(Math.random() * 2345678),
    subject:
      "Velit occaecat nulla fugiat reprehenderit et officia nulla est labore veniam sit id.",
  },
  {
    notificationNumber: Math.floor(Math.random() * 23456789),
    date: new Date("01-05-2022"),
    relatedTo: "Buying",
    tradeNumber: Math.floor(Math.random() * 2345678),
    subject: "Reference site about Lorem epsum...",
  },
  {
    notificationNumber: Math.floor(Math.random() * 23456789),
    date: new Date("01-06-2022"),
    relatedTo: "Selling",
    tradeNumber: Math.floor(Math.random() * 2345678),
    subject: "Ullamco sint irure eu fugiat deserunt.",
  },
];
