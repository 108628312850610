import { Box, Divider, Modal, Paper, Typography } from "@mui/material";
import React, { useState } from "react";

import ClaimForm from "./ClaimForm";
import Close from "@mui/icons-material/Close";
import { Heading } from "../../Styled/Globals";
import SingleTransactionTable from "../SingleTransactionTable/SingleTransactionTable";

const ResponseClaimModal = ({ open, handleClose, data, onSubmit }) => {
  const [formData, setFormData] = useState({});
  const [user, setUser] = useState("Buyer");

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  React.useEffect(
    () => setFormData({ ...formData, filersCause: null }),
    //eslint-disable-next-line
    [user]
  );

  return (
    <Modal className={"modal"} open={open} onClose={handleClose}>
      <Box
        style={{
          maxWidth: "90%",
        }}
        className={"no-scroll-bar"}
      >
        <Paper>
          {/* <Box className={"b-flex-end"} pr={1} pt={1}>
            <Close onClick={handleClose} className={"c-pointer"} />
          </Box> */}

          <Box component={"div"} className={"b-relative"}>
            <Heading>Response to Arbitration Claim </Heading>
            <Close onClick={handleClose} className={"c-absolute"} />
          </Box>
          <Box>
            <Divider />
          </Box>
          <Box px={4}>
            <Box
              sx={{
                my: 2,
                display: "flex",
                flexDirection: "column",
                rowGap: 2,
              }}
            >
              <Typography component={"p"}>
                A transaction you were a party to on Bullion MKTS has been
                arbitrated by the other party. The arbitation's statement is
                below for your review. We strive to resolve Arbitration issues
                quickly at Bullion MKTS. You are required to respond to this
                Arbitration within 24 hours or you will by default lose the
                arbitration. Please provide any documentary evidence in an
                upload after your statement.
              </Typography>
              <Box
                sx={{
                  broder: "1px solid #ccc",
                }}
              >
                <SingleTransactionTable loading={false} data={data} />
              </Box>
            </Box>
            <Box>
              <ClaimForm
                user={user}
                setUser={setUser}
                formData={formData}
                setFormData={setFormData}
                onChange={onChange}
                handleSubmit={handleSubmit}
              />
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default ResponseClaimModal;
