import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import "./DropdownButton.css";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({}));

export default function DropdownButton({
  // anchorEl,
  // setAnchorEl,
  customSX,
  options,
  onSelect,
  id,
  label,
  value,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [index, setIndex] = React.useState(null);

  const handleClick = (event) => {
    setIndex(id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box display={"flex"} alignItems="center" minWidth={"100%"} width={"100%"}>
      <Button
        key={value}
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableElevation
        onClick={handleClick}
        variant="contained"
        id={"demo-customized-button"}
        sx={(theme) => ({
          bgcolor: "primary.dark",
          textTransform: "capitalize",
          fontWeight: "normal",
          width: "100%",
          [theme.breakpoints.up("xl")]: {
            fontSize: `${value ? "16px" : "20px"} !important`,
            minHeight: "58px",
            py: 0.1,
          },
          [theme.breakpoints.down("xl")]: {
            fontSize: `${value ? "15px" : "18px"} !important`,
            padding: "1px",
            minHeight: "47px",
            minWidth: "115px",
          },
          ...customSX,
        })}
      >
        {label}
        <br />
        {value}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open && index === id}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {options.map((option, index) => {
          return (
            <MenuItem
              style={{
                color: "#000",
              }}
              key={index}
              onClick={(_) => {
                onSelect(option);
                handleClose();
              }}
              disableRipple
            >
              {option}
            </MenuItem>
          );
        })}
      </StyledMenu>
    </Box>
  );
}
