import React from "react";
import { Box, Modal, Paper } from "@mui/material";
import TradeForm from "../Markets/TradeForm";

const MarketTradeModal = ({ open, handleClose, market }) => {
  return (
    <Modal open={open} onClose={handleClose} className={"modal"}>
      <Box
        sx={{
          maxWidth: "90%",
        }}
        className={"no-scroll-bar"}
      >
        <Paper sx={{ px: 4, py: 2 }}>
          <TradeForm isModal={true} handleClose={handleClose} />
        </Paper>
      </Box>
    </Modal>
  );
};

export default MarketTradeModal;
