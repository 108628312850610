import "./TradeDetailsModal.css";

import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Modal,
  Paper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  DataHeading,
  DataTypography,
  MainHeading,
} from "../../Styled/TradeModal";
import React, { useState } from "react";

import BasicSection from "../BasicSection";
import Close from "@mui/icons-material/Close";
import CounterPartySection from "../CounterPartySection";
import DynamicSection from "../DynamicSection";
import StandardSection from "../StandardSection";
import { ReactComponent as TransactionsSVG } from "../../../assets/transactions.svg";
import moment from "moment";

const TradeDetailsModal = ({
  open,
  handleClose,
  data,
  variant = null,
  title = null,
}) => {
  const isXL = useMediaQuery(useTheme().breakpoints.up("xl"));
  const [consent, setConsent] = useState(false);
  React.useEffect(() => setConsent(false), [open]);
  return (
    <Modal className={"modal"} open={open} onClose={handleClose}>
      <Box
        sx={{
          width: 550,
        }}
        className={"no-scroll-bar"}
      >
        <Paper
          sx={(theme) => ({
            [theme.breakpoints.up("xl")]: {
              pb: 5,
            },
          })}
        >
          <Box component={"div"} width={"100%"}>
            <Box
              sx={(theme) => ({
                display: "flex",
                justifyContent: "flex-start",
                width: "100%",
                columnGap: 5,
                py: 0.5,
                pt: 1,
                position: "relative",
                [theme.breakpoints.up("xl")]: {
                  pt: 4,
                },
              })}
            >
              <Close className={"c-absolute"} onClick={handleClose} />
              {variant === null ? (
                <React.Fragment>
                  <Box
                    className={"b-flex-end"}
                    width={"20%"}
                    sx={{
                      alignItems: "flex-start",
                    }}
                  >
                    <TransactionsSVG
                      style={{ transform: isXL ? "scale(1.7)" : "scale(1.2)" }}
                    />
                  </Box>
                  <Box width={"80%"}>
                    <MainHeading>{"Trade Details"}</MainHeading>
                    <Box component={"div"} display={"flex"} gap={10}>
                      <Box>
                        <DataHeading>Date Closed</DataHeading>
                        <DataTypography>
                          {moment(Date.now()).format("MMMM DD, YYYY")}
                        </DataTypography>
                      </Box>
                      <Box>
                        <DataHeading>Time Closed</DataHeading>
                        <DataTypography>
                          {moment(Date.now()).format("hh:mm A")}
                        </DataTypography>
                      </Box>
                    </Box>
                  </Box>
                </React.Fragment>
              ) : (
                <Box px={2}>
                  <MainHeading>{title}</MainHeading>
                </Box>
              )}
            </Box>
          </Box>
          <Divider />
          <Box px={2}>
            <BasicSection data={data} />
            <CounterPartySection data={data} />
            <StandardSection data={data} />
            <DynamicSection data={data} />
            {variant === "cancel" && (
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={consent}
                      onChange={(e) => setConsent(!consent)}
                      sx={{ mt: -6 }}
                    />
                  }
                  label={
                    "I agree to cancel this trade. I acknowledge that its possible that this trade was filled during this cancellation request and accept that that is the nature of a market order."
                  }
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    disabled={!consent}
                    disableElevation
                    onClick={handleClose}
                    variant={"contained"}
                    color={"primary"}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default TradeDetailsModal;
