import { Box, Button, Typography } from "@mui/material";

import { Link } from "react-router-dom";
import MultipleTradesTable from "./MultipleTradesTable";
import React from "react";

function MultipleTrades({ SVG, bRef }) {
  return (
    <Box ref={bRef}>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
        }}
      >
        <Box
          component={"div"}
          marginBottom={2}
          display="flex"
          alignItems={"center"}
          width={"100%"}
        >
          <SVG
            style={{
              marginRight: 20,
            }}
          />
          <Typography fontSize={30} fontWeight={"bold"}>
            Multiple Orders Management
          </Typography>
        </Box>

        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          alginItems={"center"}
          width="100%"
        >
          <Button variant={"contained"} color={"primary"}>
            <Link to={"/multiple"}>
              <Typography
                color={"#fff"}
                sx={{
                  textTransform: "uppercase",
                }}
              >
                Full Dashboard
              </Typography>
            </Link>
          </Button>
        </Box>
      </Box>

      <Box component={"div"}>
        <MultipleTradesTable
          rows={[
            {
              orderNumber: Math.floor(Math.random() * 10000000),
              mkt: "Silver",
              ticker: "SLVR",
              bidAsk: `$${Math.floor(Math.random() * 100)}`,
              description: "Reference site about in lorem email",
              quantity: Math.floor(Math.random() * 100),
              price: `$${Math.floor(Math.random() * 1000)}`,
              total: `$${Math.floor(Math.random() * 1000)}`,
            },
            {
              orderNumber: Math.floor(Math.random() * 10000000),
              mkt: "Gold",
              ticker: "GOLD",
              bidAsk: `$${Math.floor(Math.random() * 100)}`,
              description: "Reference site about in lorem email",
              quantity: Math.floor(Math.random() * 100),
              price: `$${Math.floor(Math.random() * 1000)}`,
              total: `$${Math.floor(Math.random() * 1000)}`,
            },
            {
              orderNumber: Math.floor(Math.random() * 10000000),
              mkt: "Platinum",
              ticker: "PLAT",
              bidAsk: `$${Math.floor(Math.random() * 100)}`,
              description: "Reference site about in lorem email",
              quantity: Math.floor(Math.random() * 100),
              price: `$${Math.floor(Math.random() * 1000)}`,
              total: `$${Math.floor(Math.random() * 1000)}`,
            },
            {
              orderNumber: Math.floor(Math.random() * 10000000),
              mkt: "Gold",
              ticker: "AAPL",
              bidAsk: `$${Math.floor(Math.random() * 100)}`,
              description: "Reference site about in lorem email",
              quantity: Math.floor(Math.random() * 100),
              price: `$${Math.floor(Math.random() * 1000)}`,
              total: `$${Math.floor(Math.random() * 1000)}`,
            },
            {
              orderNumber: Math.floor(Math.random() * 10000000),
              mkt: "Gold",
              ticker: "AAPL",
              bidAsk: `$${Math.floor(Math.random() * 100)}`,
              description: "Reference site about in lorem email",
              quantity: Math.floor(Math.random() * 100),
              price: `$${Math.floor(Math.random() * 1000)}`,
              total: `$${Math.floor(Math.random() * 1000)}`,
            },
            {
              orderNumber: Math.floor(Math.random() * 10000000),
              mkt: "Gold",
              ticker: "AAPL",
              bidAsk: `$${Math.floor(Math.random() * 100)}`,
              description: "Reference site about in lorem email",
              quantity: Math.floor(Math.random() * 100),
              price: `$${Math.floor(Math.random() * 1000)}`,
              total: `$${Math.floor(Math.random() * 1000)}`,
            },
          ]}
          headCells={[
            {
              id: "orderNumber",
              numeric: false,
              width: "7%",
              label: "Order#",
            },

            {
              id: "mkt",
              numeric: false,
              width: "7%",
              label: "MKT",
            },
            {
              id: "ticker",
              numeric: false,
              width: "7%",
              label: "Ticker",
            },
            {
              id: "bidAsk",
              numeric: false,
              width: "7%",
              label: "Bid/Ask",
            },
            {
              id: "description",
              numeric: false,
              width: "25%",
              label: "Description",
            },
            {
              id: "quantity",
              numeric: true,
              width: "7%",
              label: "Quantity",
            },
            {
              id: "price",
              numeric: true,
              width: "7%",
              label: "Price",
            },
            {
              id: "total",
              numeric: true,
              width: "5%",
              label: "Total",
            },
            {
              id: "options",
              numeric: false,
              width: "5%",
              disableSorting: true,
              label: "",
            },
            {
              id: "action",
              numeric: false,
              width: "15%",
              label: "Action",
              disableSorting: true,
              sx: {
                justifyContent: "center",
                alignItems: "center",
                fontSize: "large",
                color: "#fff",
                paddingY: 1,
              },
            },
          ]}
          tableTitle={null}
          loading={false}
        />
      </Box>
    </Box>
  );
}

export default MultipleTrades;
