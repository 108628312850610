import { Box } from "@mui/material";
import React from "react";
import { Heading } from "../Styled/Globals";

function SizeGrid() {
  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={"100%"}
      px={5}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bgcolor: "#E5E5E5",
          borderRadius: 2,
          width: 150,
          height: 150,
        }}
      >
        <Heading sx={{ fontSize: 22 }}>1”x1”</Heading>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bgcolor: "#E5E5E5",
          borderRadius: 2,
          width: 250,
          height: 150,
        }}
      >
        <Heading sx={{ fontSize: 22 }}>1”x2”</Heading>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bgcolor: "#E5E5E5",
          borderRadius: 2,
          width: 350,
          height: 190,
        }}
      >
        <Heading sx={{ fontSize: 22 }}>2”x3”</Heading>
      </Box>
    </Box>
  );
}

export default SizeGrid;
