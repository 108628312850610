import {
  Box,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";

import CancelTradeModal from "../../MarketSimulations/CancelTradeModal";
import CustomPagination from "../../Common/CustomPagination";
import EditOrderModal from "../../MarketSimulations/EditOrderModal";
import PropTypes from "prop-types";
import TradeDetailsModal from "../../CommonModals/TradeDetailsModal/TradeDetailsModal";
import moment from "moment";
import { visuallyHidden } from "@mui/utils";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            sx={{
              padding: "4px 0px",
              paddingLeft: index === 0 && 2,
              width: headCell?.width || 100,
              verticalAlign: "bottom",
              textAlign: "left",
              background: headCell?.id === "action" && "#5f99ff",
              borderTopRightRadius: 5,
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell?.disableSorting ? (
              <Box
                component={"div"}
                sx={(theme) => ({
                  width: "100%",
                  color: theme?.palette?.primary?.main,
                  fontSize: "small",
                  padding: "5px 0px",
                  display: "flex",
                  lineHeight: 1.5,
                  justifyContent: "flex-start",
                  columnGap: 0.5,
                  [theme.breakpoints.up("xl")]: {
                    fontSize: "medium",
                  },
                  ...headCell?.sx,
                })}
              >
                {headCell?.label}
              </Box>
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                sx={(theme) => ({
                  fontSize: "small",
                  padding: "5px 0px",
                  display: "flex",
                  lineHeight: 1.5,
                  width: "100%",
                  justifyContent: "flex-start",
                  columnGap: 0.5,
                  color: theme?.palette?.primary?.main,
                  "& .MuiTableSortLabel-icon": {
                    margin: "0px",
                    fontSize: "small",
                  },
                  [theme.breakpoints.up("xl")]: {
                    fontSize: "medium",
                    "& .MuiTableSortLabel-icon": {
                      fontSize: "medium",
                    },
                  },
                  ...headCell?.sx,
                })}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme, center }) => ({
  padding: "10px 0px",
  paddingRight: "5px",
  fontSize: "14px",
  textAlign: center ? "center" : "left",
  border: "none",
  [theme.breakpoints.up("xl")]: {
    fontSize: "16px",
  },
}));

const StyledActionButton = styled(Button)(({ theme }) => ({
  fontSize: "small !important",
  padding: "5px 8px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "medium !important",
  },
}));

export default function OpenTradesManagementTable({
  rows,
  loading,
  headCells,
  onDetailSelect,
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tableData, setTableData] = useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const handleClick = (event, name) => {
  // 	const selectedIndex = selected.indexOf(name);
  // 	let newSelected = [];

  // 	if (selectedIndex === -1) {
  // 		newSelected = newSelected.concat(selected, name);
  // 	} else if (selectedIndex === 0) {
  // 		newSelected = newSelected.concat(selected.slice(1));
  // 	} else if (selectedIndex === selected.length - 1) {
  // 		newSelected = newSelected.concat(selected.slice(0, -1));
  // 	} else if (selectedIndex > 0) {
  // 		newSelected = newSelected.concat(
  // 			selected.slice(0, selectedIndex),
  // 			selected.slice(selectedIndex + 1)
  // 		);
  // 	}

  // 	setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const isSelected = (name) => selected.indexOf(name) !== -1;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [elIndex, setElIndex] = React.useState(null);

  const [openDetails, setOpenDetails] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event, index, data) => {
    setSelected(data);
    setAnchorEl(event.currentTarget);
    setElIndex(index);
  };

  const handleDetails = (data) => {
    setSelected(data);
    setOpenDetails(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setElIndex(null);
  };
  const handleEdit = () => {
    setOpenEdit(true);
    handleClose();
  };

  const handleCancel = () => {
    setOpenCancel(true);
    handleClose();
  };

  return (
    <Box mb={4} sx={{ width: "100%" }}>
      <EditOrderModal
        open={openEdit}
        handleClose={(_) => setOpenEdit(false)}
        order={selected}
      />

      <CancelTradeModal
        open={openCancel}
        handleClose={(_) => setOpenCancel(false)}
      />

      <TradeDetailsModal
        data={selected}
        handleClose={(_) => setOpenDetails(false)}
        open={openDetails}
      />

      <Paper elevation={0} sx={{ width: "100%", mb: 2, borderRadius: 3 }}>
        <Box
          display="flex"
          justifyContent={"center"}
          alignContent={"center"}
          border={"1px solid #c4c4c4"}
          borderRadius={1}
          mb={2}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <TableContainer>
              <Table sx={{ width: "100%" }} size={"small"}>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  headCells={headCells}
                />
                <TableBody>
                  {rows.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={10}>
                        <Typography align="center">No Orders</Typography>
                      </TableCell>
                    </TableRow>
                  ) : null}

                  {stableSort(tableData, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      //   const objectKeys = Object.keys(row);
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row._id}
                        >
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                            sx={{
                              pl: 2,
                            }}
                          >
                            {row?.id}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.status}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                            sx={{}}
                          >
                            {moment(row?.submittedDate)?.format("MM/DD/YYYY")}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.mkt}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.ticker}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.bidAsk}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.quantity}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            ${row?.price}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            ${row?.total}
                          </StyledTableCell>
                          <StyledTableCell
                            component={"td"}
                            id={labelId}
                            scope="row"
                          >
                            <StyledActionButton
                              variant={"contained"}
                              color={"primary"}
                              size={"small"}
                              disableElevation
                              onClick={(_) => handleDetails(row)}
                            >
                              Details
                            </StyledActionButton>
                          </StyledTableCell>
                          <StyledTableCell
                            component={"td"}
                            id={labelId}
                            scope="row"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                width: "100%",
                              }}
                            >
                              <StyledActionButton
                                variant={"contained"}
                                color={"primary"}
                                size={"small"}
                                disableElevation
                                onClick={(_) => onDetailSelect(row)}
                                sx={{ m: "auto" }}
                              >
                                Look
                              </StyledActionButton>
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell
                            component={"td"}
                            id={labelId}
                            scope="row"
                          >
                            <StyledActionButton
                              variant={"contained"}
                              color={"primary"}
                              size={"small"}
                              disableElevation
                              onClick={(e) => handleClick(e, index, row)}
                            >
                              Action
                            </StyledActionButton>

                            <Menu
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={open && elIndex === index}
                              onClose={handleClose}
                              sx={{
                                minWidth: 155,
                              }}
                            >
                              <MenuItem
                                onClick={handleEdit}
                                sx={{
                                  width: 155,
                                }}
                              >
                                <Box
                                  display={"flex"}
                                  justifyContent={"left"}
                                  alignItems="center"
                                  width={"100%"}
                                >
                                  <Typography>Edit</Typography>
                                </Box>
                              </MenuItem>
                              <MenuItem
                                onClick={handleCancel}
                                sx={{
                                  width: 155,
                                }}
                              >
                                <Box
                                  display={"flex"}
                                  justifyContent={"left"}
                                  alignItems="center"
                                  width={"100%"}
                                >
                                  <Typography>Cancel</Typography>
                                </Box>
                              </MenuItem>
                            </Menu>
                          </StyledTableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
        <CustomPagination
          rowsPerPage={rowsPerPage}
          rows={rows}
          setTableData={setTableData}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

const headCells = [
  {
    id: "id",
    numeric: true,
    width: "11%",
    label: (
      <React.Fragment>
        <Box>
          Order # /<br />
          Transaction #
        </Box>
      </React.Fragment>
    ),
  },
  {
    id: "status",
    label: "Status",
    numeric: false,
    width: "5%",
  },
  {
    id: "submittedDate",
    label: "Submitted Date",
    numeric: false,
    width: "12%",
    sx: {
      textAlign: "center",
    },
  },
  {
    id: "mkt",
    numeric: false,
    width: "7%",
    label: "MKT",
  },
  {
    id: "ticker",
    numeric: false,
    width: "5%",
    label: "Ticker",
  },
  {
    id: "bidAsk",
    numeric: false,
    width: "2%",
    label: "Bid/Ask",
  },
  {
    id: "quantity",
    numeric: true,
    width: "5%",
    label: "Quantity",
  },
  {
    id: "price",
    numeric: true,
    width: "5%",
    label: "Price",
  },
  {
    id: "total",
    numeric: false,
    width: "5%",
    label: "Total",
  },
  {
    id: "details",
    numeric: false,
    label: "Details",
    width: "5%",
    disableSorting: true,
  },
  {
    id: "seeInMarket",
    numeric: false,
    label: "See in Market",
    width: "11%",
    disableSorting: true,
    sx: {
      justifyContent: "center",
    },
  },
  {
    id: "actions",
    numeric: false,
    label: "Actions",
    width: "5%",
    disableSorting: true,
  },
];
