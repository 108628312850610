import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  Paper,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { Close } from "@mui/icons-material";
import EditOrderModal from "./EditOrderModal";
import OrderTable from "./OrderTable";
import SearchField from "../Global/SearchField";
import { SubHeading } from "../Styled/Globals";
import TradeDetailsModal from "../CommonModals/TradeDetailsModal/TradeDetailsModal";

const ChoiceButton = styled(Button)(({ theme }) => ({
  width: "100%",
}));

export default function OrderSelectionModal({ open, handleClose }) {
  const [selectedData, setSelectedData] = useState(null);
  const [choice, setChoice] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const handleClick = (data) => {
    setSelectedData(data);
    setShowDialog(true);
  };

  const handleDetailsClose = (_) => {
    setOpenDetails(false);
    setChoice(null);
  };

  const handleEditClose = (_) => {
    setOpenEdit(false);
    setChoice(null);
  };

  useEffect(() => {
    console.log("choice changed", choice);
    if (choice === "details") {
      setShowDialog(false);
      setOpenDetails(true);
    } else if (choice === "edit") {
      setShowDialog(false);
      setOpenEdit(true);
    } else {
      setOpenDetails(false);
      setOpenEdit(false);
      setShowDialog(false);
    }
  }, [choice]);

  return (
    open && (
      <Box>
        <TradeDetailsModal
          open={openDetails}
          handleClose={handleDetailsClose}
          data={selectedData}
        />

        <EditOrderModal
          order={selectedData}
          open={openEdit}
          handleClose={handleEditClose}
        />

        <Dialog onClose={(_) => setShowDialog(false)} open={showDialog}>
          <DialogTitle>
            <Box className={"close-box"}>
              <SubHeading>Select User</SubHeading>
              <Close
                className="pointer"
                fontSize={"inherit"}
                onClick={(_) => setShowDialog(false)}
              />
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box
              display={"flex"}
              width={200}
              flexDirection={"column"}
              rowGap={2}
            >
              <ChoiceButton
                disableElevation
                onClick={(_) => {
                  setChoice("details");
                }}
                variant={"contained"}
                color={"primary"}
              >
                Details
              </ChoiceButton>
              <ChoiceButton
                disableElevation
                onClick={(_) => setChoice("edit")}
                variant={"contained"}
                color={"primary"}
              >
                Edit
              </ChoiceButton>
            </Box>
          </DialogContent>
        </Dialog>

        <Modal open={open} onClose={handleClose} className={"modal"}>
          <Box
            className={"no-scroll-bar"}
            sx={{
              maxWidth: "max-content",
            }}
          >
            <Paper>
              <Box className={"b-flex-end"} sx={{ p: 2 }}>
                <Close className="c-pointer" onClick={handleClose} />
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    p: 2,
                    pt: 0,
                  }}
                >
                  <SubHeading>Select Order</SubHeading>
                  <SearchField width="45%" noMargin title={"Search"} />
                </Box>
                <Divider />
                <Box p={2}>
                  <OrderTable rows={orders} clickHandler={handleClick} />
                </Box>
              </Box>
            </Paper>
          </Box>
        </Modal>
      </Box>
    )
  );
}

const orders = [
  {
    orderTransactionNumber: 1234567890,
    status: "Pending",
    mkt: "Gold",
    ticker: "AAPL",
    bidAsk: "$800",
    description: "Selling my all assets",
    quantity: 200,
    price: "$1,000",
    total: "$1,000",
  },
  {
    orderTransactionNumber: 2244556677,
    status: "Pending",
    mkt: "Gold",
    ticker: "AAPL",
    bidAsk: "$800",
    description: "Selling my all assets",
    quantity: 200,
    price: "$1,000",
    total: "$1,000",
  },
  {
    orderTransactionNumber: 3873746251,
    status: "Pending",
    mkt: "Gold",
    ticker: "AAPL",
    bidAsk: "$800",
    description: "Selling my all assets",
    quantity: 200,
    price: "$1,000",
    total: "$1,000",
  },
  {
    orderTransactionNumber: 4433667755,
    status: "Pending",
    mkt: "Gold",
    ticker: "AAPL",
    bidAsk: "$800",
    description: "Selling my all assets",
    quantity: 200,
    price: "$1,000",
    total: "$1,000",
  },
  {
    orderTransactionNumber: 2938475610,
    status: "Pending",
    mkt: "Gold",
    ticker: "AAPL",
    bidAsk: "$800",
    description: "Selling my all assets",
    quantity: 200,
    price: "$1,000",
    total: "$1,000",
  },
];
