import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled, Typography } from "@mui/material";

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={(theme) => ({
              padding: "0px",
              margin: "0px",
              width: headCell?.width || "max-content",
              verticalAlign: "center",
              textAlign: "left",
              color: "primary",
              borderWidth: "thin",
              borderStyle: "solid",
              borderColor: "rgba(0, 0, 0, 0.12)",
              borderTop: "none",
              py: 2,
              ":first-child": {
                borderLeft: "none",
              },
              ":last-child": {
                borderRight: "none",
              },
            })}
          >
            <Typography
              color={"primary"}
              sx={(theme) => ({
                fontSize: "12px !important",
                fontWeight: "bold",
                textAlign: "center",
                [theme.breakpoints.up("xl")]: {
                  fontSize: "15px !important",
                },
              })}
            >
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function MarketSimulationsTable({ rows }) {
  const StyledTableCell = styled(TableCell)(({ theme, first, last }) => ({
    padding: "10px 0px",
    fontSize: "13px",
    // textAlign: center ? "center" : "left",
    textAlign: "center",
    borderWidth: "thin",
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, 0.12)",
    borderRadius: 5,
    borderLeft: first && "none",
    borderRight: last && "none",
    [theme.breakpoints.up("xl")]: {
      fontSize: "14px",
    },
  }));

  return (
    <Box sx={{ width: "100%" }}>
      <Paper elevation={0} sx={{ p: 1, borderRadius: 3 }}>
        <Paper elevation={0} sx={{ width: "100%", mb: 2 }}>
          <TableContainer
            sx={{
              m: "0px",
              width: "100%",
              borderWidth: "thin",
              borderStyle: "solid",
              borderColor: "rgba(0, 0, 0, 0.12)",
              borderRadius: 5,
              borderBottom: "none",
            }}
          >
            <Table
              sx={{
                width: "100%",
              }}
              aria-labelledby="tableTitle"
              size={"small"}
            >
              <EnhancedTableHead />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {rows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row?.title}
                    >
                      <StyledTableCell
                        component="td"
                        first
                        id={labelId}
                        scope="row"
                        sx={(theme) => ({
                          fontWeight: "bold",
                          textAlign: "left",
                          pl: 1,
                          [theme.breakpoints.up("xl")]: {
                            fontSize: "16px",
                          },
                        })}
                      >
                        {row?.title}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        {row?.coinCurrency}
                      </StyledTableCell>
                      <StyledTableCell
                        center
                        component="td"
                        id={labelId}
                        scope="row"
                      >
                        {row?.gold}
                      </StyledTableCell>
                      <StyledTableCell
                        center
                        component="td"
                        id={labelId}
                        scope="row"
                      >
                        {row?.silver}
                      </StyledTableCell>
                      <StyledTableCell
                        center
                        component="td"
                        id={labelId}
                        scope="row"
                      >
                        {row?.platinum}
                      </StyledTableCell>
                      <StyledTableCell
                        center
                        component="td"
                        id={labelId}
                        scope="row"
                      >
                        {row?.palladium}
                      </StyledTableCell>
                      <StyledTableCell
                        center
                        component="td"
                        id={labelId}
                        scope="row"
                      >
                        {row?.copper}
                      </StyledTableCell>
                      <StyledTableCell
                        center
                        component="td"
                        id={labelId}
                        scope="row"
                      >
                        {row?.rhodium}
                      </StyledTableCell>
                      <StyledTableCell
                        center
                        component="td"
                        id={labelId}
                        scope="row"
                      >
                        {row?.iridium}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        {row?.osmium}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        {row?.rhenium}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        {row?.ruthenium}
                      </StyledTableCell>
                      <StyledTableCell
                        last
                        component="td"
                        id={labelId}
                        scope="row"
                      >
                        {row?.beryllium}
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Paper>
    </Box>
  );
}

const headCells = [
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: "",
    width: "13%",
  },
  {
    id: "coin-and-currency",
    numeric: true,
    disablePadding: true,
    label: "Coin & Currency",
  },
  {
    id: "Gold",
    numeric: true,
    disablePadding: true,
    label: "Gold",
  },
  {
    id: "Silver",
    numeric: true,
    disablePadding: true,
    label: "Silver",
  },
  {
    id: "Platinum",
    numeric: true,
    disablePadding: true,
    label: "Platinum",
  },
  {
    id: "Palladium",
    numeric: true,
    disablePadding: true,
    label: "Palladium",
  },
  {
    id: "Copper",
    numeric: true,
    disablePadding: true,
    label: "Copper",
  },
  {
    id: "Rhodium",
    numeric: true,
    disablePadding: true,
    label: "Rhodium",
  },
  {
    id: "Iridium",
    numeric: true,
    disablePadding: true,
    label: "Iridium",
  },
  {
    id: "Osmium",
    numeric: true,
    disablePadding: true,
    label: "Osmium",
  },
  {
    id: "Rhenium",
    numeric: true,
    disablePadding: true,
    label: "Rhenium",
  },
  {
    id: "Ruthenium",
    numeric: true,
    disablePadding: true,
    label: "Ruthenium",
  },
  {
    id: "Beryllium",
    numeric: true,
    disablePadding: true,
    label: "Beryllium",
  },
];
