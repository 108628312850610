import React from "react";
import { Box, Modal, Paper } from "@mui/material";
import ScheduleForm from "./ScheduleForm";

const HearingRequestModal = ({ open, handleClose, variant }) => {
  return (
    <Modal open={open} onClose={handleClose} className={"modal"}>
      <Box
        sx={{
          width: 850,
        }}
        className={"no-scroll-bar"}
      >
        <Paper>
          <ScheduleForm variant={"Hearing"} handleClose={handleClose} />
        </Paper>
      </Box>
    </Modal>
  );
};

export default HearingRequestModal;
