import {
  Box,
  Button,
  Divider,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import Close from "@mui/icons-material/Close";
import { SubHeading } from "../Styled/Globals";

export default function CustomDateModal({ open, handleClose, onSubmit }) {
  const [dates, setDates] = useState({
    startDate: "2012-02-01",
    endDate: "2013-01-31",
  });

  return (
    <Modal className={"modal"} open={open} onClose={handleClose}>
      <Box className={"no-scroll-bar"} sx={{ width: 450 }}>
        <Paper>
          <Box
            sx={{
              display: "flex",
              p: 2,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <SubHeading>Select Custom Date Range</SubHeading>
            <Close
              onClick={handleClose}
              fontSize={"small"}
              sx={{ cursor: "pointer" }}
            />
          </Box>
          <Divider />
          <Box sx={{ p: 2 }}>
            <Box
              sx={(theme) => ({
                display: "flex",
                alignItems: "center",
                columnGap: 4,
              })}
            >
              <TextField
                id="date"
                label="Start Date"
                type="date"
                defaultValue="2012-02-01"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) =>
                  setDates({ ...dates, startDate: e.target.value })
                }
              />
              <Typography sx={{ fontWeight: "bold" }}>To</Typography>{" "}
              <TextField
                id="date"
                label="End Date"
                type="date"
                defaultValue="2013-01-31"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) =>
                  setDates({ ...dates, endDate: e.target.value })
                }
              />
            </Box>
            <Button
              fullWidth
              variant={"contained"}
              color={"primary"}
              size={"small"}
              disableElevation
              sx={{ mt: 2 }}
              onClick={(_) => onSubmit(dates)}
            >
              Submit
            </Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
}
