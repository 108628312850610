import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import EditOrderModal from "./EditOrderModal";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";
import { visuallyHidden } from "@mui/utils";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    console.log(property, event);
    onRequestSort(event, property);
  };
  const tableStyling = {
    padding: "0px 0px",
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={(theme) => ({
              ...tableStyling,
              width: headCell?.width || "10%",
              verticalAlign: "bottom",
              textAlign: "left",
              fontSize: "small",
              [theme.breakpoints.up("xl")]: {
                fontSize: "medium",
              },
            })}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell?.disableSorting ? (
              headCell?.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                sx={(theme) => ({
                  fontSize: "small",
                  padding: "5px 0px",
                  display: "flex",
                  lineHeight: 1.5,
                  width: "100%",
                  justifyContent: "flex-start",
                  columnGap: 1,
                  "& .MuiTableSortLabel-icon": {
                    margin: "0px",
                    fontSize: "small",
                  },
                  [theme.breakpoints.up("xl")]: {
                    fontSize: "medium",
                    "& .MuiTableSortLabel-icon": {
                      fontSize: "medium",
                    },
                  },
                  ...headCell?.sx,
                })}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function SimulatedTradesTable({ rows, clickHandler }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (item) => {
    console.log(item);
    setSelected(item);
    clickHandler(item);
    // setOpenEditModal(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (item) =>
    item?.orderTransactionNumber === selected?.orderTransactionNumber;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const StyledTableCell = styled(TableCell)(({ theme, center }) => ({
    padding: "10px 0px",
    paddingRight: "5px",
    fontSize: "14px",
    textAlign: center ? "center" : "left",
    border: "none",
    [theme.breakpoints.up("xl")]: {
      fontSize: "18px",
    },
  }));

  // const StyledButton = styled(Button)(({ theme }) => ({
  //   padding: "5px 0px !important",
  //   width: "100%",
  //   fontSize: "14px !important",
  //   [theme.breakpoints.up("xl")]: {
  //     fontSize: "16px !important",
  //   },
  // }));

  const ViewButton = styled(Typography)(({ theme }) => ({
    color: "#5f99ff",
    fontWeight: "normal",
    textDecoration: "underline",
    fontSize: "small !important",
    padding: "0px",
    cursor: "pointer",
    [theme.breakpoints.up("xl")]: {
      fontSize: "medium !important",
    },
  }));

  const [openEditModal, setOpenEditModal] = React.useState(false);

  return (
    <Box sx={{ width: "100%" }}>
      <EditOrderModal
        open={openEditModal}
        handleClose={(_) => setOpenEditModal(false)}
        order={selected}
      />
      <Paper sx={{ width: "100%", m: "auto", borderRadius: 2, padding: 2 }}>
        <TableContainer
          sx={{
            m: "0px",
            width: "100%",
          }}
        >
          <Table
            sx={{ width: "100%" }}
            aria-labelledby="tableTitle"
            size={"small"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  //   const isItemSelected = isSelected(
                  //     row?.orderTransactionNumber
                  //   );

                  return (
                    <TableRow
                      hover
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={(_) => handleClick(row)}
                      role="checkbox"
                      //   selected={isItemSelected}
                      key={row?.orderTransactionNumber}
                    >
                      <StyledTableCell
                        component="td"
                        id={labelId}
                        scope="row"
                        sx={{ pl: 1 }}
                      >
                        {row?.orderTransactionNumber}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        {row?.status}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        {row?.mkt}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        {row?.ticker}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        {row?.bidAsk}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        {row?.description}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        {row?.quantity}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        {row?.price}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        {row?.total}
                      </StyledTableCell>
                      <StyledTableCell component="td" id={labelId} scope="row">
                        <ViewButton
                          variant={"text"}
                          color={"primary"}
                          size={"small"}
                        >
                          View
                        </ViewButton>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Box>
  );
}

const headCells = [
  {
    id: "transactionNumber",
    numeric: false,
    label: "Order Transaction#",
    width: "10%",
  },
  {
    id: "status",
    numeric: false,
    label: "Status",
    width: "8%",
  },
  {
    id: "mkt",
    numeric: false,
    label: "MKT",
    width: "5%",
  },
  {
    id: "ticker",
    numeric: false,
    label: "Ticker",
    width: "8%",
  },
  {
    id: "bid-ask",
    numeric: false,
    label: "Bid/Ask",
    width: "8%",
  },
  {
    id: "description",
    numeric: false,
    label: "Description",
    width: "15%",
  },
  {
    id: "quantity",
    numeric: true,
    label: "Quantity",
    width: "8%",
  },
  {
    id: "price",
    numeric: true,
    label: "Price",
    width: "8%",
  },
  {
    id: "total",
    numeric: true,
    label: "Total",
    width: "8%",
  },
  {
    id: "detail",
    numeric: false,
    label: "Detail",
    width: "5%",
    disableSorting: true,
  },
];
