import React, { useState } from "react";
import { Box, Divider, Modal, Paper, Typography } from "@mui/material";
import QuestionsForm from "./QuestionsForm";
import SingleTransactionTable from "../SingleTransactionTable/SingleTransactionTable";
import { Heading, SubHeading } from "../../Styled/Globals";
import Close from "@mui/icons-material/Close";

const QuestionAnswerModal = ({ open, handleClose, onSubmit, data }) => {
  const [formData, setFormData] = useState({});
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  return (
    <Modal className={"modal"} open={open} onClose={handleClose}>
      <Box
        sx={{
          maxWidth: "95%",
        }}
        className={"no-scroll-bar"}
      >
        <Paper>
          <Box component={"div"} className={"b-relative"}>
            <Heading>Answer Questions on Arbitration Claim </Heading>
            <Close onClick={handleClose} className={"c-absolute"} />
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: 1,
              py: 1,
              px: 4,
            }}
          >
            <Typography component={"p"}>
              The Arbitrator at Bullion MKTS has a question or questions related
              to the transaction listed below that is in Arbitration. Please
              return an answer within 24 hours to avoid a default judgement
              against you.
            </Typography>
            <Box
              sx={{
                borderRadius: 5,
              }}
            >
              <SubHeading>Transaction Details</SubHeading>
              <SingleTransactionTable loading={false} data={data} />
            </Box>
            <Box>
              <QuestionsForm
                formData={formData}
                setFormData={setFormData}
                onChange={onChange}
                handleSubmit={handleSubmit}
              />
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default QuestionAnswerModal;
