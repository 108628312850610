import {
  Box,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useRef } from "react";

import { AdminContainer } from "../../components/Styled/Globals";
import Analysis from "../../components/AdminPanel/Analysis";
import ArbirtationManagement from "../../components/AdminPanel/Arbitrations/ArbirtationManagement";
import Articles from "../../components/AdminPanel/Articles/Articles";
import FundsManagement from "../../components/AdminPanel/FundsManagement/FundsManagement";
import Hexagon from "../../components/Hexagon/Hexagon";
import Lists from "../../components/AdminPanel/Lists/Lists";
import ManageUsers from "../../components/AdminPanel/Users/ManageUsers";
import Management from "../../components/AdminPanel/Management/Management";
import Messaging from "../../components/AdminPanel/Messaging/Messaging";
import NewMarketRequests from "../../components/AdminPanel/NewMarketRequests/NewMarketRequests";
import OrderTransactions from "../../components/AdminPanel/OrderTransactions/OrderTransactions";
import SimulationManagement from "../../components/AdminPanel/SimulationMangement/SimulationManagement";

// import useIntersection from "../../components/hooks/useIntersections";

const AdminPanel = () => {
  const drawerWidth = useMediaQuery(useTheme().breakpoints.up("xl"))
    ? 280
    : 200;

  const analysisRef = useRef(null);
  const contentRef = useRef(null);
  const managementRef = useRef(null);
  const messagingRef = useRef(null);
  const manageUsersRef = useRef(null);
  const ordersRef = useRef(null);
  const fundsRef = useRef(null);
  const newMarketRef = useRef(null);
  const arbitrationRef = useRef(null);
  const simulationsRef = useRef(null);

  // const analysisViewPort = useIntersection(analysisRef, "0px");
  // const contentViewPort = useIntersection(contentRef, "-10px");
  // const managmentViewPort = useIntersection(managementRef, "0px");
  // const messagingViewPort = useIntersection(messagingRef, "0px");
  // const manageUsersViewPort = useIntersection(manageUsersRef, "0px");
  // const ordersViewPort = useIntersection(ordersRef, "0px");
  // const fundsRefViewPort = useIntersection(fundsRef, "0px");
  // const newMarketViewPort = useIntersection(newMarketRef, "0px");
  // const arbitrationViewPort = useIntersection(arbitrationRef, "0px");
  // const simulationsViewPort = useIntersection(simulationsRef, "0px");

  const getSX = (theme, item) => {
    if (item.ref) {
      return {
        fontWeight: "bold",
        fontSize: 28,
        color: "#2152A9",
      };
    }
    return {
      fontWeight: "bold",
      fontSize: 14,
      paddingX: 5,
      [theme.breakpoints.up("xl")]: {
        fontSize: 18,
      },
    };
  };
  const _menus = [
    {
      title: "Analysis",
      handler: () => analysisRef.current?.scrollIntoView(),
      isActive: true,
      // ref: analysisViewPort,
    },
    {
      title: "Content",
      handler: () => contentRef?.current?.scrollIntoView(),
      // ref: !analysisViewPort && contentViewPort,
    },
    {
      title: "Management",
      handler: () => managementRef?.current?.scrollIntoView(),
      // ref: !contentViewPort && managmentViewPort,
    },
    {
      title: "Messaging",
      handler: () => messagingRef?.current?.scrollIntoView(),
      // ref: !managmentViewPort && messagingViewPort,
    },
    {
      title: "Manage Users",
      handler: () => manageUsersRef?.current?.scrollIntoView(),
      // ref: !messagingViewPort && manageUsersViewPort,
    },
    {
      title: "Orders & Transactions",
      handler: () => ordersRef?.current?.scrollIntoView(),
      // ref: !manageUsersViewPort && ordersViewPort,
    },
    {
      title: "Funds Management",
      handler: () => fundsRef?.current?.scrollIntoView(),
      // // ref: !ordersViewPort && fundsRefViewPort,
    },
    {
      title: "New Markets",
      handler: () => newMarketRef?.current?.scrollIntoView(),
      // // ref: !fundsRefViewPort && newMarketViewPort,
    },
    {
      title: "Arbitration",
      handler: () => arbitrationRef?.current?.scrollIntoView(),
      // ref: !newMarketViewPort && arbitrationViewPort,
    },
    {
      title: "Simulations",
      handler: () => simulationsRef?.current?.scrollIntoView(),
      // ref: !arbitrationViewPort && simulationsViewPort,
    },
    {
      title: "View Market as User",
    },
  ];

  return (
    <Box sx={{ display: "flex", mb: { md: -5, xl: -10 } }}>
      <Box
        component={"aside"}
        sx={{
          alignSelf: "start",
          position: "sticky",
          background: "#fff",
          top: 0,
          width: drawerWidth,
          scrollbarWidth: "none" /* Firefox */,
          "-ms-overflow-style": "none" /* IE 10+ */,
          "::-webkit-scrollbar": {
            background: "#c4c4c4" /* Chrome/Safari/Webkit */,
            width: "0px",
          },
        }}
      >
        <List
          sx={(theme) => ({
            overflowY: " auto",
            height: "100vh",
            scrollbarWidth: "none" /* Firefox */,
            "-ms-overflow-style": "none" /* IE 10+ */,
            "::-webkit-scrollbar": {
              background: "#c4c4c4" /* Chrome/Safari/Webkit */,
              width: "0px",
            },
          })}
        >
          {_menus.map((menu, index) => {
            return (
              <ListItem
                button
                key={index}
                onClick={menu.handler}
                sx={{
                  marginY: 1,
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  borderRight: index === 0 ? "6px solid #5F99FF" : "",
                  paddingY: menu.isActive && 2,
                  fontSize: menu.isActive && 20,
                }}
              >
                <Typography sx={(theme) => getSX(theme, menu)}>
                  {menu.title}
                </Typography>
              </ListItem>
            );
          })}
        </List>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <AdminContainer>
          <Hexagon
            width={450}
            title={
              <Box
                display="flex"
                width={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography color={"primary"} fontWeight={"bold"} fontSize={26}>
                  Administration Panel{" "}
                </Typography>
              </Box>
            }
          />

          <Analysis bRef={analysisRef} />
          <Articles bRef={contentRef} />
          <Management bRef={managementRef} />
          <Messaging bRef={messagingRef} />
          <ManageUsers bRef={manageUsersRef} />
          <Lists />
          <OrderTransactions bRef={ordersRef} />
          <FundsManagement bRef={fundsRef} />
          <NewMarketRequests bRef={newMarketRef} />
          <ArbirtationManagement bRef={arbitrationRef} />
          <Box pb={10}>
            <SimulationManagement bRef={simulationsRef} />
          </Box>
        </AdminContainer>
      </Box>
    </Box>
  );
};

export default AdminPanel;
