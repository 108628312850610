import React from "react";
import "./Hexagon.css";
import { Box } from "@mui/material";

const Hexagon = ({ title, width, hasIcon = true }) => {
  return (
    <Box position={"relative"}>
      <div
        style={{
          borderTop: "3px solid #5F99FF",
          borderLeft: "none",
          borderRight: "none",
          borderBottom: "none",
          position: "absolute",
          top: hasIcon ? "50%" : "51%",
          // top: "50%",
          left: "0",
          margin: "auto",
          width: "100%",
        }}
      ></div>
      <Box
        component={"div"}
        className="hexagon"
        margin="10px auto 5px auto"
        sx={(theme) => ({
          // width:width || 350
          width: "500px",
          [theme.breakpoints.up("xl")]: {
            width: "550px",
          },
        })}

        // margin="10px auto 20px auto"
      >
        <Box
          component={"div"}
          margin={"auto"}
          display="flex"
          alignItems={"center"}
          height={"100%"}
          width={"80%"}
          fontSize={18}
          justifyContent={"center"}
        >
          {/* <Typography
            fontSize={30}
            variant={"h2"}
            fontWeight={"bold"}
            color="primary"
          > */}
          {title}
          {/* </Typography> */}
        </Box>
      </Box>
    </Box>
  );
};

export default Hexagon;
