export const sampleArbitrations = [
  {
    transactionNumber: Math.floor(Math.random() * 10000),
    date: new Date("01-02-2022"),
    mkt: "Gold",
    ticker: "AAPL",
    description: "Ut voluptate ullamco enim occaecat adipisicing non qui.",
    quantity: Math.floor(Math.random() * 100),
    price: Math.floor(Math.random() * 1000),
    total: Math.floor(Math.random() * 1000),
    dateFiled: new Date("01-03-2021"),
    type: "Coin",
    dateArchived: Date.now(),
    cause: "Item did not arrive",
    status: "Filed",
  },
  {
    transactionNumber: Math.floor(Math.random() * 10000),
    date: new Date("01-03-2022"),
    mkt: "Silver",
    ticker: "SLVR",
    description:
      "Ullamco et ex esse nulla qui ex cupidatat ad Lorem Lorem nostrud.",
    quantity: Math.floor(Math.random() * 100),
    price: Math.floor(Math.random() * 1000),
    total: Math.floor(Math.random() * 1000),
    dateFiled: new Date("01-04-2021"),
    type: "Coin",
    dateArchived: Date.now(),
    cause: "Item did not arrive",
    status: "Statement Requested",
  },
  {
    transactionNumber: Math.floor(Math.random() * 10000),
    date: new Date("01-02-2022"),
    mkt: "Bronze",
    ticker: "BRZN",
    description: "Irure id Lorem tempor eiusmod.",
    quantity: Math.floor(Math.random() * 100),
    price: Math.floor(Math.random() * 1000),
    total: Math.floor(Math.random() * 1000),
    dateFiled: new Date("01-03-2021"),
    type: "Coin",
    dateArchived: Date.now(),
    cause: "Item did not arrive",
    status: "In Review",
  },
  {
    transactionNumber: Math.floor(Math.random() * 10000),
    date: new Date("01-02-2022"),
    mkt: "Gold",
    ticker: "AAPL",
    description: "Reprehenderit amet aliquip ullamco in ea enim proident.",
    quantity: Math.floor(Math.random() * 100),
    price: Math.floor(Math.random() * 1000),
    total: Math.floor(Math.random() * 1000),
    dateFiled: new Date("01-03-2021"),
    type: "Coin",
    dateArchived: Date.now(),
    cause: "Item did not arrive",
    status: "On Hold",
  },
  {
    transactionNumber: Math.floor(Math.random() * 10000),
    date: new Date("01-03-2022"),
    mkt: "Silver",
    ticker: "SLVR",
    description: "Dolore exercitation occaecat amet veniam sunt eiusmod.",
    quantity: Math.floor(Math.random() * 100),
    price: Math.floor(Math.random() * 1000),
    total: Math.floor(Math.random() * 1000),
    dateFiled: new Date("01-04-2021"),
    type: "Coin",
    dateArchived: Date.now(),
    cause: "Item did not arrive",
    status: "Requested",
  },
  {
    transactionNumber: Math.floor(Math.random() * 10000),
    date: new Date("01-02-2022"),
    mkt: "Bronze",
    ticker: "BRZN",
    description:
      "Non non occaecat Lorem ut cillum dolor irure tempor voluptate.",
    quantity: Math.floor(Math.random() * 100),
    price: Math.floor(Math.random() * 1000),
    total: Math.floor(Math.random() * 1000),
    dateFiled: new Date("01-03-2021"),
    type: "Coin",
    dateArchived: Date.now(),
    cause: "Item did not arrive",
    status: "In Review",
  },
];
