import { Box, Paper, Typography } from "@mui/material";

import Hexagon from "../../components/Hexagon/Hexagon";
import { ReactComponent as MarketQuotesSVG } from "../../assets/market-quotes.svg";
import MarketQuotesTable from "../../components/MarketQuotes/MarketQuotesTable";
import React from "react";
import { StyledContainer } from "../../components/Styled/Globals";
import berylliumImg from "../../assets/metals/small/beryllium.png";
import copperImg from "../../assets/metals/small/copper.png";
import goldImg from "../../assets/metals/small/gold.png";
import iridiumImg from "../../assets/metals/small/iridium.png";
import osmiumImg from "../../assets/metals/small/osmium.png";
import palladiumImg from "../../assets/metals/small/palladium.png";
import platinumImg from "../../assets/metals/small/platinum.png";
import rheniumImg from "../../assets/metals/small/rhenium.png";
import rhodiumImg from "../../assets/metals/small/rhodium.png";
import rutheniumImg from "../../assets/metals/small/ruthenium.png";
import silverImg from "../../assets/metals/small/silver.png";

function MarketQuotes() {
  const _headcells = [
    {
      id: "notificationNumber",
      numeric: false,
      disablePadding: true,
      label: "",
    },
    {
      id: "",
      numeric: false,
      disablePadding: true,
      label: "",
    },
    {
      id: "market-price",
      numeric: false,
      disablePadding: true,
      label: "Market Price",
    },
    {
      id: "average-bid-ask",
      numeric: false,
      disablePadding: true,
      label: "Average Bid/Ask",
    },
    {
      id: "median",
      numeric: false,
      disablePadding: true,
      label: "Median Bid/Ask",
    },
    {
      id: "market-price",
      numeric: false,
      disablePadding: true,
      label: "Market Price",
    },
    {
      id: "average-bid-ask",
      numeric: false,
      disablePadding: true,
      label: "Average Bid/Ask",
    },
    {
      id: "median",
      numeric: false,
      disablePadding: true,
      label: "Median Bid/Ask",
    },
  ];

  const _rowData = [
    {
      metal: "Gold",
      img: goldImg,
      establishment: {
        marketPrice: 200,
        avgBidAsk: 500,
        medianBidAsk: 1000,
      },
      bmkts: {
        marketPrice: 150,
        avgBidAsk: 450,
        medianBidAsk: 850,
      },
    },
    {
      metal: "Silver",
      img: silverImg,
      establishment: {
        marketPrice: 200,
        avgBidAsk: 500,
        medianBidAsk: 1000,
      },
      bmkts: {
        marketPrice: 150,
        avgBidAsk: 450,
        medianBidAsk: 850,
      },
    },
    {
      metal: "Platinum",
      img: platinumImg,
      establishment: {
        marketPrice: 200,
        avgBidAsk: 500,
        medianBidAsk: 1000,
      },
      bmkts: {
        marketPrice: 150,
        avgBidAsk: 450,
        medianBidAsk: 850,
      },
    },
    {
      metal: "Palladium",
      img: palladiumImg,
      establishment: {
        marketPrice: 200,
        avgBidAsk: 500,
        medianBidAsk: 1000,
      },
      bmkts: {
        marketPrice: 150,
        avgBidAsk: 450,
        medianBidAsk: 850,
      },
    },
    {
      metal: "Copper",
      img: copperImg,
      establishment: {
        marketPrice: 200,
        avgBidAsk: 500,
        medianBidAsk: 1000,
      },
      bmkts: {
        marketPrice: 150,
        avgBidAsk: 450,
        medianBidAsk: 850,
      },
    },
    {
      metal: "Rhodium",
      img: rhodiumImg,
      establishment: {
        marketPrice: 200,
        avgBidAsk: 500,
        medianBidAsk: 1000,
      },
      bmkts: {
        marketPrice: 150,
        avgBidAsk: 450,
        medianBidAsk: 850,
      },
    },
    {
      metal: "Iridium",
      img: iridiumImg,
      establishment: {
        marketPrice: 200,
        avgBidAsk: 500,
        medianBidAsk: 1000,
      },
      bmkts: {
        marketPrice: 150,
        avgBidAsk: 450,
        medianBidAsk: 850,
      },
    },
    {
      metal: "Osmium",
      img: osmiumImg,
      establishment: {
        marketPrice: 200,
        avgBidAsk: 500,
        medianBidAsk: 1000,
      },
      bmkts: {
        marketPrice: 150,
        avgBidAsk: 450,
        medianBidAsk: 850,
      },
    },
    {
      metal: "Rhenium",
      img: rheniumImg,
      establishment: {
        marketPrice: 200,
        avgBidAsk: 500,
        medianBidAsk: 1000,
      },
      bmkts: {
        marketPrice: 150,
        avgBidAsk: 450,
        medianBidAsk: 850,
      },
    },
    {
      metal: "Ruthenium",
      img: rutheniumImg,
      establishment: {
        marketPrice: 200,
        avgBidAsk: 500,
        medianBidAsk: 1000,
      },
      bmkts: {
        marketPrice: 150,
        avgBidAsk: 450,
        medianBidAsk: 850,
      },
    },
    {
      metal: "Beryllium",
      img: berylliumImg,
      establishment: {
        marketPrice: 200,
        avgBidAsk: 500,
        medianBidAsk: 1000,
      },
      bmkts: {
        marketPrice: 150,
        avgBidAsk: 450,
        medianBidAsk: 850,
      },
    },
  ];

  return (
    <Box height={"100%"}>
      <StyledContainer>
        <Box>
          <Box display={"flex"} marginTop={0} justifyContent={"flex-end"}>
            <Paper
              sx={{
                paddingX: 5,
              }}
            >
              <Typography
                fontSize={20}
                fontWeight={"bold"}
                sx={{ textDecoration: "underline" }}
                textAlign={"end"}
              >
                Your Cleared Funds
              </Typography>
              <Typography color={"primary"} textAlign={"end"}>
                $000,000,000.00
              </Typography>
            </Paper>
            <br />
          </Box>
          <Box display={"flex"} justifyContent={"flex-end"}>
            <Typography>Available for Trading</Typography>
          </Box>
        </Box>
        <Box style={{ marginTop: -25 }}>
          <Hexagon
            width={450}
            title={
              <Box
                display="flex"
                width={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <MarketQuotesSVG
                  style={{
                    transform: "scale(0.90",
                    marginRight: "10px",
                  }}
                />
                <Typography color={"primary"} fontWeight={"bold"} fontSize={26}>
                  Market Quotes
                </Typography>
              </Box>
            }
          />
        </Box>
        <Box mt={2}>
          <MarketQuotesTable
            rows={_rowData}
            headCells={_headcells}
            tableTitle={null}
            loading={false}
          />
        </Box>
      </StyledContainer>
    </Box>
  );
}

export default MarketQuotes;
