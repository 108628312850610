import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";

import CustomPagination from "../Common/CustomPagination";
import PropTypes from "prop-types";
import moment from "moment";
import { visuallyHidden } from "@mui/utils";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            sx={{
              padding: "0px 0px",
              paddingLeft: index === 0 && 2,
              width: headCell?.width || 100,
              verticalAlign: "bottom",
              textAlign: "left",
              background: headCell?.id === "action" && "#5f99ff",
              borderTopRightRadius: 5,
              borderRadius: 5,
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell?.disableSorting ? (
              <Box
                component={"div"}
                sx={(theme) => ({
                  width: "100%",
                  display: "flex",
                  color: theme?.palette?.primary?.main,
                  fontSize: "16px",
                  [theme.breakpoints.up("xl")]: {
                    fontSize: "medium",
                  },
                  ...headCell?.sx,
                })}
              >
                {headCell?.label}
              </Box>
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                sx={(theme) => ({
                  fontSize: "small",
                  padding: "5px 0px",
                  display: "flex",
                  lineHeight: 1.5,
                  width: "100%",
                  justifyContent: "flex-start",
                  columnGap: 0.3,
                  color: theme?.palette?.primary?.main,
                  "& .MuiTableSortLabel-icon": {
                    margin: "0px",
                    fontSize: "small",
                  },
                  [theme.breakpoints.up("xl")]: {
                    fontSize: "medium",
                    "& .MuiTableSortLabel-icon": {
                      fontSize: "medium",
                    },
                  },
                  ...headCell?.sx,
                })}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function RecentTransactionsTable({
  rows,
  tableTitle,
  loading,
  handleArbitrate,
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tableData, setTableData] = useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  // 	const selectedIndex = selected.indexOf(name);
  // 	let newSelected = [];

  // 	if (selectedIndex === -1) {
  // 		newSelected = newSelected.concat(selected, name);
  // 	} else if (selectedIndex === 0) {
  // 		newSelected = newSelected.concat(selected.slice(1));
  // 	} else if (selectedIndex === selected.length - 1) {
  // 		newSelected = newSelected.concat(selected.slice(0, -1));
  // 	} else if (selectedIndex > 0) {
  // 		newSelected = newSelected.concat(
  // 			selected.slice(0, selectedIndex),
  // 			selected.slice(selectedIndex + 1)
  // 		);
  // 	}

  // 	setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const StyledTableCell = styled(TableCell)(({ theme, center }) => ({
    padding: "10px 0px",
    paddingRight: "5px",
    fontSize: "14px",
    textAlign: center ? "center" : "left",
    border: "none",
    [theme.breakpoints.up("xl")]: {
      fontSize: "16px",
    },
  }));

  const StyledActionButton = styled(Button)(({ theme }) => ({
    fontSize: "small !important",
    padding: "5px 8px",
    [theme.breakpoints.up("xl")]: {
      fontSize: "medium !important",
    },
  }));

  return (
    <Box mb={4} sx={{ width: "100%" }}>
      <Box sx={{ width: "100%", mb: 2, borderRadius: 5, bgcolor: "#fff" }}>
        <Box display="flex" justifyContent={"center"} alignContent={"center"}>
          {loading ? (
            <CircularProgress />
          ) : (
            <TableContainer
              sx={(theme) => ({
                minHeight: "380px",
              })}
            >
              <Table sx={{ width: "100%" }} size={"small"}>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  headCells={headCells}
                />
                <TableBody>
                  {rows.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={10}>
                        <Typography align="center">No Transactions</Typography>
                      </TableCell>
                    </TableRow>
                  ) : null}

                  {stableSort(tableData, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      //   const objectKeys = Object.keys(row);
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row._id}
                          selected={isItemSelected}
                        >
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                            sx={{
                              pl: 2,
                            }}
                          >
                            {row?.status}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.transactionNumber}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {moment(row?.date).format("MMM DD, YYYY")}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.ticker}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.mkt}
                          </StyledTableCell>

                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.description}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            ${row?.price}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.quantity}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            ${row?.total}
                          </StyledTableCell>

                          <StyledTableCell
                            component={"td"}
                            id={labelId}
                            scope="row"
                          >
                            <StyledActionButton
                              variant={"contained"}
                              color={"primary"}
                              size={"small"}
                              disableElevation
                              onClick={(_) => handleArbitrate(row)}
                            >
                              Arbitrate
                            </StyledActionButton>
                          </StyledTableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Box>
      <CustomPagination
        rowsPerPage={rowsPerPage}
        rows={rows}
        setTableData={setTableData}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
}
const headCells = [
  {
    id: "status",
    numeric: false,
    width: "8%",
    label: "Transaction Status",
  },
  {
    id: "transactionNumber",
    numeric: true,
    label: "Transaction #",
    width: "4%",
  },
  {
    id: "date",
    numeric: false,
    label: "Transaction Date",
    width: "4%",
  },
  {
    id: "ticker",
    numeric: false,
    width: "5%",
    label: "Ticker",
  },
  {
    id: "mkt",
    numeric: false,
    width: "5%",
    label: "MKT",
    sx: {
      paddingLeft: "2px",
    },
  },
  {
    id: "description",
    numeric: false,
    width: "25%",
    label: "Description",
  },
  {
    id: "price",
    numeric: true,
    width: "2%",
    label: "Price",
  },
  {
    id: "quantity",
    numeric: true,
    width: "3%",
    label: "Quantity",
  },
  {
    id: "total",
    numeric: false,
    width: "2%",
    label: "Total",
  },
  {
    id: "options",
    numeric: false,
    width: "2%",
    label: "",
  },
];
