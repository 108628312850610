import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import { StyledButton, SubHeading } from "../../Styled/Globals";

import OrderTransactionButton from "./OrderTransactionButton";
import UserOrdersModal from "../Users/UserOrdersModal";
import { styled } from "@mui/lab/node_modules/@mui/system";

function TransactionButtons() {
  const [transactionActions, setTransactionActions] = useState([
    {
      title: "Time Period",
      options: [
        "Last 30 Minutes",
        "Last Hour",
        "Last 12 Hour",
        "Last 24 Hours",
        "Last 48 Hours",
        "Last Week",
        "Last Month",
      ],
      selected: null,
    },
    {
      title: "Type",
      options: ["Order", "Transaction"],
      selected: null,
    },
    {
      title: "Progress",
      options: ["Bid", "Ask", "Closed"],
      selected: null,
    },
    {
      title: "Status",
      options: [
        "Open",
        "Paused",
        "Closed",
        "Shipped",
        "Settled",
        "Expired",
        "Cancelled",
        "Arbitrated",
      ],
      selected: null,
    },
    {
      title: "MKT",
      options: [
        "All",
        "Gold",
        "Silver",
        "Platinum",
        "Palladium",
        "Copper",
        "Rhodium",
        "Iridium",
        "Osmium",
        "Rhenium",
        "Ruthenium",
        "Berylium",
        "Numismatic",
      ],
      selected: null,
    },
    {
      title: "Ticker",
      options: [
        "All",
        "Gold",
        "Silver",
        "Platinum",
        "Palladium",
        "Copper",
        "Rhodium",
        "Iridium",
        "Osmium",
        "Rhenium",
        "Ruthenium",
        "Berylium",
        "Numismatic",
      ],
      selected: null,
    },
    {
      title: "New User",
      options: ["Last 24 Hour", "Last Week", "Last Month", "Last Year"],
      selected: null,
    },
    {
      title: "Total",
      options: [
        "$100-$1,000",
        "$1,000-$5,000",
        "$5,000-$10,000",
        "$10,000-$50,000",
        "$50,000-$100,000",
        "$100,000+",
      ],
      selected: null,
    },
  ]);

  const TickerActionButton = styled(StyledButton)({
    fontSize: 14,
    fontWeight: "normal",
    height: "100%",
    width: "100%",
  });

  const onSelected = (selected, title) => {
    const newArray = transactionActions.map((tAction) => {
      if (title === tAction.title) {
        tAction.selected = selected;
      }
      return tAction;
    });
    setTransactionActions(newArray);
  };

  const [openUserOrders, setOpenUserOrders] = useState(false);
  const [openUserTransactions, setOpenUserTransactions] = useState(false);

  const handleUserOrders = () => setOpenUserOrders(true);
  const handleUserTransactions = () => setOpenUserTransactions(true);

  return (
    <Box>
      <UserOrdersModal
        open={openUserOrders}
        variant={"orders"}
        handleClose={(_) => setOpenUserOrders(false)}
      />

      <UserOrdersModal
        open={openUserTransactions}
        variant={"transactions"}
        handleClose={(_) => setOpenUserTransactions(false)}
      />

      <SubHeading
        sx={{
          my: 2,
        }}
      >
        See Transactions By:
      </SubHeading>
      <Grid container spacing={4}>
        {transactionActions.map((button) => {
          return (
            <Grid item md={6}>
              <OrderTransactionButton
                options={button.options}
                onTimeSelect={(time) => onSelected(time, button.title)}
                selectedTime={button.title}
                extras={button.selected}
                id={button.selected}
              />
              {/* <TickerActionButton>{button.title}</TickerActionButton> */}
            </Grid>
          );
        })}
        <Grid item md={12}>
          <TickerActionButton
            sx={{
              fontSize: 16,
              height: 50,
            }}
            onClick={handleUserOrders}
          >
            See All Orders
          </TickerActionButton>
        </Grid>
        <Grid item md={12}>
          <TickerActionButton
            onClick={handleUserTransactions}
            sx={{
              height: 50,
              fontSize: 16,
            }}
          >
            See All Transactions
          </TickerActionButton>
        </Grid>
      </Grid>
    </Box>
  );
}

export default TransactionButtons;
