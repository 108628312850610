import { Box, Paper, Typography } from "@mui/material";
import React from "react";

function InProgress() {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      my={20}
    >
      <Paper sx={{ padding: 5 }}>
        <Typography fontSize={32} fontWeight={"bold"} textAlign={"center"}>
          Page not found!
        </Typography>
      </Paper>
    </Box>
  );
}

export default InProgress;
