import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import React, { useState } from "react";

import { ReactComponent as ArchiveSVG } from "../../../assets/archive.svg";
import CancelOpenOrderModal from "./CancelOpenOrderModal";
import CustomPagination from "../../../components/Common/CustomPagination";
import EditOpenOrderModal from "./EditOpenOrderModal";
import { ReactComponent as GearSVG } from "../../../assets/gears.svg";
import { ReactComponent as MarkAsReadSVG } from "../../../assets/mark-as-read.svg";
import PropTypes from "prop-types";
import TradeDetailsModal from "../../../components/CommonModals/TradeDetailsModal/TradeDetailsModal";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            sx={{
              padding: "4px 0px",
              pl: index === 0 && 2,
              borderRadius: index === 0 && 10,

              width: headCell?.width || 100,
              verticalAlign: "bottom",
              textAlign: "left",
              background: headCell?.id === "action" && "#5f99ff",
              borderTopRightRadius: 5,
            }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell?.disableSorting ? (
              <Box
                component={"div"}
                sx={{ width: "100%", display: "flex", ...headCell?.sx }}
              >
                {headCell?.label}
              </Box>
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                sx={(theme) => ({
                  fontSize: "small",
                  padding: "5px 0px",
                  display: "flex",
                  lineHeight: 1.5,
                  width: "100%",
                  justifyContent: "flex-start",
                  columnGap: 1,
                  color: theme?.palette?.primary?.main,
                  "& .MuiTableSortLabel-icon": {
                    margin: "0px",
                    fontSize: "small",
                  },
                  [theme.breakpoints.up("xl")]: {
                    fontSize: "medium",
                    "& .MuiTableSortLabel-icon": {
                      fontSize: "medium",
                    },
                  },
                  ...headCell?.sx,
                })}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme, center }) => ({
  padding: "10px 0px",
  paddingRight: "5px",
  fontSize: "14px",
  textAlign: center ? "center" : "left",
  border: "none",
  [theme.breakpoints.up("xl")]: {
    fontSize: "16px",
  },
}));

const StyledActionButton = styled(Button)(({ theme }) => ({
  fontSize: "small !important",
  padding: "5px 8px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "medium !important",
  },
}));
export default function OpenOrderTable({
  rows,
  headCells,
  tableTitle,
  loading,
  onDetailsHandler,
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState();
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuIndex, setMenuIndex] = React.useState(null);
  const [tableData, setTableData] = useState([]);
  const open = Boolean(anchorEl);

  const handleDetailsClick = (data) => {
    setSelected(data);
    setOpenDetailsModal(true);
  };

  const handleEditClick = (data) => {
    setSelected(data);
    setOpenEditModal(true);
  };

  const handleCancelModal = (data) => {
    setSelected(data);
    setOpenCancelModal(true);
  };

  const handleClick = (event, id) => {
    setMenuIndex(id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setMenuIndex(null);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box mb={4} sx={{ width: "100%" }}>
      <TradeDetailsModal
        open={openDetailsModal}
        handleClose={(_) => setOpenDetailsModal(false)}
        data={selected}
      />
      <EditOpenOrderModal
        open={openEditModal}
        handleClose={(_) => {
          setOpenEditModal(false);
          handleClose();
        }}
        data={selected}
      />

      <CancelOpenOrderModal
        open={openCancelModal}
        handleClose={(_) => {
          setOpenCancelModal(false);
          handleClose();
        }}
        data={selected}
      />

      <Paper sx={{ bgcolor: "#fff", width: "100%", mb: 2, borderRadius: 3 }}>
        <Box
          display="flex"
          justifyContent={"center"}
          alignContent={"center"}
          // minHeight={350}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <TableContainer
              sx={{
                minHeight: "360px",
              }}
            >
              <Table sx={{ width: "100%" }} size={"small"}>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  headCells={headCells}
                />
                <TableBody>
                  {rows.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={10}>
                        <Typography align="center">No Open Orders</Typography>
                      </TableCell>
                    </TableRow>
                  ) : null}

                  {stableSort(tableData, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row._id}
                        >
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                            sx={{ pl: 2 }}
                          >
                            {row?.orderNumber}
                          </StyledTableCell>

                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.mkt}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.ticker}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                            style={{}}
                          >
                            ${row?.bidAsk}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.description}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            {row?.quantity}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            ${row?.price}
                          </StyledTableCell>
                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            ${row?.total}
                          </StyledTableCell>

                          <StyledTableCell
                            component="td"
                            id={labelId}
                            scope="row"
                          >
                            <StyledActionButton
                              variant={"contained"}
                              color={"primary"}
                              size={"small"}
                              onClick={(_) => handleDetailsClick(row)}
                              disableElevation
                            >
                              Details
                            </StyledActionButton>
                          </StyledTableCell>
                          <TableCell
                            component="td"
                            id={labelId}
                            scope="row"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              border: "none",
                            }}
                          >
                            <GearSVG
                              onClick={(e) => handleClick(e, labelId)}
                              style={{
                                cursor: "pointer",
                              }}
                            />
                            <Menu
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={open && menuIndex === labelId}
                              onClose={handleClose}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                              sx={{
                                minWidth: 155,
                              }}
                            >
                              <MenuItem
                                onClick={(_) => handleEditClick(row)}
                                sx={{
                                  width: 155,
                                }}
                              >
                                <Box
                                  display={"flex"}
                                  justifyContent={"left"}
                                  alignItems="center"
                                  width={"100%"}
                                >
                                  <MarkAsReadSVG style={{ marginRight: 10 }} />
                                  <Typography>Edit</Typography>
                                </Box>
                              </MenuItem>

                              <MenuItem
                                onClick={(_) => handleCancelModal(row)}
                                sx={{
                                  width: 155,
                                }}
                              >
                                <Box
                                  display={"flex"}
                                  justifyContent={"left"}
                                  alignItems="center"
                                  width={"100%"}
                                >
                                  <ArchiveSVG style={{ marginRight: 10 }} />
                                  <Typography>Cancel</Typography>
                                </Box>
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
      <CustomPagination
        rowsPerPage={rowsPerPage}
        rows={rows}
        setTableData={setTableData}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
}
