import "../../Css/Table.css";

import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import React, { useState } from "react";

import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            sx={
              headCell.id === "actions"
                ? {
                    backgroundColor: "#5f99ff",
                    justifyContent: "center",
                    paddingX: 1,
                    paddingY: 1,
                    borderTopRightRadius: 10,
                  }
                : {
                    paddingX: 1,
                    paddingY: 1,
                  }
            }
            // paddingY={"10px"}
            // paddingX={"none"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={
                headCell.id === "actions"
                  ? {
                      justifyContent: "center",
                      width: "100%",
                      backgroundColor: "#5f99ff",
                      paddingX: 3,
                      paddingY: 0,
                    }
                  : {}
              }
            >
              <Typography
                fontSize={18}
                color={headCell.id === "actions" ? "#fff" : "primary"}
              >
                {headCell.label}
              </Typography>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  rows,
  headCells,
  // actions,

  tableTitle,
  loading,
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const dense = true;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Box mb={4} sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Box
          px={2}
          display="flex"
          justifyContent={"center"}
          alignContent={"center"}
          py={3}
          // minHeight={350}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <TableContainer>
              <Table sx={{ minWidth: 500 }} size={dense ? "small" : "medium"}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}></TableCell>
                    <TableCell colSpan={3}>
                      <Typography
                        fontSize={32}
                        color={"text.dark"}
                        fontWeight={"bold"}
                        textAlign={"center"}
                      >
                        Establishment
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={3}>
                      <Typography
                        fontSize={32}
                        color={"text.dark"}
                        fontWeight={"bold"}
                        textAlign={"center"}
                      >
                        B MKTS
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  headCells={headCells}
                />
                <TableBody>
                  {rows.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={10}>
                        <Typography align="center">No Results</Typography>
                      </TableCell>
                    </TableRow>
                  ) : null}

                  {stableSort(rows, getComparator(order, orderBy)).map(
                    (row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      // const objectKeys = Object.keys(row);
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row._id}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            sx={{ fontSize: 25 }}
                          >
                            {row?.metal}
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            sx={{ minWidth: 250 }}
                          >
                            <img
                              width={50}
                              src={row?.img}
                              alt={row?.metal}
                              className={"center-block "}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            sx={{ fontSize: 18, textAlign: "left" }}
                          >
                            ${row?.establishment?.marketPrice}
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            sx={{ fontSize: 18, textAlign: "center" }}
                            padding="none"
                          >
                            ${row?.establishment?.avgBidAsk}
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            sx={{ fontSize: 18, textAlign: "left" }}
                          >
                            ${row?.establishment?.medianBidAsk}
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            sx={{ fontSize: 18, textAlign: "left" }}
                          >
                            ${row?.bmkts?.marketPrice}
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            sx={{ fontSize: 18, textAlign: "center" }}
                            padding="none"
                          >
                            ${row?.bmkts?.avgBidAsk}
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            sx={{ fontSize: 18, textAlign: "left" }}
                          >
                            ${row?.bmkts?.medianBidAsk}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Paper>
    </Box>
  );
}
