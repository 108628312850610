import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";

import { ReactComponent as BellIcon } from "../../assets/bell-icon.svg";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import Logo from "../../assets/bullion-logo.png";
import Markets from "./Links/Markets";
import NavLink from "./NavLink";
import { ReactComponent as ProfileIcon } from "../../assets/profile-icon.svg";
import ProfileViewModal from "../Profile/ProfileViewModal/ProfileViewModal";
import { StyledContainer } from "../Styled/Globals";

const Navbar = () => {
  const containerStyle = {
    display: "flex",
    // flexDirection: "row",
    // justifyContent: "space-between",
    alignItems: "center",
    height: "75px",
    background: "#fff",
  };

  const [openProfileModal, setOpenProfileModal] = useState(false);

  const learn = [
    { title: "How It Works", link: "/howitworks" },
    { title: "Protections", link: "/protections" },
    {
      title: "Policies",
      link: "/policies",
    },
  ];
  const information = [
    { title: "Blog", link: "/blog" },
    { title: "Knowledge", link: "/knowledge" },
    { title: "Help", link: "/help" },
    // { title: "Help", linK: "/help" },
    { title: "Arbitration", link: "/arbitration" },
  ];
  const bmkts = [
    { title: "About Us", link: "/about" },
    { title: "Partnerships", link: "/partnerships" },
    { title: "Contact Us", link: "/contact" },
  ];
  const profile = [
    { title: "Account", link: "/account" },
    { title: "Settings", link: "/settings" },
    { title: "Funding", link: "/funding" },
    { title: "Open Order", link: "/account/openorders" },
    { title: "Multiple Orders", link: "/multiple" },
    { title: "Transactions", link: "/transactions" },
    { title: "Help", link: "/help" },
  ];

  return (
    <StyledContainer
      style={containerStyle}
      sx={
        {
          // boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%)",
        }
      }
    >
      <Grid container>
        <Grid item md={2}>
          <Link to={"/"}>
            <img src={Logo} alt="bullion-logo" width={120} />
          </Link>
        </Grid>
        <Grid item md={7}>
          <Box
            height={"100%"}
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            display={"flex"}
          >
            <Markets />
            <NavLink
              // anchorEl={openLearn}
              // setAnchorEl={setOpenLearn}
              options={learn}
              name={"How it works"}
              title={"Learn"}
            />
            <NavLink
              // anchorEl={openInformation}
              // setAnchorEl={setOpenInformation}
              options={information}
              title={"Information"}
            />
            <NavLink
              // anchorEl={openBMKTS}
              // setAnchorEl={setOpenBMKTS}
              options={bmkts}
              title={"B MKTS"}
            />
          </Box>
        </Grid>
        <Grid item md={3}>
          <Box
            height={"100%"}
            sx={{
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
            display={"flex"}
          >
            <Link
              to={"/notifications"}
              style={{
                marginTop: "8px",
              }}
            >
              <BellIcon
                style={{
                  width: 30,
                }}
              />
            </Link>
            <NavLink
              // anchorEl={openProfile}
              // setAnchorEl={setOpenProfile}
              options={profile}
              showArrow={false}
              title={
                <ProfileIcon
                  style={{
                    width: 30,
                  }}
                />
              }
            />
            <Typography
              onClick={() => setOpenProfileModal(true)}
              sx={{ cursor: "pointer", fontSize: 18, fontWeight: "bold" }}
            >
              John Doe
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <ProfileViewModal
        open={openProfileModal}
        handleClose={() => setOpenProfileModal(false)}
      />
    </StyledContainer>
  );
};

export default Navbar;
