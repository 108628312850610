import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

import { ReactComponent as BulkSellerSVG } from "../../assets/partnerships/bulk-seller.svg";
import { ReactComponent as ClubsSVG } from "../../assets/partnerships/clubs.svg";
import Hexagon from "../../components/Hexagon/Hexagon";
import { ReactComponent as MarketingSVG } from "../../assets/partnerships/marketing.svg";
import { ReactComponent as PartnershipsHexaSVG } from "../../assets/partnerships.svg";
import { ReactComponent as PartnershipsSVG } from "../../assets/partnerships/partnerships.svg";
import React from "react";
import { StyledContainer } from "../../components/Styled/Globals";
import partnershipsImg from "../../assets/partnerships/partnerships.png";

function Partnerships() {
  const _list = [
    {
      icon: <BulkSellerSVG />,
      title: "Bulk Seller Deals",
      description:
        "Refiners, Mints, Distribution/Sales Companies. We can offer great volume discounts on commissions for partners that can deliver a large amount of supply to the marketplace.",
    },
    {
      icon: <ClubsSVG />,
      title: "Associations, Clubs, and Organizations",
      description:
        "If you represent an entity that can bring a lot of prospective buyers to the Bullion MKTS platform, we’d love to discuss innovative ways for us to work together to do so.",
    },
    {
      icon: <MarketingSVG />,
      title: "Affiliate Marketing",
      description:
        "If you own or represent an entity that has a larger audience that aligns with precious metals and numismatic investors, we’d be happy to discuss innovative ways for us to profit together for driving sellers and buyers to the Bullion MKTS platform.",
    },
    {
      icon: <PartnershipsSVG />,
      title: "Innovative Partnerships ",
      description:
        "Bullion MKTS is open to any other creative and innovative ways that we can work with value-add organizations or individuals.",
    },
  ];

  return (
    <Box height={"100%"} pb={5}>
      <StyledContainer>
        <Hexagon
          title={
            <Box
              display="flex"
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <PartnershipsHexaSVG
                style={{
                  marginRight: "10px",
                }}
              />
              <Typography color={"primary"} fontWeight={"bold"} fontSize={26}>
                Partnerships
              </Typography>
            </Box>
          }
        />
        <Box component={"div"} position={"relative"} marginY={7}>
          <Box
            sx={(theme) => ({
              position: "absolute",
              bgcolor: theme.palette.primary.dark,
              minHeight: "105%",
              borderRadius: 2,
              width: "60vw",
              top: 0,
              right: -20,
              color: theme.palette.primary.dark,
              zIndex: -1,
            })}
          >
            .
          </Box>
          <Grid container spacing={4}>
            <Grid item md={5.5}>
              <Box
                display="flex"
                justifyContent={"flex-end"}
                alignItems="center"
                height={"100%"}
              >
                <img
                  src={partnershipsImg}
                  alt="partnerships"
                  width={"450px"}
                  // width={isXL ? "550px" : "450px"}
                />
              </Box>
            </Grid>
            <Grid item md={6.5}>
              <Box component={"p"} color={"#fff"}>
                <Typography fontSize={22}>
                  Bullion MKTS is always looking for new partnerships that can
                  be mutually beneficial to both parties.
                </Typography>
                <br />
                <Typography fontSize={22}>
                  Specifically, we'd love to talk to you if you are proposing
                  one of the following types of partnerships.
                </Typography>
              </Box>
              <Box>
                <List>
                  {_list.map((item) => {
                    return (
                      <ListItem key={item.title}>
                        <ListItemIcon sx={{ marginRight: 5 }}>
                          <Box
                            bgcolor={"background.dark"}
                            height={85}
                            width={85}
                            borderRadius={"50%"}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            {item.icon}
                          </Box>
                        </ListItemIcon>
                        <ListItemText>
                          <Typography component={"p"} color={"#fff"}>
                            <Typography component={"span"} fontWeight={"bold"}>
                              {item.title}
                            </Typography>{" "}
                            - {item.description}
                          </Typography>
                        </ListItemText>
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </StyledContainer>
    </Box>
  );
}

export default Partnerships;
