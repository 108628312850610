import { Box, Grid, Paper, Typography } from "@mui/material";
import React from "react";

function ProtectionsGrid({ variant }) {
  const _buyer = [
    "System holds Buyer funds in escrow from Sellers for a specific time based on Seller's experience and reputation that is released when certain events tied to delivery occur.",
    "Requires shipping with tracking so Buyers know their order is on-the-way.",
    "Seller is responsible for package until it is delivered at your door.",
    "Trade only with Sellers with a reputational level you trust.",
    "Zero tolerance for fraudulent sellers - they are banned from the site.",
  ];

  const _seller = [
    "Funds are settled on the platform before being able to be traded so payment is not a concern.",
    "Dynamic pricing tools based on real time market quotes with limit, stop, basis +$, basis x %, floor, and ceiling options.",
    "Dashboard designed to manage multiple sell orders at the same time.",
    "Trade only with Buyers with a reputational level you trust.",
    "Zero tolerance for fraudulent buyers - they are banned from the site.",
  ];
  const getMapper = () => {
    return variant === "buyer" ? _buyer : _seller;
  };

  return (
    <Box>
      <Paper
        className={variant === "buyer" ? "" : "bg-dark-accent"}
        elevation={1}
        sx={{ padding: 5, display: "grid", rowGap: 3 }}
      >
        <Typography
          variant={"h2"}
          component={"h2"}
          fontSize={39}
          fontWeight={"bold"}
          color={variant === "buyer" ? "primary.dark" : "#fff"}
          textTransform={"capitalize"}
        >
          {variant} Protections
        </Typography>
        <Grid container spacing={5}>
          {getMapper().map((protection, index) => {
            return (
              <React.Fragment>
                <Grid item xs={12} md={3}>
                  <img
                    src={require(`../../assets/buyer-seller-protections/${variant}/${
                      index + 1
                    }.png`)}
                    className="center-block w-100"
                    alt=""
                    srcset=""
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  sx={{
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Typography
                    variant={"p"}
                    component={"p"}
                    sx={(theme) => {
                      return {
                        fontSize: 24,
                        [theme.breakpoints.up("xl")]: {
                          fontSize: 28,
                        },
                      };
                    }}
                  >
                    {protection}
                  </Typography>
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      </Paper>
    </Box>
  );
}

export default ProtectionsGrid;
