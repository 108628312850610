import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import Hexagon from "../../components/Hexagon/Hexagon";
import { StyledContainer } from "../../components/Styled/Globals";
import { ReactComponent as TransactionsSVG } from "../../assets/transactions.svg";
import SearchField from "../../components/Global/SearchField";
import TransactionsTable from "../MyAccount/Transactions/TransactionsTable";
import { sampleTransactions } from "../../constants/SampleData/transactions";
import TradeDetailsModal from "../../components/CommonModals/TradeDetailsModal/TradeDetailsModal";

function Transactions() {
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const handleDetailModal = (data) => {
    setSelectedData(data);
    setOpenDetailsModal(true);
  };

  return (
    <Box height={"100%"}>
      <TradeDetailsModal
        open={openDetailsModal}
        handleClose={(_) => setOpenDetailsModal(false)}
        data={selectedData}
      />
      <StyledContainer>
        <Hexagon
          title={
            <Box
              display="flex"
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={1.5}
            >
              <TransactionsSVG />
              <Typography color={"primary"} fontWeight={"bold"} fontSize={26}>
                Transactions
              </Typography>
            </Box>
          }
        />
        <Box component={"div"}>
          <Box component={"div"} my={2}>
            <SearchField width={500} title={"Search"} noMargin />
          </Box>
          <Box>
            <TransactionsTable
              onDetailSelect={handleDetailModal}
              showDetails={true}
              showId={true}
              rows={[...sampleTransactions]}
              headCells={[
                {
                  id: "status",
                  numeric: false,
                  width: "1%",
                  label: "Status",
                },
                {
                  id: "transactionNumber",
                  numeric: true,
                  width: "9%",
                  label: "Transaction ID",
                },
                {
                  id: "action",
                  numeric: false,
                  label: "Action",
                  width: "15%",
                  sx: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#fff",
                    fontSize: "large",
                  },
                },
                {
                  id: "mkt",
                  numeric: false,
                  width: "5%",
                  label: "MKT",
                  sx: {
                    paddingLeft: "2px",
                  },
                },
                {
                  id: "ticker",
                  numeric: false,
                  width: "5%",
                  label: "Ticker",
                },
                {
                  id: "bidAsk",
                  numeric: false,
                  width: "2%",
                  label: "Bid/Ask",
                },
                {
                  id: "description",
                  numeric: false,
                  width: "24%",
                  label: "Description",
                },
                {
                  id: "quantity",
                  numeric: true,
                  width: "2%",
                  label: "Quantity",
                },
                {
                  id: "price",
                  numeric: true,
                  width: "2%",
                  label: "Price",
                },
                {
                  id: "total",
                  numeric: false,
                  width: "2%",
                  label: "Total",
                },
                {
                  id: "details",
                  numeric: false,
                  width: "2%",
                  label: "",
                  disableSorting: true,
                },
              ]}
              tableTitle={null}
              loading={false}
            />

            {/* <TransactionsTable rows={_data} tableTitle={null} loading={false} /> */}
          </Box>
        </Box>
      </StyledContainer>
    </Box>
  );
}

export default Transactions;
