import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import CustomPagination from "../../../../Common/CustomPagination";
import moment from "moment";

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            sx={{
              paddingX: 1,
              paddingY: 1,
              maxWidth: 100,
            }}
          >
            <Typography
              fontWeight={"bold"}
              fontSize={14}
              color={"primary.dark"}
            >
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable({ rows, loading }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tableData, setTableData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Paper
        sx={{
          width: "100%",
          border: "1px solid #c4c4c4",
          borderRadius: "5px",
          pb: 1,
        }}
        elevation={0}
      >
        <Box display="flex" justifyContent={"center"} alignContent={"center"}>
          {loading ? (
            <CircularProgress />
          ) : (
            <TableContainer
              sx={{
                minHeight: "180px",
              }}
            >
              <Table sx={{ minWidth: 150, border: "none" }}>
                <EnhancedTableHead headCells={headCells} />
                <TableBody>
                  {tableData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          tabIndex={-1}
                          hover
                          key={row._id}
                          sx={{
                            border: "none",
                          }}
                        >
                          <TableCell
                            component="td"
                            scope="row"
                            sx={{
                              border: "none",
                              textAlign: "center",
                              fontSize: 14,
                              py: 0.5,
                              maxWidth: 50,
                            }}
                            padding={"none"}
                          >
                            {row?.id}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "none",
                              textAlign: "center",
                            }}
                            component="td"
                            padding={"none"}
                            scope="row"
                          >
                            {row?.name}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "none",
                              textAlign: "center",
                            }}
                            component="td"
                            padding={"none"}
                            scope="row"
                          >
                            {moment(row?.date).format("DD MMMM, YYYY")}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "none",
                              textAlign: "center",
                            }}
                            component="td"
                            padding={"none"}
                            scope="row"
                          >
                            {row?.attachment}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "none",
                              textAlign: "center",
                              minWidth: 100,
                            }}
                            component="td"
                            padding={"none"}
                            scope="row"
                          >
                            {row?.link}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
      <CustomPagination
        rowsPerPage={rowsPerPage}
        rows={rows}
        setTableData={setTableData}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
}

const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "Transaction #",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Recording Name",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: true,
    label: "Date",
  },
  {
    id: "attachment",
    numeric: true,
    disablePadding: true,
    label: "Attachment",
  },
  {
    id: "link",
    numeric: true,
    disablePadding: true,
    label: "Link",
  },
];
