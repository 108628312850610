import "./App.css";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import AboutUs from "./views/AboutUs/AboutUs";
import AdminPanel from "./views/AdminPanel/AdminPanel";
import Arbitration from "./views/Arbitration/Arbitration";
import ArchivedNotifications from "./views/Notifications/ArchivedNotifications";
import BerylliumMarket from "./views/Markets/BerylliumMarket";
import BullionNumismaticTypes from "./views/Knowledge/BullionNumismaticTypes";
import BuyerSellerProtection from "./views/Help/BuyerSellerProtection";
import CoinCurrencyMarket from "./components/Markets/CoinCurrencyMarket";
import ContactUs from "./views/ContactUs";
import CopperMarket from "./views/Markets/CopperMarket";
import { CssBaseline } from "@mui/material/";
import Footer from "./components/Footer/Footer";
import Funding from "./views/Funding/Funding";
import GoldMarket from "./views/Markets/GoldMarket";
import Help from "./views/Help";
import HelpArticle from "./views/Help/HelpArticle";
import HistoryPoliticsCulture from "./views/Knowledge/HistoryPoliticsCulture";
import Home from "./views/Home";
import HowItWorks from "./views/Help/HowItWorks";
import IridiumMarket from "./views/Markets/IridiumMarket";
import KnowledgeHome from "./views/Knowledge/KnowledgeHome";
import MarketPlacePolicy from "./views/MarketPlacePolicy";
import MarketQuotes from "./views/MarketQuotes/MarketQuotes";
import MarketSimulations from "./views/MarketSimulations/MarketSimulations";
import Markets from "./views/Markets/Markets";
import MultipleTradesManagement from "./views/MultipleTradesManagement/MultipleTradesManagement";
import MyAccount from "./views/MyAccount/MyAccount";
import Navbar from "./components/Navbar/Navbar";
import NotFound from "./views/NotFound";
import Notifications from "./views/Notifications/Notifications";
import OpenOrders from "./views/OpenOrders/OpenOrders";
import OsmiumMarket from "./views/Markets/OsmiumMarket";
import PalladiumMarket from "./views/Markets/PalladiumMarket";
import Partnerships from "./views/Partnerships/Partnerships";
import PlatinumMarket from "./views/Markets/PlatinumMarket";
import PrivacyAgreement from "./views/PrivacyAgreement";
import React from "react";
import RequestNewMarket from "./views/RequestNewMarket/RequestNewMarket";
import RheniumMarket from "./views/Markets/RheniumMarket";
import RhodiumMarket from "./views/Markets/RhodiumMarket";
import RutheniumMarket from "./views/Markets/RutheniumMarket";
import ScrollToTop from "./components/Global/ScrollToTop";
import Settings from "./views/Settings/Settings";
import ShippingSettlements from "./views/ShippingSettlements/ShippingSettlements";
import SilverMarket from "./views/Markets/SilverMarket";
import TermsAndConditions from "./views/TermsAndConditions";
import TickerMarket from "./views/TIckerMarket/TickerMarket";
import Transactions from "./views/Transactions/Transactions";
import UserAgreement from "./views/UserAgreement";
import { createBreakpoints } from "@mui/system";
import { createBrowserHistory } from "history";

const breakpoints = createBreakpoints({});
const theme = createTheme({
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.MuiTableRow-root:hover": {
            backgroundColor: "#ecf3ff !important",
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          ".MuiTableRow-root.MuiTableRow-root:hover": {
            backgroundColor: "#fff !important",
            borderRadius: 10,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#5F99FF",
          "&.Mui-checked": {
            color: "#5F99FF",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        redText: {
          color: "#FF0000",
          fontSize: 24,
          [breakpoints.down("xl")]: {
            fontSize: 20,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          "&.MuiInputBase-sizeSmall": {
            fontSize: "14px",
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
        InputLabelProps: {
          sx: {
            fontSize: 16,
            [breakpoints.down("xl")]: {
              fontSize: 14,
            },
          },
        },

        inputProps: {
          sx: {
            fontSize: 16,
            [breakpoints.down("xl")]: {
              fontSize: 14,
            },
          },
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        InputLabelProps: {
          sx: {
            fontSize: 16,
            [breakpoints.down("xl")]: {
              fontSize: 14,
            },
          },
        },
        inputProps: {
          sx: {
            fontSize: 16,
            [breakpoints.down("xl")]: {
              fontSize: 14,
            },
          },
        },
      },
    },
    MuiButton: {
      // defaultProps: {
      //   color: "primary",
      //   variant: "contained",
      //   disableElevation: true,

      // },
      styleOverrides: {
        root: ({ ownerState }) => ({
          fontSize: 20,
          textTransform: "capitalize",
          [breakpoints.down("xl")]: {
            fontSize: 16,
          },
          ...(ownerState.color === "primary" && {
            color: "#fff",
          }),
        }),
      },
    },
  },
  palette: {
    background: {
      default: "#ecf3ff",
      dark: "#5F99FF",
      accent: "#1c4895",
    },
    primary: {
      default: "#ecf3ff",
      main: "#5f99ff",
      accent: "#ecf3ff",
      dark: "#2958ac",
    },
    text: {
      dark: "#1c4895",
      info: "#606060",
    },
  },
});

const history = createBrowserHistory();
const App = () => {
  console.log("Width", window.innerWidth);
  console.log("Height", window.innerHeight);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router history={history}>
        <Navbar />
        <ScrollToTop>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Home />} />
            <Route path="/help" element={<Help />} />
            <Route path="/protections" element={<BuyerSellerProtection />} />
            <Route path="/howitworks" element={<HowItWorks />} />
            <Route path="/help/article" element={<HelpArticle />} />
            <Route path="/funding" element={<Funding />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/account" element={<MyAccount />} />
            <Route path="/user" element={<UserAgreement />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/privacy" element={<PrivacyAgreement />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/partnerships" element={<Partnerships />} />
            <Route path="/markets" element={<Markets />} />
            <Route path="/markets/gold" element={<GoldMarket />} />
            <Route path="/markets/silver" element={<SilverMarket />} />
            <Route path="/markets/platinum" element={<PlatinumMarket />} />
            <Route path="/markets/palladium" element={<PalladiumMarket />} />
            <Route path="/markets/copper" element={<CopperMarket />} />
            <Route path="/markets/iridium" element={<IridiumMarket />} />
            <Route path="/markets/osmium" element={<OsmiumMarket />} />
            <Route path="/markets/rhenium" element={<RheniumMarket />} />
            <Route path="/markets/ruthenium" element={<RutheniumMarket />} />
            <Route path="/markets/beryllium" element={<BerylliumMarket />} />
            <Route path="/markets/rhodium" element={<RhodiumMarket />} />
            <Route path="/account/openorders" element={<OpenOrders />} />

            <Route
              path="/markets/coinscurrency"
              element={<CoinCurrencyMarket />}
            />
            <Route path="/market-quotes" element={<MarketQuotes />} />
            <Route path={"/transactions"} element={<Transactions />} />
            <Route path={"/arbitration"} element={<Arbitration />} />
            <Route path={"/multiple"} element={<MultipleTradesManagement />} />
            <Route path={"/request"} element={<RequestNewMarket />} />
            <Route path="/ss" element={<ShippingSettlements />} />
            <Route
              path="/knowledge/markets/ticker"
              element={<TickerMarket />}
            />
            <Route
              path={"/notifications/archived"}
              element={<ArchivedNotifications />}
            />
            <Route path={"/policies"} element={<MarketPlacePolicy />} />
            <Route path="/knowledge" element={<KnowledgeHome />} />
            <Route
              path={"/knowledge/history-politics-culture"}
              element={<HistoryPoliticsCulture />}
            />
            <Route
              path={"/knowledge/bullionnumismatics"}
              element={<BullionNumismaticTypes />}
            />

            <Route
              path={"/admin/simulations"}
              element={<MarketSimulations />}
            />
            <Route path={"/admin"} element={<AdminPanel />} />
          </Routes>
        </ScrollToTop>
        <Footer />
      </Router>
    </ThemeProvider>
  );
};

export default App;
