import {
  AttachmentsGrid,
  StyledTextArea,
  SubHeading,
} from "../../../Styled/Globals";
import {
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Paper,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider, TimePicker } from "@mui/lab";
import React, { useState } from "react";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import AttachmentFile from "../../../Styled/AttachmentFile";
import { Close } from "@mui/icons-material";

const RequestDocumentationModal = ({ open, handleClose }) => {
  //eslint-disable-next-line
  const [files, setFiles] = useState([
    {
      type: "pdf",
      name: "file_1.pdf",
    },
    {
      type: "pdf",
      name: "file_2.pdf",
    },
    {
      type: "pdf",
      name: "file_3.pdf",
    },
  ]);

  return (
    <Modal open={open} onClose={handleClose} className={"modal"}>
      <Box
        sx={{
          width: 800,
        }}
        className={"no-scroll-bar"}
      >
        <Paper>
          <Box className={"close-box"} sx={{ px: 4, py: 1 }}>
            <SubHeading>Request Documentation</SubHeading>
            <Close className={"c-pointer"} onClick={(_) => handleClose()} />
          </Box>
          <Divider />
          <Box px={4} py={2}>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <TextField name={"to"} label={"To"} fullWidth size={"small"} />
              </Grid>
              <Grid item md={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Date"
                    inputFormat="MM/dd/yyyy"
                    onChange={(e) => {}}
                    renderInput={(params) => (
                      <TextField
                        className="calender-icon"
                        {...params}
                        size={"small"}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    onChange={(e) => {}}
                    renderInput={(params) => (
                      <TextField
                        className="calender-icon"
                        {...params}
                        size={"small"}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={12}>
                <TextField
                  name={"question"}
                  label={
                    "Question for Arbitrated Transaction#" +
                    Math.floor(Math.random() * 1000000)
                  }
                  readOnly={true}
                  value={"Dummy question with a dummy transaction number."}
                  fullWidth
                  size={"small"}
                />
              </Grid>
              <Grid item md={12}>
                <StyledTextArea
                  name={"evidence"}
                  placeholder={
                    "Admin writes documentary evidence requested here"
                  }
                  minRows={7}
                  style={{
                    width: "100%",
                    padding: 10,
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <AttachmentsGrid>
                  {files.map((file, index) => (
                    <AttachmentFile
                      name={file?.name}
                      type={file?.type}
                      key={index}
                    />
                  ))}
                </AttachmentsGrid>
              </Grid>
            </Grid>
          </Box>
          <Box
            px={4}
            pb={2}
            display={"flex"}
            width={"100%"}
            justifyContent={"flex-end"}
          >
            <Button
              disableElevation
              variant={"contained"}
              color={"primary"}
              sx={{
                width: "25%",
              }}
              onClick={handleClose}
            >
              Send
            </Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default RequestDocumentationModal;
