import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as PrivacyAgreementSVG } from "../assets/privacy-agreement.svg";
import Hexagon from "../components/Hexagon/Hexagon";
import { StyledContainer } from "../components/Styled/Globals";

const PrivacyAgreement = () => {
  return (
    <Box height={"100%"} paddingBottom={20}>
      <StyledContainer>
        <Hexagon
          width={400}
          title={
            <Box
              display="flex"
              width={"100%"}
              justifyContent={"space-around"}
              alignItems={"center"}
            >
              <PrivacyAgreementSVG />
              <Typography fontWeight={"bold"} color={"primary"} fontSize={26}>
                Privacy Agreement
              </Typography>
            </Box>
          }
        />

        <Box>
          <Paper
            sx={{
              height: "100vh",
              padding: 4,
            }}
          >
            <Typography component={"p"} variant={"p"}>
              Some text Some text Some text Some textSome textSome textSome
              textSome textSome textSome textSome textSome textSome textSome
              textSome textSome textSome textSome textSome textSome textSome
              textSome textSome textSome textSome textSome textSome textSome
              textSome textSome textSome textSome text
            </Typography>
          </Paper>
        </Box>
      </StyledContainer>
    </Box>
  );
};

export default PrivacyAgreement;
