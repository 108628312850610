import { Box, Button, Typography } from "@mui/material";

import ArbitrationTable from "./ArbitrationTable";
import { Link } from "react-router-dom";
import React from "react";
import { sampleArbitrations } from "../../../constants/SampleData/arbitrations";

function Arbitration({ SVG, bRef }) {
  return (
    <Box ref={bRef}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          component={"div"}
          marginBottom={2}
          display="flex"
          alignItems={"center"}
          width={"100%"}
        >
          <SVG
            style={{
              marginRight: 20,
            }}
          />
          <Typography fontSize={30} fontWeight={"bold"}>
            Arbitration
          </Typography>
        </Box>

        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          alginItems={"center"}
          width="100%"
        >
          <Button variant={"contained"} color={"primary"}>
            <Link to={"/arbitration"}>
              <Typography
                color={"#fff"}
                sx={{
                  textTransform: "uppercase",
                }}
              >
                Full Dashboard
              </Typography>
            </Link>
          </Button>
        </Box>
      </Box>

      <Box component={"div"}>
        <ArbitrationTable
          rows={[...sampleArbitrations]}
          headCells={[
            {
              id: "transactionNumber",
              numeric: false,
              width: "5%",
              label: "Transaction#",
            },
            {
              id: "date",
              numeric: false,
              width: "5%",
              label: "Transaction Date",
            },
            {
              id: "mkt",
              numeric: false,
              width: "5%",
              label: "MKT",
            },
            {
              id: "ticker",
              numeric: false,
              width: "5%",
              label: "Ticker",
            },
            {
              id: "description",
              numeric: false,
              width: "10%",
              label: "Description",
            },
            {
              id: "quantity",
              numeric: false,
              width: "5%",
              label: "Quantity",
            },
            {
              id: "price",
              numeric: true,
              width: "5%",
              label: "Price",
            },
            {
              id: "total",
              numeric: true,
              width: "5%",
              label: "Total",
            },
            {
              id: "dateFiled",
              numeric: false,
              width: "5%",
              label: "Date Filed",
            },
            {
              id: "type",
              numeric: false,
              width: "5%",
              label: "Type",
            },
            {
              id: "cause",
              numeric: false,
              width: "10%",
              label: "Cause",
            },
            {
              id: "status",
              numeric: false,
              width: "5%",
              label: "Status",
            },
            {
              id: "action",
              numeric: false,
              width: "5%",
              label: "Action",
              disableSorting: true,
              sx: {
                alignItems: "center",
                justifyContent: "center",
                fontSize: "large",
                color: "#fff",
                padding: 0,
                height: "max-content",
              },
            },
          ]}
          tableTitle={null}
          loading={false}
        />
      </Box>
    </Box>
  );
}

export default Arbitration;
