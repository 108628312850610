import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Heading } from "../../Styled/Globals";
// import "./AuthModals.css";

const ChangeNumberModal = ({ open, handleClose }) => {
  const [data, setData] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    handleClose();
  };

  const handleOnChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });

  return (
    <Modal open={open} onClose={handleClose} className={"modal"}>
      <Box
        className={"no-scroll-bar"}
        sx={{
          maxWidth: "50%",
        }}
      >
        <Paper sx={{ px: 4, py: 2 }}>
          <Box className={"close-box"}>
            <Heading>Change Phone Number for 2FA</Heading>
            <Close className={"c-pointer"} onClick={handleClose} />
          </Box>
          <Box component={"form"} onSubmit={handleSubmit}>
            <Grid marginTop={2} container spacing={2}>
              <Grid item md={4} display="flex" alignItems={"center"}>
                <Typography fontSize={16}>Current 2FA Number</Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  size={"small"}
                  fullWidth
                  type="password"
                  name={"oldPassword"}
                  value={data?.oldPassword}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item md={4} display="flex" alignItems={"center"}>
                <Typography fontSize={16}>New 2FA Number</Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  size={"small"}
                  fullWidth
                  type="password"
                  name={"newPassowrd"}
                  value={data?.newPassowrd}
                  onChange={handleOnChange}
                />
              </Grid>
              <Grid item md={4} display="flex" alignItems={"center"}>
                <Typography fontSize={16}>
                  Enter Current Password to Confirm{" "}
                </Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                  size={"small"}
                  fullWidth
                  type="password"
                  name={"currentPassword"}
                  value={data?.currentPassword}
                  onChange={handleOnChange}
                />
              </Grid>
            </Grid>
            <Box
              component={"div"}
              display="flex"
              justifyContent={"flex-end"}
              alignItems={"center"}
              mt={2}
            >
              {/* BButon */}
              <Button
                type={"submit"}
                variant={"contained"}
                color={"primary"}
                sx={{
                  width: "25%",
                }}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default ChangeNumberModal;
