import { Button, Grid, Menu, MenuItem, Typography } from "@mui/material";
import { Heading, StyledContainer } from "../../components/Styled/Globals";
import React, { useState } from "react";

import { Box } from "@mui/system";
import CancelOrderSelectionModal from "../../components/MultipleTradesManagement/CancelMultipleOrders/CancelOrderSelectionModal";
import Hexagon from "../../components/Hexagon/Hexagon";
import ManagePriceNotificationsModal from "../../components/MultipleTradesManagement/ManagePriceNotificationsModal/ManagePriceNotificationsModal";
import { ReactComponent as MultipleTradesManagementSVG } from "../../assets/multiple-trades.svg";
import NewMultipleOrdersModal from "../../components/MultipleTradesManagement/NewMultipleOrdersModal/NewMultipleOrdersModal";
import NewOrderModal from "../../components/CommonModals/NewOrderModal/NewOrderModal";
import OrdersTable from "./OrdersTable";
import PriceNotificationForm from "../../components/MultipleTradesManagement/PriceNotification/PriceNotificationForm";
import SearchField from "../../components/Global/SearchField";
import TradeDetailsModal from "../../components/CommonModals/TradeDetailsModal/TradeDetailsModal";
import { styled } from "@mui/lab/node_modules/@mui/system";

// import OpenOrderTable from "../MyAccount/OpenOrder/OpenOrderTable";

// import OpenOrderTable from "../../components/Tables/OpenOrderTable/OpenOrderTable";

// import OpenOrderModal from "../../components/CommonModals/OpenOrderModal/OpenOrderModal";

const DarkButton = styled(Button)({
  variant: "contained",
  backgroundColor: "#2152A9",
  color: "#fff",
  display: "block",
  textTransform: "none",
  fontWeight: "bold",
  fontSize: 30,
  height: "100%",
  width: "100%",
  padding: "32px 5px",
  ":hover": {
    color: "#fff",
    backgroundColor: "#2958ac",
  },
});
function MultipleTradesManagement() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentMenu, setCurrentMenu] = React.useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, menu) => {
    setAnchorEl(event.currentTarget);
    setCurrentMenu(menu);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (data) => {
    setSelectedData(data);
    setOpenEditOrder(true);
  };

  const handleCancel = (data) => {
    setSelectedData(data);
    setOpenCancelSingleOrder(true);
  };

  const [openPriceNotifications, setOpenPriceNotifications] = useState(false);
  const [openCreateSingleOrder, setOpenCreateSingleOrder] = useState(false);
  const [openCancelSingleOrder, setOpenCancelSingleOrder] = useState(false);
  const [openCreateMultipleOrders, setOpenCreateMultipleOrders] =
    useState(false);
  const [openCancelMultipleOrdersModal, setOpenCancelMultipleOrdersModal] =
    useState(false);
  const [openEditOrder, setOpenEditOrder] = useState(false);

  return (
    <Box height={"100%"}>
      <StyledContainer>
        <Hexagon
          width={525}
          title={
            <Box
              display="flex"
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={1.5}
            >
              <MultipleTradesManagementSVG />
              <Typography color={"primary"} fontWeight={"bold"} fontSize={26}>
                Multiple Orders Mangement
              </Typography>
            </Box>
          }
        />

        <Box
          display={"flex"}
          width={475}
          justifyContent={"center"}
          margin={"auto"}
          alignItems={"center"}
        >
          <SearchField width="100%" title={"Search"} />
        </Box>
        <StyledContainer>
          <Grid container spacing={4}>
            <Grid item md={4}>
              <Button
                id="demo-positioned-button"
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={(e) => handleClick(e, "create")}
                variant={"contained"}
                sx={{
                  variant: "contained",
                  backgroundColor: "#2152A9",
                  color: "#fff",
                  display: "block",
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: 30,
                  height: "100%",
                  width: "100%",
                  padding: "32px 5px",
                  ":hover": {
                    color: "#fff",
                    backgroundColor: "#2958ac",
                  },
                }}
                // onClick={() => setOpenCreateSingleOrder(true)}
              >
                Create <br /> New Order (s)
              </Button>
              <Menu
                id="create-menu"
                aria-labelledby="create-button"
                anchorEl={anchorEl}
                open={open && currentMenu === "create"}
                onClose={handleClose}
              >
                <MenuItem
                  sx={{ width: "100%" }}
                  onClick={() => {
                    setOpenCreateSingleOrder(true);
                    handleClose();
                  }}
                >
                  Single Order
                </MenuItem>
                <MenuItem
                  sx={{ width: "100%" }}
                  onClick={() => {
                    setOpenCreateMultipleOrders(true);
                    handleClose();
                  }}
                >
                  Multiple Orders
                </MenuItem>
              </Menu>
            </Grid>
            <Grid item md={4}>
              <DarkButton onClick={() => console.log("edit ")}>
                Edit <br /> Existing Order (s)
              </DarkButton>
            </Grid>
            <Grid item md={4}>
              <DarkButton
                onClick={(e) => handleClick(e, "cancel")}
                // onClick={() => setOpenCancelMultipleOrdersModal(true)}
              >
                Cancel <br /> Existing Order (s)
              </DarkButton>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open && currentMenu === "cancel"}
                onClose={handleClose}
              >
                <MenuItem
                  sx={{ width: "100%" }}
                  onClick={() => {
                    setOpenCancelSingleOrder(true);
                    handleClose();
                  }}
                >
                  Single Order
                </MenuItem>
                <MenuItem
                  sx={{ width: "100%" }}
                  onClick={() => {
                    setOpenCancelMultipleOrdersModal(true);
                    handleClose();
                  }}
                >
                  Multiple Orders
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </StyledContainer>

        <Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"100%"}
          >
            <Heading width={"100%"}>Open Orders</Heading>
            <SearchField width="750px" title={"Search Open Orders"} />
          </Box>
          <Box>
            {/* <TradesTable
              headCells={openOrdersHeadCells}
              rows={_openOrderData}
            /> */}
            <OrdersTable
              rows={_openOrderData}
              headCells={openOrdersHeadCells}
              handleEdit={handleEdit}
              handleCancel={handleCancel}
            />
          </Box>
        </Box>
        {/* Executed Trades */}
        <Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"100%"}
          >
            <Heading width={"100%"}>Executed Trades</Heading>
            <SearchField width="750px" title={"Search Executed Trades"} />
          </Box>
          <Box>
            {/* <OpenOrderTable
              headCells={openOrdersHeadCells}
              rows={_openOrderData}
            /> */}
            <OrdersTable
              rows={_openOrderData}
              headCells={openOrdersHeadCells}
              handleEdit={handleEdit}
              handleCancel={handleCancel}
            />
          </Box>
        </Box>

        {/* Price Notification */}
        <Box>
          <Heading width={"100%"}>Executed Trades</Heading>
          <PriceNotificationForm
            onEditExisting={() => setOpenPriceNotifications(true)}
          />
        </Box>
      </StyledContainer>
      <NewOrderModal
        title={"Create New Order"}
        open={openCreateSingleOrder}
        handleClose={() => setOpenCreateSingleOrder(false)}
      />
      <NewOrderModal
        title={"Edit Order"}
        open={openEditOrder}
        handleClose={(_) => {
          setOpenEditOrder(false);
          setSelectedData(null);
        }}
        editData={selectedData}
      />
      <NewMultipleOrdersModal
        open={openCreateMultipleOrders}
        handleClose={() => setOpenCreateMultipleOrders(false)}
      />
      <ManagePriceNotificationsModal
        open={openPriceNotifications}
        handleClose={() => setOpenPriceNotifications(false)}
      />
      <CancelOrderSelectionModal
        open={openCancelMultipleOrdersModal}
        handleClose={() => setOpenCancelMultipleOrdersModal(false)}
      />
      <TradeDetailsModal
        open={openCancelSingleOrder}
        handleClose={(_) => {
          setOpenCancelSingleOrder(false);
        }}
        data={selectedData}
        variant={"cancel"}
        title="Cancel Trade"
      />
    </Box>
  );
}

// const _singleOpenOrder = {
//   transactionNumber: Math.floor(Math.random() * 59595),
//   mkt: "Gold",
//   ticker: "AAPL",
//   bidAsk: 800,
//   description: "Reference site about in lorem email",
//   quantity: 200,
//   price: 1000,
//   total: 1000,
// };

const _openOrderData = [
  {
    orderNumber: 1234567890,
    mkt: "Gold",
    ticker: "AAPL",
    bidAsk: 800,
    description: "Reference site about in lorem email",
    quantity: 200,
    price: 1000,
    total: 1000,
  },
  {
    orderNumber: 4356783514,
    mkt: "Platinum",
    ticker: "PLAT",
    bidAsk: 800,
    description: "Reference site about in lorem email",
    quantity: 500,
    price: 2000,
    total: 3500,
  },
  {
    orderNumber: 8473920582,
    mkt: "Silver",
    ticker: "SLVR",
    bidAsk: 830,
    description: "Reference site about in lorem email",
    quantity: 250,
    price: 1030,
    total: 1020,
  },
  {
    orderNumber: 2984769302,
    mkt: "Gold",
    ticker: "AAPL",
    bidAsk: 800,
    description: "Reference site about in lorem email",
    quantity: 200,
    price: 1000,
    total: 1000,
  },
  {
    orderNumber: 1385739584,
    mkt: "Gold",
    ticker: "AAPL",
    bidAsk: 800,
    description: "Reference site about in lorem email",
    quantity: 200,
    price: 1000,
    total: 1000,
  },
  {
    orderNumber: 9585746303,
    mkt: "Gold",
    ticker: "AAPL",
    bidAsk: 800,
    description: "Reference site about in lorem email",
    quantity: 200,
    price: 1000,
    total: 1000,
  },
];

const openOrdersHeadCells = [
  {
    id: "orderNumber",
    numeric: false,
    width: "10%",
    label: "Order#",
  },
  {
    id: "mkt",
    numeric: false,
    width: "5%",
    label: "MKT",
  },
  {
    id: "ticker",
    numeric: false,
    width: "2%",
    label: "Ticker",
  },
  {
    id: "bidAsk",
    numeric: false,
    width: "2%",
    label: "Bid/Ask",
  },
  {
    id: "description",
    numeric: false,
    width: "25%",
    label: "Description",
  },
  {
    id: "quantity",
    numeric: true,
    width: "5%",
    label: "Quantity",
  },
  {
    id: "price",
    numeric: true,
    width: "5%",
    label: "Price",
  },
  {
    id: "total",
    numeric: true,
    width: "5%",
    label: "Total",
  },
  {
    id: "options",
    numeric: false,
    width: "5%",
    label: "",
    disableSorting: true,
  },
  {
    id: "action",
    numeric: false,
    width: "15%",
    label: "Action",
    disableSorting: true,
    sx: {
      alignItems: "center",
      justifyContent: "center",
      fontSize: "large",
      color: "#fff",
      paddingY: 1,
    },
  },
];

export default MultipleTradesManagement;
