import { Box } from "@mui/material";
import React, { useState } from "react";
import { CardButton } from "../../Styled/Globals";
import ArticleSearchModal from "./ArticleSearchModal";
import ArticleTypeModal from "./ArticleTypeModal";

const Articles = ({ bRef }) => {
  const [openArticleModal, setOpenArticleModal] = useState(false);
  const [openArticleSearchModal, setOpenArticleSearchModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [searchType, setSearchType] = useState("Test");

  return (
    <Box ref={bRef} display={"flex"} width={"100%"} my={5} columnGap={4}>
      <CardButton
        onClick={() => {
          setModalType("Edit");
          setOpenArticleModal(true);
        }}
      >
        Edit Existing Article
      </CardButton>
      <CardButton
        onClick={() => {
          setModalType("Create");
          setOpenArticleModal(true);
        }}
      >
        Create New Article
      </CardButton>
      <CardButton
        onClick={() => {
          setModalType("Remove");
          setOpenArticleModal(true);
        }}
      >
        Remove Article
      </CardButton>

      <ArticleTypeModal
        title={modalType}
        open={openArticleModal}
        handleClose={() => setOpenArticleModal(false)}
        handleSubmit={(type) => {
          if (modalType === "Edit") {
            setOpenArticleModal(false);
            setSearchType(type);
            setOpenArticleSearchModal(true);
          } else {
            setOpenArticleModal(false);
          }
        }}
      />
      <ArticleSearchModal
        type={searchType}
        open={openArticleSearchModal}
        handleClose={() => setOpenArticleSearchModal(false)}
      />
    </Box>
  );
};

export default Articles;
