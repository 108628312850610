import {
  Box,
  Grid,
  Paper,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { ReactComponent as AboutLogoSVG } from "../../assets/about-us/about-logo.svg";
import Hexagon from "../../components/Hexagon/Hexagon";
import React from "react";
import andyImg from "../../assets/about-us/andy.png";
import companyImg from "../../assets/about-us/company.png";
import copper from "../../assets/homepage/copper.png";
import gold from "../../assets/homepage/gold.png";
import palladium from "../../assets/homepage/palladium.png";
import platinum from "../../assets/homepage/platinum.png";
import silver from "../../assets/homepage/silver.png";

const LeadershipText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xl")]: {
    fontSize: 20,
  },
}));

const About = () => {
  const _metalsRow = [gold, silver, platinum, palladium];
  const isXL = useMediaQuery(useTheme().breakpoints.up("xl"));

  return (
    <Box>
      <Box
        sx={{
          paddingRight: "50px",
          paddingLeft: "50px",
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        <Hexagon
          width={400}
          title={
            <Box
              display="flex"
              justifyContent={"center"}
              margin={"auto"}
              width={"100%"}
              gap={4}
              alignItems={"center"}
            >
              <AboutLogoSVG />
              <Typography fontSize={26} color={"primary"} fontWeight={"bold"}>
                About Us
              </Typography>
            </Box>
          }
        />
        <Box component={"div"}>
          <Typography variant={"h4"} fontWeight={"bold"} component={"h4"}>
            Company
          </Typography>
          <Box component={"div"} position={"relative"}>
            <Box
              sx={(theme) => ({
                position: "absolute",
                bgcolor: theme.palette.primary.dark,
                minHeight: "105%",
                borderRadius: 2,
                width: "60vw",
                top: 0,
                right: -20,
                color: theme.palette.primary.dark,
                zIndex: -1,
              })}
            >
              .
            </Box>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Box
                  sx={(theme) => ({
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    height: "100%",
                  })}
                >
                  <img
                    src={companyImg}
                    alt="company"
                    width={isXL ? 550 : 450}
                  />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Box>
                  <Paper sx={{ width: "100%", paddingX: 4, paddingY: 1 }}>
                    <Box display={"flex"}>
                      {_metalsRow.map((metal) => {
                        return (
                          <img
                            src={metal}
                            alt="metal"
                            width={isXL ? 100 : 65}
                            className={"center-block"}
                          />
                        );
                      })}
                    </Box>
                    <CompanyTextBox />
                  </Paper>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box component={"div"} paddingBottom={5} paddingTop={5}>
          <Typography variant={"h4"} fontWeight={"bold"} component={"h4"}>
            Leadership
          </Typography>
          <Box position={"relative"}>
            <Box className={"vertical-blue-bg-andy"}></Box>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Box
                  display={"flex"}
                  alignItems={"start"}
                  justifyContent={"flex-end"}
                >
                  <img src={andyImg} alt="andy-founder-ceo" />
                </Box>
              </Grid>
              <Grid item md={6}>
                <Typography variant={"h4"} fontWeight={"bold"} component={"h4"}>
                  Andrew D. Polacek, Founder &#38; CEO
                </Typography>
                <Box
                  // bgcolor={"background.dark"}
                  position="relative"
                  color={"#fff"}
                  className={"founder-notes"}
                  padding={2}
                  borderRadius={3}
                  fontWeight={"bold"}
                >
                  <Box
                    sx={(theme) => ({
                      position: "absolute",
                      bgcolor: theme.palette.primary.main,
                      minHeight: "105%",
                      borderRadius: 2,
                      width: "60vw",
                      top: 0,
                      right: -20,
                      color: theme.palette.primary.main,
                      zIndex: -1,
                    })}
                  >
                    .
                  </Box>
                  <LeadershipText component={"p"} variant={"p"}>
                    Mr. Polacek is a former middle market investment banker and
                    private equity executive. More recently, he was the top
                    business development strategist and revenue generator at an
                    Inc5000 consumer technology company.
                  </LeadershipText>
                  <br />
                  <LeadershipText component={"p"} variant={"p"}>
                    Values, morals, and ethics are more important to Mr. Polacek
                    than profit, and this alienated him from most of those he
                    worked with and for during his career, leading him to become
                    an entrepreneur.
                  </LeadershipText>
                  <br />
                  <LeadershipText component={"p"} variant={"p"}>
                    Mr. Polacek formed BULLION MKTS after being banned from
                    purchasing precious metals on eBay with no legitimate reason
                    or explanation.
                  </LeadershipText>{" "}
                  <br />
                  <LeadershipText component={"p"} variant={"p"}>
                    He has been an investor of precious metals and numismatics
                    for over 15 years.
                  </LeadershipText>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box component={"div"}>
          <Typography variant={"h4"} fontWeight={"bold"} component={"h4"}>
            Mission &#38; Vision
          </Typography>
          <Box
            component={"p"}
            variant={"p"}
            fontSize={20}
            textAlign={"justify"}
          >
            <Typography component={"p"} variant={"p"}>
              BULLION MKTS mission is to provide ethical, fair, and free
              marketplaces, free of manipulation, for every precious metal
              commodity and numismatic item there is. We will accomplish this
              through offering low commissions and low to zero payment
              processing fees as well as building better products that add value
              to buyers and sellers and focus on user's needs.
            </Typography>
            <br />
            <Typography component={"p"} variant={"p"}>
              Our vision is to become the preeminent digital marketplace for
              precious metals and numismatics to supplant the large, corrupt
              entities that currently set prices through collusion with
              globalist criminals. We want to be the People's exchange of hard
              money assets.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default About;

const CompanyTextBox = () => {
  const CompanyText = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    [theme.breakpoints.up("xl")]: {
      fontSize: "17px",
    },
  }));
  const isXL = useMediaQuery(useTheme().breakpoints.up("xl"));

  return (
    <Box
      sx={(theme) => ({
        textAlign: "justify",
        display: "flex",
        flexDirection: "column",
        rowGap: 1,
        [theme.breakpoints.up("xl")]: {
          rowGap: 1.5,
        },
      })}
    >
      <CompanyText component={"p"} variant={"p"}>
        Bullion MKTS was founded in 2021 by Andrew D. Polacek, a former finance
        and technology executive, as an online marketplace to commoditize
        physical precious metals and numismatics for trade under ticker symbols.
        The vision of the Bullion MKTS is to create a People's precious metals
        marketplace to make markets more fair, free, and ethical than the
        current legacy options.
      </CompanyText>
      <CompanyText component={"p"} variant={"p"}>
        After Mr. Polacek was banned from buying precious metals on eBay and
        they gave him the run around on why he was banned and refused to
        reinstate his ability to purchase precious metals on the platform, he
        decided to answer the question: ”What are you going to do, build your
        own? with “Yes, and better.”
      </CompanyText>
      <CompanyText component={"p"} variant={"p"}>
        This is a time period of embracing the future while remembering and
        harnessing the lessons from the past - including the lessons of the
        perpetual value of hard money.
      </CompanyText>
      <CompanyText component={"p"} variant={"p"}>
        We strive to deliver to our partners, buyers, sellers, and anyone we do
        business with the same level of honesty, transparency, ethics, and good
        faith that we expect from them.
      </CompanyText>
      <CompanyText component={"p"} variant={"p"}>
        We are self-financed, independent from any and all banks, financial
        institutions, and/or globalist oligarchs or their incompetent and
        unethical institutions.
      </CompanyText>
      <Box display={"flex"} justifyContent={"space-between"}>
        <CompanyText component={"p"} variant={"p"}>
          We are based in St. Louis, MO.
        </CompanyText>
        <img src={copper} width={isXL ? 100 : 75} alt={"copper"} />
      </Box>
    </Box>
  );
};
