import { Box, Modal, Paper } from "@mui/material";

import ArchivedArbitrationsTable from "./ArchivedArbitrationsTable";
import Close from "@mui/icons-material/Close";
import { Heading } from "../../Styled/Globals";
import React from "react";
import { sampleArbitrations } from "../../../constants/SampleData/arbitrations";

function ArhivedModal({ open, handleClose }) {
  return (
    <Modal className={"modal"} open={open} onClose={handleClose}>
      <Box className={"no-scroll-bar"} sx={{ maxWidth: "90%" }}>
        <Paper
          sx={(theme) => ({
            p: 2,
          })}
        >
          <Box sx={(theme) => ({ display: "flex", flexDirection: "column" })}>
            <Box className="close-box" mb={2}>
              <Heading>Archived Arbitrations</Heading>
              <Close className={"c-pointer"} onClick={handleClose} />
            </Box>

            <Box>
              <ArchivedArbitrationsTable rows={[...sampleArbitrations]} />
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
}

export default ArhivedModal;
