import { getRandomNumber } from "./helper";
import moment from "moment";

export const sampleSimulations = [
  {
    id: Math.floor(Math.random() * 999999999),
    description:
      "In deserunt pariatur culpa Lorem esse do consectetur occaecat.",
    status: "Awaiting",
    submittedDate: Date.now(),
    mkt: "Gold",
    ticker: "GLD",
    bidAsk: "Bid",
    quantity: Math.floor(Math.random() * 22),
    price: Math.floor(Math.random() * 2828),
    total: Math.floor(Math.random() * 9999),
  },
  {
    id: Math.floor(Math.random() * 9939999),
    description:
      "Ut laboris est aute incididunt dolor minim et nisi exercitation excepteur amet ut.",
    status: "Confirmed",
    submittedDate: Date.now(),
    mkt: "Silver",
    ticker: "SLVR",
    bidAsk: "Ask",
    quantity: Math.floor(Math.random() * 22),
    price: Math.floor(Math.random() * 2828),
    total: Math.floor(Math.random() * 9999),
  },
  {
    id: Math.floor(Math.random() * 9939999),
    status: "Awaiting",
    description: "Velit duis consequat ullamco veniam.",

    submittedDate: Date.now(),
    mkt: "Platinum",
    ticker: "PLAT",
    bidAsk: "Bid",
    quantity: Math.floor(Math.random() * 22),
    price: Math.floor(Math.random() * 2828),
    total: Math.floor(Math.random() * 9999),
  },
  {
    id: Math.floor(Math.random() * 9939999),
    status: "Awaiting",
    submittedDate: Date.now(),
    description:
      "Aute exercitation magna dolor occaecat reprehenderit aliquip est est cillum consectetur consectetur sint.",

    mkt: "Gold",
    ticker: "GLD",
    bidAsk: "Bid",
    quantity: Math.floor(Math.random() * 22),
    price: Math.floor(Math.random() * 2828),
    total: Math.floor(Math.random() * 9999),
  },
  {
    id: Math.floor(Math.random() * 9939999),
    status: "Confirmed",
    submittedDate: Date.now(),
    description:
      "Exercitation eu deserunt irure proident nisi ullamco cupidatat ad ipsum id tempor magna.",

    mkt: "Silver",
    ticker: "SLVR",
    bidAsk: "Ask",
    quantity: Math.floor(Math.random() * 22),
    price: Math.floor(Math.random() * 2828),
    total: Math.floor(Math.random() * 9999),
  },
  {
    id: Math.floor(Math.random() * 9939999),
    status: "Awaiting",
    submittedDate: Date.now(),
    description: "Amet ea minim eu culpa laborum elit sint.",
    mkt: "Platinum",
    ticker: "PLAT",
    bidAsk: "Bid",
    quantity: Math.floor(Math.random() * 22),
    price: Math.floor(Math.random() * 2828),
    total: Math.floor(Math.random() * 9999),
  },
  {
    id: Math.floor(Math.random() * 9939999),
    status: "Awaiting",
    description: "Quis nisi quis ipsum cupidatat ex.",
    submittedDate: Date.now(),
    mkt: "Gold",
    ticker: "GLD",
    bidAsk: "Bid",
    quantity: Math.floor(Math.random() * 22),
    price: Math.floor(Math.random() * 2828),
    total: Math.floor(Math.random() * 9999),
  },
  {
    id: Math.floor(Math.random() * 9939999),
    status: "Confirmed",
    submittedDate: Date.now(),
    description: "Do duis laborum ex aute proident non nisi minim.",
    mkt: "Silver",
    ticker: "SLVR",
    bidAsk: "Ask",
    quantity: Math.floor(Math.random() * 22),
    price: Math.floor(Math.random() * 2828),
    total: Math.floor(Math.random() * 9999),
  },
  {
    id: Math.floor(Math.random() * 9939999),
    status: "Awaiting",
    submittedDate: Date.now(),
    description:
      "Dolor culpa proident exercitation id nulla excepteur aute aliqua irure.",
    mkt: "Platinum",
    ticker: "PLAT",
    bidAsk: "Bid",
    quantity: Math.floor(Math.random() * 22),
    price: Math.floor(Math.random() * 2828),
    total: Math.floor(Math.random() * 9999),
  },
  {
    id: Math.floor(Math.random() * 9939999),
    status: "Awaiting",
    submittedDate: Date.now(),
    description:
      "Cupidatat ea veniam ad aute dolor aliqua mollit officia fugiat nostrud exercitation ex ut dolore.",
    mkt: "Gold",
    ticker: "GLD",
    bidAsk: "Bid",
    quantity: Math.floor(Math.random() * 22),
    price: Math.floor(Math.random() * 2828),
    total: Math.floor(Math.random() * 9999),
  },
  {
    id: Math.floor(Math.random() * 9939999),
    status: "Confirmed",
    submittedDate: Date.now(),
    description:
      "Esse minim exercitation id quis elit excepteur ex veniam Lorem ea magna officia.",
    mkt: "Silver",
    ticker: "SLVR",
    bidAsk: "Ask",
    quantity: Math.floor(Math.random() * 22),
    price: Math.floor(Math.random() * 2828),
    total: Math.floor(Math.random() * 9999),
  },
  {
    id: Math.floor(Math.random() * 9939999),
    status: "Awaiting",
    submittedDate: Date.now(),
    description:
      "Aliqua ea nostrud duis officia eu quis et non anim duis ea voluptate nulla labore.",
    mkt: "Platinum",
    ticker: "PLAT",
    bidAsk: "Bid",
    quantity: Math.floor(Math.random() * 22),
    price: Math.floor(Math.random() * 2828),
    total: Math.floor(Math.random() * 9999),
  },
];

export const sampleMockUsers = [
  {
    id: getRandomNumber(1000),
    mockUserName: "shujja",
    joinDate: Date.now(),
    reputation: getRandomNumber(100),
    transactions: getRandomNumber(1000),
    buyerTransactions: getRandomNumber(500),
    sellerTransactions: getRandomNumber(500),
    arbitrations: getRandomNumber(2),
    arbitrationsLost: getRandomNumber(1),
    arbitrationsToTransactions: getRandomNumber(1),
    opposingTradeUser: "vecna",
  },
  {
    id: getRandomNumber(1000),
    mockUserName: "eleven",
    joinDate: Date.now(),
    reputation: getRandomNumber(100),
    transactions: getRandomNumber(1000),
    buyerTransactions: getRandomNumber(500),
    sellerTransactions: getRandomNumber(500),
    arbitrations: getRandomNumber(2),
    arbitrationsLost: getRandomNumber(1),
    arbitrationsToTransactions: getRandomNumber(1),
    opposingTradeUser: "nancy",
  },
  {
    id: getRandomNumber(1000),
    mockUserName: "steve",
    joinDate: Date.now(),
    reputation: getRandomNumber(100),
    transactions: getRandomNumber(1000),
    buyerTransactions: getRandomNumber(500),
    sellerTransactions: getRandomNumber(500),
    arbitrations: getRandomNumber(2),
    arbitrationsLost: getRandomNumber(1),
    arbitrationsToTransactions: getRandomNumber(1),
    opposingTradeUser: "dwight",
  },
  {
    id: getRandomNumber(1000),
    mockUserName: "ww",
    joinDate: Date.now(),
    reputation: getRandomNumber(100),
    transactions: getRandomNumber(1000),
    buyerTransactions: getRandomNumber(500),
    sellerTransactions: getRandomNumber(500),
    arbitrations: getRandomNumber(2),
    arbitrationsLost: getRandomNumber(1),
    arbitrationsToTransactions: getRandomNumber(1),
    opposingTradeUser: "jim",
  },
  {
    id: getRandomNumber(1000),
    mockUserName: "hank",
    joinDate: Date.now(),
    reputation: getRandomNumber(100),
    transactions: getRandomNumber(1000),
    buyerTransactions: getRandomNumber(500),
    sellerTransactions: getRandomNumber(500),
    arbitrations: getRandomNumber(2),
    arbitrationsLost: getRandomNumber(1),
    arbitrationsToTransactions: getRandomNumber(1),
    opposingTradeUser: "andy_12",
  },
  {
    id: getRandomNumber(1000),
    mockUserName: "jon_snow",
    joinDate: Date.now(),
    reputation: getRandomNumber(100),
    transactions: getRandomNumber(1000),
    buyerTransactions: getRandomNumber(500),
    sellerTransactions: getRandomNumber(500),
    arbitrations: getRandomNumber(2),
    arbitrationsLost: getRandomNumber(1),
    arbitrationsToTransactions: getRandomNumber(1),
    opposingTradeUser: "snape",
  },
];
