import {
  Autocomplete,
  Box,
  Button,
  Modal,
  Paper,
  TextField,
} from "@mui/material";
import {
  DataBox,
  DataLabel,
  SubHeading,
} from "../../../components/Styled/Globals";

import Close from "@mui/icons-material/Close";
import React from "react";

export default function EditInfoModal({
  open,
  variant,
  value,
  handleSubmit,
  handleClose,
}) {
  const [data, setData] = React.useState(null);
  const handleChange = (e) => setData(e.target.value);
  React.useEffect(() => setData(value), [open, value]);

  const handleSave = () => handleSubmit(data);

  return (
    <Modal open={open} onClose={handleClose} className={"modal"}>
      <Box
        className="no-scroll-bar"
        sx={{
          width: "550px",
        }}
      >
        <Paper sx={{ p: 2 }}>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <SubHeading>
                Edit {variant === "username" ? "Username" : "Account Type"}
              </SubHeading>
              <Close
                fontSize={"small"}
                sx={{ cursor: "pointer" }}
                onClick={handleClose}
              />
            </Box>
            <Box>
              {variant === "username" ? (
                <DataBox>
                  <DataLabel>User Name</DataLabel>
                  <TextField
                    size={"small"}
                    fullWidth
                    type="text"
                    value={data}
                    name={"username"}
                    onChange={handleChange}
                    readonly={true}
                  />
                </DataBox>
              ) : (
                <DataBox>
                  <DataLabel>Account Type</DataLabel>
                  <Autocomplete
                    name={"accountType"}
                    size={"small"}
                    options={["Buyer", "Seller", "Trader"]}
                    onChange={(e, accountType) => setData(accountType)}
                    value={data}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size={"small"}
                        type="text"
                        value={data}
                        fullWidth
                      />
                    )}
                  />
                </DataBox>
              )}
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                mt: 2,
              }}
            >
              <Button
                disableElevation
                variant="contained"
                color="primary"
                onClick={handleSave}
                sx={{ width: "25%" }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
}
