import React from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import workingImg from "../../assets/homepage/working.png";
import { StyledContainer } from "../Styled/Globals";
import ImageModal from "../CommonModals/ImageModal";

const WorkingGrid = ({ setOpenSignUp }) => {
  const [openImg, setOpenImg] = React.useState(false);

  return (
    <Box marginBottom={5} className="blue-bg">
      <ImageModal
        open={openImg}
        handleClose={(_) => setOpenImg(false)}
        src={workingImg}
        alt={"how it works infography"}
      />
      <StyledContainer>
        <Typography
          variant={"h4"}
          component={"h4"}
          sx={(theme) => ({
            fontSize: 33,
            fontWeight: "bold",
            width: "fit-content",
            margin: "auto",
            pt: 2,
            color: "#fff",
            [theme.breakpoints.up("xl")]: {
              fontSize: 40,
            },
          })}
        >
          How it Works
          <Divider
            sx={(theme) => ({
              borderColor: "#fff",
              blockSize: "fit-content",
              margin: "10px auto",
              [theme.breakpoints.up("xl")]: {
                margin: "15px auto",
              },
            })}
          />
        </Typography>

        <img
          src={workingImg}
          alt="how it works"
          width={"80%"}
          className="center-block pointer"
          onClick={(_) => setOpenImg(true)}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
          paddingTop={2}
        >
          <Box
            width={"50%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent="flex-end"
            gap={5}
          >
            <Link
              to="/howitworks"
              className="body-link"
              style={{
                textAlign: "center",
              }}
            >
              Learn More
            </Link>
            <Button
              variant={"contained"}
              color={"primary"}
              style={{
                textTransform: "none",
                fontWeight: "bold",
                color: "#fff",
                width: 350,
                display: "block",
                height: "50px",
              }}
              disableElevation
              onClick={() => setOpenSignUp(true)}
            >
              Sign Up
            </Button>
          </Box>
        </Box>
      </StyledContainer>
    </Box>
  );
};

export default WorkingGrid;
