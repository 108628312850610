import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  styled,
  Typography,
} from "@mui/material";
import moment from "moment";
import TradeDetailsModal from "../CommonModals/TradeDetailsModal/TradeDetailsModal";

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            sx={{
              padding: "0px 0px",
              paddingLeft: index === 0 && 2,
              width: headCell?.width || 100,
              verticalAlign: "bottom",
              textAlign: "left",
              background: headCell?.id === "action" && "#5f99ff",
              borderTopRightRadius: 5,
            }}
          >
            <Box
              component={"p"}
              sx={(theme) => ({
                fontSize: "medium",
                padding: "0px 0px",
                display: "flex",
                lineHeight: 1.5,
                width: "100%",
                justifyContent: "flex-start",
                columnGap: 0.3,
                color: theme?.palette?.primary?.dark,
                [theme.breakpoints.up("xl")]: {
                  fontSize: "medium",
                },
                ...headCell?.sx,
              })}
            >
              {headCell.label}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function SingleOpenOrderTable({ data, loading }) {
  const StyledTableCell = styled(TableCell)(({ theme, center }) => ({
    padding: "10px 0px",
    paddingRight: "5px",
    fontSize: "14px",
    textAlign: center ? "center" : "left",
    border: "none",
    [theme.breakpoints.up("xl")]: {
      fontSize: "16px",
    },
  }));

  const [openDetails, setOpenDetails] = React.useState(false);
  const handleDetailsModal = (_) => setOpenDetails(true);
  const handleDetailsModalClose = (_) => setOpenDetails(false);
  return (
    <Box sx={{ width: "100%" }}>
      <TradeDetailsModal
        open={openDetails}
        handleClose={handleDetailsModalClose}
        data={data}
      />
      <Paper sx={{ width: "100%", border: "1px solid #c4c4c4" }} elevation={0}>
        <Box display="flex" justifyContent={"center"} alignContent={"center"}>
          {loading ? (
            <CircularProgress />
          ) : (
            <TableContainer>
              <Table sx={{ minWidth: 750 }} size={"small"}>
                <EnhancedTableHead headCells={headCells} />
                <TableBody>
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={data?.transactionNumber}
                  >
                    <StyledTableCell
                      component="td"
                      scope="row"
                      sx={{
                        pl: 2,
                      }}
                    >
                      {data?.orderNumber}
                    </StyledTableCell>

                    <StyledTableCell component="td" scope="row" padding="none">
                      {data?.mkt}
                    </StyledTableCell>
                    <StyledTableCell component="td" scope="row">
                      {data?.ticker}
                    </StyledTableCell>
                    <StyledTableCell component="td" scope="row">
                      {data?.bidAsk}
                    </StyledTableCell>
                    <StyledTableCell component="td" scope="row">
                      {data?.description}
                    </StyledTableCell>
                    <StyledTableCell component="td" scope="row">
                      {data?.quantity}
                    </StyledTableCell>
                    <StyledTableCell component="td" scope="row">
                      ${data?.price}
                    </StyledTableCell>
                    <StyledTableCell component="td" scope="row">
                      ${data?.total}
                    </StyledTableCell>
                    <StyledTableCell
                      component="td"
                      scope="row"
                      sx={{
                        pr: 2,
                      }}
                    >
                      <Typography
                        sx={(theme) => ({
                          color: theme.palette.primary.main,
                          fontWeight: "500",
                          textDecoration: "underline",
                          cursor: "pointer",
                        })}
                        onClick={handleDetailsModal}
                      >
                        View
                      </Typography>
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Paper>
    </Box>
  );
}

const headCells = [
  {
    id: "orderNumber",
    numeric: true,
    label: "Order#",
    width: "16%",
  },

  {
    id: "mkt",
    numeric: false,
    label: "MKT",
    width: "8%",
  },
  {
    id: "ticker",
    numeric: false,
    label: "Ticker",
    width: "8%",
  },
  {
    id: "bidAsk",
    numeric: false,
    label: "Bid/Ask",
    width: "8%",
  },
  {
    id: "description",
    numeric: false,
    label: "Description",
    width: "30%",
  },
  {
    id: "quantity",
    numeric: true,
    label: "Quantity",
    width: "10%",
  },
  {
    id: "price",
    numeric: true,
    label: "Price",
    width: "10%",
  },
  {
    id: "total",
    numeric: false,
    label: "Total",
    width: "10%",
  },
  {
    id: "detail",
    numeric: false,
    label: "Detail",
    width: "10%",
    sx: { pr: 2 },
  },
];
